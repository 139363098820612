export function getSystemLabel(system) {
    switch (system) {
        case "aposoft":
            return "aposoft";
        case "adg_s3000":
            return "ADG S3000";
        case "adg_a3000":
            return "ADG A3000";
        case "awinta_prokras":
            return "Awinta Prokas";
        case "awinta_one":
            return "Awinta One";
        case "pharmatechnik":
            return "Pharmatechnik IXOS";
        case "cgm_lauer":
            return "CGM Lauer";
        case "others":
            return "Andere";
        case "deos_secret":
            return "Deos Secret";
        default:
            return "Unbekannt";
    }
}
