import { useEffect, useState } from "react";
import firebase from "../config";
import { toYYYYMMDD } from "../utility/formatDate";
import "firebase/functions";
export default function useAnalyticsFunction(start, end, selected, ready, category // Exclusively cache-breaker for categories that use the same histogram type
) {
    var _a = useState(null), data = _a[0], setData = _a[1];
    useEffect(function () {
        var _a;
        if (ready !== null && ready !== void 0 ? ready : true) {
            var generateSelectedHistograms = firebase
                .app()
                .functions("europe-west3")
                .httpsCallable('generateSelectedHistograms');
            generateSelectedHistograms({ startDate: toYYYYMMDD(start), endDate: toYYYYMMDD(end), selected: (_a = {}, _a[selected] = true, _a) })
                .then(function (result) {
                // Read result of the Cloud Function.
                setData(result.data);
            });
        }
    }, [start, end, selected, ready, category]);
    return data;
}
