var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useEffect, useMemo } from "react";
import { Button, SelectMenu } from "evergreen-ui";
import { useState } from "react";
import { UserContext } from "../contexts/UserContext";
export default function TagSelectMenu(_a) {
    var onChange = _a.onChange, value = _a.value;
    var user = useContext(UserContext).user;
    var isUser = user[0];
    var tags = useMemo(function () { return ((isUser === null || isUser === void 0 ? void 0 : isUser.tags) ? Array.from(new Set(isUser === null || isUser === void 0 ? void 0 : isUser.tags.concat(value))) : []); }, [isUser === null || isUser === void 0 ? void 0 : isUser.tags, value]);
    var _b = useState(value !== null && value !== void 0 ? value : []), selectedItemsState = _b[0], setSelectedItems = _b[1];
    var _c = useState((value === null || value === void 0 ? void 0 : value.length) === 1
        ? value[0]
        : (value === null || value === void 0 ? void 0 : value.length) > 1
            ? value.length + " ausgewählt..."
            : null), selectedItemNamesState = _c[0], setSelectedItemNames = _c[1];
    useEffect(function () {
        if (onChange) {
            onChange(selectedItemsState);
        }
    }, [onChange, selectedItemsState]);
    return (_jsx(SelectMenu, __assign({ isMultiSelect: true, title: "W\u00E4hle mehrere Tags", options: tags.map(function (label) { return ({ label: label, value: label }); }), selected: selectedItemsState, onSelect: function (item) {
            var selected = __spreadArray(__spreadArray([], selectedItemsState, true), [item.value], false);
            var selectedItems = selected;
            var selectedItemsLength = selectedItems.length;
            var selectedNames = "";
            if (selectedItemsLength === 0) {
                selectedNames = "";
            }
            else if (selectedItemsLength === 1) {
                selectedNames = selectedItems.toString();
            }
            else if (selectedItemsLength > 1) {
                selectedNames = selectedItemsLength.toString() + " ausgewählt...";
            }
            setSelectedItems(selectedItems);
            setSelectedItemNames(selectedNames);
        }, onDeselect: function (item) {
            var deselectedItemIndex = selectedItemsState.indexOf(item.value);
            var selectedItems = selectedItemsState.filter(function (_item, i) { return i !== deselectedItemIndex; });
            var selectedItemsLength = selectedItems.length;
            var selectedNames = "";
            if (selectedItemsLength === 0) {
                selectedNames = "";
            }
            else if (selectedItemsLength === 1) {
                selectedNames = selectedItems.toString();
            }
            else if (selectedItemsLength > 1) {
                selectedNames = selectedItemsLength.toString() + " ausgewählt...";
            }
            setSelectedItems(selectedItems);
            setSelectedItemNames(selectedNames);
        } }, { children: _jsx(Button, { children: selectedItemNamesState || "Tags anwählen..." }) })));
}
