var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Pane } from "evergreen-ui";
import React from "react";
export default function SignatureViewer(_a) {
    var image = _a.image, style = _a.style;
    return (_jsx("img", { style: __assign({ boxShadow: "0px 0px 5px rgba(0,0,0,0.2)", margin: 10, width: "10%" }, (style && style)), src: image }));
}
