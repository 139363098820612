var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { toaster } from "evergreen-ui";
import React, { useEffect, useState } from "react";
import { PlanNames, PlanRank } from "../types/Plans";
import { usePlanInfo } from "../utility/use-plan-info";
export default function withPaywall(
// Component, the paywall should apply to
WrappedComponent, 
// The minimum plan that is required
minimumPlan, 
// A custom component to show instead of the WrappedComponent when paywalled
AlternativeComponent, 
// A function to selectively override the default check, if the default check returned false.
// This is useful, if a feature is included in a higher plan, but may also be purchased independently as an add-on
exceptionCheck) {
    return function PaywallWrapper(props) {
        // Boolean, whether access is allowed. Undefined, if not yet determined
        var _a = useState(), state = _a[0], setState = _a[1];
        var currentPlan = usePlanInfo().currentPlan;
        useEffect(function () {
            setState(shouldDisplayWithPlan(minimumPlan, currentPlan, exceptionCheck));
        }, [currentPlan]);
        switch (state) {
            case true:
                return _jsx(WrappedComponent, __assign({}, props));
            case false:
                return AlternativeComponent ? (_jsx(AlternativeComponent, __assign({}, props))) : (_jsxs("p", { children: ["Dieses Feature ist ab dem Plan ", PlanNames[minimumPlan], " verf\u00FCgbar."] }));
            default:
                // ToDo: Add a loader that is compatible with all sizes
                return null;
        }
    };
}
export function shouldDisplayWithPlan(minimumPlan, currentPlan, exceptionCheck) {
    if (!currentPlan) {
        // No valid plan was resolved yet, so reflect that in the state
        return undefined;
    }
    console.log(currentPlan);
    if (!PlanRank.includes(currentPlan)) {
        // A plan was resolved, but it is not valid, so inform the user to contact us and log.
        toaster.warning("Dein aktueller Plan konnte nicht ermittelt werden. Bitte wende dich an den apomap Support,\n         um alle Features deines Abonnements nutzen zu k\u00F6nnen.");
        console.warn("Paywall resolved plan", currentPlan);
        return false;
    }
    var minRankIndex = PlanRank.indexOf(minimumPlan);
    var rankIndex = PlanRank.indexOf(currentPlan);
    if (minRankIndex <= rankIndex) {
        // The plan clears the bar by default, so set this directly
        return true;
    }
    if (exceptionCheck) {
        // If a custom exception check is defined, run it as the last resort
        return exceptionCheck(currentPlan);
    }
    // No more options here, so we reject and paywall the user
    return false;
}
