var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
//Universal (customer/business) component for choosing
//an available date for a call
import React, { useState, useEffect } from "react";
import firebase from "../config";
import "firebase/auth";
import moment from "moment";
import { IconButton, TrashIcon, toaster, Text, SelectMenu, Button, MobileVideoIcon, } from "evergreen-ui";
require("firebase/firestore");
//database reference
var db = firebase.firestore();
export default function CallDateSelect(props) {
    //is data loading
    var _a = useState(false), isLoading = _a[0], setLoading = _a[1];
    //all available dates of business
    var _b = useState([]), allDates = _b[0], setAllDates = _b[1];
    useEffect(function () {
        getDates();
    }, []);
    //gets all available dates of business
    function getDates() {
        //set loading
        setLoading(true);
        db.collection("apos_on_demand")
            .doc(props.eMail)
            .get()
            .then(function (snap) {
            var data = snap.data();
            //RawDateArray is for shiftDate to find the date in array
            //to shift choosen date to booked
            var dateObject = [];
            //If availableCalls are available
            if (data.availableCallDates) {
                props.setRawAvailableDateArray(data.availableCallDates);
                //filters out all past dates
                var filteredDates = data.availableCallDates.filter(function (date) { return moment(date.seconds * 1000).diff(moment()) >= 0; });
                //creats an label / value object for every date
                //for ui selcetmenu purpose
                dateObject = filteredDates.map(function (date) {
                    var value = new Date(date.seconds * 1000);
                    var label = moment(value).format("DD.MM HH:mm") + " Uhr";
                    return { value: value, label: label };
                });
                //If field is defined but no available dates set this
                if (dateObject.length === 0) {
                    dateObject.push({
                        value: undefined,
                        label: "Kein Termin verfügbar",
                    });
                }
            }
            else {
                //Else set no available dates
                props.setRawAvailableDateArray([]);
                dateObject.push({ value: undefined, label: "Kein Termin verfügbar" });
            }
            //If blookedCalls are available
            if (data.bookedCallDates) {
                props.setRawBookedDateArray(data.bookedCallDates);
            }
            else {
                var array = [];
                //Else set no available dates
                props.setRawBookedDateArray(array);
            }
            //set all data
            setAllDates(dateObject);
            setLoading(false);
        })
            .catch(function (error) {
            toaster.danger("Ein Fehler ist beim Laden der Termine aufgetreten");
        });
    }
    return (_jsxs("div", __assign({ style: { display: "flex", flexDirection: "column" } }, { children: [_jsx(Text, __assign({ marginBottom: 5 }, { children: props.selectedDate.value
                    ? "Call geplant am: " + props.selectedDate.label
                    : "Kein Video-Call geplant" })), _jsxs("div", __assign({ style: { display: "flex", flexDirection: "row", alignItems: "center" } }, { children: [_jsx(SelectMenu, __assign({ title: "Termin w\u00E4hlen", options: allDates, selected: props.selectedDate, onSelect: function (item) { return props.setSelectedDate(item); } }, { children: _jsx(Button, __assign({ isLoading: isLoading, marginTop: 5, iconBefore: MobileVideoIcon, marginRight: 16, appearance: "primary", intent: props.selectedDate.value ? "success" : "none" }, { children: props.selectedDate.value ? "Termin gewählt" : "Anruf planen" })) })), props.selectedDate.value ? (_jsx(IconButton, { onClick: function () {
                            return props.setSelectedDate({ label: "Termin", value: undefined });
                        }, icon: TrashIcon, intent: "danger" })) : (_jsx(Text, {}))] }))] })));
}
