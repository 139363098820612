var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Badge } from 'evergreen-ui';
export default function getBadgeFromActionType(type, amount) {
    var badge;
    switch (type) {
        case "start":
            badge = _jsx(Badge, __assign({ style: { fontFamily: "regular", fontSize: "0.9em" }, color: "#F8E3DA" }, { children: "Tourstart" }));
            break;
        case "end":
            badge = _jsx(Badge, __assign({ style: { fontFamily: "regular", fontSize: "0.9em" }, color: "#F8E3DA" }, { children: "Tourende" }));
            break;
        case "pickupShipment":
            badge = _jsx(Badge, __assign({ style: { fontFamily: "regular", fontSize: "0.9em" }, color: "#F8E3DA" }, { children: amount > 1 ? "".concat(amount, " Mitnahmen am Standort") : "Mitnahme an Standort" }));
            break;
        case "deliverShipment":
            badge = _jsx(Badge, __assign({ style: { fontFamily: "regular", fontSize: "0.9em" }, color: "#F8E3DA" }, { children: amount > 1 ? "".concat(amount, " Abgaben am Standort") : "Abgabe an Standort" }));
            break;
        default:
            badge = _jsx(Badge, __assign({ style: { fontFamily: "regular", fontSize: "0.9em" }, color: "#F8E3DA" }, { children: "Unbekannt" }));
            break;
    }
    return badge;
}
