import { jsx as _jsx } from "react/jsx-runtime";
//Manages the access controll on routes
//Depending of the user state (loggedin/not loggedin) shows different switches
//Switches are
//OpenRoutes -> all Routes accessable for not logged in users
//ProtectedRoutes => all Routes just accessable for loggedin users
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/UserContext";
import { BrowserRouter as Router } from "react-router-dom";
import Loading from "../routes/Loading";
import ProtectedRoutes from "./ProtectedRoutes";
import OpenRoutes from "./OpenRoutes";
import firebase from "../config";
import "firebase/auth";
export default function Routing() {
    //Get user und ready state form AuthProvider
    var ready = useContext(UserContext).ready;
    var _a = useState(undefined), user = _a[0], setUser = _a[1];
    var isReady = ready[0];
    useEffect(function () {
        firebase.auth().onAuthStateChanged(function (user) {
            setUser(user);
        });
    }, []);
    //If Not ready show loading screen
    if (!isReady) {
        return _jsx(Loading, {});
    }
    else {
        //State of User depends on accessable routes
        return _jsx(Router, { children: user ? _jsx(ProtectedRoutes, {}) : _jsx(OpenRoutes, {}) });
    }
}
