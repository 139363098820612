var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { toaster } from "evergreen-ui";
import "firebase/firestore";
import moment from "moment";
import firebase from "../config";
export var optimizeTour = function (tour) { return __awaiter(void 0, void 0, void 0, function () {
    var getLocationCoord, locationCoords;
    return __generator(this, function (_a) {
        getLocationCoord = function (tour) {
            var coords = [];
            tour.startPoint.map(function (loc) { return coords.push(loc.lon, loc.lat); });
            return coords;
        };
        locationCoords = getLocationCoord(tour);
        new Promise(function (res, rej) { return __awaiter(void 0, void 0, void 0, function () {
            var allTaskIdsFromTour, addedTasksForTour, allTasksForTour, startAction, endAction, allTasks, taskData, timeWindowPast, vehicles, startTime, prepareDataForOptimization;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        allTaskIdsFromTour = tour.activities.flatMap(function (task) {
                            return task.type === "deliverShipment" ? task.id : [];
                        });
                        addedTasksForTour = __spreadArray(__spreadArray([], allTaskIdsFromTour, true), ((_a = tour.tasksToAdd) !== null && _a !== void 0 ? _a : []), true);
                        allTasksForTour = addedTasksForTour === null || addedTasksForTour === void 0 ? void 0 : addedTasksForTour.filter(function (taskId) { var _a; return !((_a = tour === null || tour === void 0 ? void 0 : tour.tasksToRemove) === null || _a === void 0 ? void 0 : _a.includes(taskId)); });
                        //Check if empty, then prompt a deletion
                        if (allTasksForTour.length === 0) {
                            toaster.notify("Bitte löschen Sie diese Tour! Es sind keine Aufträge mehr in dieser Tour enthalten.");
                            rej();
                            return [2 /*return*/];
                        }
                        startAction = tour.activities.find(function (activity) { return activity.type === "start"; });
                        endAction = tour.activities.find(function (activity) { return activity.type === "end"; });
                        return [4 /*yield*/, Promise.all(allTasksForTour.map(function (taskId) {
                                return firebase.firestore().collection("tasks").doc(taskId).get();
                            }))];
                    case 1:
                        allTasks = _b.sent();
                        taskData = allTasks.map(function (task) { return task.data(); });
                        timeWindowPast = taskData.filter(function (task) {
                            return task.task_latest &&
                                moment(task.task_latest, "HH:mm").isBefore(moment(tour.startTime, "HH:mm"));
                        });
                        if (timeWindowPast.length > 0) {
                            timeWindowPast.map(function (task) {
                                return toaster.warning("Zeitfenster des Auftrages ".concat(task.customer_name, " in der Vergangenheit!"));
                            });
                            rej();
                            return [2 /*return*/];
                        }
                        vehicles = [
                            {
                                driver: {
                                    id: tour.driver,
                                },
                                startLocation: [startAction.address.lon, startAction.address.lat],
                                startLocationId: startAction.location_id.split("_")[1],
                                endLocation: [endAction.address.lon, endAction.address.lat],
                                endLocationId: endAction.location_id.split("_")[1],
                            },
                        ];
                        startTime = moment(tour.date + " " + tour.startTime, "YY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm");
                        firebase
                            .firestore()
                            .collection("customerPlans")
                            .doc(firebase.auth().currentUser.uid)
                            .update({
                            ongoingOptimizations: firebase.firestore.FieldValue.increment(1),
                            ongoingOptimizationsSet: firebase.firestore.Timestamp.now().toMillis() + 5000,
                        });
                        prepareDataForOptimization = firebase
                            .app()
                            .functions("europe-west3")
                            .httpsCallable("prepareDataForOptimization");
                        prepareDataForOptimization({
                            taskIds: allTasksForTour,
                            vehicles: vehicles,
                            startTime: startTime,
                            tourId: tour.id,
                            centralDeliveryStartPoint: tour.centralDelivery
                                ? { coords: locationCoords }
                                : null,
                        })
                            .then(function (result) {
                            res(result);
                        })
                            .catch(function (err) {
                            rej(err);
                        });
                        return [2 /*return*/];
                }
            });
        }); });
        return [2 /*return*/];
    });
}); };
