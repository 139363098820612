var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Pane } from "evergreen-ui";
import React, { useContext } from "react";
import { PaymentContext } from "../../contexts/PaymentContext";
import { usePlan } from "../../hooks/usePlan";
export var AddonPaymentBlocker = function (_a) {
    var children = _a.children;
    var plan = usePlan();
    //PaymentDialog
    //@ts-ignore
    var _b = useContext(PaymentContext), payMethodeManagementState = _b.payMethodeManagementState, payMethodeManagementLayout = _b.payMethodeManagementLayout;
    var setPaymentMethodeManagement = payMethodeManagementState[1];
    var setPaymentMethodeLayout = payMethodeManagementLayout[1];
    if (plan === null || plan === void 0 ? void 0 : plan.isPaymentSet)
        return _jsx(_Fragment, { children: children });
    return (_jsx(_Fragment, { children: _jsxs(Pane, __assign({ elevation: 1, margin: 8, padding: 8, borderRadius: 6 }, { children: [_jsx(Pane, __assign({ pointerEvents: "none", cursor: "wait", opacity: 0.4 }, { children: children })), _jsx(Button, __assign({ onClick: function () {
                        setPaymentMethodeManagement(true);
                        setPaymentMethodeLayout(2);
                    }, size: "large", intent: "success", appearance: "primary" }, { children: "Zahlungsmethode hinterlegen" }))] })) }));
};
