export function getLabelFromType(type) {
    switch (type) {
        case "delivery":
            return "Lieferung";
        case "pickup":
            return "Abholung";
        default:
            return "Lieferung";
    }
}
