var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Heading, Pane, Popover, ResetIcon } from "evergreen-ui";
import React, { memo, useEffect, useRef, useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { toDDMMYYYY } from "../../utility/formatDate";
import moment from "moment";
import ignoreTime from "../../utility/ignoreTime";
var DateRangePicker = memo(function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var _l = useState((_b = (_a = props.initialState) === null || _a === void 0 ? void 0 : _a.start) !== null && _b !== void 0 ? _b : undefined), startDate = _l[0], setStartDate = _l[1];
    var _m = useState((_d = (_c = props.initialState) === null || _c === void 0 ? void 0 : _c.end) !== null && _d !== void 0 ? _d : undefined), endDate = _m[0], setEndDate = _m[1];
    var _o = useState(""), displayText = _o[0], setDisplayText = _o[1];
    var lastSavedStart = useRef((_f = (_e = props.initialState) === null || _e === void 0 ? void 0 : _e.start) !== null && _f !== void 0 ? _f : undefined);
    var lastSavedEnd = useRef((_h = (_g = props.initialState) === null || _g === void 0 ? void 0 : _g.end) !== null && _h !== void 0 ? _h : undefined);
    useEffect(function () {
        if (startDate &&
            endDate &&
            ignoreTime(startDate).toISOString() ===
                ignoreTime(endDate).toISOString()) {
            setDisplayText(toDDMMYYYY(startDate));
        }
        else if (startDate && endDate) {
            setDisplayText("".concat(toDDMMYYYY(startDate), " - ").concat(toDDMMYYYY(endDate)));
        }
        else if (startDate) {
            setDisplayText("ab dem ".concat(toDDMMYYYY(startDate)));
        }
        else if (endDate) {
            setDisplayText("bis zum ".concat(toDDMMYYYY(endDate)));
        }
        else {
            setDisplayText("Zeitspanne eingrenzen");
        }
    }, [startDate, endDate]);
    var normalizedInitial = [
        ignoreTime((_j = props.initialState.start) !== null && _j !== void 0 ? _j : new Date()).toISOString(),
        ignoreTime((_k = props.initialState.end) !== null && _k !== void 0 ? _k : new Date()).toISOString(),
    ];
    useEffect(function () {
        props.onChange(startDate, endDate);
    }, __spreadArray([], normalizedInitial, true));
    var reset = function () {
        setStartDate(undefined);
        setEndDate(undefined);
        lastSavedStart.current = undefined;
        lastSavedEnd.current = undefined;
        props.onChange(undefined, undefined);
    };
    return (_jsxs("div", __assign({ style: { display: "flex", flexDirection: "row", gap: "4px" } }, { children: [_jsx(Popover, __assign({ onClose: function () {
                    console.log("Closing");
                    setStartDate(lastSavedStart.current);
                    setEndDate(lastSavedEnd.current);
                }, content: function (_a) {
                    var close = _a.close;
                    return (_jsx(Pane, __assign({ width: 935, height: 390, paddingX: 0, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }, { children: _jsxs("div", __assign({ style: { display: "flex", width: "100%" } }, { children: [_jsxs("div", __assign({ style: { flex: 3 } }, { children: [_jsx("div", __assign({ style: {
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                            } }, { children: _jsx(Heading, __assign({ size: 200, style: {
                                                    paddingLeft: 24,
                                                    marginTop: 10,
                                                    marginBottom: -24,
                                                } }, { children: "Von" })) })), _jsx(DayPicker, { mode: "single", selected: startDate, onSelect: setStartDate })] })), _jsxs("div", __assign({ style: { flex: 3 } }, { children: [_jsx(Heading, __assign({ size: 200, style: {
                                                paddingLeft: 24,
                                                marginTop: 10,
                                                marginBottom: -24,
                                            } }, { children: "Bis" })), _jsx(DayPicker, { mode: "single", selected: endDate, onSelect: setEndDate })] })), _jsxs("div", __assign({ style: {
                                        flex: 2,
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                        display: "flex",
                                        flexDirection: "column",
                                    } }, { children: [_jsx(Heading, __assign({ size: 200, style: { marginTop: 10, marginBottom: 10 } }, { children: "Zeitraum" })), _jsxs("div", __assign({ style: {
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 12,
                                                flex: 1,
                                            } }, { children: [_jsxs("div", __assign({ style: { flex: 1 } }, { children: [_jsx(Button, __assign({ justifyContent: "left", appearance: "minimal", style: { width: "100%" }, onClick: function () {
                                                                setStartDate(moment().toDate());
                                                                setEndDate(moment().toDate());
                                                            } }, { children: "Heute" })), _jsx(Button, __assign({ justifyContent: "left", appearance: "minimal", style: { width: "100%" }, onClick: function () {
                                                                setStartDate(moment().subtract(1, "day").toDate());
                                                                setEndDate(moment().subtract(1, "day").toDate());
                                                            } }, { children: "Gestern" })), _jsx(Button, __assign({ justifyContent: "left", appearance: "minimal", style: { width: "100%" }, onClick: function () {
                                                                setStartDate(moment().set("weekday", 0).toDate());
                                                                setEndDate(moment().set("weekday", 6).toDate());
                                                            } }, { children: "Diese Woche" })), _jsx(Button, __assign({ justifyContent: "left", appearance: "minimal", style: { width: "100%" }, onClick: function () {
                                                                setStartDate(moment()
                                                                    .subtract(1, "week")
                                                                    .set("weekday", 0)
                                                                    .toDate());
                                                                setEndDate(moment()
                                                                    .subtract(1, "week")
                                                                    .set("weekday", 6)
                                                                    .toDate());
                                                            } }, { children: "Letzte Woche" })), _jsx(Button, __assign({ justifyContent: "left", appearance: "minimal", style: { width: "100%" }, onClick: function () {
                                                                setStartDate(moment().set("date", 1).toDate());
                                                                setEndDate(moment()
                                                                    .add(1, "month")
                                                                    .set("date", 1)
                                                                    .subtract(1, "day")
                                                                    .toDate());
                                                            } }, { children: "Dieser Monat" })), _jsx(Button, __assign({ justifyContent: "left", appearance: "minimal", style: { width: "100%" }, onClick: function () {
                                                                setStartDate(moment()
                                                                    .subtract(1, "month")
                                                                    .set("date", 1)
                                                                    .toDate());
                                                                setEndDate(moment().set("date", 1).subtract(1, "day").toDate());
                                                            } }, { children: "Letzter Monat" })), _jsx(Button, __assign({ justifyContent: "left", appearance: "minimal", style: { width: "100%" }, onClick: function () {
                                                                setStartDate(moment().set("month", 0).set("date", 1).toDate());
                                                                setEndDate(moment().set("month", 11).set("date", 31).toDate());
                                                            } }, { children: "Dieses Jahr" })), _jsx(Button, __assign({ justifyContent: "left", appearance: "minimal", style: { width: "100%" }, onClick: function () {
                                                                setStartDate(moment()
                                                                    .subtract(1, "year")
                                                                    .set("month", 0)
                                                                    .set("date", 1)
                                                                    .toDate());
                                                                setEndDate(moment()
                                                                    .subtract(1, "year")
                                                                    .set("month", 11)
                                                                    .set("date", 31)
                                                                    .toDate());
                                                            } }, { children: "Letztes Jahr" }))] })), _jsx(Button, __assign({ appearance: "primary", width: "100%", onClick: function () {
                                                        lastSavedStart.current = startDate;
                                                        lastSavedEnd.current = endDate;
                                                        if (startDate &&
                                                            endDate &&
                                                            startDate.getTime() > endDate.getTime()) {
                                                            lastSavedEnd.current = startDate;
                                                            lastSavedStart.current = endDate;
                                                        }
                                                        props.onChange(startDate, endDate);
                                                        close();
                                                    } }, { children: "Ok" }))] }))] }))] })) })));
                } }, { children: _jsx(Button, __assign({ style: { width: "100%" } }, { children: displayText })) })), _jsx(Button, __assign({ onClick: reset }, { children: _jsx(ResetIcon, {}) }))] })));
}, function (prevProps, nextProps) {
    return prevProps.onChange === nextProps.onChange;
});
export default DateRangePicker;
