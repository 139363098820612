var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defaultTheme } from "evergreen-ui";
var intentColors = {
    none: "#2F2E41",
    info: "grey",
    success: "#49BEEF",
    warning: "orange",
    danger: "#DD3B30",
};
export var getButtonColor = function (theme, props) {
    return intentColors[props.intent || "none"] || intentColors.none;
};
var theme = __assign(__assign({}, defaultTheme), { colors: __assign(__assign({}, defaultTheme.colors), { text: {
            success: "red",
        } }), fontFamilies: __assign(__assign({}, defaultTheme.fontFamilies), { ui: '"Montserrat",' + defaultTheme.fontFamilies.ui }), fontSizes: __assign(__assign({}, defaultTheme.fontSizes), { 2: "13px", body: "13px" }), components: __assign(__assign({}, defaultTheme.components), { TextInputField: __assign({}, defaultTheme.components.TextInputField), Button: __assign(__assign({}, defaultTheme.components.Button), { spinnerColor: "hotpink", sizes: __assign(__assign({}, defaultTheme.components.Button.sizes), { regular: __assign(__assign({}, defaultTheme.components.Button.sizes.medium), { height: 32, borderRadius: 16 }), large: {
                    height: 45,
                    lineHeight: "45px",
                    minWidth: 32,
                    paddingLeft: 32,
                    paddingRight: 32,
                    fontWeight: "fontWeights.bold",
                    textTransform: "uppercase",
                    borderRadius: 22, //(theme, props)=>(console.log(props), 22),
                } }), baseStyle: __assign(__assign({}, defaultTheme.components.Button.baseStyle), { borderRadius: 0 }), appearances: __assign(__assign({}, defaultTheme.components.Button.appearances), { default: __assign(__assign({}, defaultTheme.components.Button.appearances.default), { color: getButtonColor, border: function (theme, props) {
                        switch (props.size) {
                            case "large":
                                return "2px solid ".concat(getButtonColor(theme, props));
                            case "regular":
                                return "1px solid ".concat(getButtonColor(theme, props));
                            default:
                                return defaultTheme.components.Button.appearances.default.border(theme, props);
                        }
                    } }), primary: __assign(__assign({}, defaultTheme.components.Button.appearances.primary), { color: "white", background: getButtonColor, border: function (theme, props) { return "2px solid ".concat(getButtonColor(theme, props)); }, _hover: {
                        color: "white",
                        backgroundColor: "#49beef",
                    } }), primaryWhite: __assign(__assign({}, defaultTheme.components.Button.appearances.primary), { color: "white", background: getButtonColor, textDecoration: "none", border: function (theme, props) { return "2px solid ".concat(getButtonColor(theme, props)); }, _hover: {
                        color: getButtonColor,
                        backgroundColor: "#ffff",
                    } }), segment: __assign(__assign({}, defaultTheme.components.Button.appearances.default), { height: 45, fontSize: 15, flex: 1 }) }) }), Heading: __assign(__assign({}, defaultTheme.components.Heading), { baseStyle: __assign(__assign({}, defaultTheme.components.Heading.baseStyle), { color: "#49beef", fontFamily: "Montserrat", fontWeight: 700 }), sizes: __assign(__assign({}, defaultTheme.components.Heading.sizes), { large: {
                    fontSize: 20,
                }, medium: {
                    fontSize: 18,
                } }) }), Input: __assign(__assign({}, defaultTheme.components.Input), { baseStyle: __assign(__assign({}, defaultTheme.components.Input.baseStyle), { borderRadius: 0 }), sizes: __assign(__assign({}, defaultTheme.components.Input.sizes), { large: {
                    minHeight: 45,
                    fontSize: "13px",
                } }) }), Select: __assign(__assign({}, defaultTheme.components.Select), { baseStyle: __assign(__assign({}, defaultTheme.components.Select.baseStyle), { borderRadius: 0, background: "white" }) }), DialogHeader: {
            baseStyle: {
                padding: 22,
            },
        }, DialogFooter: {
            baseStyle: {
                "&>*": { width: "100%" },
                padding: 22,
            },
        }, Badge: __assign(__assign({}, defaultTheme.components.Badge), { baseStyle: __assign(__assign({}, defaultTheme.components.Badge.baseStyle), { textTransform: "none", padding: 4, paddingLeft: 8, paddingRight: 8, height: 23, fontSize: 10 }) }) }) });
export default theme;
