var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Heading, Pane, Paragraph } from "evergreen-ui";
import logo from "../assets/images/logo.png";
export var WelcomeVideo = function (_a) {
    var setShowWelcomeVideo = _a.setShowWelcomeVideo;
    var handleClick = function () {
        setShowWelcomeVideo(false);
        window.location.reload();
    };
    return (_jsxs(Pane, __assign({ elevation: 2, style: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            // height: 400,
            padding: 20,
            width: 600,
            borderRadius: 15,
            boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
        }, backgroundColor: "white" }, { children: [_jsx(Pane, __assign({ style: {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: 4,
                } }, { children: _jsx("img", { src: logo, style: { height: 24 }, alt: "apomap-logo" }) })), _jsxs(Pane, __assign({ style: { textAlign: "center", padding: "2rem" } }, { children: [_jsx(Heading, __assign({ className: "headline", marginBottom: 10 }, { children: "Jetzt geht es los \uD83D\uDE0D" })), _jsx(Paragraph, __assign({ className: "bodytext" }, { children: "Damit du dich zurecht findest haben wir dir ein kurzes Video aufgenommen." }))] })), _jsx(Pane, __assign({ style: {
                    width: "100%",
                    height: 350,
                    borderRadius: "15px",
                    border: "none",
                    marginBottom: 25,
                } }, { children: _jsx("iframe", { src: "https://www.loom.com/embed/a80db49ac9a74acf9085ac42adebcf77", allowFullScreen: true, title: "Embedded LoomVideo", style: {
                        width: "100%",
                        height: "100%",
                        border: "none",
                        borderRadius: "15px",
                    } }) })), _jsx(Button, __assign({ style: {
                    backgroundColor: "#2D3B51",
                    border: "none",
                    borderRadius: "25px",
                    padding: "1.5rem",
                    color: "#ccc",
                    fontWeight: "bolder",
                    fontSize: "1rem",
                }, 
                // isLoading={isLoading}øø
                // isDisabled={isCode?.length !== 4}
                onClick: handleClick }, { children: "JETZT LOSLEGEN" }))] })));
};
