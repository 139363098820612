var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import logo from "../assets/images/logo.png";
import ErrorReportingService from "../services/ErrorReportingService";
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { hasError: false, supportCode: "××××××××" };
        return _this;
    }
    ErrorBoundary.getDerivedStateFromError = function (error) {
        // Update state so the next render will show the fallback UI.
        ErrorReportingService.nativeWarn("GetDerivedState", error);
        // To not impact development, do not show an overlay in developer Mode
        if (ErrorReportingService.developerMode) {
            ErrorReportingService.nativeError("ErrorBoundary would have been set off, but dev mode is active", error);
            return { hasError: false };
        }
        // Otherwise, show fallback UI
        return { hasError: true };
    };
    ErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        console.error(error);
        var supportCode = ErrorReportingService.submitLogs(errorInfo);
        this.setState(__assign(__assign({}, this.state), { supportCode: supportCode }));
    };
    ErrorBoundary.prototype.render = function () {
        if (this.state.hasError) {
            return (_jsx("div", __assign({ className: "backgroundGradient", style: {
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                } }, { children: _jsxs("div", __assign({ style: {
                        width: "100%",
                        maxWidth: 600,
                        padding: 20,
                    } }, { children: [_jsx("img", { alt: "apomap", src: logo, style: { height: 64, width: "100%", objectFit: "contain" } }), _jsx("h1", { children: "Es ist ein Fehler aufgetreten" }), _jsx("p", __assign({ style: { lineHeight: 1.35 } }, { children: "Leider ist bei der Ausf\u00FChrung von apomap ein Fehler aufgetreten. Bitte laden Sie die Seite daher neu und versuchen Sie es erneut. Sollte der Fehler erneut auftreten, kontaktieren Sie uns bitte. Wir werden Ihnen schnellstm\u00F6glich bei der L\u00F6sung des Problems helfen." })), _jsxs("p", __assign({ style: {
                                fontSize: 16,
                                marginTop: 20,
                                marginBottom: 52,
                            } }, { children: ["Ihr Supportcode lautet", " ", _jsx("span", __assign({ style: { fontWeight: "bold" } }, { children: this.state.supportCode }))] })), _jsxs("div", __assign({ style: {
                                display: "flex",
                                gap: "10px",
                                flexDirection: "column",
                                alignItems: "center",
                            } }, { children: [_jsx("a", __assign({ href: "#", onClick: function () { return window.location.reload(); }, className: "fallbackButton primary" }, { children: "Seite neu laden" })), _jsx("a", __assign({ href: "https://apomap.de/kontakt/", target: "_blank", className: "fallbackButton secondary" }, { children: "Support kontaktieren" }))] }))] })) })));
        }
        return this.props.children;
    };
    return ErrorBoundary;
}(React.Component));
export default ErrorBoundary;
