import mapboxgl from "mapbox-gl";
import * as ReactDOM from "react-dom";
export default function addMarker(map, location, color, type, id, element) {
    var el = document.createElement("div");
    ReactDOM.render(element, el);
    var marker = new mapboxgl.Marker(el) // initialize a new marker
        .setLngLat(location) // Marker [lng, lat] coordinates
        .addTo(map.current);
    return marker;
}
