var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Badge, Button, DuplicateIcon, Pane, Paragraph, Table, Tooltip, } from "evergreen-ui";
import "firebase/auth";
import "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import LocationLabel from "../components/LocationLabel";
import firebase from "../config";
import { TaskContext } from "../contexts/TaskContext";
import { TaskDialogsContext } from "../contexts/TaskDialogsContext";
import { useQueryState } from "../hooks/useQueryState";
import getBadgeFromActionType from "../utility/getBadgeFromActionType";
import getBadgeFromTaskType from "../utility/getBadgeFromTaskType";
import { getTaskById } from "../utility/getTaskById";
import { normaliseMoneyString } from "../utility/normaliseMoneyString";
import { usePlanInfo } from "../utility/use-plan-info";
import { createId } from "../utility/utility";
import StatusBadge from "./StatusBadge";
import { TagElement } from "./Task";
import TaskEditButton from "./TaskEditButton";
export default function TaskListRow(props) {
    var _this = this;
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    //Different Display between driver action and task
    var dialogSettings = useContext(TaskDialogsContext).dialogSettings;
    var shownDialog = dialogSettings[0], setShownDialog = dialogSettings[1];
    var currentPlan = usePlanInfo().currentPlan;
    var _m = useQueryState(), _ = _m[0], setQueryData = _m[1];
    var changeStatusOfTask = function (status, id) {
        var historyObj = { timestamp: new Date() };
        if (status === "successfull") {
            historyObj.type = status;
            historyObj.msg =
                "Der Auftrag wurde von der Apotheke als erfolgreich markiert";
            historyObj.id = createId();
        }
        else {
            historyObj.type = status;
            historyObj.msg =
                "Der Auftrag wurde von der Apotheke manuell auf nicht zugeteilt gestellt";
            historyObj.id = createId();
        }
        firebase
            .firestore()
            .collection("tasks")
            .doc(id)
            .update({
            task_status: status,
            task_history: firebase.firestore.FieldValue.arrayUnion(historyObj),
            task_prepared: status === "prepared",
            processed_date: firebase.firestore.FieldValue.serverTimestamp(),
        })
            .then(function () { });
    };
    var _o = useContext(TaskContext), tours = _o.tours, tasks = _o.tasks;
    var areTasks = tasks[0], setTasks = tasks[1];
    console.log((_a = props.data) === null || _a === void 0 ? void 0 : _a.collectiveTask, (_b = props.data) === null || _b === void 0 ? void 0 : _b.taskIds);
    var _p = useState([]), areGroupedTasks = _p[0], setGroupedTasks = _p[1];
    useEffect(function () {
        (function () { return __awaiter(_this, void 0, void 0, function () {
            var groupedTasksId, groupedTasksPromises, groupedTasks;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(((_a = props.data) === null || _a === void 0 ? void 0 : _a.collectiveTask) && areGroupedTasks.length === 0)) return [3 /*break*/, 2];
                        groupedTasksId = (_b = props.data) === null || _b === void 0 ? void 0 : _b.taskIds;
                        console.log("EVAL COLLECTIVE", groupedTasksId);
                        groupedTasksPromises = groupedTasksId.map(function (tId) {
                            return getTaskById(tId);
                        });
                        return [4 /*yield*/, Promise.all(groupedTasksPromises)];
                    case 1:
                        groupedTasks = _c.sent();
                        setGroupedTasks(groupedTasks);
                        console.log("Finish", areGroupedTasks);
                        _c.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })();
    }, [props.data]);
    if (props.type === "task") {
        if (!props.data)
            return null;
        return (_jsx(Pane, __assign({ style: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                witdh: "100%",
            } }, { children: _jsxs(Table.Row, __assign({ style: { width: "100%" } }, { children: [_jsx(Table.TextCell, { children: _jsxs(Pane, __assign({ style: { display: "flex", flexDirection: "column" } }, { children: [((_c = props === null || props === void 0 ? void 0 : props.tour) === null || _c === void 0 ? void 0 : _c.tasksToRemove) &&
                                    ((_d = props === null || props === void 0 ? void 0 : props.tour) === null || _d === void 0 ? void 0 : _d.tasksToRemove.length) &&
                                    ((_f = (_e = props === null || props === void 0 ? void 0 : props.tour) === null || _e === void 0 ? void 0 : _e.tasksToRemove) === null || _f === void 0 ? void 0 : _f.includes((_g = props === null || props === void 0 ? void 0 : props.data) === null || _g === void 0 ? void 0 : _g.task_id)) ? (_jsx(Badge, __assign({ color: "#F66277" }, { children: "von der Tour zu entfernen" }))) : props.tour.id === props.data.task_tour ? (_jsx(StatusBadge, { plan: currentPlan, onChange: function (status, id) { return changeStatusOfTask(status, id); }, status: props.data.task_status, id: props.data.task_id, driver: true })) : props.data.task_status === "deleted" ? (_jsx(StatusBadge, { status: "deleted", id: props.data.task_id, driver: true })) : props.data.task_prevTour ? (_jsx(Button, { onClick: function () {
                                        setShownDialog({ type: "", task: {} });
                                        setQueryData({ tourId: props.data.task_prevTour });
                                        window.location.reload();
                                    } })) : (_jsx(StatusBadge, { status: "unassigned", id: props.data.task_id, driver: true })), getBadgeFromTaskType(props.data.task_type)] })) }), _jsx(Table.TextCell, { children: _jsxs(Paragraph, __assign({ className: "bodytext" }, { children: [props.data.task_status !== "deleted" && props.data.customer_name, _jsx(Paragraph, { children: ((_h = props.data) === null || _h === void 0 ? void 0 : _h.collectiveTask) && (_jsx(Tooltip, __assign({ content: _jsxs(Pane, __assign({ style: { color: "#fff" } }, { children: [_jsxs("p", __assign({ style: {
                                                        textDecoration: "underline",
                                                        marginBottom: "0.5rem",
                                                    } }, { children: [areGroupedTasks.length, " untergeordnete Auftr\u00E4ge:"] })), areGroupedTasks.map(function (task) { return (_jsxs(_Fragment, { children: [task.customer_name, " ", _jsx("br", {})] })); })] })) }, { children: _jsx(DuplicateIcon, { marginLeft: 10, cursor: "pointer" }) }))) }), ((_k = (_j = props.data) === null || _j === void 0 ? void 0 : _j.task_tags) === null || _k === void 0 ? void 0 : _k.length) > 0 ? (_jsx(TagElement, { tags: props.data.task_tags, cursor: "pointer" })) : null] })) }), _jsx(Tooltip, __assign({ content: props.data.task_address }, { children: _jsx(Table.TextCell, { children: _jsx(Paragraph, __assign({ className: "bodytext" }, { children: props.data.task_status !== "deleted" &&
                                    props.data.task_address })) }) })), _jsx(Table.TextCell, { children: props.data.task_status !== "deleted" && (_jsx(LocationLabel, { id: props.data.task_origin })) }), _jsx(Table.TextCell, { children: _jsx(Paragraph, __assign({ className: "bodytext" }, { children: props.data.task_status !== "deleted" &&
                                normaliseMoneyString(props.data.task_open_amount) })) }), _jsx(Table.TextCell, { children: _jsx(Paragraph, __assign({ className: "bodytext" }, { children: props.data.task_status !== "deleted" &&
                                props.data.task_planed_delivery_waiting + "min" })) }), !props.print && (_jsx(Table.TextCell, { children: _jsxs(Pane, __assign({ style: {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                            } }, { children: [_jsx(Paragraph, __assign({ className: "bodytext" }, { children: props.data.task_type === "delivery"
                                        ? props.data.task_planed_delivery
                                        : props.data.task_planed_pickup })), props.data.task_status !== "deleted" && (_jsx(TaskEditButton, { task: props.data }))] })) }))] }), props.data.task_id) })));
    }
    else {
        return (_jsx(Pane, __assign({ style: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                witdh: "100%",
            } }, { children: _jsxs(Table.Row, __assign({ style: { width: "100%" } }, { children: [_jsx(Table.TextCell, { children: _jsxs(Pane, __assign({ style: { display: "flex", flexDirection: "column" } }, { children: [getBadgeFromActionType(props.data.type, props.data.ids ? props.data.ids.length : 0), getBadgeFromTaskType(props.data.type)] })) }), _jsx(Table.TextCell, { children: _jsx(Paragraph, { className: "bodytext" }) }), _jsx(Table.TextCell, { children: _jsx(LocationLabel, { centralDelivery: (_l = props === null || props === void 0 ? void 0 : props.tour) === null || _l === void 0 ? void 0 : _l.centralDelivery, index: props.index, type: props, id: props.data.location_id.split("_")[1] }) }), _jsx(Table.TextCell, { children: _jsx(Paragraph, { className: "bodytext" }) }), _jsx(Table.TextCell, { children: _jsx(Paragraph, { className: "bodytext" }) }), _jsx(Table.TextCell, {}), !props.print && (_jsx(Table.TextCell, { children: _jsx(Paragraph, __assign({ className: "bodytext" }, { children: props.data.action_planed_time })) }))] }), props.data.location_id) })));
    }
}
