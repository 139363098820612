var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, Button, Card, Heading, Pane, Paragraph, ShoppingCartIcon, ThemeProvider, } from "evergreen-ui";
import { Link } from "react-router-dom";
import firebase from "../../config";
import theme from "../../theme";
export var ChangePlans = function () {
    return (
    //@ts-ignore
    _jsx(ThemeProvider, __assign({ value: theme }, { children: _jsx(Pane, __assign({ backgroundColor: "#49beef20", display: "flex", width: "100vw", height: "100vh", alignItems: "center", justifyContent: "center" }, { children: _jsxs(Card, __assign({ maxWidth: "60vw", elevation: 2, padding: 12, backgroundColor: "white" }, { children: [_jsxs(Pane, { children: [_jsx(Badge, __assign({ color: "red" }, { children: "Konto tempor\u00E4r gesperrt" })), _jsx(Heading, __assign({ size: 700, marginTop: 12 }, { children: "Dein Konto ist tempor\u00E4r gesperrt. Aber keine Panik!" }))] }), _jsx(Paragraph, __assign({ marginTop: 12 }, { children: "Seit dem 01.01.2024 haben wir unsere apomap Pl\u00E4ne angepasst. Leider hast du deinen lite Account noch nicht geupgraded oder auf den neuen apomap Starter Plan gewechselt. Du kannst dies nun problemlos erledigen oder dich einfach direkt an unseren Support wenden." })), _jsx(Link, __assign({ to: "/upgrade" }, { children: _jsx(Button, __assign({ marginTop: 12, size: "medium", iconAfter: ShoppingCartIcon }, { children: "Neuen Plan w\u00E4hlen" })) })), _jsx(Button, __assign({ intent: "danger", marginTop: 12, size: "medium", marginLeft: 8, onClick: function () { return firebase.auth().signOut(); } }, { children: "Abmelden" }))] })) })) })));
};
