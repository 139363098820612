var calculateTrackerStats = function (trackerData) {
    var trackerStats = [];
    for (var _i = 0, trackerData_1 = trackerData; _i < trackerData_1.length; _i++) {
        var tracker = trackerData_1[_i];
        var minTemp = Math.min.apply(Math, tracker === null || tracker === void 0 ? void 0 : tracker.temp);
        var maxTemp = Math.max.apply(Math, tracker === null || tracker === void 0 ? void 0 : tracker.temp);
        var avgTemp = ((tracker === null || tracker === void 0 ? void 0 : tracker.temp.reduce(function (sum, temp) { return sum + temp; }, 0)) /
            (tracker === null || tracker === void 0 ? void 0 : tracker.temp.length)).toFixed(1);
        trackerStats.push({
            trackerName: tracker.trackerName,
            minTemp: minTemp === Infinity ? "n/a" : minTemp,
            maxTemp: maxTemp === -Infinity ? "n/a" : maxTemp,
            avgTemp: isNaN(Number(avgTemp)) ? "n/a" : Number(avgTemp),
        });
    }
    return trackerStats;
};
export default calculateTrackerStats;
