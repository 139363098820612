var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button, SelectMenu } from "evergreen-ui";
import { useState } from "react";
export var PermissionSelectBox = function (_a) {
    var selectedItemsState = _a.selectedItemsState, setSelectedItems = _a.setSelectedItems;
    var options = useState([
        { label: "Lesen:Aufträge", value: "read:tasks" },
        { label: "Lesen:Touren", value: "read:tours" },
        { label: "Lesen:Fahrer", value: "read:drivers" },
        { label: "Lesen:Standorte", value: "read:locations" },
        { label: "Schreiben:Aufträge", value: "write:tasks" },
        { label: "Schreiben:Touren", value: "write:tours" },
        { label: "Schreiben:Fahrer", value: "write:drivers" },
        { label: "Schreiben:Standorte", value: "write:locations" },
    ])[0];
    var _b = useState(""), selectedItemNamesState = _b[0], setSelectedItemNames = _b[1];
    return (_jsx(SelectMenu, __assign({ isMultiSelect: true, title: "Mehrere w\u00E4hlbar", options: options, selected: selectedItemsState, onSelect: function (item) {
            var selected = __spreadArray(__spreadArray([], selectedItemsState, true), [item.value], false);
            var selectedItems = selected;
            console.log(selectedItems);
            var selectedItemsLength = selectedItems.length;
            var selectedNames = "";
            if (selectedItemsLength === 0) {
                selectedNames = "";
            }
            else if (selectedItemsLength > 0) {
                selectedNames = selectedItemsLength.toString() + " gewählt...";
            }
            setSelectedItems(selectedItems);
            setSelectedItemNames(selectedNames);
        }, onDeselect: function (item) {
            var deselectedItemIndex = selectedItemsState.indexOf(item.value);
            var selectedItems = selectedItemsState.filter(function (_item, i) { return i !== deselectedItemIndex; });
            var selectedItemsLength = selectedItems.length;
            var selectedNames = "";
            if (selectedItemsLength === 0) {
                selectedNames = "";
            }
            else if (selectedItemsLength > 0) {
                selectedNames = selectedItemsLength.toString() + " gewählt...";
            }
            setSelectedItems(selectedItems);
            setSelectedItemNames(selectedNames);
        } }, { children: _jsx(Button, { children: selectedItemNamesState || "Berechtigungen wählen" }) })));
};
