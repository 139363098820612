var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Pane, Textarea, TextInput } from "evergreen-ui";
import { useState } from "react";
import ApoFieldLabel from "./ApoFieldLabel";
var textareaProps = {
    is: Textarea,
    style: { maxWidth: "100%" },
};
var ApoTextInput = function (_a) {
    var label = _a.label, value = _a.value, placeholder = _a.placeholder, defaultValue = _a.defaultValue, required = _a.required, _b = _a.size, size = _b === void 0 ? "large" : _b, hint = _a.hint, type = _a.type, _c = _a.numberProps, numberProps = _c === void 0 ? {} : _c, spellCheck = _a.spellCheck, isTextarea = _a.isTextarea, maxWidth = _a.maxWidth, minHeight = _a.minHeight, onChange = _a.onChange, onFocus = _a.onFocus, onBlur = _a.onBlur, disabled = _a.disabled, inputRef = _a.inputRef, marginLeft = _a.marginLeft, _d = _a.marginBottom, marginBottom = _d === void 0 ? 10 : _d;
    var _e = useState(defaultValue !== undefined ? defaultValue : value), internalValue = _e[0], setInternalValue = _e[1];
    var optionalTextareaProps = isTextarea ? textareaProps : undefined;
    // const handleChange = useCallback(
    //   (e) => {
    //     let newValue = e.target.value;
    //     if (newValue) {
    //       setInternalValue(newValue);
    //       onChange && onChange(e);
    //     }
    //   },
    //   [onChange]
    // );
    return (_jsxs(Pane, __assign({ marginLeft: marginLeft }, { children: [label && _jsx(ApoFieldLabel, { required: required, value: label }), _jsx(TextInput, __assign({ ref: inputRef, disabled: disabled, spellCheck: spellCheck, value: value !== undefined ? value : internalValue, placeholder: placeholder, onChange: onChange, onFocus: onFocus, onBlur: onBlur, size: size, hint: hint, type: type, marginBottom: marginBottom, width: "100%", maxWidth: maxWidth }, (minHeight !== undefined ? { minHeight: minHeight } : {}), optionalTextareaProps, numberProps))] })));
};
export default ApoTextInput;
