export function getLabelForVehicle(vehicle) {
    switch (vehicle) {
        case "car":
            return "Auto";
        case "motorbike":
            return "Motorrad";
        case "foot":
            return "Fußgänger";
        case "bike":
            return "Fahrrad";
        case "scooter":
            return "Motorroller";
        default:
            return "Auto";
    }
}
