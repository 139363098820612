var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { toaster } from "evergreen-ui";
import firebase from "firebase/app";
import { PrinterSelectionMode, pscDefaultValue, } from "../contexts/PrinterSelectionContext";
import { getPrinters } from "../hooks/usePrinters";
import { PrintingIntentType } from "../types/PrintingIntent";
import { PrintingIntentResponseErrorCode, } from "../types/PrintingIntentResponse";
import { toDDMMYYYY } from "../utility/formatDate";
import { generateId } from "../utility/generatePseudoRandomString";
var PrintingService = /** @class */ (function () {
    function PrintingService() {
    }
    PrintingService.submitPrintingIntent = function (pi) {
        return __awaiter(this, void 0, Promise, function () {
            var response, pir, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, PrintingService.print(pi)];
                    case 1:
                        response = _a.sent();
                        pir = response.data;
                        if (!pir.success) {
                            switch (pir.error_code) {
                                case PrintingIntentResponseErrorCode.ERROR_WHILE_SENDING:
                                    toaster.danger("Dein Druckauftrag konnte nicht versandt werden. Bitte überprüfe deine Internetverbindung und versuche es erneut.");
                                    break;
                                case PrintingIntentResponseErrorCode.APH_UNREACHABLE:
                                    toaster.danger("Dein Druckauftrag konnte nicht versandt werden, weil eine Störung in unserer Infrastruktur vorliegt. Bitte versuche es später erneut oder kontaktiere uns.");
                                    break;
                                case PrintingIntentResponseErrorCode.UNKNOWN_ERROR:
                                    toaster.danger("Dein Druckauftrag konnte nicht versandt werden. Bitte versuche es später erneut oder kontaktiere uns.");
                                    break;
                            }
                        }
                        return [2 /*return*/, pir];
                    case 2:
                        e_1 = _a.sent();
                        console.error("PrintingService", e_1);
                        toaster.warning("Bei der Bearbeitung deines Druckauftrags ist ein unerwarteter Fehler aufgetreten. Es ist möglich, dass der Druckauftrag dennoch ausgeführt wird. Bitte prüfe daher erst deinen Drucker, bevor du den Auftrag erneut versendest.");
                        return [2 /*return*/, {
                                rid: pi.rid,
                                success: false,
                                error_code: PrintingIntentResponseErrorCode.ERROR_WHILE_SENDING,
                            }];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Prints a Task Label for the given [task]
     *
     * @param task The task to print the label for
     * @param targetLocation The location the label should be printed at. This can be overwritten, should the
     * targetLocation not have a printer, as specified in the docs
     * @param forcedTargetPrinter Should you be running a batch operation, you should determine a printer on your own
     * and then force it here. That way, users will not be presented with a dialogue for each element.
     */
    PrintingService.printTaskLabel = function (task, targetLocation, forcedTargetPrinter) {
        return __awaiter(this, void 0, Promise, function () {
            var targetPrinter, _a, pi;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(forcedTargetPrinter !== null && forcedTargetPrinter !== void 0)) return [3 /*break*/, 1];
                        _a = forcedTargetPrinter;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, PrintingService.determinePrinter(targetLocation)];
                    case 2:
                        _a = (_b.sent());
                        _b.label = 3;
                    case 3:
                        targetPrinter = _a;
                        pi = {
                            rid: generateId(10),
                            type: PrintingIntentType.TASK_LABEL,
                            target_printer: targetPrinter,
                            content: {
                                name: task.customer_name,
                                jobNo: task.task_job_no,
                                addr: task.task_address,
                                tags: task.task_tags,
                                taskUid: task.task_id,
                                notes: task.task_note,
                                paymentDue: 
                                // @ts-expect-error This is for backwards compatibility, which the task type does not reflect
                                task.task_open_amount === 0 || task.task_open_amount === "0"
                                    ? undefined
                                    : task.task_open_amount,
                                prio: !(task.task_priority === null ||
                                    task.task_priority === undefined ||
                                    task.task_priority === 20),
                                delivery: task.task_type === "delivery",
                            },
                        };
                        return [4 /*yield*/, PrintingService.submitPrintingIntent(pi)];
                    case 4: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    PrintingService.printTourLabel = function (tour, driverName, forcedTargetPrinter) {
        var _a;
        return __awaiter(this, void 0, Promise, function () {
            var startLocation, targetPrinter, _b, pi;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        startLocation = (_a = tour.activities
                            .find(function (a) { return a.type == "start"; })) === null || _a === void 0 ? void 0 : _a.location_id.replace("tourstart_", "");
                        if (!startLocation)
                            throw new Error("Unable to determine the tour starting point");
                        if (!(forcedTargetPrinter !== null && forcedTargetPrinter !== void 0)) return [3 /*break*/, 1];
                        _b = forcedTargetPrinter;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, PrintingService.determinePrinter(startLocation)];
                    case 2:
                        _b = (_c.sent());
                        _c.label = 3;
                    case 3:
                        targetPrinter = _b;
                        pi = {
                            rid: generateId(10),
                            type: PrintingIntentType.TOUR_LABEL,
                            target_printer: targetPrinter,
                            target_location: startLocation,
                            content: {
                                driverName: driverName,
                                tourUid: tour.id,
                                startTime: tour.startTime,
                                startDate: toDDMMYYYY("20" + tour.date),
                            },
                        };
                        return [4 /*yield*/, PrintingService.submitPrintingIntent(pi)];
                    case 4: return [2 /*return*/, _c.sent()];
                }
            });
        });
    };
    PrintingService.getTargetPreference = function () {
        var _a;
        return ((_a = window.localStorage.getItem("printing-target-preference")) !== null && _a !== void 0 ? _a : undefined);
    };
    PrintingService.setTargetPreference = function (pid) {
        window.localStorage.setItem("printing-target-preference", pid);
    };
    /**
     * Determines the id of a printer to use for the request, considering the locationId. Should no locationId be provided,
     * all printers will be presented to the user.
     * Returns a promise, that resolves as soon as a determination has been made. This may take a while, if user input
     * is needed.
     *
     * @returns [string] printer id
     */
    PrintingService.determinePrinter = function (locationId) {
        return __awaiter(this, void 0, Promise, function () {
            var printers, printersInLocation, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        return [4 /*yield*/, getPrinters()];
                    case 1:
                        printers = _a.sent();
                        console.log("Printers debug", printers, "at loc", locationId);
                        if (printers === null) {
                            toaster.warning("Der Druckauftrag konnte nicht gestartet werden.");
                            throw new Error("Unable to get printers");
                        }
                        printersInLocation = printers.filter(function (p) { return p.locationId === locationId; });
                        // (1a) If the location has one printer, choose it
                        if (printersInLocation.length === 1) {
                            console.log("[Print] Location only had one printer, choosing that.");
                            return [2 /*return*/, printersInLocation[0].pid];
                        }
                        if (!(printersInLocation.length > 1)) return [3 /*break*/, 3];
                        console.log("[Print] Location only had more than printer, prompting user.");
                        return [4 /*yield*/, PrintingService.promptPrinterSelection(printersInLocation, false)];
                    case 2: return [2 /*return*/, (_a.sent()).pid];
                    case 3:
                        if (!(printers.length > 0)) return [3 /*break*/, 5];
                        console.log("[Print] Location does not have a printer, prompting user globally.");
                        return [4 /*yield*/, PrintingService.promptPrinterSelection(printers, true)];
                    case 4: return [2 /*return*/, (_a.sent())
                            .pid];
                    case 5:
                        // (3) Give up, since there is no printer
                        toaster.danger("Der Druckauftrag konnte nicht gestartet werden, da kein Drucker eingerichtet ist. Bitte richte zuerst einen Drucker in den Einstellungen ein.");
                        throw new Error("No printer configured");
                    case 6:
                        e_2 = _a.sent();
                        console.log(e_2);
                        throw new Error("");
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    PrintingService.promptPrinterSelection = function (candidates, isFallback) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        var _a = PrintingService.printerSelectionContextStates.mode, mode = _a[0], setMode = _a[1];
                        var _b = PrintingService.printerSelectionContextStates.shown, shown = _b[0], setShown = _b[1];
                        var _c = PrintingService.printerSelectionContextStates.candidates, _ = _c[0], setCandidates = _c[1];
                        var _d = PrintingService.printerSelectionContextStates.selectionCallback, selCallback = _d[0], setSelCallback = _d[1];
                        console.log("Setting");
                        setSelCallback(function () {
                            return function (printer) {
                                console.log("Got callback with printer", printer);
                                if (printer === undefined) {
                                    throw new Error("WHY?!");
                                }
                                if (printer === null) {
                                    reject();
                                    return;
                                }
                                console.log("Resolving with", printer);
                                resolve(printer);
                            };
                        });
                        console.log(selCallback);
                        setCandidates(candidates);
                        setMode(isFallback
                            ? PrinterSelectionMode.FALLBACK
                            : PrinterSelectionMode.DEFAULT);
                        console.log("Showing modal");
                        setShown(true);
                    })];
            });
        });
    };
    PrintingService.print = firebase
        .app()
        .functions("europe-west3")
        .httpsCallable("print");
    PrintingService.printerSelectionContextStates = pscDefaultValue;
    return PrintingService;
}());
export default PrintingService;
// @ts-ignore
window.ps = PrintingService;
