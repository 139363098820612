var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EditIcon, IconButton, Pane, toaster, TrashIcon } from "evergreen-ui";
import "firebase/firestore";
import { useMemo } from "react";
import firebase from "../../config";
import { CardContent, ContentLayout } from "./CardContent";
export var AutomatedTourCard = function (_a) {
    var _b, _c;
    var tour = _a.tour, setDialogState = _a.setDialogState, locationsMap = _a.locationsMap, driversMap = _a.driversMap;
    var startLocationName = useMemo(function () {
        var _a, _b, _c;
        return (_b = (_a = locationsMap[tour.startLocation]) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : (_c = tour.individualStartLocation) === null || _c === void 0 ? void 0 : _c.id;
    }, [locationsMap, (_b = tour.individualStartLocation) === null || _b === void 0 ? void 0 : _b.id, tour.startLocation]);
    var endLocationName = useMemo(function () { var _a, _b, _c; return (_b = (_a = locationsMap[tour.endLocation]) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : (_c = tour.individualEndLocation) === null || _c === void 0 ? void 0 : _c.id; }, [locationsMap, tour.endLocation, (_c = tour.individualEndLocation) === null || _c === void 0 ? void 0 : _c.id]);
    var driversName = useMemo(function () {
        var _a, _b;
        return tour.driver
            ? ((_a = driversMap[tour.driver]) === null || _a === void 0 ? void 0 : _a.firstName) +
                " " +
                ((_b = driversMap[tour.driver]) === null || _b === void 0 ? void 0 : _b.lastName)
            : "Nicht festgelegt";
    }, [driversMap, tour.driver]);
    var getShortDay = function (key) {
        switch (key) {
            case "monday":
                return { rank: 0, type: "Mo" };
            case "tuesday":
                return { rank: 1, type: "Di" };
            case "wednesday":
                return { rank: 2, type: "Mi" };
            case "thursday":
                return { rank: 3, type: "Do" };
            case "friday":
                return { rank: 4, type: "Fr" };
            case "saturday":
                return { rank: 5, type: "Sa" };
            case "sunday":
                return { rank: 6, type: "So" };
            default:
                return { rank: 7, type: "XX" };
        }
    };
    var daysString = useMemo(function () {
        return Object.entries(tour.weekdays)
            .filter(function (_a) {
            var key = _a[0], value = _a[1];
            return value;
        })
            .map(function (_a) {
            var key = _a[0];
            return getShortDay(key);
        })
            .sort(function (a, b) { return a.rank - (b === null || b === void 0 ? void 0 : b.rank); })
            .map(function (entry) { return entry === null || entry === void 0 ? void 0 : entry.type; })
            .join(", ");
    }, [tour.weekdays]);
    var deleteWorkflow = function () {
        firebase
            .firestore()
            .collection("schedules")
            .doc(tour.workflowId)
            .delete()
            .then(function () {
            toaster.success("Erfolgreich gelöscht");
        })
            .catch(function (err) { return toaster.danger("Ein Fehler ist aufgetreten"); });
    };
    return (_jsxs(Pane, __assign({ backgroundColor: "#ECF0F9", borderRadius: 6, marginTop: 24, style: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "92%",
            gap: "1rem",
            padding: "0.5rem",
        } }, { children: [_jsxs(Pane, __assign({ style: {
                    display: "flex",
                    flex: 1,
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: "1rem",
                } }, { children: [_jsx(ContentLayout, { children: _jsx(CardContent, { subject: tour.tourName, value: daysString }) }), _jsxs(ContentLayout, { children: [_jsx(CardContent, { subject: "Startpunkt:", value: startLocationName }), _jsx(CardContent, { subject: "Endpunkt:", value: endLocationName })] }), _jsxs(ContentLayout, { children: [_jsx(CardContent, { subject: "Startzeit:", value: tour.startTime }), _jsx(CardContent, { subject: "Fahrer:", value: driversName })] })] })), _jsxs(Pane, __assign({ style: {
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    width: "10%",
                    gap: "1rem",
                    padding: "1rem",
                } }, { children: [_jsx(IconButton, { onClick: function () { return setDialogState({ shown: true, workflow: tour }); }, icon: EditIcon }), _jsx(IconButton, { onClick: function () { return deleteWorkflow(); }, intent: "danger", icon: TrashIcon })] }))] })));
};
