var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Heading, Pane, ThemeProvider, Text } from "evergreen-ui";
import NavigatorBar from "../container/NavigatorBar";
import theme from "../theme";
function PaywallInfo(pageName, pageDesc, minPlan, isHighestPlan, other, isPage, heigth, width, showDefaultInfo) {
    return function PaywallInfoPage() {
        return (_jsx(ThemeProvider
        /* @ts-ignore */
        , __assign({ 
            /* @ts-ignore */
            value: theme }, { children: isPage ? (_jsxs(Pane, __assign({ style: { display: "flex", flexDirection: "row" } }, { children: [_jsx(NavigatorBar, {}), _jsxs(Pane, __assign({ elevation: 2, style: { padding: 25, margin: 25, width: "90vw" } }, { children: [_jsx("div", __assign({ style: { display: "flex", flexDirection: "row" } }, { children: _jsxs("div", __assign({ style: { flex: 1 } }, { children: [_jsx(Heading, __assign({ size: 900, style: { marginBottom: 3 } }, { children: pageName })), _jsx(Heading, __assign({ size: 700, style: { color: "#000000", marginBottom: 18 } }, { children: pageDesc }))] })) })), _jsxs("div", __assign({ style: {
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    minHeight: 600,
                                } }, { children: [_jsxs(Heading, __assign({ size: 900, style: {
                                            marginBottom: 20,
                                            maxWidth: 800,
                                            color: "#2D3B51",
                                            textAlign: "center",
                                        } }, { children: ["Dieses Feature ist nur f\u00FCr Kunden mit ", minPlan, _jsx("span", __assign({ style: { marginLeft: 8, marginRight: 8 } }, { children: isHighestPlan ? null : "oder höher" })), "verf\u00FCgbar."] })), other && _jsx("div", __assign({ style: { marginBottom: 20 } }, { children: other })), _jsx(Button, __assign({ marginTop: 5, size: "large", appearance: "primary", backgroundColor: "#2D3B51", onClick: function () { return (window.location.href = "/upgrade"); } }, { children: "Jetzt upgraden" }))] }))] }))] }))) : (_jsx(Pane, __assign({ elevation: 2, style: {
                    padding: 20,
                    width: width,
                    height: heigth,
                    position: "relative",
                } }, { children: _jsxs(Pane, __assign({ style: {
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                    } }, { children: [showDefaultInfo && (_jsxs(_Fragment, { children: [_jsx(Heading, __assign({ size: 700, style: { marginBottom: 3 } }, { children: pageName })), _jsx(Text, __assign({ size: 400, style: { color: "#000000", marginBottom: 18 } }, { children: pageDesc })), _jsxs(Text, __assign({ size: 600, style: {
                                        marginBottom: 20,
                                        maxWidth: 800,
                                        color: "#2D3B51",
                                        textAlign: "center",
                                    } }, { children: ["Dieses Feature ist nur f\u00FCr Kunden mit ", minPlan, _jsx("span", __assign({ style: { marginLeft: 8, marginRight: 8 } }, { children: isHighestPlan ? null : "oder höher" })), "verf\u00FCgbar."] }))] })), other && _jsx("div", __assign({ style: { marginBottom: 20 } }, { children: other })), _jsx(Button, __assign({ marginTop: 5, size: "large", appearance: "primary", backgroundColor: "#2D3B51", onClick: function () { return (window.location.href = "/upgrade"); } }, { children: "Jetzt upgraden" }))] })) }))) })));
    };
}
export default PaywallInfo;
