var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Pane, Table } from "evergreen-ui";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { motion } from "framer-motion/dist/framer-motion";
import { useContext, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import TableSortHeader from "../components/TableSortHeader";
import TourCard from "../components/TourCard";
import TourSelectCard from "../components/TourSelectCard";
import firebase from "../config";
import { LayoutContext } from "../contexts/LayoutContext";
import { TaskContext } from "../contexts/TaskContext";
import { OptimizationBar } from "./OptimizationBar";
import TableSpacer from "./TableSpacer";
var TourLoadingPlaceholder = function () {
    return (_jsx(_Fragment, { children: _jsx(motion.div, __assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.5 } }, { children: _jsx(Pane, __assign({ style: { height: 10, position: "relative" } }, { children: _jsx(Skeleton, { borderRadius: 0, baseColor: "#ffff", highlightColor: "lightgrey", style: { height: 10 } }) })) })) }));
};
export default function TourList(props) {
    var _a, _b;
    var tours = useContext(TaskContext).tours;
    var areTours = tours[0], setTours = tours[1];
    var _c = useState([]), allTours = _c[0], setAllTours = _c[1];
    var state = useContext(LayoutContext).state;
    var isEditMode = state[0], setEditMode = state[1];
    function getAllTours() {
        firebase
            .firestore()
            .collection("tours")
            .where("owner", "==", firebase.auth().currentUser.uid)
            .where("status", "==", "finished")
            .orderBy("created", "desc")
            .get()
            .then(function (snap) {
            var tours = [];
            snap.forEach(function (doc) {
                var data = doc.data();
                tours.push(data);
            });
            setAllTours(tours);
        });
    }
    useEffect(function () {
        if (props.all) {
            getAllTours();
        }
        else {
            setAllTours(areTours);
        }
    }, [areTours, props.all]);
    var sortedToursByDate = useMemo(function () {
        return allTours.sort(function (a, b) {
            var dateA = a.date.split("-").reverse().join("");
            var dateB = b.date.split("-").reverse().join("");
            if (dateA === dateB) {
                var timeA = a.startTime.split(":").join("");
                var timeB = b.startTime.split(":").join("");
                return timeA.localeCompare(timeB);
            }
            return dateA.localeCompare(dateB);
        });
    }, [allTours]);
    return (_jsxs(Pane, __assign({ style: { width: "100%", height: "60%", padding: 10 } }, { children: [((_a = props === null || props === void 0 ? void 0 : props.ongoingOptimizations) === null || _a === void 0 ? void 0 : _a.length) && _jsx(TourLoadingPlaceholder, {}), _jsxs(Table.Head, __assign({ style: { backgroundColor: "#F8FBFF" } }, { children: [!isEditMode && _jsx(TableSortHeader, { title: "Status" }), _jsx(TableSortHeader, { title: "Fahrer*in" }), _jsx(TableSortHeader, { title: "Datum" }), _jsx(TableSortHeader, { title: "Startzeit" }), _jsx(TableSortHeader, { title: "Aufträge" }), !isEditMode && !props.select && _jsx(TableSortHeader, { title: "Status" })] })), _jsxs(Pane, __assign({ style: { height: "100%", overflow: "scroll" } }, { children: [(_b = props.optimizations) === null || _b === void 0 ? void 0 : _b.map(function (optimization) { return (_jsx(OptimizationBar, { optimization: optimization })); }), sortedToursByDate
                        .filter(function (_a) {
                        var status = _a.status;
                        return status === "prepared" ||
                            status === "ontheway" ||
                            status === "empty";
                    })
                        .map(function (tour, index) {
                        return props.select ? (_jsx(TourSelectCard, { isSelectedTourId: props.isSelectedTourId, setId: props.setId, select: props.select, tour: tour, index: index }, tour.id)) : (_jsx(TourCard, { hover: props.hover, select: props.select, tour: tour, index: index }, tour.id));
                    }), !props.all && (_jsx(TableSpacer, { button: allTours.filter(function (_a) {
                            var status = _a.status;
                            return status === "finished";
                        }).length > 0, title: "Heute durchgef\u00FChrte Touren" })), sortedToursByDate
                        .filter(function (_a) {
                        var status = _a.status;
                        return status === "finished";
                    })
                        .map(function (tour, index) {
                        return props.select ? (_jsx(TourSelectCard, { isSelectedTourId: props.isSelectedTourId, setId: props.setId, select: props.select, tour: tour, index: index }, tour.id)) : (_jsx(TourCard, { hover: props.hover, select: props.select, tour: tour, index: index }, tour.id));
                    })] }))] })));
}
