var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Avatar, Pane, Pill } from "evergreen-ui";
import "firebase/auth";
import { motion } from "framer-motion/dist/framer-motion";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import firebase from "../config";
import { MapContext } from "../contexts/MapContext";
import addMarker from "../utility/addMarker";
import { usePlanInfo } from "../utility/use-plan-info";
import { deleteNode } from "../utility/utility";
import Pin from "./Pin";
import DriverChat from "./DriverChat";
export default function DriverAvatar(props) {
    var _a, _b, _c, _d;
    var map = useContext(MapContext).map;
    var _e = useState(false), isShown = _e[0], setIsShown = _e[1];
    var mapRef = map[0], setMapRef = map[1];
    var _f = useState(), marker = _f[0], setMarker = _f[1];
    var _g = useState(), prevLocation = _g[0], setPrevLocation = _g[1];
    var currentPlan = usePlanInfo().currentPlan;
    var markCarToMap = useCallback(function () {
        if (!props.driver.isOnline) {
            return;
        }
        try {
            marker.remove();
        }
        catch (error) { }
        var m = addMarker(mapRef, props.driver.lastPosition, undefined, "driver", null, _jsx(Pin, { driver: props.driver, activity: { type: "driver" } }));
        if (prevLocation) {
            var direction = angleFromCoordinate(prevLocation, props.driver.lastPosition);
        }
        setMarker(m);
    }, [mapRef, props.driver.lastPosition]);
    var relevantNotifications = useMemo(function () {
        return Object.values(props.notifications).flatMap(function (entry) {
            return entry.from === props.driver.id ? entry : [];
        });
    }, [props.driver.id, props.notifications]);
    useEffect(function () {
        try {
            markCarToMap();
        }
        catch (error) { }
    }, [markCarToMap, props.driver.lastPosition]);
    function angleFromCoordinate(loc1, loc2) {
        var p1 = {
            x: loc1[1],
            y: loc1[0],
        };
        var p2 = {
            x: loc2[1],
            y: loc2[0],
        };
        // angle in radians
        var angleRadians = Math.atan2(p2.y - p1.y, p2.x - p1.x);
        // angle in degrees
        var angleDeg = (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI;
        var cardinal = getCardinal(angleDeg);
        return cardinal;
    }
    function getCardinal(angle) {
        var degreePerDirection = 360 / 8;
        var offsetAngle = angle + degreePerDirection / 2;
        return offsetAngle >= 0 * degreePerDirection &&
            offsetAngle < 1 * degreePerDirection
            ? "N"
            : offsetAngle >= 1 * degreePerDirection &&
                offsetAngle < 2 * degreePerDirection
                ? "NE"
                : offsetAngle >= 2 * degreePerDirection &&
                    offsetAngle < 3 * degreePerDirection
                    ? "E"
                    : offsetAngle >= 3 * degreePerDirection &&
                        offsetAngle < 4 * degreePerDirection
                        ? "SE"
                        : offsetAngle >= 4 * degreePerDirection &&
                            offsetAngle < 5 * degreePerDirection
                            ? "S"
                            : offsetAngle >= 5 * degreePerDirection &&
                                offsetAngle < 6 * degreePerDirection
                                ? "SW"
                                : offsetAngle >= 6 * degreePerDirection &&
                                    offsetAngle < 7 * degreePerDirection
                                    ? "W"
                                    : "NW";
    }
    return (_jsxs(_Fragment, { children: [_jsx(DriverChat, { shown: isShown, onClose: function () { return setIsShown(false); }, driver: props.driver, currentPlan: currentPlan }), _jsx(motion.div, __assign({ initial: { opacity: 0, scale: 0.5, x: 100 }, animate: { opacity: 1, scale: 1, x: 0 }, transition: { duration: 0.3, loop: true }, whileHover: {
                    scale: 1.1,
                    transition: { duration: 0.3 },
                }, whileTap: { scale: 0.9 } }, { children: _jsxs(Pane, __assign({ style: { cursor: "pointer", position: "relative" }, onClick: function () {
                        deleteNode("notifications_" + firebase.auth().currentUser.uid);
                        setIsShown(true);
                    } }, { children: [relevantNotifications.length > 0 && (_jsx("div", __assign({ style: { position: "absolute", top: -15, left: 15, zIndex: 20 } }, { children: _jsx(Pill, __assign({ color: "red", display: "inline-flex", margin: 8 }, { children: relevantNotifications.length })) }))), _jsx(Avatar, { name: ((_b = (_a = props === null || props === void 0 ? void 0 : props.driver) === null || _a === void 0 ? void 0 : _a.firstName) === null || _b === void 0 ? void 0 : _b.toUpperCase()) +
                                " " +
                                ((_d = (_c = props === null || props === void 0 ? void 0 : props.driver) === null || _c === void 0 ? void 0 : _c.lastName) === null || _d === void 0 ? void 0 : _d.toUpperCase()), src: props.driver.image, size: 40, marginBottom: 5 })] })) }))] }));
}
