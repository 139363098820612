var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { toaster } from "evergreen-ui";
import "firebase/functions";
import moment from "moment";
import { createContext, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import firebase from "../config";
export var PlanContext = createContext(null);
export default function PlanContextProvider(_a) {
    var _this = this;
    var children = _a.children;
    //History for redirect
    var history = useHistory();
    var location = useLocation();
    //Store for the information of the account
    var _b = useState(), isPlanData = _b[0], setPlanData = _b[1];
    var doAccessCheck = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var callable;
        return __generator(this, function (_a) {
            if (location.pathname === "/change-plans")
                return [2 /*return*/];
            if (location.pathname.includes("/onboarding"))
                return [2 /*return*/];
            if (location.pathname.includes("/signup"))
                return [2 /*return*/];
            callable = firebase
                .app()
                .functions("europe-west3")
                .httpsCallable("checkAccess");
            callable().then(function (res) {
                var result = res.data;
                if (result.isLite) {
                    if (moment().format("YYYY") === "2024" &&
                        location.pathname !== "/upgrade") {
                        history.push("/change-plans");
                    }
                    else {
                        var days = moment("01.01.2024", "DD.MM.YYYY").diff(moment(), "days");
                        if (days !== 0) {
                            toaster.danger("Sperrung ihres Kontos in ".concat(days, " Tagen!"), {
                                description: "Wieso? In den vergangenen Wochen haben wir dir bereits kommuniziert, dass ab dem 01.01.2024 neue apomap Pläne eingeführt werden. Diese Pläne wurden entwickelt, um dir erweiterte Funktionen und eine noch effizientere Arbeitsweise zu bieten. Unter dem 🛒  Symbol kannst du die neuen Pläne buchen oder kontaktiere für mehr Informationen einfach unseren Support 👌🏽",
                                duration: 30,
                                hasCloseButton: false,
                                id: "dd",
                            });
                        }
                    }
                }
            });
            return [2 /*return*/];
        });
    }); }, [history, location]);
    useEffect(function () {
        doAccessCheck();
    }, [history, location]);
    //Gets plan data -> how many free deliverys left etc.
    useEffect(function () {
        firebase
            .firestore()
            .collection("customerPlans")
            .doc(firebase.auth().currentUser.uid)
            .onSnapshot(function (doc) {
            var data = doc.data();
            if (!data) {
                //If data exists he has already done the onboarding
                //If no data then redirect to onboarding -> in case in signup
                //customer tries to jump directly to /dashboard
                history.push("/onboarding/1");
                return;
            }
            if (data.blocked) {
                history.push("/settings");
            }
            setPlanData(data);
            if (data.currentPeriodEnds) {
                //Check plan period for reset and payment blockage
                //Serverside-Checking how to handle periode
                //Clientside-Checking to minimize api-Calls
                //but also checkt on serverside to prevent fraud
                if (moment.unix(data.currentPeriodEnds).diff(moment(), "days") < -3) {
                    // const checkPeriodReset = firebase
                    //   .app()
                    //   .functions("europe-west3")
                    //   .httpsCallable("checkPeriodReset");
                    // checkPeriodReset();
                    console.log("Periode abgelaufen");
                }
            }
            else {
                if (data.subscriptionId) {
                    //Shouldt be not set but when then here it will be set
                    var setPeriodeInfo = firebase
                        .app()
                        .functions("europe-west3")
                        .httpsCallable("setPeriodeInfo");
                    setPeriodeInfo();
                }
            }
        });
    }, []);
    return (_jsx(PlanContext.Provider, __assign({ value: { plan: [isPlanData, setPlanData] } }, { children: children })));
}
