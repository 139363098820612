var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { TaskBarAlertContext } from "./TaskBarAlertContext";
export default function TaskBarAlertContextProvider(_a) {
    var children = _a.children;
    //Handles Alert-Box over Taskbar
    var _b = useState({
        intent: undefined,
        title: undefined,
        description: undefined,
    }), taskBarAlertBox = _b[0], setTaskBarAlertBox = _b[1];
    return (_jsx(TaskBarAlertContext.Provider, __assign({ value: [taskBarAlertBox, setTaskBarAlertBox] }, { children: children })));
}
