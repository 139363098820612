var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ArrowUpIcon, Button, Dialog, IconButton, Pane, Paragraph, Select, Switch, toaster, Tooltip, } from "evergreen-ui";
import "firebase/auth";
import { useContext, useRef, useState } from "react";
import ReactDataGrid from "react-data-grid";
import { CSVReader } from "react-papaparse";
import firebase from "../config";
import { LocationContext } from "../contexts/LocationContext";
import { TaskContext } from "../contexts/TaskContext";
import { createNewTask } from "../utility/createNewTask";
import { getCoordinates } from "../utility/getCoordinates";
import { useSetFlag } from "../utility/use-set-flag";
export default function CSVImporter(props) {
    var csvRef = useRef();
    var _a = useState(false), isLoading = _a[0], setLoading = _a[1];
    var _b = useState(false), shouldBeFav = _b[0], setShouldBeFav = _b[1];
    var _c = useState(false), isDialogShown = _c[0], setDialogShown = _c[1];
    var _d = useState(), isCSVData = _d[0], setCSVData = _d[1];
    var _e = useState(0), rowCount = _e[0], setCount = _e[1];
    var _f = useState([{}]), areCols = _f[0], setCols = _f[1];
    var _g = useState([{}]), areRows = _g[0], setRows = _g[1];
    var locations = useContext(LocationContext).locations;
    var areBusinessLocations = locations[0], setBusinessLocations = locations[1];
    var _h = useState(undefined), responsibleBusiness = _h[0], setResponsibleBusiness = _h[1];
    var headers = useContext(TaskContext).headers;
    var _j = useState(false), isEdited = _j[0], setEdited = _j[1];
    var checkOrderCreated = useSetFlag().checkOrderCreated;
    var reportCsvUploads = useSetFlag().reportCsvUploads;
    var _k = useState({ encoding: "ISO-8859-1" }), config = _k[0], setConfig = _k[1];
    function handleOpenDialog(e) {
        // Note that the ref is set async, so it might be null at some point
        if (csvRef.current) {
            csvRef.current.open(e);
        }
    }
    function HeaderSelect(props) {
        var _a = useState(props.col), value = _a[0], setValue = _a[1];
        function onChange(value, index) {
            setCols(function (state) {
                var newState = __spreadArray([], state, true);
                newState[index].name = value;
                return newState;
            });
            setValue(value);
        }
        return (_jsxs(Select, __assign({ value: value, onChange: function (e) { return onChange(e.target.value, props.i); } }, { children: [_jsx("option", __assign({ value: "Ignorieren" }, { children: "Ignorieren" })), _jsx("option", __assign({ value: "Vorname" }, { children: "Vorname" })), _jsx("option", __assign({ value: "Nachname" }, { children: "Nachname" })), _jsx("option", __assign({ value: "Name" }, { children: "Vor- u. Nachname" })), _jsx("option", __assign({ value: "ZeitfensterVon" }, { children: "Zeitfenster von" })), _jsx("option", __assign({ value: "ZeitfensterBis" }, { children: "Zeitfenster bis" })), _jsx("option", __assign({ value: "Datum" }, { children: "Datum" })), _jsx("option", __assign({ value: "Betrag" }, { children: "Betrag" })), _jsx("option", __assign({ value: "Hinweis" }, { children: "Notiz" })), _jsx("option", __assign({ value: "Email" }, { children: "Email" })), _jsx("option", __assign({ value: "Telefon" }, { children: "Telefon" })), _jsx("option", __assign({ value: "Stra\u00DFe" }, { children: "Stra\u00DFe" })), _jsx("option", __assign({ value: "PLZ" }, { children: "PLZ" })), _jsx("option", __assign({ value: "Ort" }, { children: "Ort" })), _jsx("option", __assign({ value: "PLZ u. Ort" }, { children: "PLZ u. Ort" })), _jsx("option", __assign({ value: "Stra\u00DFe, PLZ u. Ort" }, { children: "Stra\u00DFe, PLZ u. Ort" })), _jsx("option", __assign({ value: "Jobnummer" }, { children: "Jobnummer" }))] })));
    }
    function handleOnFileLoad(data) {
        var colArr = [];
        var cols = data[0];
        var rows = data;
        cols.data.map(function (col, i) {
            var colObj = {};
            colObj.key = i;
            colObj.name = col;
            colObj.editable = true;
            colObj.headerRenderer = _jsx(HeaderSelect, { col: col, i: i });
            colArr.push(colObj);
        });
        data = data.shift();
        //row aufbau {0:"name",1:"datum",2:"betrag",3:"Notiz",4:"Email",5:"Tel",6:"str",7:"plz",8:"ort"}
        var rowArr = [];
        var rowCount = 0;
        //Replace umlaute with options
        var umlautFilter = function (value) {
            return value
                .replace("Ã¼", "ü")
                .replace("ÃŸ", "ß")
                .replace("Ã¤", "ä")
                .replace("Ã¶", "ö")
                .replace(/\u00c4/g, "Ae")
                .replace(/\u00e4/g, "ae")
                .replace(/\u00dc/g, "Ue")
                .replace(/\u00fc/g, "ue")
                .replace(/\u00d6/g, "Oe")
                .replace(/\u00f6/g, "oe")
                .replace(/\u00df/g, "ss");
        };
        rows
            .filter(function (row) {
            console.log(row);
            return !(row.data.reduce(function (accu, curr) {
                console.log("Rowdata", accu + curr);
                return accu + curr;
            }, "") === "");
        })
            .map(function (row, i) {
            var rowObj = {};
            row.data.map(function (val, i) {
                rowObj[i] = umlautFilter(val);
            });
            rowCount++;
            rowArr.push(rowObj);
        });
        setCols(colArr);
        setCount(rowCount);
        setRows(rowArr);
        setCSVData(data);
        setDialogShown(true);
    }
    function handleOnError(err, file, inputElem, reason) {
        console.log(err);
    }
    //{"cellKey":0,"fromRow":14,"toRow":14,"rowIds":[null],"updated":{"0":"df"},"action":"CELL_UPDATE","fromRowData":{"0":"Lisa Lauer ","1":"11.11.2020","2":"148,7","3":"","4":"","5":"","6":"Oberhofer Weg 10","7":"59955","8":"Winterberg"}}
    function onGridRowsUpdated(_a) {
        var fromRow = _a.fromRow, toRow = _a.toRow, updated = _a.updated;
        setRows(function (state) {
            var rows = state.slice();
            for (var i = fromRow; i <= toRow; i++) {
                rows[i] = __assign(__assign({}, rows[i]), updated);
            }
            return rows;
        });
    }
    function showWarnings(upload, index) {
        if (upload) {
            toaster.warning("Der Auftrag in Zeile ".concat(index + 1, " wird nicht verarbeitet wegen fehlender Daten"));
        }
        else {
            toaster.notify("Der Auftrag in Zeile ".concat(index + 1, " wird in dieser Form nicht verarbeitet werden k\u00F6nnen!"));
        }
    }
    var importAsFav = function (newTask) {
        var _a, _b, _c, _d, _e, _f, _g;
        firebase
            .firestore()
            .collection("favorites")
            .doc(newTask.task_id)
            .set({
            name: newTask.customer_name,
            phone: (_a = newTask.customer_phone) !== null && _a !== void 0 ? _a : "",
            mail: (_b = newTask.customer_mail) !== null && _b !== void 0 ? _b : "",
            type: newTask.task_type,
            note: (_c = newTask.task_note) !== null && _c !== void 0 ? _c : null,
            location: {
                address: newTask.task_address,
                coords: newTask.task_coords,
            },
            earliest: (_d = newTask.task_earliest) !== null && _d !== void 0 ? _d : "",
            latest: (_e = newTask.task_latest) !== null && _e !== void 0 ? _e : "",
            processTime: (_f = newTask.task_process_time) !== null && _f !== void 0 ? _f : null,
            origin: responsibleBusiness.id,
            owner: firebase.auth().currentUser.uid,
            uid: (_g = newTask.task_id) !== null && _g !== void 0 ? _g : null,
        })
            .then(function () {
            toaster.success("Ein neuer Favorit wurde angelegt");
        })
            .catch(function () {
            toaster.notify("Der Favorit konnte nicht angelegt werden");
        });
    };
    function createTasks(upload) {
        return __awaiter(this, void 0, void 0, function () {
            var nameIndex, firstNameIndex, lastNameIndex, dateIndex, moneyIndex, noteIndex, emailIndex, phoneIndex, streetIndex, zipIndex, cityIndex, zipcityIndex, earliestIndex, latestIndex, job_no, streetzipcityIndex;
            var _this = this;
            return __generator(this, function (_a) {
                nameIndex = areCols.filter(function (col) { return col.name === "Name"; });
                firstNameIndex = areCols.filter(function (col) { return col.name === "Vorname"; });
                lastNameIndex = areCols.filter(function (col) { return col.name === "Nachname"; });
                dateIndex = areCols.filter(function (col) { return col.name === "Datum"; });
                moneyIndex = areCols.filter(function (col) { return col.name === "Betrag"; });
                noteIndex = areCols.filter(function (col) { return col.name === "Hinweis"; });
                emailIndex = areCols.filter(function (col) { return col.name === "Email"; });
                phoneIndex = areCols.filter(function (col) { return col.name === "Telefon"; });
                streetIndex = areCols.filter(function (col) { return col.name === "Straße"; });
                zipIndex = areCols.filter(function (col) { return col.name === "PLZ"; });
                cityIndex = areCols.filter(function (col) { return col.name === "Ort"; });
                zipcityIndex = areCols.filter(function (col) { return col.name === "PLZ u. Ort"; });
                earliestIndex = areCols.filter(function (col) { return col.name === "ZeitfensterVon"; });
                latestIndex = areCols.filter(function (col) { return col.name === "ZeitfensterBis"; });
                job_no = areCols.filter(function (col) { return col.name === "Jobnummer"; });
                streetzipcityIndex = areCols.filter(function (col) { return col.name === "Straße, PLZ u. Ort"; });
                areRows.map(function (row, posi) { return __awaiter(_this, void 0, void 0, function () {
                    var query, _a, location, alternatives, originalAddressQuery, unclear_location, newTask, error_1, query, _b, location, alternatives, originalAddressQuery, unclear_location, newTask, error_2, query, _c, location, alternatives, originalAddressQuery, unclear_location, newTask, error_3;
                    return __generator(this, function (_d) {
                        switch (_d.label) {
                            case 0:
                                //Check for required fields but not related to street
                                if ((firstNameIndex.length !== 0 && nameIndex.length === 0) ||
                                    (lastNameIndex.length !== 0 && nameIndex.length === 0)) {
                                    if (lastNameIndex.length === 0 || firstNameIndex.length === 0) {
                                        toaster.warning("Zu dem Vorname-Feld wird immer ein Nachname-Feld benötigt");
                                        return [2 /*return*/];
                                    }
                                }
                                if ((nameIndex.length !== 1 && firstNameIndex.length === 0) ||
                                    dateIndex.length !== 1) {
                                    toaster.warning("Eines der erforderlichen Felder \"Vor- u. Nachname\"/\"Datum\" ist nicht angegeben. Es ist m\u00F6glicherweise doppelt oder garnicht angew\u00E4hlt!");
                                    return [2 /*return*/];
                                }
                                if (!(streetzipcityIndex.length === 0)) return [3 /*break*/, 14];
                                if (!(zipcityIndex.length === 0)) return [3 /*break*/, 7];
                                if (!(streetIndex.length != 1 ||
                                    zipIndex.length != 1 ||
                                    cityIndex.length != 1)) return [3 /*break*/, 1];
                                toaster.warning("In ihrer Tabelle ist anscheinend Stra\u00DFe, PLZ und Ort seperat! Bitte geben Sie die entsprechenden Zeilen an!");
                                return [2 /*return*/];
                            case 1:
                                _d.trys.push([1, 5, , 6]);
                                query = row[streetIndex[0].key] +
                                    " " +
                                    row[zipIndex[0].key] +
                                    " " +
                                    row[cityIndex[0].key];
                                return [4 /*yield*/, getCoordinates(query)];
                            case 2:
                                _a = _d.sent(), location = _a.location, alternatives = _a.alternatives, originalAddressQuery = _a.originalAddressQuery, unclear_location = _a.unclear_location;
                                console.log(location);
                                checkOrderCreated();
                                newTask = createNewTask(nameIndex[0]
                                    ? row[nameIndex[0].key]
                                    : firstNameIndex[0]
                                        ? row[firstNameIndex[0].key] + " " + row[lastNameIndex[0].key]
                                        : "", emailIndex[0] ? row[emailIndex[0].key] : "", phoneIndex[0] ? row[phoneIndex[0].key] : "", "delivery", new Date(), earliestIndex[0] ? row[earliestIndex[0].key] : "", latestIndex[0] ? row[latestIndex[0].key] : "", null, null, noteIndex[0] ? row[noteIndex[0].key] : "", location.label, location.value, moneyIndex[0] ? row[moneyIndex[0].key] : "", responsibleBusiness, firebase.auth().currentUser.uid, "CSV-Import", job_no[0] ? row[job_no[0].key] : undefined, alternatives, originalAddressQuery, unclear_location);
                                if (!upload) return [3 /*break*/, 4];
                                if (shouldBeFav) {
                                    importAsFav(newTask);
                                    return [2 /*return*/];
                                }
                                return [4 /*yield*/, firebase
                                        .firestore()
                                        .collection("tasks")
                                        .doc(newTask.task_id)
                                        .set(newTask)];
                            case 3:
                                _d.sent();
                                _d.label = 4;
                            case 4: return [3 /*break*/, 6];
                            case 5:
                                error_1 = _d.sent();
                                console.log(error_1);
                                showWarnings(upload, posi);
                                return [3 /*break*/, 6];
                            case 6: return [3 /*break*/, 13];
                            case 7:
                                if (!(streetIndex.length != 1)) return [3 /*break*/, 8];
                                toaster.warning("In ihrer Tabelle ist PLZ u. Ort in einem Feld! Bitte geben Sie die entsprechenden Zeile f\u00FCr die Stra\u00DFe an!");
                                return [2 /*return*/];
                            case 8:
                                _d.trys.push([8, 12, , 13]);
                                query = row[streetIndex[0].key] + " " + row[zipcityIndex[0].key];
                                return [4 /*yield*/, getCoordinates(query)];
                            case 9:
                                _b = _d.sent(), location = _b.location, alternatives = _b.alternatives, originalAddressQuery = _b.originalAddressQuery, unclear_location = _b.unclear_location;
                                newTask = createNewTask(nameIndex[0]
                                    ? row[nameIndex[0].key]
                                    : firstNameIndex[0]
                                        ? row[firstNameIndex[0].key] + " " + row[lastNameIndex[0].key]
                                        : "", emailIndex[0] ? row[emailIndex[0].key] : "", phoneIndex[0] ? row[phoneIndex[0].key] : "", "delivery", new Date(), earliestIndex[0] ? row[earliestIndex[0].key] : "", latestIndex[0] ? row[latestIndex[0].key] : "", null, null, noteIndex[0] ? row[noteIndex[0].key] : "", location.label, location.value, moneyIndex[0] ? row[moneyIndex[0].key] : "", responsibleBusiness, firebase.auth().currentUser.uid, "CSV-Import", job_no[0] ? row[job_no[0].key] : undefined, alternatives, originalAddressQuery, unclear_location);
                                if (!upload) return [3 /*break*/, 11];
                                if (shouldBeFav) {
                                    importAsFav(newTask);
                                    return [2 /*return*/];
                                }
                                return [4 /*yield*/, firebase
                                        .firestore()
                                        .collection("tasks")
                                        .doc(newTask.task_id)
                                        .set(newTask)];
                            case 10:
                                _d.sent();
                                _d.label = 11;
                            case 11: return [3 /*break*/, 13];
                            case 12:
                                error_2 = _d.sent();
                                console.log(error_2);
                                showWarnings(upload, posi);
                                return [3 /*break*/, 13];
                            case 13: return [3 /*break*/, 19];
                            case 14:
                                _d.trys.push([14, 18, , 19]);
                                query = row[streetzipcityIndex[0].key];
                                return [4 /*yield*/, getCoordinates(query)];
                            case 15:
                                _c = _d.sent(), location = _c.location, alternatives = _c.alternatives, originalAddressQuery = _c.originalAddressQuery, unclear_location = _c.unclear_location;
                                newTask = createNewTask(nameIndex[0]
                                    ? row[nameIndex[0].key]
                                    : firstNameIndex[0]
                                        ? row[firstNameIndex[0].key] + " " + row[lastNameIndex[0].key]
                                        : "", emailIndex[0] ? row[emailIndex[0].key] : "", phoneIndex[0] ? row[phoneIndex[0].key] : "", "delivery", new Date(), earliestIndex[0] ? row[earliestIndex[0].key] : "", latestIndex[0] ? row[latestIndex[0].key] : "", null, null, noteIndex[0] ? row[noteIndex[0].key] : "", location.label, location.value, moneyIndex[0] ? row[moneyIndex[0].key] : "", responsibleBusiness, firebase.auth().currentUser.uid, "CSV-Import", job_no[0] ? row[job_no[0].key] : undefined, alternatives, originalAddressQuery, unclear_location);
                                if (!upload) return [3 /*break*/, 17];
                                if (shouldBeFav) {
                                    importAsFav(newTask);
                                    return [2 /*return*/];
                                }
                                return [4 /*yield*/, firebase
                                        .firestore()
                                        .collection("tasks")
                                        .doc(newTask.task_id)
                                        .set(newTask)];
                            case 16:
                                _d.sent();
                                _d.label = 17;
                            case 17: return [3 /*break*/, 19];
                            case 18:
                                error_3 = _d.sent();
                                console.log(error_3);
                                showWarnings(upload, posi);
                                return [3 /*break*/, 19];
                            case 19: return [2 /*return*/];
                        }
                    });
                }); });
                if (upload) {
                    setLoading(false);
                    setDialogShown(false);
                }
                else {
                    setLoading(false);
                }
                reportCsvUploads(areRows.length);
                return [2 /*return*/];
            });
        });
    }
    function uploadCsv(upload) {
        if (responsibleBusiness === undefined) {
            toaster.notify("Bitte wählen Sie den Standort aus, welche für diese Aufträge verantwortlich sind.");
        }
        else {
            createTasks(upload);
        }
    }
    return (_jsxs(_Fragment, { children: [_jsxs(Dialog, __assign({ isShown: isDialogShown, title: "Datei-Import", confirmLabel: "Importieren", shouldCloseOnOverlayClick: false, shouldCloseOnEscapePress: false, hasClose: true, hasFooter: false, cancelLabel: "Abbrechen", width: "90vw", height: "90vh" }, { children: [areBusinessLocations.map(function (location, index) {
                        return (_jsx(Button, __assign({ onClick: function () { return setResponsibleBusiness(location); } }, { children: location.name }), index));
                    }), _jsx(Button, __assign({ onClick: function () { return setConfig({ encoding: "UTF-8" }); } }, { children: "Umlaute" })), _jsxs(Pane, __assign({ marginTop: 8, marginBottom: 8, display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(Paragraph, { children: "Als Favoriten importieren" }), _jsx(Switch, { marginLeft: 8, checked: shouldBeFav, onChange: function () { return setShouldBeFav(function (state) { return !state; }); } })] })), _jsx(ReactDataGrid, { columns: areCols, rows: areRows, rowGetter: function (i) { return areRows[i]; }, rowsCount: rowCount, onGridRowsUpdated: function (obj) { return onGridRowsUpdated(obj); }, enableCellSelect: true }), _jsxs(Pane, __assign({ style: {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-around",
                            marginBottom: 20,
                        } }, { children: [_jsx(Button, __assign({ isDisable: isLoading, onClick: function () { return setDialogShown(false); } }, { children: "Abbrechen" })), _jsxs(Pane, { children: [_jsx(Button, __assign({ isLoading: isLoading, appearance: "primary", intent: "warning", onClick: function () { return uploadCsv(false); } }, { children: "Pr\u00FCfen" })), _jsx(Button, __assign({ isLoading: isLoading, appearance: "primary", onClick: function () { return uploadCsv(true); } }, { children: "Hochladen" }))] })] }))] })), _jsx(CSVReader, __assign({ ref: csvRef, onFileLoad: function (e) { return handleOnFileLoad(e); }, onError: function (e) { return handleOnError(e); }, config: config, noClick: true, noDrag: true, noProgressBar: true }, { children: function (_a) {
                    var file = _a.file;
                    return (_jsx(Tooltip, __assign({ content: "CSV Datei hochladen" }, { children: _jsx(IconButton, { size: "large", intent: "success", icon: _jsx(ArrowUpIcon, { size: 28, color: "#fff" }), onClick: function (e) { return handleOpenDialog(e); }, marginLeft: 5, style: { backgroundColor: "#49beef", border: "none" } }) })));
                } }))] }));
}
