var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Heading, Pane } from "evergreen-ui";
import React from "react";
var DashboardCard = function (_a) {
    var children = _a.children, title = _a.title, size = _a.size, horizontalPadding = _a.horizontalPadding, style = _a.style, contentStyle = _a.contentStyle, onClick = _a.onClick;
    return (_jsxs(Pane, __assign({ style: __assign({ flex: size, backgroundColor: "#FFF", borderRadius: 6, boxShadow: "0px 10px 35px #D8DAE5", paddingTop: 16, paddingBottom: 8, paddingLeft: (horizontalPadding !== null && horizontalPadding !== void 0 ? horizontalPadding : true) ? 18 : 0, paddingRight: (horizontalPadding !== null && horizontalPadding !== void 0 ? horizontalPadding : true) ? 18 : 0, flexGrow: size, cursor: onClick ? "unset" : "pointer" }, style), onClick: onClick }, { children: [title && (_jsx(Heading, __assign({ size: 700, style: {
                    color: "#2D3B51",
                    marginBottom: 10,
                    marginLeft: (horizontalPadding !== null && horizontalPadding !== void 0 ? horizontalPadding : true) ? 0 : 18,
                    marginRight: (horizontalPadding !== null && horizontalPadding !== void 0 ? horizontalPadding : true) ? 0 : 18
                } }, { children: title }))), _jsx("div", __assign({ style: __assign({ height: "calc(100% - 36px)" }, contentStyle) }, { children: children }))] })));
};
export default DashboardCard;
