var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Pane } from "evergreen-ui";
import { useState } from "react";
//@ts-ignore
import { TwitterPicker } from "react-color";
export var ColorPickerButton = function (_a) {
    var color = _a.color, onClick = _a.onClick;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    return (_jsxs(Pane, __assign({ position: "relative" }, { children: [_jsx(Button, __assign({ style: { backgroundColor: color }, onClick: function () { return setIsOpen(!isOpen); } }, { children: "Farbe Ausw\u00E4hlen" })), isOpen && (_jsx(Pane, __assign({ position: "relative", zIndex: 200 }, { children: _jsx(TwitterPicker, { zIndex: 200, onChange: function (color) {
                        onClick(color.hex);
                        setIsOpen(false);
                    } }) })))] })));
};
