export var PrinterCapabilities;
(function (PrinterCapabilities) {
    PrinterCapabilities["LABEL"] = "label";
    PrinterCapabilities["RECEIPT"] = "receipt";
    PrinterCapabilities["AUTONOMOUS"] = "autonomous";
})(PrinterCapabilities || (PrinterCapabilities = {}));
export var PrinterStatus;
(function (PrinterStatus) {
    PrinterStatus["OFFLINE"] = "offline";
    PrinterStatus["ERRORED"] = "errored";
    PrinterStatus["READY"] = "ready";
})(PrinterStatus || (PrinterStatus = {}));
export var PrinterType;
(function (PrinterType) {
    PrinterType["EPSON_TI"] = "epson-ti";
})(PrinterType || (PrinterType = {}));
