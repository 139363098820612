var formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
export function normaliseMoneyString(moneyAmount) {
    /**
     * "parseFloat" in JavaScript parses the string only up to the first non-numeric character. In this case, the comma (,) is considered a non-numeric character, and parsing stops at the comma. So, parseFloat("1,000") returns 1. To fix this issue, we use Number along with replace to convert the formatted number string back to a numeric value
     */
    try {
        if (typeof moneyAmount === "string") {
            // Replace comma with dot for correct parsing
            var numericValue = Number(moneyAmount.replace(",", "."));
            // Check if numericValue is a valid number after parsing
            if (isNaN(numericValue)) {
                throw new Error("Invalid number format");
            }
            moneyAmount = numericValue;
        }
        return formatter.format(moneyAmount);
    }
    catch (e) {
        // If an error occurs during formatting, return the input as is instead of the fallback value of "_,__€".
        return moneyAmount.toString();
    }
}
