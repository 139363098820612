var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Avatar, Button, ChatIcon, Heading, IconButton, LockIcon, Pane, Paragraph, PhoneIcon, Position, SideSheet, Tooltip, TrashIcon, } from "evergreen-ui";
import { useContext, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import DriverAvatar from "../components/DriverAvatar";
import firebase from "../config";
import { DriverContext } from "../contexts/DriverContext";
import { LayoutContext } from "../contexts/LayoutContext";
import { NotificationContext } from "../contexts/NotificationContext";
import { usePlan } from "../hooks/usePlan";
import { deleteNode } from "../utility/utility";
var NotificationCard = function (_a) {
    var notification = _a.notification;
    if (notification.type === "chat") {
        return (_jsxs(Pane, __assign({ marginBottom: 15, padding: 10, elevation: 1, display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row" }, { children: [_jsx(ChatIcon, { marginRight: 15 }), _jsxs(Pane, { children: [_jsx(Heading, { children: notification.name }), _jsx(Paragraph, { children: notification.msg })] })] })));
    }
    if (notification.type === "call") {
        return (_jsxs(Pane, __assign({ marginBottom: 15, padding: 10, elevation: 1, display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row" }, { children: [_jsx(PhoneIcon, { marginRight: 15 }), _jsxs(Pane, { children: [_jsx(Heading, { children: notification.name }), _jsx(Paragraph, { children: "Eingehender Anruf" })] })] })));
    }
};
var DriverNavBar = function () {
    var drivers = useContext(DriverContext).drivers;
    var areDrivers = drivers[0], setDrivers = drivers[1];
    var state = useContext(LayoutContext).state;
    var isEditMode = state[0], setEditMode = state[1];
    var notificationContext = useContext(NotificationContext).notifications;
    var notifications = notificationContext[0];
    var history = useHistory();
    var _a = useState(false), isNotificationTrayShown = _a[0], setNotificationTrayShown = _a[1];
    var plan = usePlan();
    var availableDrivers = useMemo(function () { return areDrivers.filter(function (driver) { return !driver.archived; }); }, [areDrivers]);
    var userPlan = useMemo(function () { return plan === null || plan === void 0 ? void 0 : plan.subscriptionLabel; }, [plan === null || plan === void 0 ? void 0 : plan.subscriptionLabel]);
    return (_jsxs(_Fragment, { children: [isNotificationTrayShown && (_jsxs(SideSheet, __assign({ position: Position.LEFT, isShown: isNotificationTrayShown, onCloseComplete: function () {
                    setNotificationTrayShown(false);
                } }, { children: [_jsxs(Pane, __assign({ margin: 40, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }, { children: [_jsx(Heading, __assign({ marginBottom: 10, className: "headline" }, { children: "Nachrichten" })), _jsx(Tooltip, __assign({ content: "Benachrichtigungen l\u00F6schen" }, { children: _jsx(IconButton, { onClick: function () {
                                        return deleteNode("notifications_" + firebase.auth().currentUser.uid);
                                    }, icon: TrashIcon }) }))] })), _jsx(Pane, __assign({ style: { padding: 20 } }, { children: Object.keys(notifications).map(function (key) {
                            return (_jsx(NotificationCard, { notification: notifications[key] }, key));
                        }) }))] }))), userPlan === "apomap-lite" ? (_jsxs(Pane, __assign({ elevation: 2, style: {
                    width: 60,
                    height: "99%",
                    marginLeft: 20,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundImage: "url(".concat("DriverBarBlur.png", ")"),
                    backgroundPosition: "top",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                } }, { children: [_jsx(Heading, { size: 700 }), _jsx(LockIcon, { size: 50, color: "#49beef" }), _jsx(Button, __assign({ size: "large", color: "#fff", style: {
                            width: "60px",
                            backgroundColor: "#2D3B51",
                            border: "none",
                        }, onClick: function () { return (window.location.href = "/upgrade"); } }, { children: "Upgrade" }))] }))) : (_jsxs(Pane, __assign({ padding: 2, paddingX: 5, elevation: 2, style: {
                    borderTopWidth: 7,
                    borderTopColor: "#49beef",
                    borderTopStyle: "solid",
                    width: 60,
                    height: "99%",
                    marginLeft: 20,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    overflowY: "auto",
                } }, { children: [_jsx(Pane, { marginTop: "15px" }), availableDrivers.map(function (driver, index) {
                        return (_jsx(DriverAvatar, { notifications: notifications, driver: driver }, driver.id));
                    }), _jsx(Avatar, { onClick: function () { return history.push("/employees"); }, name: "+", size: 40 })] })))] }));
};
export default DriverNavBar;
