var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, Pane, SelectMenu, Tooltip, WarningSignIcon, } from "evergreen-ui";
import { getLabelFromStatus } from "../utility/getLabelFromStatus";
import ReoptimizeBadge from "./ReoptimizeBadge";
export default function StatusBadge(_a) {
    var _b, _c;
    var status = _a.status, onChange = _a.onChange, id = _a.id, driver = _a.driver, plan = _a.plan, tour = _a.tour;
    var badge;
    switch (status) {
        case "an GLS übertragen":
            badge = (_jsx(Badge, __assign({ isInteractive: true, color: "#C0C0C0" }, { children: "an GLS \u00FCbertragen" })));
            break;
        case "auf dem Weg zum Kunden":
            badge = (_jsx(Badge, __assign({ isInteractive: true, color: "#49BEEF" }, { children: "auf dem Weg zum Kunden" })));
            break;
        case "Ausgeliefert durch GLS":
            badge = (_jsx(Badge, __assign({ isInteractive: true, color: "#E1F4DD" }, { children: "Ausgeliefert durch GLS" })));
            break;
        case "unassigned":
            badge = (_jsx(Badge, __assign({ isInteractive: true, color: "#C0C0C0" }, { children: "Nicht zugeteilt" })));
            break;
        case "assigned":
            badge = (_jsx(Badge, __assign({ isInteractive: true, color: "#D3F5F7" }, { children: "Zugeteilt" })));
            break;
        case "ontheway":
            badge = (_jsx(Badge, __assign({ isInteractive: true, color: "#49BEEF" }, { children: "Auf dem Weg" })));
            break;
        case "optimizing":
            badge = (_jsx(Badge, __assign({ isInteractive: true, color: "#49BEEF" }, { children: "Optimierung l\u00E4uft" })));
            break;
        case "pickedup":
            badge = (_jsx(Badge, __assign({ isInteractive: true, color: "#E235FE" }, { children: "Eingesammelt" })));
            break;
        case "successfull":
            badge = (_jsx(Badge, __assign({ isInteractive: true, color: "#E1F4DD" }, { children: "Zugestellt" })));
            break;
        case "declined":
            badge = (_jsx(Badge, __assign({ isInteractive: true, color: "#F66277" }, { children: "Nicht angetroffen" })));
            break;
        case "error":
            badge = (_jsx(Badge, __assign({ isInteractive: true, color: "red" }, { children: "Fehler" })));
            break;
        case "prepared":
            badge = (_jsx(Badge, __assign({ isInteractive: true, color: "#FFB020" }, { children: "Vorbereitet" })));
            break;
        case "finished":
            badge = (_jsx(Badge, __assign({ isInteractive: true, color: "#9B5CF6" }, { children: "Beendet" })));
            break;
        case "payment":
            badge = (_jsx(Badge, __assign({ isInteractive: true, color: "#BDC4A7" }, { children: "Zahlung" })));
            break;
        case "signature":
            badge = (_jsx(Badge, __assign({ isInteractive: true, color: "#93827F" }, { children: "Unterschrift" })));
            break;
        case "note":
            badge = (_jsx(Badge, __assign({ isInteractive: true, color: "#FEEDEF" }, { children: "Notiz" })));
            break;
        case "deleted":
            badge = (_jsx(Badge, __assign({ isInteractive: true, color: "#45050C" }, { children: "Gel\u00F6scht" })));
            break;
        case "attached":
            badge = (_jsx(Tooltip, __assign({ content: "".concat(driver), position: "top" }, { children: _jsx(Badge, __assign({ isInteractive: true, color: "#93827F" }, { children: "Angehangen" })) })));
            break;
        case "empty":
            badge = (_jsx(Badge, __assign({ isInteractive: true, color: "#BDC4A7" }, { children: "Nicht optimiert" })));
            break;
        default:
            badge = (_jsx(Badge, __assign({ isInteractive: true, color: "#9B5CF6" }, { children: "Unbekannt" })));
            break;
    }
    if ((tour && tour.tasksToAdd && ((_b = tour.tasksToAdd) === null || _b === void 0 ? void 0 : _b.length) > 0) ||
        (tour && tour.tasksToRemove && ((_c = tour.tasksToRemove) === null || _c === void 0 ? void 0 : _c.length) > 0)) {
        return _jsx(ReoptimizeBadge, { tour: tour });
    }
    if (status === "ontheway" && !tour) {
        return (_jsx(SelectMenu, __assign({ hasFilter: false, title: "Status \u00E4ndern", options: ["successfull", "declined"].map(function (label) { return ({
                label: getLabelFromStatus(label),
                value: label,
            }); }), selected: status, onSelect: function (e) { return onChange(e.value, id); } }, { children: badge })));
    }
    if (status === "an GLS übertragen" ||
        status === "auf dem Weg zum Kunden" ||
        status === "Ausgeliefert durch GLS") {
        return (_jsx(SelectMenu, __assign({ hasFilter: false, title: "Status \u00E4ndern", options: ["unassigned", "prepared"].map(function (label) { return ({
                label: getLabelFromStatus(label),
                value: label,
            }); }), selected: status, onSelect: function (e) { return onChange(e.value, id); } }, { children: badge })));
    }
    if ((status === "unassigned" && onChange) ||
        (status === "prepared" && onChange)
    // ||(status === "attached" && onChange)
    ) {
        return (_jsx(SelectMenu, __assign({ hasFilter: false, title: "Status \u00E4ndern", options: ["unassigned", "prepared"].map(function (label) { return ({
                label: getLabelFromStatus(label),
                value: label,
            }); }), selected: status, onSelect: function (e) { return onChange(e.value, id); } }, { children: badge })));
    }
    else if (status === "error") {
        return (_jsx(SelectMenu, __assign({ hasFilter: false, title: "Status \u00E4ndern", options: ["unassigned"].map(function (label) { return ({
                label: getLabelFromStatus(label),
                value: label,
            }); }), selected: status, onSelect: function (e) { return onChange(e.value, id); } }, { children: badge })));
    }
    else if (!driver &&
        status !== "unassigned" &&
        status !== "prepared" &&
        status !== "deleted" &&
        status !== "attached" &&
        status !== "error" &&
        status !== "successfull" &&
        status !== "declined") {
        return (_jsxs(Pane, __assign({ style: { display: "flex", alignItems: "flex-start" } }, { children: [_jsx(SelectMenu, __assign({ hasFilter: false, title: "Status \u00E4ndern", options: ["unassigned", "prepared", "successfull"].map(function (label) { return ({
                        label: getLabelFromStatus(label),
                        value: label,
                    }); }), selected: status, onSelect: function (e) { return onChange(e.value, id); } }, { children: badge })), _jsx(Tooltip, __assign({ content: "Zugeteilter Auftrag ohne Tour" }, { children: _jsx(WarningSignIcon, { position: "relative", size: 10 }) }))] })));
    }
    else if (status === "assigned" && onChange) {
        return (_jsx(SelectMenu, __assign({ hasFilter: false, title: "Status \u00E4ndern", options: ["successfull", "declined"].map(function (label) { return ({
                label: getLabelFromStatus(label),
                value: label,
            }); }), selected: status, onSelect: function (e) { return onChange(e.value, id); } }, { children: badge })));
    }
    else {
        return badge;
    }
}
