var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Button, Dialog, Heading, Pane, Paragraph, toaster, } from "evergreen-ui";
import "firebase/auth";
import "firebase/firestore";
import { useCallback, useContext, useEffect, useState, } from "react";
import firebase from "../../config";
import { LocationContext } from "../../contexts/LocationContext";
import useFavoritesOriginResolver from "./utils/use-favorites-origin-resolver";
export var FavoritesOriginResolver = function () {
    var _a, _b, _c, _d;
    var _e = useFavoritesOriginResolver(), isShown = _e.isShown, setIsShown = _e.setIsShown, favorites = _e.favorites;
    //@ts-ignore
    var locations = useContext(LocationContext).locations;
    var businessLocations = locations[0];
    var _f = useState(0), activeIndex = _f[0], setActiveIndex = _f[1];
    var addLocationToFavorite = function (selectedLocationId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            firebase
                .firestore()
                .collection("favorites")
                .doc(favorites[activeIndex].uid)
                .update({
                origin: selectedLocationId,
            })
                .then(function () {
                toaster.success("Standort erfolgreich hinzugefügt");
                setActiveIndex(function (s) { return s + 1; });
            })
                .catch(function (e) { return toaster.danger(e); });
            return [2 /*return*/];
        });
    }); };
    var close = useCallback(function () {
        setActiveIndex(0);
        setIsShown(false);
    }, [setIsShown]);
    useEffect(function () {
        if (activeIndex === favorites.length && isShown) {
            toaster.success("Erfolgreich abgeschlossen!");
            close();
        }
    }, [activeIndex, favorites, close, isShown]);
    return (_jsxs(Dialog, __assign({ width: "80vw", isShown: isShown, title: "Favoriten-Pr\u00FCfung", onCloseComplete: close, confirmLabel: "Beenden", hasCancel: false }, { children: [_jsxs(Heading, { children: ["Fehlerhafter Favorit ", activeIndex + 1, "/", favorites.length] }), _jsx(Paragraph, __assign({ maxWidth: 350, fontStyle: "italic" }, { children: "Bei diesem Favoriten wurde der zugeh\u00F6rige Standort nicht richtig vom System erfasst. Bitte helfen Sie uns diesen, dem richtigen Standort zuzuweisen" })), _jsxs("div", { children: [_jsxs(Paragraph, __assign({ fontSize: "larger", fontWeight: "bold", marginTop: 16 }, { children: ["Name: ", (_a = favorites[activeIndex]) === null || _a === void 0 ? void 0 : _a.name] })), _jsxs(Paragraph, __assign({ fontSize: "larger", fontWeight: "bold" }, { children: ["Adresse: ", (_b = favorites[activeIndex]) === null || _b === void 0 ? void 0 : _b.location.address] }))] }, (_d = (_c = favorites[activeIndex]) === null || _c === void 0 ? void 0 : _c.uid) !== null && _d !== void 0 ? _d : "initialAddress"), _jsx(Heading, __assign({ marginTop: 16 }, { children: "Zu welchem Standort geh\u00F6rt dieser Favorit?" })), _jsx(Pane, __assign({ marginTop: 8, display: "flex", flexDirection: "row" }, { children: businessLocations.map(function (location) { return (_jsx(Button, __assign({ onClick: function () { return addLocationToFavorite(location.id); } }, { children: location === null || location === void 0 ? void 0 : location.name }))); }) }))] })));
};
