var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
//Root of the app - from here all starts to build up
//Provides the data from loggedin user
import AuthProvider from "./container/AuthProvider";
//Manages the routing of the urls
import Routing from "./container/Routing";
//Setup for Stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
//Styling to import fonts
import "./App.css";
import ErrorBoundary from "./components/ErrorBoundary";
import QuestionProvider from "./provider/QuestionProvider";
var stripePromise = loadStripe("pk_live_diOFZKCxuQGI82ygyainYsNw00tD6C5U5X");
function App() {
    return (_jsx(Elements, __assign({ stripe: stripePromise }, { children: _jsx("div", __assign({ style: {
                width: "100vw",
                height: "100vh",
                display: "flex",
                overflow: "hidden",
            } }, { children: _jsx(QuestionProvider, { children: _jsx(AuthProvider, { children: _jsx(ErrorBoundary, { children: _jsx(Routing, {}) }) }) }) })) })));
}
export default App;
