var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
//Chatbubble from the chater
import React from "react";
import firebase from "../config";
import "firebase/database";
import { Text, TickCircleIcon, BanCircleIcon, TrashIcon, Icon, } from "evergreen-ui";
//ref to realtime database
var chats = firebase
    .app()
    .database("https://apomapdreinull-default-rtdb.europe-west1.firebasedatabase.app/");
export default function ChatBubbleOwn(props) {
    //Renders read icon if message was read
    function renderRead(read) {
        if (read) {
            return _jsx(TickCircleIcon, { size: 10, color: "success", marginRight: 5 });
        }
        else {
            return _jsx(BanCircleIcon, { size: 10, color: "base", marginRight: 5 });
        }
    }
    //Deletes message and plays sound
    function deleteMessage(node, uid) {
        chats.ref("".concat(node, "/").concat(uid)).update({ deleted: true });
    }
    //Renders delete button for own messages only
    function renderDelete(node, key, deleted) {
        if (!deleted) {
            return (_jsx(Icon, { onClick: function () {
                    deleteMessage(node, key);
                }, icon: TrashIcon, size: 12, marginRight: 5 }));
        }
    }
    return (_jsxs("div", __assign({ style: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
        } }, { children: [renderRead(props.msg.read), renderDelete(props.chatNode, props.msg.uid, props.msg.deleted), _jsx(Text, __assign({ style: {
                    boxShadow: "0 0 1px rgba(0,0,0,0.3)",
                    borderRadius: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 10,
                    paddingRight: 10,
                    maxWidth: "80%",
                    marginBottom: 5,
                } }, { children: props.msg.deleted ? _jsx(Text, { children: "Wurde gel\u00F6scht" }) : props.msg.msg }))] }), props.msg.key));
}
