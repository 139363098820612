var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Badge } from 'evergreen-ui';
export default function getBadgeFromStatus(type) {
    var badge;
    switch (type) {
        case "pickup":
            badge = _jsx(Badge, __assign({ style: { fontFamily: "regular", fontSize: "0.9em" }, color: "#6E62B6" }, { children: "Abholung" }));
            break;
        case "delivery":
            badge = _jsx(Badge, __assign({ style: { fontFamily: "regular", fontSize: "0.9em" }, color: "#6E62B6" }, { children: "Lieferung" }));
            break;
        default:
            badge = _jsx(Badge, __assign({ style: { fontFamily: "regular", fontSize: "0.9em" }, color: "#6E62B6" }, { children: "Touraktion" }));
            break;
    }
    return badge;
}
