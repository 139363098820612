export var parseAddress = function (fullTextAddress) {
    //Get number
    var firstPart = fullTextAddress.split(",")[0].split(" ");
    var number = firstPart[firstPart.length - 1];
    var parsed = parseInt(number);
    //validate number
    if (isNaN(parsed)) {
        return [];
    }
    // Getting the streetName
    firstPart.pop();
    var street = firstPart.join(" ");
    //Get city
    var secondPart = fullTextAddress.split(",")[1].split(" ");
    var city = secondPart[secondPart.length - 1];
    var zip = secondPart[1];
    return {
        street: street,
        number: number,
        city: city,
        zip: zip,
        countryCode: "DE",
    };
};
