var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { toaster } from "evergreen-ui";
import moment from "moment";
import { useState } from "react";
import firebase from "../../config";
import Loading from "../../routes/Loading";
import { detachTaskFromTour } from "../../utility/detachTaskFromTour";
import { optimizeTour } from "../../utility/optimizeTour";
import { removeTaskFromTour } from "../../utility/removeTaskFromTour";
import DriverChat from "../DriverChat";
import TourCard from "../TourCard";
import BarcodeHint from "./BarcodeHint";
import PackingPanel from "./PackingPanel";
var getTaskIdsForTour = function (tour) {
    return (tour.activities
        // @ts-ignore ToDo: Fix Tour Activity type @Max
        .filter(function (act) { return act.type === "deliverShipment"; })
        .map(function (act) { return act.id; }));
};
var getPositionForTaskInTour = function (tour, taskId) {
    return getTaskIdsForTour(tour).indexOf(taskId);
};
export var getTourByAttachedTaskId = function (taskId) { return __awaiter(void 0, void 0, Promise, function () {
    var currentUser, document, tour;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                currentUser = firebase.auth().currentUser;
                if (!currentUser) {
                    toaster.danger("Sie sind nicht angemeldet.");
                    throw new Error("Unauthenticated Firebase access attempted");
                }
                return [4 /*yield*/, firebase
                        .firestore()
                        .collection("tours")
                        .where("owner", "==", currentUser.uid)
                        .where("tasksToAdd", "array-contains", taskId)
                        .get()];
            case 1:
                document = _a.sent();
                if (document.docs.length === 0)
                    return [2 /*return*/, null];
                tour = document.docs[0].data();
                return [2 /*return*/, tour];
        }
    });
}); };
var AssignmentPanel = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    var _s = useState(false), driverChatOpen = _s[0], _setDriverChatOpen = _s[1];
    var setDriverChatOpen = function (newState) {
        _setDriverChatOpen(newState);
        // To allow for using the message box, scanning is not allowed while the chat is open and vice versa
        props.setScanAllowed(!newState);
    };
    //detaches attached task from tour
    var detachTask = function () { return __awaiter(void 0, void 0, void 0, function () {
        var tour;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getTourByAttachedTaskId(props.currentTask.task_id)];
                case 1:
                    tour = _b.sent();
                    if (!tour) return [3 /*break*/, 3];
                    return [4 /*yield*/, detachTaskFromTour(props.currentTask.task_id, tour, (_a = props.currentTask) === null || _a === void 0 ? void 0 : _a.task_prepared)];
                case 2:
                    _b.sent();
                    _b.label = 3;
                case 3:
                    props.onUncontrolledUpdate();
                    return [2 /*return*/];
            }
        });
    }); };
    //Removes  task from tour
    var removeTask = function () { return __awaiter(void 0, void 0, void 0, function () {
        var tour;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getTourByAttachedTaskId(props.currentTask.task_id)];
                case 1:
                    tour = _a.sent();
                    if (!tour) return [3 /*break*/, 3];
                    return [4 /*yield*/, removeTaskFromTour(tour, props.currentTask.task_id)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [4 /*yield*/, firebase
                        .firestore()
                        .collection("tasks")
                        .doc(props.currentTask.task_id)
                        .update({
                        task_status: "unassigned",
                        task_tour: null,
                    })];
                case 4:
                    _a.sent();
                    props.onUncontrolledUpdate();
                    return [2 /*return*/];
            }
        });
    }); };
    // @ts-ignore
    if (!props.currentTask || props.currentTour === null) {
        return (_jsx(AssignmentPanelWrapper, __assign({ statusDesc: "", statusColor: "#ffffff" }, { children: _jsx(Loading, {}) })));
    }
    switch (props.status) {
        case "unassigned":
            return (_jsxs(AssignmentPanelWrapper, __assign({ statusDesc: "Nicht zugeteilt", statusColor: "#e5e5e5" }, { children: [_jsx(AssignmentCenterInfo, { children: _jsxs(AssignmentTitle, { children: ["Du kannst diesen Auftrag", _jsx("br", {}), "einer Tour zuteilen"] }) }), _jsxs(AssignmentActionsWrapper, { children: [_jsx(AssignmentButton, __assign({ onClick: props.addTaskToTour, type: "primary" }, { children: "Tour aus der Tourenliste w\u00E4hlen" })), _jsx(AssignmentButton, __assign({ onClick: function () {
                                    props.onTaskUpdate("task_status", "prepared");
                                } }, { children: "Als vorbereitet markieren" })), _jsx(AssignmentOr, {}), _jsxs(BarcodeHint, { children: ["Scanne direkt den Code der Tour,", _jsx("br", {}), "zum Beispiel vom dem Tour-Label"] })] })] })));
        case "assigned":
            var posInTour = -1;
            if (props.currentTour) {
                posInTour = getPositionForTaskInTour(props.currentTour, props.currentTask.task_id);
            }
            return (_jsxs(AssignmentPanelWrapper, __assign({ statusDesc: "Zugeteilt", statusColor: "#adf4f8" }, { children: [_jsxs(AssignmentTitle, { children: ["Tour von\u00A0", (_b = (_a = props.currentTour) === null || _a === void 0 ? void 0 : _a.driverInfo.firstName) !== null && _b !== void 0 ? _b : "unbekanntem Fahrer", " ", (_c = props.currentTour) === null || _c === void 0 ? void 0 : _c.driverInfo.lastName] }), _jsxs(AssignmentText, { children: ["Start um ", (_e = (_d = props.currentTour) === null || _d === void 0 ? void 0 : _d.startTime) !== null && _e !== void 0 ? _e : "--:--", " Uhr"] }), _jsxs(AssignmentCenterInfo, { children: [_jsx(AssignmentLargeInfo, { children: posInTour !== -1 ? posInTour + 1 + "." : "?" }), _jsx(AssignmentText, { children: "Platz in der Tour" })] }), _jsx(AssignmentActionsWrapper, { children: _jsx(AssignmentButton, __assign({ onClick: function () { return removeTask(); } }, { children: "Zuteilung trennen" })) })] })));
        case "prepared":
            return (_jsxs(AssignmentPanelWrapper, __assign({ statusDesc: "Vorbereitet", statusColor: "#ffbe4d" }, { children: [_jsx(AssignmentCenterInfo, { children: _jsxs(AssignmentTitle, { children: ["Du kannst diesen Auftrag", _jsx("br", {}), "einer Tour zuteilen"] }) }), _jsxs(AssignmentActionsWrapper, { children: [_jsx(AssignmentButton, __assign({ onClick: props.addTaskToTour, type: "primary" }, { children: "Tour aus der Tourenliste w\u00E4hlen" })), _jsx(AssignmentOr, {}), _jsxs(BarcodeHint, { children: ["Scanne direkt den Code der Tour,", _jsx("br", {}), "zum Beispiel vom dem Tour-Label"] })] })] })));
        case "ontheway":
            // Usecase: Tüte vergessen
            return (_jsxs(_Fragment, { children: [((_f = props.currentTour) === null || _f === void 0 ? void 0 : _f.driverInfo) && (_jsx(DriverChat, { shown: driverChatOpen, onClose: function () { return setDriverChatOpen(false); }, driver: props.currentTour.driverInfo })), _jsxs(AssignmentPanelWrapper, __assign({ statusDesc: "Auf dem Weg", statusColor: "#5ec8f5" }, { children: [_jsxs(AssignmentCenterInfo, { children: [_jsxs(AssignmentTitle, { children: ["Dieser Auftrag wurde wahrscheinlich", _jsx("br", {}), "von dem/der Fahrer*in vergessen."] }), _jsxs(AssignmentText, { children: ["Er ist einer Tour von", " ", (_h = (_g = props.currentTour) === null || _g === void 0 ? void 0 : _g.driverInfo.firstName) !== null && _h !== void 0 ? _h : "unbekanntem Fahrer", " ", (_j = props.currentTour) === null || _j === void 0 ? void 0 : _j.driverInfo.lastName, " zugeordnet,", _jsx("br", {}), "die aktuell unterwegs ist."] })] }), _jsxs(AssignmentActionsWrapper, { children: [_jsx(AssignmentButton, __assign({ onClick: function () {
                                            setDriverChatOpen(true);
                                        }, type: "primary" }, { children: "Fahrerchat \u00F6ffnen" })), _jsx(AssignmentButton, __assign({ onClick: function () {
                                            props.onTaskUpdate("task_status", "declined");
                                        } }, { children: "Als nicht zugestellt markieren" }))] })] }))] }));
        case "successful":
        case "successfull":
        case "finished": // ToDo: Is this really the same as successful?
            return (_jsxs(AssignmentPanelWrapper, __assign({ statusDesc: "Erfolgreich zugestellt", statusColor: "#b8ffaa" }, { children: [_jsxs(AssignmentTitle, { children: ["Tour von", " ", (_l = (_k = props.currentTour) === null || _k === void 0 ? void 0 : _k.driverInfo.firstName) !== null && _l !== void 0 ? _l : "unbekanntem Fahrer", " ", (_m = props.currentTour) === null || _m === void 0 ? void 0 : _m.driverInfo.lastName] }), _jsxs(AssignmentText, { children: ["vom", " ", props.currentTask.processed_date
                                ? moment(props.currentTask.processed_date.seconds * 1000).format("DD.MM.YYYY")
                                : "?", ", ", (_p = (_o = props.currentTour) === null || _o === void 0 ? void 0 : _o.actualStartTime) !== null && _p !== void 0 ? _p : "--:--", " Uhr bis", " ", (_r = (_q = props.currentTour) === null || _q === void 0 ? void 0 : _q.actualEndTime) !== null && _r !== void 0 ? _r : "--:--", " Uhr"] }), _jsxs(AssignmentCenterInfo, { children: [_jsx(AssignmentText, { children: "um" }), _jsx(AssignmentLargeInfo, { children: props.currentTask.processed_date
                                    ? moment(props.currentTask.processed_date.seconds * 1000).format("HH:mm") + " Uhr"
                                    : "?" }), " ", _jsx(AssignmentText, { children: "erfolgreich ausgeliefert" })] }), _jsxs(AssignmentCenterInfo, { children: [_jsx(AssignmentText, { children: "Tour" }), _jsx(TourCard, { tour: props.currentTour, hover: false }, "key")] })] })));
        case "declined":
            return (_jsx(AssignmentPanelWrapper, __assign({ statusDesc: "Nicht angetroffen", statusColor: "#ff7385" }, { children: _jsxs(AssignmentCenterInfo, { children: [_jsx(AssignmentTitle, { children: "Dieser Auftrag ist aktuell noch unterwegs." }), _jsxs(AssignmentText, { children: ["Die zugeh\u00F6rige Tour muss zuerst beendet werden,", _jsx("br", {}), "bevor der Auftrag bearbeitet werden kann."] })] }) })));
        case "attached":
            return (_jsxs(AssignmentPanelWrapper, __assign({ statusDesc: "Angehangen", statusColor: "#ffd2a5" }, { children: [_jsxs(AssignmentCenterInfo, { children: [_jsxs(AssignmentTitle, { children: ["Dieser Auftrag ist einer", _jsx("br", {}), "noch nicht optimierten Tour angehangen."] }), _jsx(AssignmentText, { children: "Die Tour kann jetzt entweder optimiert werden, oder es k\u00F6nnen zun\u00E4chst weitere Auftr\u00E4ge hinzugef\u00FCgt werden." })] }), _jsxs(AssignmentActionsWrapper, { children: [_jsx(AssignmentButton, __assign({ onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                    var tour;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, getTourByAttachedTaskId(props.currentTask.task_id)];
                                            case 1:
                                                tour = _a.sent();
                                                if (!tour) {
                                                    toaster.warning("Die gewünschte Tour kann gerade nicht optimiert werden.");
                                                    return [2 /*return*/];
                                                }
                                                return [4 /*yield*/, optimizeTour(tour)];
                                            case 2:
                                                _a.sent();
                                                props.onTaskUpdate("task_status", "optimizing");
                                                return [2 /*return*/];
                                        }
                                    });
                                }); }, type: "primary" }, { children: "Tour optimieren" })), _jsx(AssignmentButton, __assign({ onClick: detachTask }, { children: "Von Tour trennen" })), _jsx(AssignmentOr, {}), _jsxs(BarcodeHint, { children: ["Scanne direkt den Code einer Tour,", _jsx("br", {}), "um den Auftrag in diese zu verschieben."] })] })] })));
        case "optimizing":
            return (_jsx(AssignmentPanelWrapper, __assign({ statusDesc: "Optimierung läuft", statusColor: "#c5c5c5" }, { children: _jsxs(AssignmentCenterInfo, { children: [_jsx(AssignmentTitle, { children: "Dieser Auftrag wird aktuell noch optimiert." }), _jsxs(AssignmentText, { children: ["Bitte \u00FCberpr\u00FCfen Sie diesen Auftrag sp\u00E4ter erneut.", _jsx("br", {}), "Die Optimierung dauert in der Regel wenige Minuten."] })] }) })));
    }
    return null;
};
var AssignmentPanelWrapper = function (props) {
    return (_jsxs(PackingPanel, __assign({ borderless: true, style: {
            backgroundColor: props.statusColor,
            display: "flex",
            flexDirection: "column",
        } }, { children: [_jsx("h2", __assign({ style: { opacity: 0.95, textAlign: "center", margin: "28px 10px 18px" } }, { children: props.statusDesc })), _jsx("div", __assign({ style: {
                    width: "calc(100% - (2 * (10px + 40px)))",
                    flex: 1,
                    backgroundColor: "white",
                    margin: 10,
                    borderRadius: 6,
                    padding: "35px 40px",
                    display: "flex",
                    flexDirection: "column",
                } }, { children: props.children }))] })));
};
var AssignmentTitle = function (props) { return (_jsx("h3", __assign({ style: __assign({ margin: "0 0 6px", fontSize: 23, fontWeight: "bold", color: "#3F3F46", textAlign: "center" }, props.style) }, { children: props.children }))); };
var AssignmentText = function (props) { return (_jsx("p", __assign({ style: __assign({ margin: "0 auto", fontSize: 16, fontWeight: 500, color: "#5e5e67", textAlign: "center", maxWidth: 500, lineHeight: 1.2 }, props.style) }, { children: props.children }))); };
var AssignmentCenterInfo = function (props) { return (_jsx("div", __assign({ style: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    } }, { children: props.children }))); };
var AssignmentLargeInfo = function (props) { return (_jsx("p", __assign({ style: __assign({ margin: "0 0 6px", fontSize: 80, fontWeight: "bold", color: "#3F3F46", textAlign: "center" }, props.style) }, { children: props.children }))); };
// type defaults to secondary
var AssignmentButton = function (props) { return (_jsx("button", __assign({ onClick: props.onClick, style: {
        fontSize: 16,
        color: props.type === "primary" ? "white" : "#3F3F46",
        backgroundColor: props.type === "primary" ? "#49BEEF" : "#e5e5e7",
        padding: "14px 38px",
        border: "none",
        borderRadius: 23.09,
        fontWeight: 500,
        cursor: "pointer",
    } }, { children: props.children }))); };
var AssignmentOr = function () { return (_jsxs("div", __assign({ style: { display: "flex", flexDirection: "row", gap: 10 } }, { children: [_jsx("hr", { style: { flex: 1, backgroundColor: "#CDCDCD", border: "none", height: 1 } }), _jsx("span", { children: "oder" }), _jsx("hr", { style: { flex: 1, backgroundColor: "#CDCDCD", border: "none", height: 1 } })] }))); };
var AssignmentActionsWrapper = function (props) {
    return (_jsx("div", __assign({ style: {
            display: "flex",
            flexDirection: "column",
            gap: 16,
            width: 400,
            margin: "0 auto",
        } }, { children: props.children })));
};
// const AssignmentTourDetails = (props: { tour: TourWithDriver | null | undefined, shown: boolean, onClose: () => void }) => {
//   const [tasks, setTasks] = useState<Task[] | null>(null);
//
//   useEffect(() => {
//     (async () => {
//       console.log("TESTING", "ENTRY")
//
//       if (!props.tour) {
//         setTasks(null);
//         return;
//       }
//
//       console.log("TESTING", props.tour.activities);
//
//       const taskIds = getTaskIdsForTour(props.tour);
//       console.log("TESTING", taskIds);
//
//       const taskPromises = taskIds.map((tId) => getTaskById(tId));
//
//       const taskList = (await Promise.all(taskPromises)).filter(t => t !== null) as Task[];
//
//       setTasks(taskList);
//     })();
//   }, [props.tour?.id])
//
//   if (!props.tour) {
//     return null;
//   }
//
//   return (
//     <Dialog
//       isShown={props.shown}
//       title={"Tourübersicht"}
//       confirmLabel="Fertig"
//       cancelLabel="Abbrechen"
//       shouldCloseOnOverlayClick={true}
//       shouldCloseOnEscapePress={true}
//       onCloseComplete={() => props.onClose()}
//       width={"90vw"}
//     >
//       {props.tour.status !== "empty" && (
//         <>
//           {/* @ts-ignore color used incorrectly */}
//           <Badge marginBottom={10} color="#49beef">
//             Typ: {(props.tour.centralDelivery ? "Zentraler Botendienst" : "Botendienstverbund")}
//           </Badge>
//           <TourDataTable
//             isDriverData={props.tour.driverInfo}
//             tour={props.tour}
//             tasks={tasks ?? []}
//           />
//           <TaskList tour={props.tour} areTasksInTour={tasks}/>
//         </>
//       )}
//       {!props.tour.tasksToAdd && (
//         <Paragraph fontWeight={600}>
//           Keine unoptimierten Aufträge offen
//         </Paragraph>
//       )}
//       {props.tour.tasksToAdd && props.tour.tasksToAdd?.length && (
//         <UnoptimizedTasksList tour={props.tour} tasksToAdd={props.tour.tasksToAdd}/>
//       )}
//     </Dialog>
//   )
// }
export default AssignmentPanel;
