var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, CalendarIcon, CameraIcon, Card, EditIcon, FilePicker, Heading, LinkIcon, Pane, Paragraph, PersonIcon, SavedIcon, SendMessageIcon, Spinner, TextareaField, TextInput, toaster, VideoIcon, } from "evergreen-ui";
import moment from "moment";
import React, { useState, useEffect, useRef, useContext } from "react";
import firebase from "../config";
import { UserContext } from "../contexts/UserContext";
import { useSetFlag } from "../utility/use-set-flag";
import { fetchPushToken, sendPushMessage } from "../utility/utility";
import ChatBubbleOwn from "./ChatBubbleOwn";
import ChatBubblePeer from "./ChatBubblePeer";
require("firebase/firestore");
require("firebase/storage");
require("firebase/database");
var chats = firebase
    .app()
    .database("https://apomapdreinull-default-rtdb.europe-west1.firebasedatabase.app/");
export default function Chat(_a) {
    var chatNode = _a.chatNode, id = _a.id;
    //if popup is shown
    var _b = useState(false), isShown = _b[0], setShown = _b[1];
    //currently typed msg
    var _c = useState(""), isMsg = _c[0], setMsg = _c[1];
    //all chatmessages in a array
    var _d = useState([]), isChat = _d[0], setChat = _d[1];
    //refobject for downscrolling
    var bottom = useRef(null);
    //Ref object for Scrolling
    var chatLogs = useRef([]);
    //User Info
    var _e = useContext(UserContext), user = _e.user, ready = _e.ready;
    var isUser = user[0];
    var checkChatFlag = useSetFlag().checkChatFlag;
    useEffect(function () {
        if (chatNode)
            getChat();
    }, []);
    useEffect(function () {
        if (isShown)
            bottom.current.scrollIntoView();
    }, [isShown]);
    useEffect(function () {
        bottom.current.scrollIntoView();
    }, [isChat]);
    //Set message read
    function setMsgRead(msg) {
        //if message is identified over uid
        if (msg.uid) {
            //if message is not read
            if (msg.read === false) {
                //update it to read
                chats.ref("".concat(chatNode, "/").concat(msg.uid)).update({ read: true });
            }
        }
        else {
            //message identifiyed over val()
            if (msg.val().read === false) {
                chats.ref("".concat(chatNode, "/").concat(msg.key)).update({ read: true });
            }
        }
    }
    function getChat() {
        //creats listner of all chat messages
        chats.ref("".concat(chatNode)).on("value", function (snap) {
            var messageObject = snap.val();
            if (messageObject) {
                // convert messages list from snapshot
                var messageList = Object.keys(messageObject).map(function (key) { return (__assign(__assign({}, messageObject[key]), { uid: key })); });
                //all messages not from me should be set read
                messageList.map(function (msg) {
                    if (msg.sender !== firebase.auth().currentUser.email) {
                        setMsgRead(msg);
                    }
                });
                //read messages ref
                chatLogs.current = messageList;
                setChat(messageList);
                //scroll to ref object
            }
        });
        setShown(true);
    }
    //Send message to chat
    function sendMessage() {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var token;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        //input validation
                        if (isMsg === "") {
                            toaster.warning("Geben Sie eine Nachricht ein");
                            return [2 /*return*/];
                        }
                        //sets message to realtime chat history
                        chats.ref("".concat(chatNode)).push().set({
                            sender: firebase.auth().currentUser.email,
                            msg: isMsg,
                            read: false,
                            deleted: false,
                            edited: false,
                        });
                        return [4 /*yield*/, fetchPushToken(chatNode.split("_")[1])];
                    case 1:
                        token = _b.sent();
                        sendPushMessage({
                            token: [token],
                            title: "💭 Nachricht von " + (isUser === null || isUser === void 0 ? void 0 : isUser.businessName) !== undefined
                                ? isUser === null || isUser === void 0 ? void 0 : isUser.businessName
                                : "Dashboard",
                            message: isMsg,
                        });
                        //Add to Notification-Tray
                        chats
                            .ref("notifications_".concat(chatNode.split("_")[1]))
                            .push()
                            .set({
                            type: "chat",
                            from: firebase.auth().currentUser.uid,
                            msg: isMsg,
                            name: (_a = isUser === null || isUser === void 0 ? void 0 : isUser.businessName) !== null && _a !== void 0 ? _a : "Dashboard",
                        });
                        //reset typed message
                        checkChatFlag();
                        setMsg("");
                        return [2 /*return*/];
                }
            });
        });
    }
    //Listenst to enter for sending
    function hitEnterCheck(e) {
        if (e.key === "Enter") {
            sendMessage();
        }
    }
    return (_jsxs(Pane, __assign({ flex: "1", display: "flex", overflowY: "scroll", background: "tint1" }, { children: [_jsx(Card, __assign({ backgroundColor: "white", maxHeight: "85%", width: "100%", elevation: 0, flexDirection: "column", overflow: "scroll", display: "flex", padding: 16, margin: 18 }, { children: _jsxs(_Fragment, { children: [isChat.map(function (msg) {
                            if (msg.sender === firebase.auth().currentUser.email) {
                                return (_jsx(ChatBubbleOwn, { chatNode: chatNode, msg: msg }, msg.uid));
                            }
                            else {
                                return (_jsx(ChatBubblePeer, { chatNode: chatNode, msg: msg }, msg.uid));
                            }
                        }), _jsx(Pane, { ref: bottom })] }) })), _jsxs(Card, __assign({ marginTop: 20, elevation: 0, display: "flex", bottom: 14, padding: 16, width: "100%", position: "absolute", justifyContent: "center" }, { children: [_jsx(TextInput, { width: "80%", placeholder: "Schreibe eine Nachricht...", value: isMsg, onChange: function (e) { return setMsg(e.target.value); }, onKeyDown: function (e) { return hitEnterCheck(e); } }), _jsx(Button, __assign({ onClick: function (e) { return sendMessage(); }, marginLeft: 5, iconBefore: _jsx(SendMessageIcon, {}) }, { children: "Senden" }))] }))] })));
}
