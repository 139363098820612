var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, Button, Dialog, Pane, Paragraph, Table, toaster, Tooltip, TrashIcon, } from "evergreen-ui";
import "firebase/auth";
import "firebase/firestore";
import mapboxgl from "mapbox-gl";
import moment from "moment";
import { useCallback, useContext, useEffect, useMemo, useRef, useState, } from "react";
import { useReactToPrint } from "react-to-print";
import firebase from "../config";
import { MapContext } from "../contexts/MapContext";
import { MarkerContext } from "../contexts/MarkerContext";
import { TaskContext } from "../contexts/TaskContext";
import useStore from "../store/store";
import addMarker from "../utility/addMarker";
import createTaskActionList from "../utility/createTaskActionList";
import deleteTour from "../utility/deleteTour";
import Pin from "./Pin";
import RemovedTasksList from "./RemovedTasksList";
import StatusBadge from "./StatusBadge";
import TaskList from "./TaskList";
import TourDataTable from "./TourDataTable";
import TourProgressCell from "./TourProgressCell";
import TourTrackerList from "./TourTrackerList";
import UnoptimizedTasksList from "./UnoptimizedTasksList";
export default function TourCard(_a) {
    var _this = this;
    var _b, _c, _d;
    var tour = _a.tour, hover = _a.hover;
    var map = useContext(MapContext).map;
    var mapRef = map[0], setMapRef = map[1];
    var tasks = useContext(TaskContext).tasks;
    var areTasks = tasks[0];
    var _e = useState([]), areTasksInTour = _e[0], setTasksInTour = _e[1];
    var _f = useState(), isRoute = _f[0], setRoute = _f[1];
    var _g = useState(), isDriverData = _g[0], setDriverData = _g[1];
    var _h = useState([]), tourMarkers = _h[0], setTourMarkers = _h[1];
    var markerContext = useContext(MarkerContext).markers;
    var allMarkers = markerContext[0], setAllMarkers = markerContext[1];
    var _j = useState([]), areDoneTasksInTour = _j[0], setDoneTasksInTour = _j[1];
    var _k = useState(false), isHover = _k[0], setHover = _k[1];
    var _l = useState(false), isModalShown = _l[0], setModalShown = _l[1];
    var tableRef = useRef();
    // Global state handling Drag and Drop
    var _m = useStore(), draggedTask = _m.draggedTask, setDraggedTask = _m.setDraggedTask;
    var _o = useState(false), loading = _o[0], setLoading = _o[1];
    var handlePrint = useReactToPrint({
        content: function () { return tableRef.current; },
    });
    var geatherTasks = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var _a, doneTasksInTour, cleanedUpTour, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, createTaskActionList(tour, areTasks)];
                case 1:
                    _a = _b.sent(), doneTasksInTour = _a.doneTasksInTour, cleanedUpTour = _a.cleanedUpTour;
                    setDoneTasksInTour(doneTasksInTour);
                    setTasksInTour(cleanedUpTour);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _b.sent();
                    console.log(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [tour, areTasks]);
    var clearMarkers = useCallback(function () {
        tourMarkers.map(function (marker) {
            marker.remove();
        });
        mapRef.current.removeLayer(tour.id);
        mapRef.current.removeSource(tour.id);
    }, [tourMarkers, mapRef, tour.id]);
    var getDriverInfo = useCallback(function () {
        if (tour.driver)
            firebase
                .firestore()
                .collection("drivers")
                .doc(tour.driver)
                .get()
                .then(function (doc) {
                var data = doc.data();
                setDriverData(data);
                if (tour.status !== "finished") {
                    try {
                        var points = JSON.parse(tour.points);
                        //find coords with the most elements
                        var mostPoints_1 = { coordinates: [] };
                        var allPoints_1 = { coordinates: [] };
                        points.map(function (point) {
                            if (point.coordinates.length > 2) {
                                allPoints_1.coordinates.push(point.coordinates);
                            }
                            else {
                                allPoints_1.coordinates.push([point.coordinates]);
                            }
                            if (point.coordinates.length > mostPoints_1.coordinates.length) {
                                mostPoints_1 = point;
                            }
                        });
                        setRoute(allPoints_1.coordinates.flat());
                    }
                    catch (error) {
                        console.log("Tour konnte nicht auf der Karte angezeigt werden!");
                    }
                }
            });
    }, [tour.driver, tour.points, tour.status]);
    useEffect(function () {
        getDriverInfo();
        geatherTasks();
        return function () {
            try {
                clearMarkers();
            }
            catch (error) { }
        };
    }, [areTasks, geatherTasks, getDriverInfo]);
    function plotOnMap(color, opacity) {
        try {
            mapRef.current.removeLayer(tour.id);
            mapRef.current.removeSource(tour.id);
        }
        catch (error) { }
        mapRef.current.addLayer({
            id: tour.id,
            type: "line",
            source: {
                type: "geojson",
                data: {
                    type: "Feature",
                    properties: {},
                    geometry: {
                        type: "LineString",
                        coordinates: isRoute,
                    },
                },
            },
            layout: {
                "line-join": "round",
                "line-cap": "round",
            },
            paint: {
                "line-color": color,
                "line-width": 5,
                "line-opacity": opacity,
            },
        });
    }
    function renderMarkersOnMap() {
        var markerArray = [];
        var bounds = new mapboxgl.LngLatBounds();
        var flippedTasks = {};
        areTasks.map(function (task) {
            flippedTasks[task.task_id] = task;
        });
        tour.activities.map(function (activity) {
            var marker = addMarker(mapRef, [activity.address.lon, activity.address.lat], undefined, activity.type, activity.id, _jsx(Pin, { activity: activity, tasks: flippedTasks }));
            bounds.extend(marker.getLngLat());
            markerArray.push(marker);
        });
        mapRef.current.fitBounds(bounds, { padding: 100 });
        setTourMarkers(markerArray);
    }
    function zoomToAll() {
        var bounds = new mapboxgl.LngLatBounds();
        var markers = [];
        areTasks.map(function (task) {
            var marker = addMarker(mapRef, task.task_coords, null, task.task_type, 2, _jsx(Pin, { task: task, activity: { type: task.task_type } }));
            markers.push(marker);
            bounds.extend([task.task_coords[0], task.task_coords[1]]);
        });
        setAllMarkers(markers);
        mapRef.current.fitBounds(bounds, { padding: 100 });
    }
    function showOffTour() {
        setHover(true);
        allMarkers.map(function (marker) {
            marker.remove();
        });
        setAllMarkers([]);
        renderMarkersOnMap();
        plotOnMap((isDriverData === null || isDriverData === void 0 ? void 0 : isDriverData.color) ? isDriverData.color : "#49beef", 0.8);
    }
    function hideOffTour() {
        //Deselct hover
        setHover(false);
        //Delete markers belonging to tour
        tourMarkers.map(function (marker) {
            marker.remove();
        });
        //If set, show all tasks on map and
        //Zoom out
        var state = localStorage.getItem("showPins");
        if (state === "true") {
            zoomToAll();
        }
        plotOnMap((isDriverData === null || isDriverData === void 0 ? void 0 : isDriverData.color) ? isDriverData.color : "#49beef", 0.2);
    }
    var allTasksInTour = useMemo(function () { return areTasksInTour === null || areTasksInTour === void 0 ? void 0 : areTasksInTour.filter(function (action) { return action.type === "task"; }); }, [areTasksInTour]);
    var handleDeleteTour = function () { return __awaiter(_this, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, deleteTour(tour, setLoading, toaster)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    console.error("Error deleting tour:", err_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    // * Event Handlers
    var addTaskToTour = function (taskId, tourId) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!taskId) {
                        throw new Error("Task ID is undefined");
                    }
                    return [4 /*yield*/, firebase
                            .firestore()
                            .collection("tours")
                            .doc(tourId)
                            .update({
                            tasksToAdd: firebase.firestore.FieldValue.arrayUnion(taskId),
                        })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, firebase.firestore().collection("tasks").doc(taskId).update({
                            task_status: "attached",
                            task_tour: tourId,
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsxs(Dialog, __assign({ isShown: isModalShown, title: "Tourübersicht", confirmLabel: "Fertig", cancelLabel: "Abbrechen", shouldCloseOnOverlayClick: false, shouldCloseOnEscapePress: false, onCloseComplete: function () { return setModalShown(false); }, width: "90vw" }, { children: [isModalShown && (tour === null || tour === void 0 ? void 0 : tour.status) !== "empty" ? (_jsxs(_Fragment, { children: [_jsx(Badge, __assign({ marginBottom: 10, color: "#49beef" }, { children: "Typ: " +
                                    (tour.centralDelivery
                                        ? "Zentraler Botendienst"
                                        : "Botendienstverbund") })), _jsx(TourDataTable, { isDriverData: isDriverData, tour: tour, tasks: areTasksInTour }), _jsx(TourTrackerList, { tour: tour }), _jsx(TaskList, { tour: tour, areTasksInTour: areTasksInTour }), _jsx(Paragraph, __assign({ fontSize: "xx-small", color: "white" }, { children: tour === null || tour === void 0 ? void 0 : tour.optimizationId }))] })) : (_jsxs(Pane, __assign({ padding: 12 }, { children: [_jsxs(Paragraph, __assign({ fontWeight: "bold" }, { children: ["ID: ", tour === null || tour === void 0 ? void 0 : tour.id] })), _jsx(Button, __assign({ isLoading: loading, onClick: handleDeleteTour, intent: "danger", iconBefore: _jsx(TrashIcon, {}) }, { children: "Tour l\u00F6schen" }))] }))), !tour.tasksToAdd && (_jsx(Paragraph, __assign({ fontWeight: 600 }, { children: "Keine unoptimierten Auftr\u00E4ge offen" }))), tour.tasksToAdd && ((_b = tour.tasksToAdd) === null || _b === void 0 ? void 0 : _b.length) >= 1 ? (_jsx(UnoptimizedTasksList, { tour: tour, tasksToAdd: tour.tasksToAdd })) : null, tour.tasksToRemove && tour.tasksToRemove.length ? (_jsx(RemovedTasksList, { tour: tour, tasksToRemove: tour.tasksToRemove })) : null] })), _jsx(Pane, __assign({ style: {
                    backgroundColor: isHover ? isDriverData === null || isDriverData === void 0 ? void 0 : isDriverData.color : "",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                }, onMouseEnter: function () { return hover && tour.status !== "empty" && showOffTour(); }, onMouseLeave: function () { return hover && tour.status !== "empty" && hideOffTour(); }, onDragOver: function (e) {
                    e.preventDefault();
                }, onDrop: function (e) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        addTaskToTour(draggedTask, tour.id);
                        setDraggedTask(null);
                        return [2 /*return*/];
                    });
                }); } }, { children: _jsxs(Table.Row, __assign({ backgroundColor: "rgba(0,0,0,0)", style: {
                        width: "100%",
                    } }, { children: [_jsx(Table.TextCell, { children: (tour === null || tour === void 0 ? void 0 : tour.driver) === "cure" ? (_jsx(Badge, { children: "\u00DCbertragen" })) : (_jsx(StatusBadge, { status: tour.status, tour: tour, id: tour.id, driver: true })) }), _jsx(Table.TextCell, { children: (tour === null || tour === void 0 ? void 0 : tour.withTracker) ? (
                            // @ts-ignore
                            _jsx(Tooltip, __assign({ content: "Temperatur Tracking" }, { children: _jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", className: "icon icon-tabler icon-tabler-temperature", width: "24", height: "24", viewBox: "0 0 24 24", "stroke-width": "2", stroke: "currentColor", fill: "none", strokeLinecap: "round", strokeLinejoin: "round" }, { children: [_jsx("path", { stroke: "none", d: "M0 0h24v24H0z", fill: "none" }), _jsx("path", { d: "M10 13.5a4 4 0 1 0 4 0v-8.5a2 2 0 0 0 -4 0v8.5" }), _jsx("path", { d: "M10 9l4 0" })] })) }))) : null }), _jsx(Table.TextCell, { children: (tour === null || tour === void 0 ? void 0 : tour.name)
                                ? tour.name
                                : (tour === null || tour === void 0 ? void 0 : tour.driver) === "cure"
                                    ? "Cure"
                                    : (isDriverData === null || isDriverData === void 0 ? void 0 : isDriverData.firstName) + " " + (isDriverData === null || isDriverData === void 0 ? void 0 : isDriverData.lastName) }), _jsx(Table.TextCell, { children: moment(tour.date, "YY-MM-DD").format("DD.MM.YYYY") }), _jsx(Table.TextCell, { children: tour === null || tour === void 0 ? void 0 : tour.startTime }), _jsx(Table.TextCell, { children: _jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center" }, { children: [areDoneTasksInTour === null || areDoneTasksInTour === void 0 ? void 0 : areDoneTasksInTour.length, "/", allTasksInTour === null || allTasksInTour === void 0 ? void 0 : allTasksInTour.length, _jsx(Paragraph, __assign({ marginLeft: "5", fontWeight: "600" }, { children: ((_c = tour === null || tour === void 0 ? void 0 : tour.tasksToAdd) === null || _c === void 0 ? void 0 : _c.length) >= 1 &&
                                            "+".concat((_d = tour === null || tour === void 0 ? void 0 : tour.tasksToAdd) === null || _d === void 0 ? void 0 : _d.length) }))] })) }), _jsx(Table.TextCell, { children: _jsx(TourProgressCell, { setModalShown: setModalShown, tour: tour, involvedTasks: allTasksInTour }) })] })) }))] }));
}
