var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from "react";
import AddLocationButton from "../components/AddLocationButton";
import LocationBar from "../components/LocationBar";
import { LocationContext } from "../contexts/LocationContext";
import { Pane, Heading, Paragraph, Button, Nudge, toaster, ChevronLeftIcon, ChevronRightIcon, } from "evergreen-ui";
import "firebase/firestore";
import { PaymentContext } from "../contexts/PaymentContext";
import Location from "../assets/images/location.svg";
import { Link } from "react-router-dom";
import { PlanContext } from "../contexts/PlanContext";
export default function AddLocationCard(props) {
    var locations = useContext(LocationContext).locations;
    var areBusinessLocations = locations[0], setBusinessLocations = locations[1];
    var plan = useContext(PlanContext).plan;
    var isPlan = plan[0], setPlan = plan[1];
    var locationManagementState = useContext(PaymentContext).locationManagementState;
    var setLocationManagementShown = locationManagementState[1];
    var submit = function () {
        if (areBusinessLocations.length === 0) {
            toaster.notify("Sie müssen mindestens einen Standort anlegen!");
            return;
        }
        props.setCurrentPage(4);
    };
    return (_jsxs(Pane, __assign({ width: "100%", height: "100%", display: "flex", flexDirection: "row" }, { children: [_jsx(Link, __assign({ to: "/onboarding/2" }, { children: _jsxs(Pane, __assign({ position: "absolute", top: "35px", left: "35px", display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(ChevronLeftIcon, {}), _jsx(Paragraph, { children: "Zur\u00FCck" })] })) })), _jsx(Link, __assign({ to: "/onboarding/4" }, { children: _jsxs(Pane, __assign({ position: "absolute", top: "35px", right: "35px", display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(Paragraph, { children: "Weiter" }), _jsx(ChevronRightIcon, {})] })) })), _jsxs(Pane, __assign({ width: "50%", padding: 50, display: "flex", flexDirection: "column" }, { children: [_jsxs(Pane, { children: [_jsx(Heading, __assign({ marginTop: 30, width: "100%", fontSize: "48px", color: "#2D3B51", size: "large" }, { children: "Standort hinzuf\u00FCgen" })), _jsxs(Paragraph, __assign({ fontSize: "18px" }, { children: ["Du hast noch keinen Standort angelegt?", _jsx("br", {}), " Lege jetzt deinen Hauptstandort an."] }))] }), _jsxs(Pane, __assign({ width: "100%", overflow: "scroll" }, { children: [_jsx(Pane, __assign({ style: { overflow: "scroll" } }, { children: areBusinessLocations.map(function (location, index) {
                                    return _jsx(LocationBar, { location: location }, index);
                                }) })), (isPlan === null || isPlan === void 0 ? void 0 : isPlan.locationMaximum) >
                                areBusinessLocations.filter(function (_a) {
                                    var archived = _a.archived;
                                    return !archived;
                                }).length ? (_jsx(Pane, __assign({ marginTop: 50 }, { children: _jsx(AddLocationButton, { setBusinessLocations: setBusinessLocations }) }))) : (_jsxs(Pane, { children: [_jsx(Nudge, {}), _jsx(Button, __assign({ onClick: function () { return setLocationManagementShown(true); }, marginBottom: 10 }, { children: "Mehr Standorte abonnieren" }))] }))] })), _jsx(Pane, __assign({ position: "absolute", bottom: 60, left: "40%" }, { children: _jsx(Link, __assign({ style: { textDecoration: "none" }, to: "/onboarding/4" }, { children: _jsx(Button, __assign({ backgroundColor: "#2F2E41", appearance: "primary", size: "large" }, { children: "Weiter" })) })) }))] })), _jsxs(Pane, __assign({ width: "50%", backgroundColor: "#49beef", padding: 50, display: "flex", flexDirection: "column", textAlign: "center" }, { children: [_jsx(Heading, __assign({ marginTop: 30, fontSize: "48px", size: "large", color: "white", fontWeight: 800 }, { children: "Richten wir nun gemeinsam deine Standorte ein" })), _jsxs(Paragraph, __assign({ fontFamily: "bold", width: "100%", marginTop: 10, color: "white" }, { children: ["Du kannst deine Standorte ganz leicht verkn\u00FCpfen und ", _jsx("br", {}), " Touren \u00FCber mehrere Standorte hinweg optimieren"] })), _jsx("img", { src: Location, alt: "Person", style: { width: "100%", height: "50%", marginTop: 20 } })] }))] })));
}
