var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Pane } from "evergreen-ui";
import Barcode from "react-barcode";
import { normaliseMoneyString } from "../utility/normaliseMoneyString";
import LocationLabel from "./LocationLabel";
import StatusBadge from "./StatusBadge";
export default function TourAccountingLayoutItem(_a) {
    var _b;
    var task = _a.task, isCash = _a.isCash, taskIdsNotPayed = _a.taskIdsNotPayed, tasks = _a.tasks;
    var driverNote = (_b = task.task_history.filter(function (t) { return t.type === "note"; })[0]) === null || _b === void 0 ? void 0 : _b.msg;
    var notPayed = taskIdsNotPayed.includes(task.task_id);
    var childrenTasks = (task === null || task === void 0 ? void 0 : task.collectiveTask)
        ? tasks.filter(function (t) { return task === null || task === void 0 ? void 0 : task.taskIds.includes(t.task_id); })
        : [];
    if (task === null || task === void 0 ? void 0 : task.parent_task)
        return null;
    return (_jsxs("tr", __assign({ style: {
            width: "14,28%",
            borderBottomWidth: 1,
            borderBottomColor: "black",
            borderBottomStyle: "solid",
            backgroundColor: "white",
        } }, { children: [_jsx("td", { children: task.customer_name }), _jsx("td", { children: _jsx(LocationLabel, { id: task.task_origin }) }), _jsx("td", { children: _jsx(StatusBadge, { status: task.task_status }) }), _jsx("td", { children: driverNote !== null && driverNote !== void 0 ? driverNote : "Kein Hinweis" }), _jsx("td", { children: notPayed ? (_jsx("p", __assign({ style: { fontWeight: "bold" } }, { children: "Nicht gezahlt!" }))) : isCash ? ("Bar") : ("Kartenzahlung") }), _jsx("td", { children: normaliseMoneyString(task.task_open_amount) }), _jsx("td", { children: _jsx(Pane, __assign({ style: {
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    } }, { children: childrenTasks.length !== 0 ? (_jsx(_Fragment, { children: childrenTasks.map(function (task) { return (_jsxs(_Fragment, { children: [_jsx("p", { children: task.customer_name }), _jsx(Barcode, { height: 30, value: task === null || task === void 0 ? void 0 : task.task_job_no })] })); }) })) : (_jsx(Barcode, { height: 30, value: task.task_job_no })) })) }), _jsx("td", {})] })));
}
