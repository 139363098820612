var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { CrossIcon, EditIcon, IconButton, Tooltip } from "evergreen-ui";
export var EditButton = function (_a) {
    var isEditMode = _a.isEditMode, setEditMode = _a.setEditMode, setShowActionBar = _a.setShowActionBar;
    // * Event Handler
    var handleClick = function () {
        isEditMode ? setShowActionBar(false) : setShowActionBar(true);
        setEditMode(!isEditMode);
    };
    return (
    //@ts-ignore
    _jsx(Tooltip, __assign({ content: isEditMode
            ? "Bearbeitungsmodus deaktivieren (STRG+B)"
            : "Bearbeitungsmodus aktivieren (STRG+B)" }, { children: _jsx(IconButton, { icon: isEditMode ? (_jsx(CrossIcon, { size: 28, color: "#fff" })) : (_jsx(EditIcon, { size: 28, color: "#fff" })), onClick: handleClick, style: {
                height: 44,
                width: 44,
                borderRadius: "100%",
                // borderColor: "#49beef",
                backgroundColor: "#2D3B51",
                // backgroundColor: "rgba(45,59,81,0.6)",
                border: "none",
                cursor: "pointer",
            }, intent: "success", marginLeft: 5 }) })));
};
