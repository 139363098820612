var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DashboardCard from "./DashboardCard";
import React, { useState } from "react";
import { useWPPosts } from "../../hooks/useWPPosts";
import { ChevronLeftIcon, ChevronRightIcon } from "evergreen-ui";
var POST_COUNT = 5;
var NewsAndHintsCard = function () {
    var posts = useWPPosts(POST_COUNT);
    var _a = useState(0), current = _a[0], _setCurrent = _a[1];
    var next = function () {
        _setCurrent((current + 1) % Math.min(POST_COUNT, posts.length));
    };
    var prev = function () {
        if (current === 0) {
            _setCurrent(Math.min(POST_COUNT, posts.length) - 1);
            return;
        }
        _setCurrent(current - 1);
    };
    if (posts === null) {
        return (_jsx(DashboardCard, __assign({ title: "News & Tipps", size: 2 }, { children: "Loading..." })));
    }
    if (posts.length === 0) {
        return (_jsx(DashboardCard, __assign({ title: "News & Tipps", size: 2 }, { children: "Gerade sind keine Neuigkeiten und Tipps f\u00FCr dich verf\u00FCgbar." })));
    }
    return (_jsx(DashboardCard, __assign({ title: "News & Tipps", size: 2 }, { children: _jsxs("div", __assign({ style: { display: "flex", flexDirection: "row", height: "100%", overflow: "hidden" } }, { children: [(posts.length > 1) && (_jsx("div", __assign({ style: {
                        display: "flex",
                        justifyItems: "center",
                        alignItems: "center",
                        marginRight: 10,
                        cursor: "pointer"
                    }, onClick: prev }, { children: _jsx(ChevronLeftIcon, { color: "#999ea8" }) }))), _jsxs("div", __assign({ style: { display: "flex", flexDirection: "column", flex: 1 } }, { children: [_jsx("div", { dangerouslySetInnerHTML: { __html: posts[current].excerpt.rendered }, style: { flex: 1, lineClamp: 8, textOverflow: "ellipsis", overflow: "hidden" }, className: "WPContent" }), _jsx("a", __assign({ href: posts[current].link, target: "_blank", style: { color: "#43beef", marginBottom: 10, marginTop: 4 } }, { children: "Hier klicken" }))] })), (posts.length > 1) && (_jsx("div", __assign({ style: {
                        display: "flex",
                        justifyItems: "center",
                        alignItems: "center",
                        marginLeft: 10,
                        cursor: "pointer"
                    }, onClick: next }, { children: _jsx(ChevronRightIcon, { color: "#999ea8" }) })))] })) })));
};
export default NewsAndHintsCard;
