var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AddIcon, Badge, Button, ChevronLeftIcon, ClipboardIcon, Dialog, Heading, IconButton, Pane, Paragraph, TextInput, toaster, Tooltip, TrashIcon, } from "evergreen-ui";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import LocationSelectMenu from "../components/LocationSelectMenu";
import firebase from "../config";
import NavigatorBar from "../container/NavigatorBar";
import usePrinters from "../hooks/usePrinters";
import { PrinterStatus } from "../types/Printer";
import { generateId } from "../utility/generatePseudoRandomString";
import { usePlanInfo } from "../utility/use-plan-info";
var PrinterCard = function (props) {
    return (_jsxs(Pane, __assign({ elevation: 1, style: {
            margin: 10,
            padding: 15,
            display: "flex",
            flexDirection: "row",
            backgroundColor: "white",
        } }, { children: [_jsxs(Pane, __assign({ style: {
                    justifyContent: "space-between",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                } }, { children: [_jsxs(Pane, __assign({ className: "horizontal", style: { gap: 5 } }, { children: [_jsx(Paragraph, __assign({ marginRight: 10, className: "subheadline" }, { children: props.printer.name })), props.printer.status === PrinterStatus.READY ? (_jsx(_Fragment, { children: _jsx(Badge, __assign({ color: "green" }, { children: "Aktiv" })) })) : (_jsx(Badge, __assign({ color: "neutral" }, { children: "Unbekannt" })))] })), _jsxs(Paragraph, __assign({ marginTop: 5, marginBottom: 15, className: "bodytext", style: {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        } }, { children: ["SDP Link:\u00A0", _jsxs("code", __assign({ style: { fontFamily: "monospace" } }, { children: ["https://print.apomap.de/epson/", props.printer.pid, "/sdp"] })), _jsx(Tooltip, __assign({ content: "Kopieren" }, { children: _jsx(IconButton, { onClick: function () {
                                        navigator.clipboard.writeText("https://print.apomap.de/epson/".concat(props.printer.pid, "/sdp"));
                                        toaster.notify("Token kopiert!");
                                    }, icon: ClipboardIcon, marginLeft: 2, appearance: "minimal" }) }))] }))] })), _jsx(Tooltip, __assign({ content: "L\u00F6schen" }, { children: _jsx(IconButton, { onClick: function () {
                        firebase
                            .firestore()
                            .collection("printers")
                            .doc(props.printer.pid)
                            .delete()
                            .then(function () {
                            toaster.notify("Der Drucker ".concat(props.printer.name, " wurde gel\u00F6scht"));
                            setTimeout(function () {
                                window.location.reload();
                            }, 1500);
                        })
                            .catch(function (err) {
                            toaster.warning("Der Drucker konnte nicht entfernt werden");
                            console.log(err);
                        });
                    }, icon: TrashIcon, marginLeft: 2, appearance: "default", intent: "danger" }) }))] })));
};
var AddDialog = function (props) {
    var _a = useState(""), printerName = _a[0], setPrinterName = _a[1];
    var _b = useState(null), isLocation = _b[0], setLocation = _b[1];
    var saveChanges = function () {
        var _a;
        if (printerName.length === 0) {
            toaster.notify("Der Name muss mindestens 1 Zeichen lang sein!");
            return;
        }
        if (isLocation === null) {
            toaster.notify("Es muss ein Ort ausgewählt werden");
            return;
        }
        var pid = generateId(32);
        firebase
            .firestore()
            .collection("printers")
            .doc(pid)
            .set({
            capabilities: ["label", "receipt"],
            locationId: isLocation.value,
            name: printerName,
            ownerId: (_a = firebase.auth().currentUser) === null || _a === void 0 ? void 0 : _a.uid,
            pid: pid,
            status: "online",
            type: "epson-ti",
        })
            .then(function () {
            props.setShown(false);
            toaster.success("Drucker ".concat(printerName, " wurde erfolgreich gespeichert"));
            setTimeout(function () {
                window.location.reload();
            }, 1500);
        })
            .catch(function (err) {
            console.log(err);
        });
    };
    return (_jsx(Pane, { children: _jsxs(Dialog, __assign({ isShown: props.shown, title: "Neuen Drucker hinzuf\u00FCgen", onCloseComplete: function () { return props.setShown(false); }, onConfirm: function () { return saveChanges(); }, confirmLabel: "Erstellen", cancelLabel: "Abbrechen", shouldCloseOnOverlayClick: false, shouldCloseOnEscapePress: false }, { children: [_jsx(Paragraph, __assign({ className: "subheadline" }, { children: "Bitte vergeben Sie diesem Drucker einen eindeutigen Namen" })), _jsx(TextInput, { margin: 10, placeholder: "Mein Labeldrucker", onChange: function (e) { return setPrinterName(e.target.value); }, value: printerName }), _jsx(Pane, __assign({ style: { marginLeft: 10 } }, { children: _jsx(LocationSelectMenu, { isSelected: isLocation, setSelected: setLocation }) }))] })) }));
};
export default function Printers() {
    // @ts-ignore
    var currentPlan = usePlanInfo().currentPlan;
    var history = useHistory();
    var printers = usePrinters();
    var _a = useState(false), addShown = _a[0], setAddShown = _a[1];
    return (_jsxs(Pane, __assign({ style: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            background: "linear-gradient(-3deg,#E4EBF5 0%, rgba(255,255,255,1) 100%)",
        } }, { children: [_jsx(NavigatorBar, {}), _jsxs(Pane, __assign({ style: { padding: 25, margin: 25, width: "100%", overflow: "scroll" } }, { children: [addShown && (_jsx(AddDialog, { shown: addShown, setShown: function (a) { return setAddShown(a); } })), _jsx(Button, __assign({ onClick: function () { return history.push("/settings"); }, marginY: 8, marginRight: 12, iconBefore: ChevronLeftIcon }, { children: "Zur\u00FCck" })), _jsx(Heading, __assign({ size: 900 }, { children: "Drucker" })), _jsx(Paragraph, __assign({ size: 400 }, { children: "Hier kannst du deine Drucker mit apomap verbinden." })), _jsx("div", { style: {
                            width: "100%",
                            height: 2,
                            backgroundColor: "#EFEFEF",
                            borderRadius: 2,
                            marginTop: 10,
                            marginBottom: 10,
                        } }), currentPlan !== "apomap-lite" && (_jsx(Pane, __assign({ style: {
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: 12,
                            width: "100%",
                        } }, { children: _jsx(Button, __assign({ onClick: function () { return setAddShown(true); }, iconAfter: AddIcon }, { children: "Drucker hinzuf\u00FCgen" })) }))), (printers !== null && printers !== void 0 ? printers : []).map(function (p) { return (_jsx(PrinterCard, { printer: p }, p.pid)); })] }))] })));
}
