var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ChevronLeftIcon, Heading, Nudge, Pane, Paragraph, } from "evergreen-ui";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import AddLocationButton from "../components/AddLocationButton";
import FeatureLabel from "../components/FeatureLabel";
import LocationBar from "../components/LocationBar";
import NavigatorBar from "../container/NavigatorBar";
import { LocationContext } from "../contexts/LocationContext";
import { PaymentContext } from "../contexts/PaymentContext";
import { PlanContext } from "../contexts/PlanContext";
export default function Locations() {
    var locations = useContext(LocationContext).locations;
    var areBusinessLocations = locations[0], setBusinessLocations = locations[1];
    var locationManagementState = useContext(PaymentContext).locationManagementState;
    var setLocationManagementShown = locationManagementState[1];
    var plan = useContext(PlanContext).plan;
    var isPlanData = plan[0];
    var history = useHistory();
    return (_jsxs(Pane, __assign({ style: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            background: "linear-gradient(-3deg,#E4EBF5 0%, rgba(255,255,255,1) 100%)",
        } }, { children: [_jsx(NavigatorBar, {}), _jsxs(Pane, __assign({ style: {
                    padding: 25,
                    margin: 25,
                    width: "100%",
                    maxWidth: "950px",
                    overflowY: "auto",
                    overflowX: "hidden",
                } }, { children: [_jsx(Button, __assign({ onClick: function () { return history.push("/settings"); }, marginY: 8, marginRight: 12, iconBefore: ChevronLeftIcon }, { children: "Zur\u00FCck" })), _jsx(Heading, __assign({ size: 900 }, { children: "Standort" })), _jsxs(Paragraph, __assign({ size: 400 }, { children: ["Hier k\u00F6nnen Sie verschiedenen Apotheken-Standorte anlegen. Sie haben maximal ", isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.locationMaximum, " Standorte zu Verf\u00FCgung."] })), _jsx("div", { style: {
                            width: "100%",
                            height: 2,
                            backgroundColor: "#EFEFEF",
                            borderRadius: 2,
                            marginTop: 10,
                            marginBottom: 10,
                        } }), _jsx(Pane, { children: _jsx(Pane, __assign({ style: {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            } }, { children: _jsxs(Pane, { children: [(isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.locationMaximum) >
                                        areBusinessLocations.filter(function (_a) {
                                            var archived = _a.archived;
                                            return !archived;
                                        })
                                            .length && (_jsx(AddLocationButton, { setBusinessLocations: setBusinessLocations })), _jsx(FeatureLabel, { type: "Pro-Feature", content: "Du hast mehr als eine Filiale oder einen Apothekenverbund? Mit dem Pro Plan kannst du jederzeit weitere Standorte f\u00FCr 49,00 \u20AC im Monat hinzuf\u00FCgen!" }), _jsx(Nudge, __assign({ isShown: true, tooltipContent: (isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.subscriptionPeriod) === "annual"
                                            ? "Pro Standort / 588€"
                                            : "Pro Standort / 49€" }, { children: _jsx(Button, __assign({ onClick: function () { return setLocationManagementShown(true); }, marginY: 8, marginRight: 12 }, { children: "Standort-Abonement verwalten" })) }))] }) })) }), areBusinessLocations
                        .filter(function (_a) {
                        var archived = _a.archived;
                        return !archived;
                    })
                        .map(function (location, index) {
                        return _jsx(LocationBar, { location: location }, location.id);
                    })] }))] })));
}
