var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, CrossIcon, Dialog, Heading, Pane, PlusIcon, } from "evergreen-ui";
import React from "react";
import BlueBar from "./BlueBar";
export default function TourDialog(_a) {
    var isShown = _a.isShown, setIsShown = _a.setIsShown;
    return (_jsx(Pane, { children: _jsxs(Dialog, __assign({ isShown: isShown, cancelLabel: "Abbrechen", confirmLabel: "Hinzuf\u00FCgen", shouldCloseOnOverlayClick: false, shouldCloseOnEscapePress: false, hasHeader: false, hasFooter: false, contentContainerProps: {
                className: "dialog-content-container",
                padding: 0,
            }, containerProps: { className: "square-corners" }, width: "80vw" }, { children: [_jsx(BlueBar, {}), _jsx(CrossIcon, { onClick: function () { return setIsShown(false); }, style: {
                        position: "absolute",
                        right: 20,
                        top: 20,
                        cursor: "pointer",
                    } }), _jsx(Pane, __assign({ padding: 20 }, { children: _jsx(Heading, __assign({ size: "large", marginBottom: 20 }, { children: "TOUR ERSTELLEN" })) }))] })) }));
}
