var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
//Remindercomponent which is renderd
//if request has a sceduled meeting
//Possibility to cancel the meeting with reason
import React, { useState, useEffect } from "react";
import firebase from "../config";
import "firebase/auth";
import moment from "moment";
import { Dialog, Alert, CrossIcon, Button, Pane, Paragraph, TextareaField, toaster, InlineAlert, } from "evergreen-ui";
require("firebase/firestore");
//database reference
var db = firebase.firestore();
export default function CallReminder(props) {
    //If reject dialog is shown to cancel the meeting
    var _a = useState(false), showDialog = _a[0], setDialog = _a[1];
    //reason for canceling the meeting
    var _b = useState(""), isReason = _b[0], setReason = _b[1];
    //Shows loadingprocess for cancel-process
    var _c = useState(false), isLoading = _c[0], setLoading = _c[1];
    //shows downloadprocess for loading dates
    var _d = useState(false), isDownloading = _d[0], setDownloading = _d[1];
    //Is meeting in the past
    var _e = useState(false), isPast = _e[0], setPast = _e[1];
    //Rawdates for unshift helper for
    //holding booked and available arrays
    var _f = useState([]), rawAvailableDateArray = _f[0], setRawAvailableDateArray = _f[1];
    var _g = useState([]), rawBookedDateArray = _g[0], setRawBookedDateArray = _g[1];
    //checks if scheduled meeting
    //is in past or future
    //disables it and other text
    useEffect(function () {
        calcDiff();
    }, []);
    //calculates time difference and sets state
    function calcDiff() {
        //calculate difference between now and meeting time
        var diff = moment(props.callDate.value.seconds * 1000).diff(moment());
        //if between 10+- minutes he can start call
        if (diff > -600000 && diff < 600000) {
            setPast(false);
        }
        else if (diff < -600000) {
            //To late for call not anymore available
            setPast(true);
        }
        else {
            //if in future
            setPast(false);
        }
    }
    //Cancels the meeting when not to recent
    //and adds cancel-reason to request for docu
    function cancelCallDate() {
        if (isReason === "") {
            toaster.warning("Sie müssen einen Grund angeben!");
            return;
        }
        setLoading(true);
        unshiftDate(props.callDate.value);
    }
    //renders alert depening if meeting is in past
    //or future / present
    function renderAlert() {
        if (isPast) {
            return (_jsx(Alert, { intent: "success", marginBottom: 10, marginTop: 10, title: "Sie hatten am ".concat(props.callDate.label, " einen Live-Anruf-Termin") }));
        }
        else {
            return (_jsx(_Fragment, { children: _jsx(Alert, { intent: "success", marginBottom: 10, marginTop: 10, title: "Sie haben am ".concat(props.callDate.label, " einen Live-Anruf-Termin") }) }));
        }
    }
    //gets all available and booked dates of business
    //and then opens up cancel dialog
    function getDates() {
        //set loading
        setDownloading(true);
        db.collection("apos_on_demand")
            .doc(props.eMail)
            .get()
            .then(function (snap) {
            var data = snap.data();
            //RawDateArray is for shiftDate to find the date in array
            //to shift choosen date to booked
            //If not defined there arent any defined of the busienss
            setRawAvailableDateArray(data.availableCallDates ? data.availableCallDates : []);
            setRawBookedDateArray(data.bookedCallDates ? data.bookedCallDates : []);
            //After loading show dialog
            setDownloading(false);
            setDialog(true);
        })
            .catch(function (error) {
            setDownloading(false);
            toaster.danger("Ein Fehler ist beim Laden der Termine ein aufgetreten");
        });
    }
    //Shifts selected date for call from booked to available
    function unshiftDate(date) {
        //gets seconds of selected date
        var seconds = date.seconds;
        //gets the arrays and filters them
        var newAvailableDates = rawAvailableDateArray;
        newAvailableDates.push(date);
        var newBookedDates = rawBookedDateArray.filter(function (data) { return data.seconds !== seconds; });
        //changes dates of business
        db.collection("apos_on_demand")
            .doc(props.eMail)
            .update({
            availableCallDates: newAvailableDates,
            bookedCallDates: newBookedDates,
        })
            .then(function (doc) {
            //sets calldate to request
            db.collection("teleOnDemand").doc(props.id).update({
                callDate: firebase.firestore.FieldValue.delete(),
                cancelReason: isReason,
            });
            toaster.success("Termin erfolgreich abgesagt!");
            setLoading(false);
            setDialog(false);
        })
            .catch(function (error) {
            setLoading(false);
            console.log(error);
        });
    }
    //renders canceldialog depending on past or future
    //Just cancel meeting if it is in future
    function renderCancel() {
        if (!isPast) {
            return (_jsx(Button, __assign({ isLoading: isDownloading, onClick: function () { return getDates(); }, iconBefore: CrossIcon, intent: "danger", appearance: "minimal" }, { children: "Absagen" })));
        }
    }
    return (_jsxs("div", { children: [renderAlert(), renderCancel(), _jsx(Pane, { children: _jsxs(Dialog, __assign({ isShown: showDialog, title: "Termin absagen", cancelLabel: "Abbrechen", intent: "danger", hasClose: false, isConfirmLoading: isLoading, onCloseComplete: function () { return setDialog(false); }, confirmLabel: "Absagen", onConfirm: function () { return cancelCallDate(); } }, { children: [_jsx(Paragraph, __assign({ size: 700 }, { children: "Warum m\u00F6chtest du diesen Termin absagen?" })), _jsx(TextareaField, { label: "Beschreibung des Grundes", grammarly: true, spellCheck: true, required: isReason === "" ? true : false, placeholder: isReason, onChange: function (e) { return setReason(e.target.value); } })] })) })] }));
}
