var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import firebase from "../config";
import { generateId } from "../utility/generatePseudoRandomString";
var ErrorReportingService = /** @class */ (function () {
    function ErrorReportingService() {
    }
    ErrorReportingService.hookConsoleFunctions = function () {
        ErrorReportingService.nativeLog = console.log;
        ErrorReportingService.nativeWarn = console.warn;
        ErrorReportingService.nativeError = console.error;
        console.log = function log() {
            var input = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                input[_i] = arguments[_i];
            }
            ErrorReportingService.recordLog.apply(ErrorReportingService, __spreadArray(["log"], input, false));
            if (ErrorReportingService.publishLogs) {
                ErrorReportingService.nativeLog.apply(ErrorReportingService, input);
            }
        };
        console.warn = function warn() {
            var input = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                input[_i] = arguments[_i];
            }
            ErrorReportingService.recordLog.apply(ErrorReportingService, __spreadArray(["warn"], input, false));
            if (ErrorReportingService.publishLogs) {
                ErrorReportingService.nativeWarn.apply(ErrorReportingService, input);
            }
        };
        console.error = function error() {
            var input = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                input[_i] = arguments[_i];
            }
            ErrorReportingService.recordLog.apply(ErrorReportingService, __spreadArray(["error"], input, false));
            if (ErrorReportingService.publishLogs) {
                ErrorReportingService.nativeError.apply(ErrorReportingService, input);
            }
        };
    };
    ErrorReportingService.recordLog = function (severity) {
        var data = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            data[_i - 1] = arguments[_i];
        }
        if (data.length === 0 || (data.length === 1 && data[0] === ""))
            return;
        try {
            ErrorReportingService.logs.push({
                severity: severity,
                content: data.map(function (d) { return JSON.stringify(d); }).join(" "),
                timestamp: Date.now()
            });
        }
        catch (e) {
            ErrorReportingService.logs.push({
                severity: severity,
                content: "Log was not serializable",
                timestamp: Date.now()
            });
        }
        if (ErrorReportingService.logs.length > 200) {
            ErrorReportingService.logs = ErrorReportingService.logs.splice(0, 1);
        }
    };
    ErrorReportingService.submitLogs = function (errorInfo) {
        var _a;
        if (ErrorReportingService.developerMode) {
            this.nativeWarn("Would have errored, but prevented reporting in devMode.");
            return "00000000";
        }
        var supportId = generateId(8);
        this.nativeWarn("Reporting", ErrorReportingService.logs.length, "as", supportId);
        firebase.firestore().collection("errorReports").doc(supportId).set({
            timestamp: new Date(),
            user: (_a = firebase.auth().currentUser) === null || _a === void 0 ? void 0 : _a.uid,
            logs: ErrorReportingService.logs,
            wasReactCrash: errorInfo !== undefined,
            reactStack: errorInfo !== null && errorInfo !== void 0 ? errorInfo : null
        });
        return supportId;
    };
    var _a, _b, _c, _d;
    ErrorReportingService.logs = [];
    ErrorReportingService.publishLogs = (_b = JSON.parse((_a = localStorage.getItem("enableLogs")) !== null && _a !== void 0 ? _a : "null")) !== null && _b !== void 0 ? _b : (window.location.toString().includes("localhost") || window.location.toString().includes("web.app"));
    ErrorReportingService.developerMode = (_d = JSON.parse((_c = localStorage.getItem("devMode")) !== null && _c !== void 0 ? _c : "null")) !== null && _d !== void 0 ? _d : window.location.toString().includes("localhost");
    return ErrorReportingService;
}());
export default ErrorReportingService;
// @ts-ignore
window.ers = ErrorReportingService;
