var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LockIcon } from "evergreen-ui";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { useEffect, useState } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import firebase from "../../config";
import withPaywall from "../../hocs/withPaywall";
import PaywallInfo from "../../routes/PaywallInfo";
import DashboardCard from "./DashboardCard";
var MONTH = {
    January: "Januar",
    February: "Februar",
    March: "März",
    April: "April",
    May: "Mai",
    June: "Juni",
    July: "Juli",
    August: "August",
    September: "September",
    October: "Oktober",
    November: "November",
    December: "Dezember",
};
var StatsOverviewCard = function () {
    var _a = useState(false), ready = _a[0], setReady = _a[1];
    var _b = useState([]), usageRecords = _b[0], setUsageRecords = _b[1];
    var getStripeUsageRecord = function () {
        var getUsageRecord = firebase
            .app()
            .functions("europe-west3")
            .httpsCallable("getUsageRecord");
        getUsageRecord()
            .then(function (resp) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                setReady(true);
                setUsageRecords(resp.data
                    .map(function (entry) { return ({
                    name: MONTH[entry.month],
                    uv: entry.count,
                }); })
                    .reverse());
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            setReady(true);
            console.log(err);
        });
    };
    useEffect(function () {
        getStripeUsageRecord();
    }, []);
    if (!ready) {
        return (_jsx(DashboardCard, __assign({ title: "Optimierte Aufträge", size: 3 }, { children: _jsx("p", { children: "Loading..." }) })));
    }
    return (_jsx(DashboardCard, __assign({ title: "Optimierte Aufträge", size: 3 }, { children: _jsx(ResponsiveContainer, __assign({ width: "100%", height: "100%" }, { children: _jsxs(BarChart, __assign({ width: 150, height: 40, data: usageRecords }, { children: [_jsx(XAxis, { dataKey: "name" }), _jsx(YAxis, {}), _jsx(Bar, { dataKey: "uv", fill: "#49BEEF" })] })) })) })));
};
export default withPaywall(StatsOverviewCard, "apomap-plus", PaywallInfo("Optimierte Aufträge", "Die Analyse der optimierten Aufträge ist Teil der Statistiken.", "apomap-plus", false, _jsx("div", __assign({ style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    } }, { children: _jsx(LockIcon, { size: 35 }) })), false, "100%", "450px", true));
