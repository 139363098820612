var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Pane, Paragraph } from "evergreen-ui";
import React from "react";
import logo from "../assets/images/logo.png";
export default function FooterBar(_a) {
    var additionalText = _a.additionalText;
    return (_jsxs(Pane, __assign({ style: {
            position: "absolute",
            width: "100%",
            height: 40,
            bottom: 0,
            backgroundColor: "#2D3B51",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
        } }, { children: [_jsxs(Pane, __assign({ flexDirection: "row", display: "flex" }, { children: [_jsx("img", { src: logo, style: { height: 25, marginLeft: 40 }, alt: "apomap-logo" }), additionalText && (_jsx(Paragraph, __assign({ color: "white" }, { children: additionalText })))] })), _jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(Paragraph, __assign({ marginRight: 25, color: "white" }, { children: _jsx("a", __assign({ href: "https://apomap.de/impressum/", target: "_blank", rel: "noreferrer", style: { color: "white", textDecoration: "none" } }, { children: "Impressum" })) })), _jsx(Paragraph, __assign({ marginRight: 25, color: "white" }, { children: _jsx("a", __assign({ href: "https://apomap.de/datenschutzerklaerung-apomap/", target: "_blank", rel: "noreferrer", style: { color: "white", textDecoration: "none" } }, { children: "Datenschutz" })) })), _jsx(Paragraph, __assign({ marginRight: 80, color: "white" }, { children: _jsx("a", __assign({ href: "https://apomap.de/allgemeine_geschaeftsbedingungen/", target: "_blank", rel: "noreferrer", style: { color: "white", textDecoration: "none" } }, { children: "AGB" })) }))] }))] })));
}
