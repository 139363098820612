export var PrintingIntentResponseFlags;
(function (PrintingIntentResponseFlags) {
    PrintingIntentResponseFlags["QUEUED"] = "queued";
    PrintingIntentResponseFlags["FUZZY_WARNING"] = "fuzzy_warning";
    PrintingIntentResponseFlags["EXECUTION_STALLED"] = "execution_stalled";
    PrintingIntentResponseFlags["POSSIBLY_UNAVAILABLE"] = "possibly_unavailable";
})(PrintingIntentResponseFlags || (PrintingIntentResponseFlags = {}));
export var PrintingIntentResponseErrorCode;
(function (PrintingIntentResponseErrorCode) {
    PrintingIntentResponseErrorCode[PrintingIntentResponseErrorCode["UNKNOWN_ERROR"] = 0] = "UNKNOWN_ERROR";
    PrintingIntentResponseErrorCode[PrintingIntentResponseErrorCode["PRINTER_UNAVAILABLE"] = 1] = "PRINTER_UNAVAILABLE";
    PrintingIntentResponseErrorCode[PrintingIntentResponseErrorCode["SPECIFY_TARGET"] = 2] = "SPECIFY_TARGET";
    PrintingIntentResponseErrorCode[PrintingIntentResponseErrorCode["INTENT_INVALID"] = 3] = "INTENT_INVALID";
    PrintingIntentResponseErrorCode[PrintingIntentResponseErrorCode["APH_UNREACHABLE"] = 4] = "APH_UNREACHABLE";
    PrintingIntentResponseErrorCode[PrintingIntentResponseErrorCode["ERROR_WHILE_SENDING"] = 5] = "ERROR_WHILE_SENDING";
})(PrintingIntentResponseErrorCode || (PrintingIntentResponseErrorCode = {}));
