/**
 * Vergleicht zwei Zeit-Strings
 *
 * @param timeA Zeit-String im Format HH:MM
 * @param timeB Zeit-String im Format HH:MM
 * @returns {number} \-1 wenn a vor b, 1 wenn a nach b, 0 wenn gleich
 */
var compareTimes = function (timeA, timeB) {
    var a = timeA.split(":").map(function (x) { return parseInt(x); });
    var b = timeB.split(":").map(function (x) { return parseInt(x); });
    // Stunde von a kleiner als Stunde von b
    if (a[0] < b[0]) {
        return -1; // a muss kleiner sein als b
    }
    // Stunde von b kleiner als Stunde von a
    if (a[0] > b[0]) {
        return 1; // b muss kleiner sein als a
    }
    // Wenn Stunden gleich sind, minuten vergleichen
    if (a[1] < b[1]) {
        return -1; // a muss kleiner sein als b
    }
    if (a[1] > b[1]) {
        return 1; // b muss kleiner sein als a
    }
    // Zeiten müssen gleich sein
    return 0;
};
export default compareTimes;
