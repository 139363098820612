export function getDriverForTask(tours, drivers, task) {
    var driverOfTask;
    var driverId;
    tours.map(function (tour) {
        var _a;
        (_a = tour === null || tour === void 0 ? void 0 : tour.activities) === null || _a === void 0 ? void 0 : _a.map(function (activity) {
            if (activity.id) {
                if (activity.id === task.task_id) {
                    driverId = tour.driver;
                }
            }
        });
    });
    drivers.map(function (driver) {
        if (driver.id === driverId) {
            driver === undefined ? (driverOfTask = "None") : (driverOfTask = driver);
        }
    });
    return driverOfTask;
}
