import { toDDMMYYYY } from "../../formatDate";
export default function taskSourceHistogramPreProc(data) {
    if (data.taskSourceHistogram === null) {
        throw new Error("Analytics PreProc: Data to process is empty");
    }
    var hist = data.taskSourceHistogram;
    var sources = hist.total.map(function (dp) { return dp.name; });
    var bars = sources.map(function (source) { return [source]; });
    var points = hist.histogram.map(function (he) {
        var histElemData = {
            name: toDDMMYYYY(he.date * 1000),
        };
        for (var _i = 0, _a = he.sources; _i < _a.length; _i++) {
            var src = _a[_i];
            histElemData[src.name] = src.count;
        }
        return histElemData;
    });
    var pieData = hist.total.map(function (dp) {
        return {
            name: dp.name,
            value: dp.count
        };
    });
    return {
        bar: {
            bars: bars,
            data: points
        },
        pie: {
            data: pieData
        }
    };
}
