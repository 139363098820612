var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
//The AuthProvider listens to the state of the user
//User can be logged in or not - Depending on this just the routes in OpenRoutes will be displayed or the in ProtectedRoutes
//Additionally to the information collected in the authentification provider the object gets populated by the data from the firestore
//Data from auth-Provider -> uid/displayName/etc -> https://rnfirebase.io/reference/auth/user#gatsby-focus-wrapper
//Data from firestore -> addititonal future information -> currently email/uid -> Duplicate of auth-Provider
import React, { useState, useEffect } from "react";
import { UserContext } from "../contexts/UserContext";
import { toaster } from "evergreen-ui";
import moment from "moment";
import firebase from "../config";
import "firebase/auth";
import "firebase/firestore";
export default function AuthProvider(props) {
    //Holds the user data
    var _a = useState(), isUser = _a[0], setUser = _a[1];
    //Is the user data fetched and ready to use
    var _b = useState(false), isReady = _b[0], setReady = _b[1];
    useEffect(function () {
        //onAuthStateChanged fires when status changed and runs function every time
        firebase.auth().onAuthStateChanged(function (user) {
            //is user legged in?
            if (user) {
                firebase
                    .firestore()
                    .collection("users")
                    .doc(firebase.auth().currentUser.uid)
                    .onSnapshot(function (doc) {
                    //If data exists he has already done the onboarding
                    if (doc.data()) {
                        var data = doc.data();
                        for (var i in data) {
                            if (i !== "email" && i !== "uid") {
                                user[i] = data[i];
                            }
                            if (i === "apiTokens") {
                                user[i] = __spreadArray([], data[i], true);
                            }
                        }
                        //Check if verification is done in seven days
                        var now = moment();
                        var registered = moment(data.created, "X");
                        var diff = now.diff(registered, "days");
                        if (diff > 7) {
                            setReady(true);
                            // toaster.danger("Ihr Account ist gesperrt!")
                            return;
                        }
                        else if (diff == 6) {
                            // toaster.danger("Verifizieren Sie ihren Account in den Einstellungen, ansonsten werden Sie morgen keinen Zugriff mehr haben!")
                        }
                        else if (4 < diff < 6) {
                            // toaster.warning("Denken Sie an die Verifizierung ihres Accounts in den Einstellungen")
                        }
                        setUser(user);
                        setReady(true);
                    }
                    else {
                        setUser(user);
                        setReady(true);
                    }
                });
            }
            else {
                //if not set ready but user still null -> OpenRoutes -> No ProtecedRoutes available
                setReady(true);
            }
        });
    }, []);
    return (_jsx(UserContext.Provider, __assign({ value: { user: [isUser, setUser], ready: [isReady, setReady] } }, { children: props.children })));
}
