import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Pane } from "evergreen-ui";
export default function BlueBar(props) {
    return (_jsx(Pane, { style: {
            width: "100%",
            height: 7,
            backgroundColor: props.color !== undefined ? props.color : "#49beef",
        } }));
}
