import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ErrorReportingService from "./services/ErrorReportingService";
ErrorReportingService.hookConsoleFunctions();
ReactDOM.render(
// <React.StrictMode>
_jsx(App, {}), 
// </React.StrictMode>,
document.getElementById("root"));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
