import firebase from "../config";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
export default function searchLocationsOnGoogle(value) {
    var searchLocationsOnGoogle = firebase
        .app()
        .functions("europe-west3")
        .httpsCallable("searchAdressOnGoogle");
    return searchLocationsOnGoogle({ value: value })
        .then(function (result) { return result; })
        .catch(function (err) { return err; });
}
