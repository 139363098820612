var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var DataCardEntry = function (props) {
    var _a;
    return (_jsxs("span", { children: [_jsx("h4", __assign({ style: {
                    margin: 0,
                    marginBottom: 3,
                    color: "#52525C",
                    fontWeight: 700
                } }, { children: props.title })), _jsx("p", __assign({ style: {
                    margin: 0,
                    marginBottom: 22,
                    color: "#52525C",
                    fontWeight: 500,
                    whiteSpace: "pre-line"
                } }, { children: (_a = props.content) !== null && _a !== void 0 ? _a : "-" }))] }));
};
export default DataCardEntry;
