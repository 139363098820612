export default function getExclamationFromPrio(number) {
    var result;
    switch (number) {
        case 1:
            result = "!!!";
            break;
        case 2:
            result = "!!";
            break;
        case 3:
            result = "!";
            break;
        default:
            result = "Keine";
            break;
    }
    return result;
}
