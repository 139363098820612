var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import "firebase/firestore";
import moment from "moment-timezone";
import firebase from "../config";
export function createId() {
    var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        S4() +
        S4());
}
export function addSecondsToStartTime(activity) {
    console.log("#######");
    console.log(activity);
    return moment.unix(activity.arr_time).format("HH:mm");
}
export function convertSecondsToClock(seconds) {
    return moment.utc(seconds * 1000).format("HH:mm");
}
export function convertClockToSeconds(clock) {
    return moment(clock, "HH:mm").diff(moment().startOf("day"), "seconds");
}
//Universal Send message
export var sendPushMessage = function (_a) {
    var token = _a.token, title = _a.title, message = _a.message;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            return [2 /*return*/, new Promise(function (res, rej) {
                    var sendPushNotification = firebase
                        .app()
                        .functions("europe-west3")
                        .httpsCallable("sendPushNotification");
                    sendPushNotification({
                        pushTokens: token,
                        title: title,
                        message: message,
                    })
                        .then(function (result) {
                        res(result);
                    })
                        .catch(function (err) {
                        rej(err);
                    });
                })];
        });
    });
};
//Universal Send message
export function fetchPushToken(uid) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (res, rej) {
                    firebase
                        .firestore()
                        .collection("drivers")
                        .doc(uid)
                        .get()
                        .then(function (doc) {
                        if (doc.exists) {
                            var data = doc.data();
                            res(data.pushToken);
                        }
                    })
                        .catch(function (err) { return rej(err); });
                })];
        });
    });
}
export var deleteNode = function (node) {
    firebase
        .app()
        .database("https://apomapdreinull-default-rtdb.europe-west1.firebasedatabase.app/")
        .ref(node)
        .remove();
};
export function getNameFromCoords(lon, lng) {
    return new Promise(function (res, rej) {
        var apiToken = "pk.eyJ1IjoiYXBvbWFwc29mdHdhcmVkZXYiLCJhIjoiY2s4dzJlYnM4MDU5cjNlbnc5bjlhbTN1NSJ9.2MVbscPKHtJSJVcLmThq2Q";
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        // ['Apple', 'Apricot', 'Banana', 'Cherry', 'Cucumber'].map((label) => ({ label, value: label }))
        fetch("https://api.mapbox.com/geocoding/v5/mapbox.places/".concat(lon, ",").concat(lng, ".json?country=de&language=de&types=locality%2Cplace%2Caddress%2Cpostcode&access_token=").concat(apiToken), requestOptions)
            .then(function (response) { return response.json(); })
            .then(function (result) {
            var hints = [];
            result.features.map(function (loc) {
                var _a, _b, _c;
                var locality = (_b = (_a = loc.context.filter(function (_a) {
                    var id = _a.id;
                    return id.split(".")[0] === "locality";
                })[0]) === null || _a === void 0 ? void 0 : _a.text) !== null && _b !== void 0 ? _b : "";
                var obj = {
                    label: (_c = loc.place_name + ", " + locality) !== null && _c !== void 0 ? _c : "",
                    value: loc.center,
                };
                hints.push(obj);
            });
            res(hints[0].label);
        })
            .catch(function (error) {
            console.log("error", error);
            rej();
        });
    });
}
