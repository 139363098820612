export var translation = function (status) {
    switch (status) {
        case "requested_driver":
        case "accept":
            return "Cure-Fahrer unterwegs";
        case "complete_stops_success":
        case "paid":
            return "Erledigt von Cure";
        case "complete_stops_declined":
            return "Nicht erreicht 🥲";
        case "start_search":
        case "reassign":
        case "schedule":
            return "Suche Cure-Fahrer";
        case "reject":
            return "Wurde abgelehnt";
        default:
            return status;
    }
};
