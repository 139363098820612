var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Heading, Pane, Paragraph, Table, TableCell } from "evergreen-ui";
export default function TourTrackerListItem(_a) {
    var tracker = _a.tracker, date = _a.date, tourStart = _a.tourStart, tourEnd = _a.tourEnd;
    return (_jsxs(Table.Row, __assign({ style: {
            width: "100%",
        } }, { children: [_jsx(TableCell, { children: _jsxs(Pane, { children: [_jsx(Heading, __assign({ size: 200, style: { fontFamily: "bold" } }, { children: "Trackername" })), _jsxs(Paragraph, { children: [" ", tracker.trackerName, " "] })] }) }), _jsx(TableCell, { children: _jsxs(Pane, { children: [_jsx(Heading, __assign({ size: 200, style: { fontFamily: "bold" } }, { children: "\u00D8 Temperatur" })), _jsxs(Paragraph, { children: [tracker.avgTemp, "\u00B0C"] })] }) }), _jsx(TableCell, { children: _jsxs(Pane, { children: [_jsx(Heading, __assign({ size: 200, style: { fontFamily: "bold" } }, { children: "Min. Temperatur" })), _jsxs(Paragraph, { children: [tracker.minTemp, "\u00B0C"] })] }) }), _jsx(TableCell, { children: _jsxs(Pane, { children: [_jsx(Heading, __assign({ size: 200, style: { fontFamily: "bold" } }, { children: "Max. Temperatur" })), _jsxs(Paragraph, { children: [tracker.maxTemp, "\u00B0C"] })] }) })] })));
}
