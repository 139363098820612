import moment from "moment";
import { useEffect, useState } from "react";
import firebase from "../config";
export default function useTaskStats() {
    var _a = useState(null), openTasks = _a[0], setOpenTasks = _a[1];
    var _b = useState(null), assignedTasks = _b[0], setAssignedTasks = _b[1];
    var _c = useState(null), onTheWayTasks = _c[0], setOnTheWayTasks = _c[1];
    var _d = useState(null), doneTasks = _d[0], setDoneTasks = _d[1];
    useEffect(function () {
        firebase
            .firestore()
            .collection("tasks")
            .where("task_owner", "==", firebase.auth().currentUser.uid)
            .where("task_status", "in", [
            "unassigned",
            "prepared",
            "assigned",
            "ontheway",
            "pickedup",
        ])
            .where("task_date", "<=", moment().endOf("day").toDate())
            .orderBy("task_date", "desc")
            .onSnapshot(function (snap) {
            setOpenTasks(snap.docs.reduce(function (accu, curr) {
                if ((curr.data().task_status === "unassigned" ||
                    curr.data().task_status === "prepared") &&
                    (!curr.data().isMerged || curr.data().collectiveTask)) {
                    return accu + 1;
                }
                return accu;
            }, 0));
            setAssignedTasks(snap.docs.reduce(function (accu, curr) {
                if (curr.data().task_status === "assigned") {
                    return accu + 1;
                }
                return accu;
            }, 0));
            setOnTheWayTasks(snap.docs.reduce(function (accu, curr) {
                if (curr.data().task_status === "ontheway") {
                    return accu + 1;
                }
                return accu;
            }, 0));
        });
        firebase
            .firestore()
            .collection("tasks")
            .where("task_owner", "==", firebase.auth().currentUser.uid)
            .where("task_status", "in", ["successfull", "declined"])
            .where("processed_date", ">=", moment().startOf("days").startOf("day").toDate())
            .where("processed_date", "<=", moment().endOf("day").toDate())
            .orderBy("processed_date", "desc")
            .onSnapshot(function (snap) {
            setDoneTasks(snap.docs.length);
        });
    }, []);
    return {
        open: openTasks,
        assigned: assignedTasks,
        onTheWay: onTheWayTasks,
        done: doneTasks,
    };
}
