var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
//Determens weather the Layout of the app is in Editmode or not
//Editmode -> Bigger Left colum with more details -> Smaller Right colum
// @ts-nocheck
import { createContext, useEffect, useState } from "react";
import PrinterSelectionModal from "../components/PrinterSelectionModal";
import PrintingService from "../services/PrintingService";
export var PrinterSelectionMode;
(function (PrinterSelectionMode) {
    PrinterSelectionMode[PrinterSelectionMode["DEFAULT"] = 0] = "DEFAULT";
    PrinterSelectionMode[PrinterSelectionMode["FALLBACK"] = 1] = "FALLBACK";
})(PrinterSelectionMode || (PrinterSelectionMode = {}));
function initialValue(value) {
    return [
        value,
        function (newValue) {
            return console.warn("State update was attempted before context was ready", newValue);
        },
    ];
}
export var pscDefaultValue = {
    shown: initialValue(false),
    candidates: initialValue([]),
    mode: initialValue(PrinterSelectionMode.DEFAULT),
    selectionCallback: initialValue(function () { }),
};
export var PrinterSelectionContext = createContext(pscDefaultValue);
export default function PrinterSelectionContextProvider(props) {
    var shown = useState(false);
    var candidates = useState([]);
    var mode = useState(PrinterSelectionMode.DEFAULT);
    var selectionCallback = useState(function () { return function () { }; });
    useEffect(function () {
        PrintingService.printerSelectionContextStates = {
            shown: shown,
            candidates: candidates,
            mode: mode,
            selectionCallback: selectionCallback,
        };
    }, [shown, candidates, mode, selectionCallback]);
    return (_jsxs(PrinterSelectionContext.Provider, __assign({ value: { shown: shown, candidates: candidates, mode: mode, selectionCallback: selectionCallback } }, { children: [_jsx(PrinterSelectionModal, {}), props.children] })));
}
