var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, CrossIcon, Dialog, Heading, IconButton, MinusIcon, Pane, Paragraph, PlusIcon, ThemeProvider, toaster, } from "evergreen-ui";
import { useEffect, useMemo, useState } from "react";
import { usePlan } from "../../hooks/usePlan";
import theme from "../../theme";
import BlueBar from "../BlueBar";
import { useAddons } from "./utils/use-addons";
export var AddonManagementModal = function (_a) {
    var _b, _c;
    var _d = useAddons(), isBooked = _d.isBooked, selectedAddon = _d.selectedAddon, shouldManage = _d.shouldManage, setAddon = _d.setAddon, setManaged = _d.setManaged, setBooked = _d.setBooked, updateAddonAmount = _d.updateAddonAmount, updateAmountLoading = _d.updateAmountLoading;
    var plan = usePlan();
    var open = useMemo(function () { return selectedAddon !== null && shouldManage && !isBooked; }, [selectedAddon, shouldManage, isBooked]);
    var currentQuantity = useMemo(function () {
        var _a, _b, _c;
        return selectedAddon
            ? parseInt((_c = (_b = (_a = plan === null || plan === void 0 ? void 0 : plan.addons) === null || _a === void 0 ? void 0 : _a[selectedAddon.default_price]) === null || _b === void 0 ? void 0 : _b.quantity) !== null && _c !== void 0 ? _c : "0")
            : 0;
    }, [plan === null || plan === void 0 ? void 0 : plan.addons, selectedAddon]);
    var _e = useState(currentQuantity), desiredQuantity = _e[0], setDesiredQuantity = _e[1];
    useEffect(function () {
        setDesiredQuantity(currentQuantity);
    }, [currentQuantity]);
    var monthlyCost = useMemo(function () { return (selectedAddon ? currentQuantity * selectedAddon.singleUnitPrice : 0); }, [currentQuantity, selectedAddon]);
    var wantsMore = useMemo(function () { return currentQuantity < desiredQuantity; }, [currentQuantity, desiredQuantity]);
    var wantsLess = useMemo(function () { return currentQuantity > desiredQuantity && desiredQuantity !== 0; }, [currentQuantity, desiredQuantity]);
    var submit = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!selectedAddon)
                return [2 /*return*/];
            updateAddonAmount({
                price_id: selectedAddon.default_price,
                amount: desiredQuantity,
            })
                .then(function () {
                toaster.success("Update war erfolgreich");
                window.location.reload();
            })
                .catch(function (err) {
                console.log(err);
                toaster.danger("Etwas ist schief gelaufen.");
            });
            return [2 /*return*/];
        });
    }); };
    if (!selectedAddon)
        return null;
    return (
    /* @ts-ignore */
    _jsx(ThemeProvider, __assign({ value: theme }, { children: _jsxs(Dialog, __assign({ isShown: open, cancelLabel: "Abbrechen", confirmLabel: "Hinzuf\u00FCgen", shouldCloseOnOverlayClick: false, shouldCloseOnEscapePress: false, hasHeader: false, hasFooter: false, contentContainerProps: {
                className: "dialog-content-container",
                padding: 0,
            }, containerProps: { className: "square-corners" }, width: "80vw" }, { children: [_jsx(BlueBar, {}), _jsx(CrossIcon, { onClick: function () { return setAddon(null); }, style: {
                        position: "absolute",
                        right: 20,
                        top: 20,
                        cursor: "pointer",
                    } }), _jsx(Pane, __assign({ overflow: "hidden", height: "65vh", display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start" }, { children: _jsxs(Pane, __assign({ width: "50%", padding: 20 }, { children: [_jsx(Heading, __assign({ size: 800, marginBottom: 20 }, { children: selectedAddon.name.toUpperCase() })), _jsxs(Pane, __assign({ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%" }, { children: [_jsx(Heading, __assign({ marginBottom: 8 }, { children: "\u00DCbersicht" })), _jsx(Paragraph, __assign({ size: 400, fontWeight: "bold" }, { children: "Beschreibung" })), _jsx(Paragraph, { children: selectedAddon.description }), _jsx(Paragraph, __assign({ size: 400, fontWeight: "bold" }, { children: "Buchungsdetails" })), _jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(Paragraph, { children: "Gebuchte Menge:" }), _jsx(Paragraph, __assign({ marginLeft: 8, fontWeight: "bold" }, { children: (_c = (_b = plan === null || plan === void 0 ? void 0 : plan.addons) === null || _b === void 0 ? void 0 : _b[selectedAddon.default_price]) === null || _c === void 0 ? void 0 : _c.quantity }))] })), _jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(Paragraph, { children: "Monatliche Kosten:" }), _jsxs(Paragraph, __assign({ marginLeft: 8, fontWeight: "bold" }, { children: [monthlyCost / 100, "\u20AC/mtl."] }))] }))] })), _jsx(Paragraph, __assign({ size: 400, fontWeight: "bold" }, { children: "Aktionen" })), _jsx(Paragraph, { children: "Gebuchte Anzahl dieser Erweiterung \u00E4ndern" }), _jsxs(Pane, __assign({ marginTop: 8, display: "flex", alignItems: "center", flexDirection: "row" }, { children: [_jsx(IconButton, { onClick: function () {
                                            return desiredQuantity !== 0 &&
                                                setDesiredQuantity(desiredQuantity - 1);
                                        }, disabled: desiredQuantity === 0, marginRight: 8, icon: MinusIcon }), _jsx(Paragraph, __assign({ fontWeight: "bold" }, { children: desiredQuantity })), _jsx(IconButton, { onClick: function () { return setDesiredQuantity(desiredQuantity + 1); }, marginLeft: 8, icon: PlusIcon })] })), wantsMore && (_jsxs(Button, __assign({ isLoading: updateAmountLoading, onClick: submit, marginTop: 8 }, { children: ["Bestellung von ", desiredQuantity - currentQuantity, " Einheiten mehr best\u00E4tigen"] }))), wantsLess && (_jsxs(Button, __assign({ isLoading: updateAmountLoading, onClick: submit, marginTop: 8 }, { children: ["Abbestellung von ", desiredQuantity - currentQuantity, " Einheiten best\u00E4tigen"] }))), desiredQuantity === 0 && (_jsx(Button, __assign({ marginTop: 8, onClick: function () {
                                    setManaged(false);
                                    setBooked(true);
                                } }, { children: "Jetzt k\u00FCndigen" })))] })) }))] })) })));
};
