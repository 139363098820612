import { create } from "zustand";
//Zustand store for all state you need for mauve
var useShopApothekeStore = create()(function (set) { return ({
    modalShown: false,
    username: null,
    password: null,
    apiKey: null,
    webHookKey: null,
    selectedTokenPermissions: [],
    pharmacy: null,
    setUsername: function (value) { return set(function () { return ({ username: value }); }); },
    setPassword: function (value) { return set(function () { return ({ password: value }); }); },
    setPharmacy: function (value) { return set(function () { return ({ pharmacy: value }); }); },
    setApiKey: function (value) { return set(function () { return ({ apiKey: value }); }); },
    setWebHookKey: function (value) { return set(function () { return ({ webHookKey: value }); }); },
    setTokenPermissions: function (value) {
        return set(function () { return ({ selectedTokenPermissions: value }); });
    },
    setModal: function (value) { return set(function () { return ({ modalShown: value }); }); },
    resetPage: function () {
        return set(function (state) { return ({
            username: (state.username = null),
            password: (state.password = null),
            pharmacy: (state.pharmacy = null),
            apiKey: (state.apiKey = null),
            webHookKey: (state.webHookKey = null),
            selectedTokenPermissions: (state.selectedTokenPermissions = []),
        }); });
    },
}); });
export var useShopApotheke = function () {
    //Getting all states and methodes out of the zustand store
    var _a = useShopApothekeStore(), modalShown = _a.modalShown, username = _a.username, password = _a.password, apiKey = _a.apiKey, setUsername = _a.setUsername, setPassword = _a.setPassword, setApiKey = _a.setApiKey, webHookKey = _a.webHookKey, setWebHookKey = _a.setWebHookKey, selectedTokenPermissions = _a.selectedTokenPermissions, setTokenPermissions = _a.setTokenPermissions, setModal = _a.setModal, resetPage = _a.resetPage, pharmacy = _a.pharmacy, setPharmacy = _a.setPharmacy;
    return {
        modalShown: modalShown,
        username: username,
        password: password,
        apiKey: apiKey,
        webHookKey: webHookKey,
        setUsername: setUsername,
        setPassword: setPassword,
        selectedTokenPermissions: selectedTokenPermissions,
        setTokenPermissions: setTokenPermissions,
        setApiKey: setApiKey,
        setWebHookKey: setWebHookKey,
        setModal: setModal,
        resetPage: resetPage,
        pharmacy: pharmacy,
        setPharmacy: setPharmacy,
    };
};
