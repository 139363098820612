export function toDDMMYYYY(rawInput) {
    if (!rawInput) {
        return "";
    }
    var input = (typeof rawInput === "string" && rawInput.length === 8) ? "20" + rawInput : rawInput;
    var d = new Date(input);
    return "".concat(d.getDate().toString().padStart(2, "0"), ".").concat((d.getMonth() + 1).toString().padStart(2, "0"), ".").concat(d.getFullYear());
}
export function toYYMMDD(rawInput) {
    if (!rawInput) {
        return "";
    }
    var d = new Date(rawInput);
    return "".concat(d.getFullYear().toString().substring(2), "-").concat((d.getMonth() + 1).toString().padStart(2, "0"), "-").concat(d.getDate().toString().padStart(2, "0"));
}
export function toYYYYMMDD(rawInput) {
    if (!rawInput) {
        return "";
    }
    var d = new Date(rawInput);
    return "".concat(d.getFullYear().toString(), "-").concat((d.getMonth() + 1).toString().padStart(2, "0"), "-").concat(d.getDate().toString().padStart(2, "0"));
}
