var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, CaretDownIcon, Pane, Paragraph, Popover, Position, SelectMenu, SortAlphabeticalDescIcon, SortAlphabeticalIcon, SortIcon, Table, TagIcon, Tooltip, } from "evergreen-ui";
import { useRef } from "react";
import ApoTextInput from "./ApoTextInput";
var checkForChange = function (selected, options, resetBehaviour) {
    switch (resetBehaviour) {
        case "all": {
            return selected.length !== options.length;
        }
        case "none": {
            return selected.length !== 0;
        }
        default: {
            return false;
        }
    }
};
export default function TableSortHeader(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var hasBeenChanged = useRef(false);
    if (!((_a = props.search) !== null && _a !== void 0 ? _a : false)) {
        return (_jsx(Table.TextHeaderCell, { children: _jsxs(Pane, __assign({ style: {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "5px",
                } }, { children: [((_b = props.sort) !== null && _b !== void 0 ? _b : false) && (_jsxs("div", __assign({ style: { cursor: "pointer" }, onClick: function () {
                            switch (props.sortState) {
                                case null: {
                                    props.onSortSet("asc");
                                    break;
                                }
                                case "asc": {
                                    props.onSortSet("desc");
                                    break;
                                }
                                case "desc": {
                                    props.onSortSet(null);
                                    break;
                                }
                                default: {
                                    props.onSortSet(null);
                                }
                            }
                        } }, { children: [props.sortState === null && _jsx(SortIcon, {}), props.sortState === "asc" && _jsx(SortAlphabeticalIcon, {}), props.sortState === "desc" && _jsx(SortAlphabeticalDescIcon, {})] }))), ((_c = props.filter) !== null && _c !== void 0 ? _c : false) && (_jsx(SelectMenu, __assign({ isMultiSelect: true, title: props.filterTitle, options: props.filterOptions, selected: props.filterState, onSelect: function (item) {
                            var newSelection = __spreadArray(__spreadArray([], props.filterState, true), [item.value], false);
                            props.onFilterChanged(newSelection);
                            hasBeenChanged.current = checkForChange(newSelection, props.filterOptions, props.filterResetBehavior);
                        }, onDeselect: function (item) {
                            var deselectedItemIndex = props.filterState.indexOf(item.value);
                            var selectedItems = props.filterState.filter(function (_item, i) { return i !== deselectedItemIndex; });
                            props.onFilterChanged(selectedItems);
                            hasBeenChanged.current = checkForChange(selectedItems, props.filterOptions, props.filterResetBehavior);
                        }, hasFilter: (_d = props.filterSearch) !== null && _d !== void 0 ? _d : false }, { children: _jsx("div", __assign({ style: { cursor: "pointer" }, onContextMenu: function (evt) {
                                evt.preventDefault();
                                if (props.filterResetBehavior === "all") {
                                    props.onFilterChanged(props.filterOptions.map(function (option) { return option.value; }));
                                }
                                else if (props.filterResetBehavior === "none") {
                                    props.onFilterChanged([]);
                                }
                                hasBeenChanged.current = false;
                            } }, { children: ((_e = props.filterResetBehavior) !== null && _e !== void 0 ? _e : false) ? (_jsx(Tooltip, __assign({ content: "Rechtsklick zum zurücksetzen." }, { children: _jsx(Button, __assign({ appearance: "minimal", iconAfter: CaretDownIcon }, { children: props.title })) }))) : (_jsx(Button, __assign({ appearance: "minimal", iconAfter: CaretDownIcon }, { children: props.title }))) })) }))), ((_f = props.timespanFilter) !== null && _f !== void 0 ? _f : false) && (_jsx(Popover, __assign({ position: Position.BOTTOM, content: _jsxs("div", __assign({ style: {
                                paddingLeft: "14px",
                                paddingRight: "14px",
                                paddingTop: "6px",
                                paddingBottom: "6px",
                            } }, { children: [_jsxs("div", { children: [_jsx(Paragraph, { children: "Von" }), _jsx(ApoTextInput, { type: "time", size: "medium", value: props.timeStart, onChange: function (evt) {
                                                props.onTimeStartChange(evt.target.value);
                                            } })] }), _jsxs("div", { children: [_jsx(Paragraph, { children: "Bis" }), _jsx(ApoTextInput, { type: "time", size: "medium", value: props.timeEnd, onChange: function (evt) {
                                                props.onTimeEndChange(evt.target.value);
                                            } })] })] })) }, { children: _jsx(Tooltip, __assign({ content: "Nach Zeitraum filtern. Rechtsklick zum zurücksetzen." }, { children: _jsx("div", __assign({ style: { cursor: "pointer" }, onContextMenu: function (evt) {
                                    evt.preventDefault();
                                    props.onTimeStartChange("");
                                    props.onTimeEndChange("");
                                } }, { children: _jsx(Button, __assign({ appearance: "minimal", iconAfter: CaretDownIcon, color: props.timeStart !== "" || props.timeEnd !== ""
                                        ? "#49beef"
                                        : null }, { children: props.title })) })) })) })))] })) }));
    }
    else {
        return (_jsxs(_Fragment, { children: [_jsx(Table.SearchHeaderCell, { value: props.searchState, onChange: props.onSearchValueChange, placeholder: props.searchPlaceholder, style: {
                        border: "1px solid #ccc",
                        borderRadius: "1rem",
                        padding: "0.5rem",
                        width: props.extended || props.isExpandedView ? "918px" : "250px",
                        marginLeft: props.marginLeft,
                        marginBottom: props.marginBottom,
                        marginTop: props.marginTop,
                        position: "relative",
                    } }), _jsxs("div", __assign({ style: {
                        display: "flex",
                        justifyItems: "center",
                        alignItems: "center",
                        gap: 14,
                        position: "absolute",
                        top: 10,
                        right: 10,
                        zIndex: 1,
                        backgroundColor: "#fff",
                    } }, { children: [((_g = props.sort) !== null && _g !== void 0 ? _g : false) && (_jsxs("div", __assign({ style: { cursor: "pointer" }, onClick: function () {
                                switch (props.sortState) {
                                    case null: {
                                        props.onSortSet("asc");
                                        break;
                                    }
                                    case "asc": {
                                        props.onSortSet("desc");
                                        break;
                                    }
                                    case "desc": {
                                        props.onSortSet(null);
                                        break;
                                    }
                                    default: {
                                        props.onSortSet(null);
                                    }
                                }
                            } }, { children: [props.sortState === null && (_jsx(Tooltip, __assign({ content: "Neue Aufträge werden zuerst angezeigt" }, { children: _jsx(SortIcon, {}) }))), props.sortState === "asc" && (_jsx(Tooltip, __assign({ content: "Aufträge werden von A-Z nach dem Nachnamen des Kunden angezeigt" }, { children: _jsx(SortAlphabeticalIcon, {}) }))), props.sortState === "desc" && (_jsx(Tooltip, __assign({ content: "Aufträge werden von Z-A nach dem Nachnamen des Kunden angezeigt" }, { children: _jsx(SortAlphabeticalDescIcon, {}) })))] }))), ((_h = props.filter) !== null && _h !== void 0 ? _h : false) && (_jsx(SelectMenu, __assign({ isMultiSelect: true, title: props.filterTitle, options: props.filterOptions, selected: props.filterState, onSelect: function (item) {
                                var newSelection = __spreadArray(__spreadArray([], props.filterState, true), [item.value], false);
                                props.onFilterChanged(newSelection);
                                hasBeenChanged.current = checkForChange(newSelection, props.filterOptions, props.filterResetBehavior);
                            }, onDeselect: function (item) {
                                var deselectedItemIndex = props.filterState.indexOf(item.value);
                                var selectedItems = props.filterState.filter(function (_item, i) { return i !== deselectedItemIndex; });
                                props.onFilterChanged(selectedItems);
                                hasBeenChanged.current = checkForChange(selectedItems, props.filterOptions, props.filterResetBehavior);
                            }, hasFilter: (_j = props.filterSearch) !== null && _j !== void 0 ? _j : false }, { children: _jsx("div", __assign({ style: { cursor: "pointer" }, onContextMenu: function (evt) {
                                    evt.preventDefault();
                                    if (props.filterResetBehavior === "all") {
                                        props.onFilterChanged(props.filterOptions.map(function (option) { return option.value; }));
                                    }
                                    else if (props.filterResetBehavior === "none") {
                                        props.onFilterChanged([]);
                                    }
                                    hasBeenChanged.current = false;
                                } }, { children: ((_k = props.filterResetBehavior) !== null && _k !== void 0 ? _k : false) ? (_jsx(Tooltip, __assign({ content: "Rechtsklick zum zurücksetzen." }, { children: _jsx(TagIcon, { color: hasBeenChanged.current ? "#49beef" : null }) }))) : (_jsx(TagIcon, {})) })) })))] }))] }));
    }
}
