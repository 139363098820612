var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Heading, Pane, Paragraph, Spinner, ThemeProvider } from "evergreen-ui";
import { useMemo } from "react";
import { AddonBookingModal } from "../components/Addons/Addon-Booking-Modal";
import { AddonCancelModal } from "../components/Addons/Addon-Cancel-Modal";
import { AddonManagementModal } from "../components/Addons/Addon-Management-Modal";
import { AddonPaymentBlocker } from "../components/Addons/Addon-Payment-Blocker";
import { AddonPreviewCard } from "../components/Addons/Addon-Preview-Card";
import { useAddons } from "../components/Addons/utils/use-addons";
import NavigatorBar from "../container/NavigatorBar";
import theme from "../theme";
export var AddOns = function (_a) {
    var _b = useAddons(), availableAddons = _b.availableAddons, setAddon = _b.setAddon, isInitializing = _b.isInitializing;
    var addOnsAvailable = useMemo(function () { return !isInitializing && availableAddons.length !== 0; }, [availableAddons.length, isInitializing]);
    return (_jsxs(ThemeProvider
    /* @ts-ignore */
    , __assign({ 
        /* @ts-ignore */
        value: theme }, { children: [_jsx(AddonBookingModal, {}), _jsx(AddonCancelModal, {}), _jsx(AddonManagementModal, {}), _jsx(NavigatorBar, {}), _jsx(Pane, __assign({ elevation: 2, style: {
                    padding: 25,
                    margin: 25,
                    width: "90vw",
                    overflow: "hidden",
                    height: "100%",
                } }, { children: _jsxs(Pane, __assign({ display: "flex", flexDirection: "column", style: {
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        gap: "0.5rem",
                        marginBottom: 18,
                    } }, { children: [_jsx(Heading, __assign({ size: 900 }, { children: "Erweiterungen" })), _jsx(Heading, __assign({ size: 700, style: { color: "#000000" } }, { children: "Hier kannst du die verf\u00FCgbaren Erweiterungen buchen und verwalten" })), isInitializing ? _jsx(Spinner, {}) : null, !addOnsAvailable && !isInitializing && (_jsx(Paragraph, { children: "Derzeit keine Erweiterungen verf\u00FCgbar" })), addOnsAvailable && (_jsx(AddonPaymentBlocker, { children: _jsx(Pane, __assign({ display: "flex", flexDirection: "row", flexWrap: "wrap" }, { children: availableAddons.map(function (a) { return (_jsx(AddonPreviewCard, { addOn: a, onPress: function () { return setAddon(a); }, title: a.name, description: a.description, image: a.images[0] })); }) })) }))] })) }))] })));
};
