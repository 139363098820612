var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import { Dialog, Overlay, Pane, Spinner, Text, TextInput, toaster, } from "evergreen-ui";
import "firebase/functions";
import generateApiKey from "generate-api-key";
import { useContext, useState } from "react";
import firebase from "../../config";
import { UserContext } from "../../contexts/UserContext";
import LocationSelectMenu from "../LocationSelectMenu";
import { PermissionSelectBox } from "../Tokens/PermissionSelectBox";
import { useShopApotheke } from "./store";
var ShopApothekeModal = function () {
    var user = useContext(UserContext).user;
    var isUser = user[0];
    var _a = useShopApotheke(), modalShown = _a.modalShown, username = _a.username, password = _a.password, apiKey = _a.apiKey, selectedTokenPermissions = _a.selectedTokenPermissions, setApiKey = _a.setApiKey, setUsername = _a.setUsername, setPassword = _a.setPassword, setModal = _a.setModal, setTokenPermissions = _a.setTokenPermissions, pharmacy = _a.pharmacy, setPharmacy = _a.setPharmacy, resetPage = _a.resetPage, webHookKey = _a.webHookKey, setWebHookKey = _a.setWebHookKey;
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var handleModalConfirmation = function () {
        setIsLoading(true);
        try {
            var apomapApiKey_1 = generateApiKey({
                method: "bytes",
                prefix: "shop",
                length: 24,
            });
            firebase
                .firestore()
                .collection("tokens")
                .doc(apomapApiKey_1)
                .set({
                key: apomapApiKey_1,
                name: "ShopApotheke",
                location: pharmacy === null || pharmacy === void 0 ? void 0 : pharmacy.value,
                owner: isUser.uid,
                permissions: selectedTokenPermissions,
            })
                .then(function () {
                firebase
                    .firestore()
                    .collection("users")
                    .doc(isUser.uid)
                    .update({
                    shopApothekeCredentials: {
                        username: username,
                        password: password,
                        apiKey: apiKey,
                        apomapApiKey: apomapApiKey_1,
                        webHookKey: webHookKey,
                    },
                });
                toaster.success("Ihre ShopApotheke-Verbindung wurde erfolgreich erstellt");
                resetPage();
                setModal(false);
            });
        }
        catch (error) {
            console.log("Something went wrong: ", error);
            toaster.danger("Ihre ShopApotheke-Verbindung konnte nicht erstellt werden");
        }
    };
    var handleClose = function () {
        setModal(false);
        resetPage();
    };
    return (_jsx(Overlay, __assign({ isShown: modalShown, shouldCloseOnClick: false, shouldCloseOnEscapePress: false }, { children: _jsxs(Dialog, __assign({ isShown: modalShown, title: "ShopApotheke Integration", onCloseComplete: handleClose, confirmLabel: "Beenden Sie die Einrichtung", onConfirm: handleModalConfirmation, hasCancel: false, isConfirmDisabled: !username || !password || !apiKey ? true : false, shouldCloseOnOverlayClick: false, shouldCloseOnEscapePress: false }, { children: [_jsxs(Pane, { children: [_jsx(Text, { children: " Registrieren Sie Ihre ShopApotheke-Zugangsdaten " }), _jsxs(Pane, __assign({ style: {
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                gap: "1rem",
                                width: "100%",
                                height: "100%",
                                marginTop: "1rem",
                            } }, { children: [_jsx(TextInput, { placeholder: "ShopApotheke Username", value: username, type: "text", onChange: function (e) { return setUsername(e.target.value); }, style: { width: "100%" } }), _jsx(TextInput, { placeholder: "ShopApotheke Password", value: password, type: "password", onChange: function (e) { return setPassword(e.target.value); }, style: { width: "100%" } }), _jsx(TextInput, { placeholder: "ShopApotheke apiKey", value: apiKey, type: "password", onChange: function (e) { return setApiKey(e.target.value); }, style: { width: "100%" } }), _jsx(TextInput, { placeholder: "ShopApotheke Webhook Key", value: webHookKey, type: "password", onChange: function (e) { return setWebHookKey(e.target.value); }, style: { width: "100%" } }), _jsx(Text, { children: " Token-Berechtigungen festlegen: " }), _jsxs(Pane, __assign({ style: {
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        gap: "0.5rem",
                                        width: "100%",
                                    } }, { children: [_jsx(PermissionSelectBox, { selectedItemsState: selectedTokenPermissions, setSelectedItems: setTokenPermissions }), _jsx(LocationSelectMenu, { isSelected: pharmacy, setSelected: setPharmacy })] }))] }))] }), isLoading && (_jsxs(Pane, __assign({ style: {
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "100%",
                        gap: "1rem",
                        marginTop: "1rem",
                    } }, { children: [_jsx(Spinner, { size: 24 }), _jsx(Text, { children: " Validierung Ihrer Informationen " })] })))] })) })));
};
export default ShopApothekeModal;
