var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { toDDMMYYYY } from "../../formatDate";
export default function taskCreationHistogramPreProc(data) {
    if (data.taskCreationHistogram === null) {
        throw new Error("Analytics PreProc: Data to process is empty");
    }
    var hist = data.taskCreationHistogram;
    var locations = hist.filter(function (he) { return he.location.length > 0; }).map(function (e) { return e.location; }).reduce(function (accu, curr) {
        return __spreadArray(__spreadArray([], accu, true), curr.map(function (c) { return c.name; }), true);
    }, []);
    var bars = Array.from(new Set(locations)).map(function (lid) { return [lid]; });
    var points = hist.map(function (he) {
        var histElemData = {
            name: toDDMMYYYY(he.date * 1000),
        };
        for (var _i = 0, _a = he.location; _i < _a.length; _i++) {
            var loc = _a[_i];
            histElemData[loc.name] = loc.delivery + loc.pickup;
        }
        return histElemData;
    });
    var pieData = [
        {
            name: "Lieferung",
            value: hist.reduce(function (globAccu, he) {
                return globAccu + he.location.reduce(function (accu, curr) {
                    return accu + curr.delivery;
                }, 0);
            }, 0)
        },
        {
            name: "Abholung",
            value: hist.reduce(function (globAccu, he) {
                return globAccu + he.location.reduce(function (accu, curr) {
                    return accu + curr.pickup;
                }, 0);
            }, 0)
        }
    ];
    return {
        bar: {
            bars: bars,
            data: points
        },
        pie: {
            data: pieData
        }
    };
}
