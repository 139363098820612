var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Paragraph } from "evergreen-ui";
import { useContext, useEffect, useState } from "react";
import { LocationContext } from "../contexts/LocationContext";
import { TaskContext } from "../contexts/TaskContext";
export default function LocationLabel(props) {
    var locations = useContext(LocationContext).locations;
    var areBusinessLocations = locations[0], setBusinessLocations = locations[1];
    var tasks = useContext(TaskContext).tasks;
    var areTasks = tasks[0], _ = tasks[1];
    var _a = useState(), name = _a[0], setName = _a[1];
    //For difference in action ->
    //tourstart_individual_**** -> Start an einer inividuellen Position -> props.id.split("_")[1] -> die Adresse als string
    //tourstart_****** -> Start an einer Location -> Da muss erstmal der Name gesucht werden!
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g;
        if (((_a = props === null || props === void 0 ? void 0 : props.id) === null || _a === void 0 ? void 0 : _a.split("-")[0]) === "individual") {
            setName(props.id.split("-")[1]);
        }
        else {
            var apoName = (_b = areBusinessLocations.filter(function (loaction) { return loaction.id === props.id; })[0]) === null || _b === void 0 ? void 0 : _b.name;
            if (apoName) {
                setName(apoName);
            }
            else {
                //If central delivery check the coordinates
                //TODO: This is so wonky omg
                if (props === null || props === void 0 ? void 0 : props.centralDelivery) {
                    var apoName_1 = (_c = areBusinessLocations.filter(function (loaction) { var _a, _b, _c; return (loaction === null || loaction === void 0 ? void 0 : loaction.coords[0]) === ((_c = (_b = (_a = props === null || props === void 0 ? void 0 : props.type) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.address) === null || _c === void 0 ? void 0 : _c.lon); })[0]) === null || _c === void 0 ? void 0 : _c.name;
                    setName(apoName_1);
                    return;
                }
                var taskId_1 = (_e = (_d = props === null || props === void 0 ? void 0 : props.type) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.id;
                var taskOrigin_1 = (_f = areTasks.find(function (entry) { return (entry === null || entry === void 0 ? void 0 : entry.task_id) === taskId_1; })) === null || _f === void 0 ? void 0 : _f.task_origin;
                var apoName_2 = (_g = areBusinessLocations === null || areBusinessLocations === void 0 ? void 0 : areBusinessLocations.filter(function (loaction) { return loaction.id === taskOrigin_1; })[0]) === null || _g === void 0 ? void 0 : _g.name;
                if (apoName_2) {
                    setName(apoName_2);
                }
            }
        }
    }, [
        areBusinessLocations,
        areTasks,
        props === null || props === void 0 ? void 0 : props.centralDelivery,
        props.id,
        props.index,
        props === null || props === void 0 ? void 0 : props.type,
    ]);
    return _jsx(Paragraph, __assign({ className: "bodytext" }, { children: name !== null && name !== void 0 ? name : "Lädt..." }));
}
