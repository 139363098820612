export function getLabelFromStatus(status) {
    switch (status) {
        case "unassigned":
            return "Nicht zugeteilt";
        case "prepared":
            return "Vorbereitet";
        case "assigned":
            return "Zugeteilt";
        case "ontheway":
            return "Auf dem Weg";
        case "declined":
            return "Nicht angetroffen";
        case "successfull":
            return "Zugestellt";
        case "pickedup":
            return "Eingesammelt";
        default:
            return status;
    }
}
