/**
 * This function computes the duration between two time strings. It assumes that
 * the times do not signify a span longer than 24 hours and that start and end are
 * ordered correctly. Assumptions are made on that basis to ensure logical results.
 *
 * @param startTime {string} Start time of span in HH:MM format
 * @param endTime {string} End time of span in HH:MM format
 * @returns {number} Time in minutes in the timespan (0 <= return < 24 * 60)
 * */
import moment from "moment";
export default function computeTimespanDuration(startTime, endTime) {
    var startParts = startTime.split(":");
    var endParts = endTime.split(":");
    var start = moment().set({
        date: 1,
        month: 0,
        year: 1970,
        hours: parseInt(startParts[0]) + 1,
        minutes: parseInt(startParts[1]),
        seconds: 0,
        milliseconds: 0
    });
    var end = moment().set({
        date: 1,
        month: 0,
        year: 1970,
        hours: parseInt(endParts[0]) + 1,
        minutes: parseInt(endParts[1]),
        seconds: 0,
        milliseconds: 0
    });
    if (start.unix() > end.unix()) {
        end.set({ date: 2 });
    }
    var millisDiff = end.unix() - start.unix();
    var minsDiff = millisDiff / 60;
    return Math.round(minsDiff);
}
