var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var PackingPanel = function (props) {
    var _a, _b;
    return (_jsx("div", __assign({ style: __assign({ flex: 1, height: ((_a = props.borderless) !== null && _a !== void 0 ? _a : false) ? "calc(90% + (28px * 2))" : "90%", borderRadius: 10, padding: ((_b = props.borderless) !== null && _b !== void 0 ? _b : false) ? "0px" : "28px 32px", boxShadow: "0 0 44px 0 rgba(113,113,123,0.15)" }, props.style) }, { children: props.children })));
};
export default PackingPanel;
