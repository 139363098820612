var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Badge, Button, Dialog, Heading, Pane, Paragraph, StatusIndicator, StopIcon, toaster, Tooltip, WarningSignIcon, } from "evergreen-ui";
import "firebase/functions";
import { useContext, useEffect, useMemo, useState, } from "react";
import firebase from "../config";
// @ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import Skeleton from "react-loading-skeleton";
import { TaskContext } from "../contexts/TaskContext";
import TaskEditButton from "./TaskEditButton";
export var OptimizationErrorDialog = function (_a) {
    var _b;
    var isShown = _a.isShown, setIsShown = _a.setIsShown, optimization = _a.optimization, setReoptimizingLoading = _a.setReoptimizingLoading;
    var tasks = useContext(TaskContext).tasks;
    var areTasks = tasks[0];
    var _c = useState(false), confirmLoading = _c[0], setConfirmLoading = _c[1];
    var errorTasks = useMemo(function () {
        var _a;
        return (_a = optimization === null || optimization === void 0 ? void 0 : optimization.error) === null || _a === void 0 ? void 0 : _a.taskId.map(function (taskId) {
            return areTasks.find(function (allTask) { return allTask.task_id === taskId; });
        });
    }, [areTasks, optimization]);
    var restartOptimization = function (removeErroredTasks) {
        if (!optimization)
            return;
        setConfirmLoading(true);
        setReoptimizingLoading(true);
        var reoptimizeFailedOptimization = firebase
            .app()
            .functions("europe-west3")
            .httpsCallable("reoptimizeFailedOptimization");
        setIsShown(false);
        reoptimizeFailedOptimization({
            optimization: optimization,
            removeErroredTasks: removeErroredTasks,
        })
            .then(function (result) {
            setIsShown(false);
            setConfirmLoading(false);
        })
            .catch(function (err) {
            console.log(err);
            toaster.warning("Ein Fehler ist unterlaufen");
            setConfirmLoading(true);
        });
    };
    return (_jsx(Pane, { children: _jsxs(Dialog, __assign({ isShown: isShown, title: "Optimierungsfehler", onCloseComplete: function () { return setIsShown(false); }, confirmLabel: "Task entfernen und neu optimieren", cancelLabel: "Abbrechen", header: function () { return (_jsxs(Pane, __assign({ display: "flex", flexDirection: "column" }, { children: [_jsx(Heading, { children: "Optimierungsfehler" }), _jsx(Paragraph, __assign({ marginTop: 8, size: 300 }, { children: "Pr\u00FCfen Sie die Fehlermeldung und machen sie Anpassungen ... an dem fehlerhaften Auftrag. Sie haven auch die Option, ohne diesen Auftrag neu zu optimieren" }))] }))); }, footer: function () { return (_jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }, { children: [_jsx(Button, __assign({ intent: "none", onClick: function () { return setIsShown(false); } }, { children: "Abbrechen" })), _jsxs(Pane, __assign({ display: "flex", flexDirection: "row" }, { children: [_jsx(Button, __assign({ isLoading: confirmLoading, intent: "info", onClick: function () { return restartOptimization(true); } }, { children: "Ohne fehlerhafte Auftr\u00E4ge optimieren" })), _jsx(Button, __assign({ isLoading: confirmLoading, intent: "success", onClick: function () { return restartOptimization(false); } }, { children: "Neu optimieren" }))] }))] }))); }, hasFooter: false }, { children: [_jsx(Paragraph, __assign({ fontWeight: 500, size: 400 }, { children: "Fehlermeldung" })), _jsx(Paragraph, __assign({ size: 300 }, { children: (_b = optimization === null || optimization === void 0 ? void 0 : optimization.error) === null || _b === void 0 ? void 0 : _b.message })), _jsx(Paragraph, __assign({ marginTop: 8, fontWeight: 500, size: 400 }, { children: errorTasks && (errorTasks === null || errorTasks === void 0 ? void 0 : errorTasks.length) > 1
                        ? " Fehlerhafte Aufträge"
                        : " Fehlerhafter Auftrag" })), errorTasks === null || errorTasks === void 0 ? void 0 : errorTasks.map(function (task, index) {
                    return task && (_jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }, { children: [_jsx(StatusIndicator, __assign({ color: "danger" }, { children: _jsxs(Paragraph, { children: ["#" + index, ", ", task.customer_name, ", ", task.task_address] }) })), (task === null || task === void 0 ? void 0 : task.task_status) && _jsx(TaskEditButton, { task: task })] })));
                })] })) }));
};
export var OptimizationBar = function (_a) {
    var optimization = _a.optimization;
    var _b = useState(false), resetLoading = _b[0], setResetLoading = _b[1];
    var _c = useState(false), reoptimizingLoading = _c[0], setReoptimizingLoading = _c[1];
    var _d = useState(false), isShown = _d[0], setIsShown = _d[1];
    var _e = useState(false), longTime = _e[0], setLongTime = _e[1];
    var resetOptimization = function () { return __awaiter(void 0, void 0, void 0, function () {
        var resetOptimization;
        return __generator(this, function (_a) {
            setResetLoading(true);
            resetOptimization = firebase
                .app()
                .functions("europe-west3")
                .httpsCallable("resetOptimization");
            resetOptimization()
                .then(function () {
                setResetLoading(false);
            })
                .catch(function () {
                setResetLoading(false);
            });
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        var timer = setTimeout(function () {
            setLongTime(true);
        }, 12000);
        return function () {
            clearTimeout(timer);
        };
    }, []);
    return (_jsxs(_Fragment, { children: [(optimization === null || optimization === void 0 ? void 0 : optimization.error) && (_jsx(OptimizationErrorDialog, { isShown: isShown, setIsShown: setIsShown, setReoptimizingLoading: setReoptimizingLoading, optimization: optimization })), _jsx(motion.div, __assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, transition: { duration: 0.5 } }, { children: _jsxs(Pane, __assign({ height: 65, width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", position: "relative" }, { children: [!optimization.error && (_jsx(Pane, __assign({ style: { position: "absolute", top: 0, width: "100%" } }, { children: _jsx(Skeleton, { circle: false, borderRadius: 0, baseColor: "#ffff", highlightColor: "#49beef", style: { height: 10 } }) }))), _jsxs(Badge, __assign({ color: optimization.error ? "red" : "green", marginLeft: 8 }, { children: ["Optimierung ", optimization.error ? "gestoppt" : "läuft"] })), (optimization === null || optimization === void 0 ? void 0 : optimization.error) && (_jsx(Pane, { children: _jsx(Button, __assign({ disabled: resetLoading, isLoading: reoptimizingLoading, onClick: function () { return setIsShown(true); }, marginRight: 8, iconBefore: WarningSignIcon, intent: "warning" }, { children: "Fehlermeldung" })) })), ((optimization === null || optimization === void 0 ? void 0 : optimization.error) || longTime) && (_jsx(Pane, { children: _jsx(Tooltip, __assign({ content: "Optimierung h\u00E4ngengeblieben? Hiermit kannst du alle Auftr\u00E4ge in Optimierung zur\u00FCcksetzen!" }, { children: _jsx(Button, __assign({ disabled: reoptimizingLoading, isLoading: resetLoading, onClick: resetOptimization, marginRight: 8, iconBefore: StopIcon, intent: "danger" }, { children: "Zur\u00FCcksetzen" })) })) }))] })) }))] }));
};
