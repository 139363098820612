var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ResetIcon, SelectMenu } from "evergreen-ui";
import React, { useEffect } from "react";
import { useDrivers } from "../../hooks/useDrivers";
import Loading from "../../routes/Loading";
var DriverSelect = function (props) {
    var drivers = useDrivers();
    var _a = React.useState([]), selectedDriverIds = _a[0], _setSelectedDriverIds = _a[1];
    var _b = React.useState(""), displayText = _b[0], setDisplayText = _b[1];
    var reset = function () {
        setSelectedDriverIds([]);
    };
    var setSelectedDriverIds = function (newValue) {
        _setSelectedDriverIds(newValue);
        props.onChange(newValue);
    };
    var driverById = function (id) {
        if (!drivers)
            throw new Error("Tried to find driver without driver defs available");
        var driver = drivers.find(function (d) { return d.id === id; });
        if (!driver)
            throw new Error("Tried to find non-existent driver");
        return driver;
    };
    useEffect(function () {
        switch (selectedDriverIds.length) {
            case 0:
                setDisplayText("Alle Fahrer*innen");
                return;
            case 1:
                var d = driverById(selectedDriverIds[0]);
                setDisplayText("Nur Touren von ".concat(d.firstName, " ").concat(d.lastName));
                return;
            default:
                setDisplayText("Touren von ".concat(selectedDriverIds.length, " Fahrer*innen"));
        }
    }, [selectedDriverIds]);
    if (!drivers) {
        return _jsx(Loading, {});
    }
    return (_jsxs("div", __assign({ style: { display: "flex", flexDirection: "row", gap: "4px" } }, { children: [_jsx(SelectMenu, __assign({ isMultiSelect: props.isMultiSelect !== undefined ? props.isMultiSelect : true, title: "Fahrer*innen ausw\u00E4hlen", options: drivers.map(function (d) {
                    return {
                        label: "".concat(d.firstName, " ").concat(d.lastName),
                        value: d.id,
                    };
                }), selected: selectedDriverIds, onSelect: function (item) {
                    setSelectedDriverIds(__spreadArray(__spreadArray([], selectedDriverIds, true), [item.value.toString()], false));
                }, onDeselect: function (item) {
                    setSelectedDriverIds(selectedDriverIds.filter(function (did) { return item.value !== did; }));
                } }, { children: _jsx(Button, __assign({ style: { flex: 1 } }, { children: displayText })) })), _jsx(Button, __assign({ onClick: reset }, { children: _jsx(ResetIcon, { size: 12.5 }) }))] })));
};
export default DriverSelect;
