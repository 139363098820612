var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Heading, Pane, SegmentedControl, Select, Spinner, ThemeProvider, } from "evergreen-ui";
import moment from "moment";
import { useEffect, useState } from "react";
import StatsBarChart from "../components/Analytics/StatsBarChart";
import StatsPieChart from "../components/Analytics/StatsPieChart";
import DateRangePicker from "../components/Documentation/DateRangePicker";
import FeatureLabel from "../components/FeatureLabel";
import NavigatorBar from "../container/NavigatorBar";
import withPaywall from "../hocs/withPaywall";
import useAnalyticsFunction from "../hooks/useAnalyticsFunction";
import { useQueryState } from "../hooks/useQueryState";
import theme from "../theme";
import { driverDutyHistogramDistPreProc, driverDutyHistogramTimePreProc, } from "../utility/analytics/dataPreprocessors/driverDutyHistogram";
import taskCreationHistogramPreProc from "../utility/analytics/dataPreprocessors/taskCreationHistogram";
import taskDeliveryHistogramPreProc from "../utility/analytics/dataPreprocessors/taskDeliveryHistogram";
import taskSourceHistogramPreProc from "../utility/analytics/dataPreprocessors/taskSourceHistogram";
import tourCreatedHistogramPreProc from "../utility/analytics/dataPreprocessors/tourCreatedHistogram";
import ignoreTime from "../utility/ignoreTime";
import PaywallInfo from "./PaywallInfo";
var CATEGORIES = [
    "Auftragseingang",
    "Erfolgreiche Aufträge",
    "Geplante Touren",
    // "Optimierte Aufträge",
    "Fahrdistanz",
    "Fahrzeit",
    "Quellen",
];
var categoryToHistType = function (input) {
    switch (input) {
        case "Auftragseingang":
            return "taskCreationHistogram";
        case "Erfolgreiche Aufträge":
            return "taskDeliveryHistogram";
        case "Geplante Touren":
            return "tourCreatedHistogram";
        case "Optimierte Aufträge":
            return "tourCreatedHistogram"; // ToDo: Get correct histogram
        case "Fahrdistanz":
            return "driverDutyHistogram";
        case "Fahrzeit":
            return "driverDutyHistogram";
        case "Quellen":
            return "taskSourceHistogram";
        default:
            // ToDo: This is a fairly dirty workaround. Replace with auto state recovery via query data validation
            return "taskCreationHistogram";
    }
};
var today = ignoreTime(new Date());
var oneWeekAgo = ignoreTime(moment().subtract(7, "days").toDate());
var Analytics = function () {
    var _a, _b, _c, _d, _e;
    var _f = useQueryState({
        category: "Auftragseingang",
        view: "bar",
        start: undefined,
        end: undefined,
    }), queryData = _f[0], setQueryData = _f[1];
    var analyticsData = useAnalyticsFunction((_a = queryData === null || queryData === void 0 ? void 0 : queryData.start) !== null && _a !== void 0 ? _a : oneWeekAgo.toISOString(), (_b = queryData === null || queryData === void 0 ? void 0 : queryData.end) !== null && _b !== void 0 ? _b : today.toISOString(), categoryToHistType(queryData === null || queryData === void 0 ? void 0 : queryData.category), queryData !== null, queryData === null || queryData === void 0 ? void 0 : queryData.category);
    var _g = useState({
        bar: { bars: [], data: [] },
        pie: { data: [] },
    }), dataShown = _g[0], setDataShown = _g[1];
    var _h = useState(true), processing = _h[0], setProcessing = _h[1];
    useEffect(function () {
        if (!queryData || !analyticsData)
            return;
        switch (queryData.category) {
            case "Auftragseingang":
                setDataShown(taskCreationHistogramPreProc(analyticsData));
                break;
            case "Erfolgreiche Aufträge":
                setDataShown(taskDeliveryHistogramPreProc(analyticsData));
                break;
            case "Geplante Touren":
                setDataShown(tourCreatedHistogramPreProc(analyticsData));
                break;
            case "Fahrdistanz":
                setDataShown(driverDutyHistogramDistPreProc(analyticsData));
                break;
            case "Fahrzeit":
                setDataShown(driverDutyHistogramTimePreProc(analyticsData));
                break;
            case "Quellen":
                setDataShown(taskSourceHistogramPreProc(analyticsData));
                break;
        }
        setProcessing(false);
        // There is no dep missing here. analyticsData should follow a queryData update, if relevant.
    }, [analyticsData]);
    return (_jsxs(ThemeProvider
    /* @ts-ignore */
    , __assign({ 
        /* @ts-ignore */
        value: theme }, { children: [_jsx(NavigatorBar, {}), _jsx(Pane, __assign({ elevation: 2, style: {
                    padding: 25,
                    margin: 25,
                    width: "90vw",
                    overflow: "hidden",
                    height: "100%",
                } }, { children: _jsx(Pane, __assign({ style: {
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        height: "90%",
                    } }, { children: _jsxs(Pane, __assign({ style: {
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            overflowX: "auto",
                            height: "100%",
                        } }, { children: [_jsxs(Pane, __assign({ style: {
                                    width: "100%",
                                    marginBottom: 10,
                                    display: "flex",
                                    flexDirection: "row",
                                } }, { children: [_jsxs("div", __assign({ style: { flex: 1 } }, { children: [_jsx(Heading, __assign({ size: 900, style: { marginBottom: 3 } }, { children: "Analytics" })), _jsx(Heading, __assign({ size: 700, style: { color: "#000000", marginBottom: 18 } }, { children: "Erhalte detaillierte Einblicke in deinen Botendienst" }))] })), _jsx(FeatureLabel, { type: "Plus- & Pro-Feature", content: "Die Statistiken erm\u00F6glichen dir den besten \u00DCberblick \u00FCber deine Auslieferungen, gefahrene Touren und vielem mehr!" })] })), _jsxs("div", __assign({ style: {
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: 6,
                                    gap: 24,
                                } }, { children: [_jsxs("div", __assign({ style: { flex: 1 } }, { children: [_jsx(Heading, __assign({ size: 600, style: { color: "#474D66", marginBottom: 6 } }, { children: "Kategorie" })), _jsx(Select, __assign({ width: "100%", value: (_c = queryData === null || queryData === void 0 ? void 0 : queryData.category) !== null && _c !== void 0 ? _c : CATEGORIES[0], onChange: function (evt) {
                                                    setProcessing(true);
                                                    setQueryData({ category: evt.target.value });
                                                } }, { children: CATEGORIES.map(function (cat) { return (_jsx("option", __assign({ value: cat }, { children: cat }))); }) }))] })), _jsxs("div", __assign({ style: { flex: 1 } }, { children: [_jsx(Heading, __assign({ size: 600, style: { color: "#474D66", marginBottom: 6 } }, { children: "Zeitraum" })), _jsx(DateRangePicker, { initialState: {
                                                    start: (queryData === null || queryData === void 0 ? void 0 : queryData.start)
                                                        ? new Date(queryData.start)
                                                        : oneWeekAgo,
                                                    end: (queryData === null || queryData === void 0 ? void 0 : queryData.end) ? new Date(queryData.end) : today,
                                                }, onChange: function (startDate, endDate) {
                                                    var _a, _b;
                                                    setQueryData({
                                                        start: (_a = startDate === null || startDate === void 0 ? void 0 : startDate.toISOString()) !== null && _a !== void 0 ? _a : undefined,
                                                        end: (_b = endDate === null || endDate === void 0 ? void 0 : endDate.toISOString()) !== null && _b !== void 0 ? _b : undefined,
                                                    });
                                                    setProcessing(true);
                                                } })] })), _jsx("div", { style: { flex: 2 } }), _jsx("div", { children: _jsx(SegmentedControl, { width: 240, size: "small", options: [
                                                { label: "Balkendiagramm", value: "bar" },
                                                { label: "Kreisdiagramm", value: "pie" },
                                            ], value: (_d = queryData === null || queryData === void 0 ? void 0 : queryData.view) !== null && _d !== void 0 ? _d : "bar", 
                                            // @ts-ignore
                                            onChange: function (value) { return setQueryData({ view: value }); } }) })] })), _jsxs(Pane, __assign({ elevation: 2, style: {
                                    position: "relative",
                                    width: "100%",
                                    marginTop: 24,
                                    height: "100%",
                                    overflow: "hidden",
                                    flex: 1,
                                    maxHeight: 700,
                                } }, { children: [processing && (_jsx("div", __assign({ style: {
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: 480,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#ffffff",
                                            zIndex: 5000,
                                            opacity: 0.7,
                                        } }, { children: _jsx(Spinner, {}) }))), ((_e = queryData === null || queryData === void 0 ? void 0 : queryData.view) !== null && _e !== void 0 ? _e : "bar") === "bar" ? (_jsx(StatsBarChart, { data: dataShown })) : (_jsx(StatsPieChart, { data: dataShown }))] }))] })) })) }))] })));
};
export default withPaywall(Analytics, "apomap-plus", PaywallInfo("Analytics", "Erhalte detaillierte Einblicke in deinen Botendienst", "apomap Plus", false, "", true));
