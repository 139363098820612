var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Pane, Paragraph, Popover, Position } from "evergreen-ui";
import { useEffect, useState } from "react";
import Driver from "../assets/images/islandicons/xiao_car.png";
import Delivery from "../assets/images/markers/delivery.svg";
import Location from "../assets/images/markers/location.svg";
import Pickup from "../assets/images/markers/pickup.svg";
import { getLabelFromType } from "../utility/getLabelFromType";
import StatusBadge from "./StatusBadge";
export default function Pin(_a) {
    var activity = _a.activity, tasks = _a.tasks, task = _a.task, driver = _a.driver, location = _a.location;
    var _b = useState(task), info = _b[0], setInfo = _b[1];
    var highlightTask = function () {
        console.log(task === null || task === void 0 ? void 0 : task.task_id);
    };
    useEffect(function () {
        if (activity.id) {
            setInfo(tasks[activity.id]);
        }
    }, [activity.id, tasks]);
    var getMarker = function (type) {
        var el;
        switch (type) {
            case "location":
                el = Location;
                break;
            case "pickupShipment":
                el = Pickup;
                break;
            case "deliverShipment":
                el = Delivery;
                break;
            case "end":
                el = null;
                break;
            case "start":
                el = null;
                break;
            case "driver":
                el = Driver;
                break;
            default:
                el = Delivery;
                break;
        }
        return el;
    };
    //Collective Tasks - Merged task dont show them on map
    if (task === null || task === void 0 ? void 0 : task.isMerged)
        return null;
    return (_jsx(Popover, __assign({ position: Position.BOTTOM_LEFT, content: _jsxs(Pane, __assign({ padding: 10 }, { children: [location && _jsx(Paragraph, { children: location.name }), driver && (_jsx(Paragraph, { children: driver.firstName + " " + driver.lastName })), info && (_jsxs(_Fragment, { children: [_jsxs(Pane, __assign({ style: {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                            } }, { children: [_jsx(Paragraph, __assign({ fontFamily: "bold", marginRight: 20 }, { children: info === null || info === void 0 ? void 0 : info.customer_name })), _jsx(StatusBadge, { driver: true, status: info === null || info === void 0 ? void 0 : info.task_status })] })), _jsx("div", { style: {
                                width: "100%",
                                height: 1,
                                marginTop: 2,
                                marginBottom: 2,
                                backgroundColor: "grey",
                            } }), _jsx(Pane, __assign({ style: {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-end",
                                justifyContent: "space-between",
                            } }, { children: _jsxs(Pane, __assign({ style: { maxWidth: 250 } }, { children: [_jsx(Pane, __assign({ style: { display: "flex", flexDirection: "row" } }, { children: _jsxs(Paragraph, { children: ["Adresse: ", info === null || info === void 0 ? void 0 : info.task_address] }) })), _jsxs(Paragraph, { children: ["Art: ", getLabelFromType(info === null || info === void 0 ? void 0 : info.task_type)] }), (info === null || info === void 0 ? void 0 : info.task_note) && (_jsxs(Paragraph, { children: ["Hinweis: ", info === null || info === void 0 ? void 0 : info.task_note] }))] })) }))] }))] })) }, { children: _jsxs("div", __assign({ onClick: function () { return highlightTask(); }, style: { cursor: "pointer" } }, { children: [_jsx("figcaption", __assign({ style: { marginBottom: 20 } }, { children: (activity === null || activity === void 0 ? void 0 : activity.type) === "start" ? "Start" : "" })), _jsx("img", { style: { width: (activity === null || activity === void 0 ? void 0 : activity.type) === "driver" ? 35 : 25 }, src: getMarker(activity === null || activity === void 0 ? void 0 : activity.type) })] })) })));
}
