var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
//This is the main workingspace of the app where you can create tasks
//and tours and overview the process
import { Heading, Pane } from "evergreen-ui";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/functions";
import moment from "moment";
import { useContext, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import CountOverviewCard from "../components/Dashboard/CountOverviewCard";
import DateTimeIndicator from "../components/Dashboard/DateTimeIndicator";
import MapFrame from "../components/Dashboard/MapFrame";
import NewsAndHintsCard from "../components/Dashboard/NewsAndHintsCard";
import PlanOverviewCard from "../components/Dashboard/PlanOverviewCard";
import StatsOverviewCard from "../components/Dashboard/StatsOverviewCard";
import ToursOverviewCard from "../components/Dashboard/ToursOverviewCard";
import firebase from "../config";
import NavigatorBar from "../container/NavigatorBar";
import { UserContext } from "../contexts/UserContext";
import { VerificationWindow } from "./Verification";
import { WelcomeVideo } from "./WelcomeVideo";
export default function Dashbaord() {
    var _a, _b;
    var user = useContext(UserContext).user;
    var isUser = user[0];
    var _c = useState(false), showWelcomeVideo = _c[0], setShowWelcomeVideo = _c[1];
    var history = useHistory();
    var showVerification = useMemo(function () {
        var _a;
        return !firebase.auth().currentUser.emailVerified &&
            moment((_a = isUser === null || isUser === void 0 ? void 0 : isUser.created) === null || _a === void 0 ? void 0 : _a.milliseconds).isAfter(moment("27.08.2022", "DD.MM.YYYY"));
    }, [(_a = isUser === null || isUser === void 0 ? void 0 : isUser.created) === null || _a === void 0 ? void 0 : _a.milliseconds]);
    return (_jsxs(_Fragment, { children: [_jsx(NavigatorBar, {}), showVerification && (_jsx(Pane, __assign({ position: "absolute", width: "100%", height: "100%", zIndex: 200, display: "flex", justifyContent: "center", alignItems: "center", style: {
                    backgroundImage: "linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0))",
                    backdropFilter: "blur(10px)",
                } }, { children: _jsx(VerificationWindow, { setShowWelcomeVideo: setShowWelcomeVideo }) }))), showWelcomeVideo && (_jsx(Pane, __assign({ position: "absolute", width: "100%", height: "100%", zIndex: 200, display: "flex", justifyContent: "center", alignItems: "center", style: {
                    backgroundImage: "linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0))",
                    backdropFilter: "blur(10px)",
                } }, { children: _jsx(WelcomeVideo, { setShowWelcomeVideo: setShowWelcomeVideo }) }))), _jsx("div", __assign({ style: { width: "100%", height: "100vh", overflowY: "auto" } }, { children: _jsxs(Pane, __assign({ style: {
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        minHeight: 900,
                        height: "100vh",
                        padding: 20,
                        flexDirection: "column",
                        background: "transparent linear-gradient(270deg, #FFFFFF 0%, #F2F5FA 52%, #E4EBF5 100%) 90% 0% no-repeat padding-box",
                    } }, { children: [_jsxs(Pane, __assign({ style: {
                                width: "100%",
                                height: 80,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: 12,
                            } }, { children: [_jsx(Heading, __assign({ onClick: function () {
                                        return firebase
                                            .firestore()
                                            .collection("users")
                                            .where("stripeId", "==", "cus_L57rvKF5QOFVyh")
                                            .get()
                                            .then(function (snap) {
                                            var found = [];
                                            snap.forEach(function (doc) {
                                                var data = doc.data();
                                                found.push(data);
                                            });
                                            console.log(found);
                                        })
                                            .catch(function (err) {
                                            console.log(err);
                                        });
                                    }, style: { fontFamily: "bold", color: "#2D3B51" } }, { children: "Guten Tag vom apomap Team \uD83D\uDC4B\uD83C\uDFFD" })), _jsxs(Pane, __assign({ style: {
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-around",
                                        alignItems: "center",
                                        gap: "10px",
                                    } }, { children: [_jsx(Heading, __assign({ style: { fontFamily: "bold", color: "#2D3B51" } }, { children: (_b = isUser === null || isUser === void 0 ? void 0 : isUser.businessName) !== null && _b !== void 0 ? _b : "Geschäftsname" })), _jsx(DateTimeIndicator, {})] }))] })), _jsxs(Pane, __assign({ style: {
                                width: "100%",
                                height: "90%",
                                display: "flex",
                                flexDirection: "column",
                                gap: 36,
                            } }, { children: [_jsx(Pane, __assign({ style: {
                                        display: "flex",
                                        flexDirection: "row",
                                        flex: 2,
                                        gap: 36,
                                        maxHeight: 270,
                                    } }, { children: _jsxs(_Fragment, { children: [_jsx(CountOverviewCard, {}), _jsx(StatsOverviewCard, {}), _jsx(NewsAndHintsCard, {})] }) })), _jsxs(Pane, __assign({ style: {
                                        display: "flex",
                                        flexDirection: "row",
                                        flex: 4,
                                        gap: 36,
                                    } }, { children: [_jsx(Pane, __assign({ style: {
                                                flex: 2,
                                            } }, { children: _jsx(MapFrame, {}) })), _jsxs(Pane, __assign({ style: {
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 1,
                                                gap: 36,
                                            } }, { children: [_jsx(ToursOverviewCard, {}), _jsx(PlanOverviewCard, {})] }))] }))] }))] })) }))] }));
}
