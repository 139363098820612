var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { Heading, Pane, Paragraph, Button, TextInputField, toaster, } from "evergreen-ui";
import firebase from "../config";
import "firebase/auth";
import "firebase/firestore";
import LogoBig from "../components/LogoBig";
import BlueBar from "../components/BlueBar";
export default function ForgotPassword() {
    var _a = useState(), email = _a[0], setEmail = _a[1];
    var resetPassword = function () {
        if (!email) {
            toaster.notify("Bitte geben Sie Ihre E-Mail Adresse an.");
            return;
        }
        firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(function () { return toaster.notify("Wurde versendet. Prüfen Sie ihr Postfach"); })
            .catch(function (err) { return toaster.warning("Ein Fehler ist unterlaufen"); });
    };
    return (_jsxs(Pane, __assign({ style: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            background: "linear-gradient(-3deg, rgba(73,190,239,1) 0%, rgba(73,190,239,1) 30%, #E4EBF5 30%, rgba(255,255,255,1) 100%)",
        } }, { children: [_jsx(LogoBig, {}), _jsxs(Pane, __assign({ elevation: 2, style: {
                    width: "25vw",
                    minWidth: 400,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "white",
                    justifyContent: "flex-start",
                    margin: 20,
                } }, { children: [_jsx(BlueBar, {}), _jsxs(Pane, __assign({ padding: 30, style: { width: "100%" } }, { children: [_jsx(Heading, __assign({ marginBottom: 10, className: "headline", style: { color: "#49beef" } }, { children: "Passwort zur\u00FCcksetzen" })), _jsx(Pane, __assign({ style: {
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                } }, { children: _jsx(Paragraph, __assign({ marginRight: 10, className: "bodytext" }, { children: "Bitte geben sie die E-Mail Adresse ihres Accounts ein. Wir senden Ihnen eine E-Mail in der sie ihr Passwort neu vergeben k\u00F6nnen." })) })), _jsx(TextInputField, { label: _jsx(Paragraph, __assign({ className: "bodytext" }, { children: "E-Mail Adresse*" })), spellCheck: false, value: email, onChange: function (e) { return setEmail(e.target.value); } }), _jsx(Button, __assign({ size: "large", marginBottom: 20, style: {
                                    fontFamily: "bold",
                                    borderRadius: 50,
                                    backgroundColor: "#49beef",
                                    color: "white",
                                    width: "100%",
                                    height: 50,
                                }, onClick: function () { return resetPassword(); } }, { children: "SENDEN" }))] }))] }))] })));
}
