var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from "react";
import { Pane, Popover, Menu, Heading, CrossIcon, Button, Dialog, TextInputField, SelectMenu, Position, FilePicker, Avatar, toaster, Paragraph, Tooltip, HelpIcon, TextInput, } from "evergreen-ui";
import { GithubPicker, TwitterPicker } from "react-color";
import { createId } from "../utility/utility";
import firebase from "../config";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import BlueBar from "./BlueBar";
import { DriverContext } from "../contexts/DriverContext";
import moment from "moment";
var storageRef = firebase.storage().ref();
export default function DriverDialog(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var getDrivers = useContext(DriverContext).getDrivers;
    var _p = useState(false), isLoading = _p[0], setLoading = _p[1];
    var _q = useState(((_a = props.driver) === null || _a === void 0 ? void 0 : _a.firstName) ? (_b = props.driver) === null || _b === void 0 ? void 0 : _b.firstName : null), isFirstName = _q[0], setFirstName = _q[1];
    var _r = useState(((_c = props.driver) === null || _c === void 0 ? void 0 : _c.lastName) ? (_d = props.driver) === null || _d === void 0 ? void 0 : _d.lastName : null), isLastName = _r[0], setLastName = _r[1];
    var _s = useState(((_e = props.driver) === null || _e === void 0 ? void 0 : _e.pin) ? (_f = props.driver) === null || _f === void 0 ? void 0 : _f.pin : null), isPIN = _s[0], setPIN = _s[1];
    var _t = useState(((_g = props.driver) === null || _g === void 0 ? void 0 : _g.vehicles) ? props.driver.vehicles : []), selectedItemsState = _t[0], setSelectedItems = _t[1];
    var _u = useState(((_h = props.driver) === null || _h === void 0 ? void 0 : _h.vehicles)
        ? "".concat(props.driver.vehicles.length, " Fahrzeuge gew\u00E4hlt")
        : "Fahrzeuge wählen"), selectedItemNamesState = _u[0], setSelectedItemNames = _u[1];
    var _v = useState(((_j = props.driver) === null || _j === void 0 ? void 0 : _j.image) ? (_k = props.driver) === null || _k === void 0 ? void 0 : _k.image : null), isImageUrl = _v[0], setImageUrl = _v[1];
    var _w = useState(null), isImageFile = _w[0], setImageFile = _w[1];
    var _x = useState(false), isPickerShown = _x[0], setPickerShown = _x[1];
    var _y = useState(((_l = props.driver) === null || _l === void 0 ? void 0 : _l.color) ? (_m = props.driver) === null || _m === void 0 ? void 0 : _m.color : null), isSelectedColor = _y[0], setSelectedColor = _y[1];
    var options = useState([
        { label: "Auto", value: "car" },
        { label: "Fußgänger", value: "foot" },
        { label: "Fahrrad", value: "bike" },
        { label: "Roller", value: "scooter" },
    ])[0];
    //LocationPicker
    var _z = useState([]), areLocations = _z[0], setLocations = _z[1];
    var _0 = useState(((_o = props.driver) === null || _o === void 0 ? void 0 : _o.address)
        ? { label: props.driver.address, value: props.driver.coords }
        : null), isSelected = _0[0], setSelected = _0[1];
    var _1 = useState([]), isSelection = _1[0], setSelection = _1[1];
    function addItemToSelection(item) {
        setSelected(item);
        setSelection(function (state) {
            var newState = __spreadArray([], state, true);
            newState.push(item);
            return newState;
        });
    }
    function uploadFileAndGetUrl(file, id) {
        return new Promise(function (resolve, reject) {
            storageRef
                .child("driver_image_".concat(id))
                .put(file)
                .then(function (snapshot) {
                snapshot.ref.getDownloadURL().then(function (url) {
                    resolve(url);
                });
            })
                .catch(function (err) { return reject(err); });
        });
    }
    function addDriver() {
        return __awaiter(this, void 0, void 0, function () {
            var isImageUrlInCloud, id, driverObj;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!isFirstName ||
                            !isLastName ||
                            !isPIN ||
                            !isSelectedColor ||
                            selectedItemsState.length === 0) {
                            toaster.notify("Gebe bitte alle erforderlichen Daten an!");
                            return [2 /*return*/];
                        }
                        if (isPIN.length < 4) {
                            toaster.notify("Der PIN muss mindestens 4 Zeichen lang sein!");
                            return [2 /*return*/];
                        }
                        isImageUrlInCloud = null;
                        id = createId();
                        if (!isImageUrl) return [3 /*break*/, 2];
                        return [4 /*yield*/, uploadFileAndGetUrl(isImageFile, id)];
                    case 1:
                        isImageUrlInCloud = _a.sent();
                        _a.label = 2;
                    case 2:
                        driverObj = {
                            employer: firebase.auth().currentUser.uid,
                            image: isImageUrlInCloud,
                            id: id,
                            firstName: isFirstName,
                            lastName: isLastName,
                            pin: isPIN,
                            address: null,
                            coords: null,
                            color: isSelectedColor,
                            vehicles: selectedItemsState,
                            isOnline: false,
                        };
                        return [4 /*yield*/, firebase.firestore().collection("drivers").doc(id).set(driverObj)];
                    case 3:
                        _a.sent();
                        getDrivers();
                        reset();
                        return [2 /*return*/];
                }
            });
        });
    }
    function processImage(files) {
        if (files[0].type === "image/jpeg" || files[0].type === "image/png") {
            setImageFile(files[0]);
            setImageUrl(URL.createObjectURL(files[0]));
        }
        else {
            toaster.warning("Bitte laden Sie eine JPEG/PNG-Datei hoch!");
        }
    }
    function saveChanges() {
        return __awaiter(this, void 0, void 0, function () {
            var isImageUrlInCloud;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (isPIN.length < 4) {
                            toaster.notify("Der PIN muss mindestens 4 Zeichen lang sein!");
                            return [2 /*return*/];
                        }
                        setLoading(true);
                        isImageUrlInCloud = null;
                        if (!(isImageFile !== null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, uploadFileAndGetUrl(isImageFile, props.driver.id)];
                    case 1:
                        isImageUrlInCloud = _a.sent();
                        _a.label = 2;
                    case 2:
                        firebase
                            .firestore()
                            .collection("drivers")
                            .doc(props.driver.id)
                            .update({
                            image: isImageUrlInCloud
                                ? isImageUrlInCloud
                                : props.driver.image
                                    ? props.driver.image
                                    : null,
                            firstName: isFirstName,
                            lastName: isLastName,
                            address: null,
                            coords: null,
                            color: isSelectedColor,
                            vehicles: selectedItemsState,
                            pin: isPIN,
                        })
                            .then(function () {
                            setLoading(false);
                            getDrivers();
                            reset();
                            toaster.notify("Änderungen erfolgreich gespeichert");
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function deleteDriver() {
        setLoading(true);
        firebase
            .firestore()
            .collection("tours")
            .where("driver", "==", props.driver.id)
            .get()
            .then(function (snap) {
            var toursOfDriver = [];
            snap.forEach(function (doc) {
                var data = doc.data();
                toursOfDriver.push(data);
            });
            if (toursOfDriver.length > 0) {
                setLoading(false);
                toaster.notify("Diesem Fahrer war bereits eine Tour gegewießen. Daher kann dieser nicht mehr gelöscht werden.");
                return;
            }
            else {
                firebase
                    .firestore()
                    .collection("drivers")
                    .doc(props.driver.id)
                    .delete();
                setLoading(false);
                setTimeout(function () {
                    getDrivers();
                    reset();
                    toaster.success("Der Fahrer wurde erfolgreich gelöscht");
                }, 400);
            }
        });
    }
    var reset = function () {
        setFirstName(null);
        setLastName(null);
        setPIN(null);
        setSelectedItems([]);
        setSelectedItemNames("Fahrzeug wählen");
        setImageUrl(null);
        setImageFile(null);
        setSelectedColor(null);
        setSelected(null);
        props.setShown(false);
    };
    var _2 = useState(moment().unix()), pickerKey = _2[0], setPickerKey = _2[1];
    return (_jsx(_Fragment, { children: _jsxs(Dialog, __assign({ isShown: props.isShown, hasFooter: false, hasHeader: false, style: { borderRadius: 15 }, contentContainerProps: { padding: 0 }, shouldCloseOnOverlayClick: false, shouldCloseOnEscapePress: false }, { children: [_jsxs(Pane, __assign({ style: {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: 20,
                    } }, { children: [_jsx(Heading, __assign({ className: "headline", style: { color: "#2D3B51", fontSize: 24 } }, { children: props.driver ? "Fahrer*in bearbeiten" : "Fahrer*in hinzufügen" })), _jsx(CrossIcon, { style: { cursor: "pointer" }, onClick: reset })] })), _jsxs(Pane, __assign({ style: { padding: 20 } }, { children: [_jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "flex-end" }, { children: [_jsx(Avatar, { name: isFirstName + " " + isLastName, src: isImageUrl, size: 80 }), _jsx(FilePicker, { marginLeft: 16, multiple: false, width: 250, onChange: function (files) { return processImage(files); }, placeholder: "Profilbild hochladen" })] })), _jsxs("div", __assign({ style: {
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: 15,
                                gap: 15,
                            } }, { children: [_jsx(TextInputField, { label: "Vorname", required: true, onChange: function (e) { return setFirstName(e.target.value); }, value: isFirstName, width: "100%" }), _jsx(TextInputField, { label: "Nachname", required: true, onChange: function (e) { return setLastName(e.target.value); }, value: isLastName, width: "100%" })] })), _jsx("div", __assign({ style: {
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                            } }, { children: _jsx(Pane, { children: props.driver && isSelected === null && (_jsx(Paragraph, { children: props.driver.address })) }) })), _jsxs("div", __assign({ style: {
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                            } }, { children: [_jsxs(Pane, { children: [_jsx(Tooltip, __assign({ content: "Sie k\u00F6nnen mehr als einen Fahrzeugtyp ausw\u00E4hlen" }, { children: _jsxs(Pane, __assign({ justifyContent: "space-between", alignItems: "center", display: "flex", flexDirection: "row", width: "160px" }, { children: [_jsx(Paragraph, __assign({ marginBottom: 5 }, { children: "Fahrzeuge ausw\u00E4hlen*" })), _jsx(HelpIcon, { size: 10, marginBottom: 5 })] })) })), _jsx(SelectMenu, __assign({ isMultiSelect: true, title: "Fahrzeuge ausw\u00E4hlen", options: options, selected: selectedItemsState, onSelect: function (item) {
                                                console.log(item);
                                                var selected = __spreadArray(__spreadArray([], selectedItemsState, true), [item.value], false);
                                                var selectedItems = selected;
                                                var selectedItemsLength = selectedItems.length;
                                                var selectedNames = "";
                                                if (selectedItemsLength === 0) {
                                                    selectedNames = "";
                                                }
                                                else {
                                                    selectedNames =
                                                        selectedItemsLength.toString() +
                                                            " Fahrzeug(e) gewählt...";
                                                }
                                                setSelectedItems(selectedItems);
                                                setSelectedItemNames(selectedNames);
                                            }, onDeselect: function (item) {
                                                var deselectedItemIndex = selectedItemsState.indexOf(item.value);
                                                var selectedItems = selectedItemsState.filter(function (_item, i) { return i !== deselectedItemIndex; });
                                                var selectedItemsLength = selectedItems.length;
                                                var selectedNames = "";
                                                if (selectedItemsLength === 0) {
                                                    selectedNames = "";
                                                }
                                                else {
                                                    selectedNames =
                                                        selectedItemsLength.toString() +
                                                            " Fahrzeug(e) gewählt...";
                                                }
                                                setSelectedItems(selectedItems);
                                                setSelectedItemNames(selectedNames);
                                            } }, { children: _jsx(Button, { children: selectedItemNamesState || "Fahrzeuge wählen..." }) }))] }), _jsxs(Pane, { children: [_jsx(Tooltip, __assign({ content: "Mit dem PIN kann sich dein Fahrer in die apomap App einloggen" }, { children: _jsxs(Pane, __assign({ justifyContent: "space-between", alignItems: "center", display: "flex", flexDirection: "row" }, { children: [_jsx(Paragraph, { children: "PIN vergeben*" }), _jsx(HelpIcon, { size: 10 })] })) })), _jsx(TextInputField, { onChange: function (e) {
                                                if (e.target.value.length > 4) {
                                                    toaster.notify("Der PIN darf maximial 4 Zeichen lang sein");
                                                    return;
                                                }
                                                if (e.target.value
                                                    .split("")
                                                    .find(function (el) {
                                                    return ![
                                                        "1",
                                                        "2",
                                                        "3",
                                                        "4",
                                                        "5",
                                                        "6",
                                                        "7",
                                                        "8",
                                                        "9",
                                                        "0",
                                                    ].includes(el);
                                                })) {
                                                    toaster.notify("Der PIN darf nur aus Zahlen bestehen!");
                                                    return;
                                                }
                                                setPIN(e.target.value);
                                            }, value: isPIN, marginBottom: 0 })] }), _jsxs("div", { children: [_jsx(Paragraph, __assign({ marginBottom: 5 }, { children: "Farbe ausw\u00E4hlen*" })), _jsx(Button, __assign({ style: { backgroundColor: isSelectedColor }, onClick: function () { return setPickerShown(!isPickerShown); } }, { children: "Routenfarbe w\u00E4hlen" })), isPickerShown && (_jsx(Pane, __assign({ position: "absolute", zIndex: 200 }, { children: _jsx(TwitterPicker, { onChange: function (color) {
                                                    setSelectedColor(color.hex);
                                                    setPickerShown(false);
                                                } }) })))] })] }))] })), _jsx(Pane, __assign({ style: {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        padding: 20,
                    } }, { children: props.driver ? (_jsxs(Pane, { children: [_jsx(Button, __assign({ isLoading: isLoading, intent: "danger", onClick: function () { return deleteDriver(); } }, { children: "L\u00F6schen" })), _jsx(Button, __assign({ isLoading: isLoading, intent: "success", marginLeft: 20, onClick: function () { return saveChanges(); } }, { children: "Speichern" }))] })) : (_jsx(_Fragment, { children: _jsx(Button, __assign({ isLoading: isLoading, appearance: "primary", onClick: function () { return addDriver(); } }, { children: "Erstellen" })) })) }))] })) }));
}
