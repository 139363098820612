var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Label } from 'evergreen-ui';
import React from 'react';
var ApoFieldLabel = function (_a) {
    var value = _a.value, required = _a.required;
    return (_jsxs(Label, __assign({ fontSize: '15px', fontWeight: 400, marginBottom: 8, display: 'block' }, { children: [value, required ? _jsx("span", __assign({ style: { color: '#49beef' } }, { children: " *" })) : ''] })));
};
export default ApoFieldLabel;
