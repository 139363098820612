var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import felix from "../assets/images/payment-felix.svg";
import "../theme/styles.css";
import { Button, CrossIcon, Dialog, Heading, Pane, Paragraph, TextInput, toaster, } from "evergreen-ui";
import BlueBar from "../components/BlueBar";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import firebase from "../config";
import { IbanElement, useElements, useStripe } from "@stripe/react-stripe-js";
//New theme
import { ThemeContext } from "evergreen-ui";
import { LocationContext } from "../contexts/LocationContext";
import { PaymentContext } from "../contexts/PaymentContext";
import { PlanContext } from "../contexts/PlanContext";
import { UserContext } from "../contexts/UserContext";
import theme from "../theme";
var ThemeProvider = ThemeContext.Provider;
export default function LocationManagementDialog(props) {
    var stripe = useStripe();
    var elements = useElements();
    var locationManagementState = useContext(PaymentContext).locationManagementState;
    var locationManagementShown = locationManagementState[0], setLocationManagementShown = locationManagementState[1];
    var _a = useContext(UserContext), user = _a.user, ready = _a.ready;
    var _b = useState(false), isLoading = _b[0], setLoading = _b[1];
    var isUser = user[0];
    var _c = useState(1), locationAmount = _c[0], setLocationAmount = _c[1];
    var plan = useContext(PlanContext).plan;
    var isPlanData = plan[0];
    var locations = useContext(LocationContext).locations;
    var areBusinessLocations = locations[0], setBusinessLocations = locations[1];
    var _d = useState(0), activeBusinessLocations = _d[0], setActiveBusinessLocations = _d[1];
    var _e = useState(false), isEditMode = _e[0], setEditMode = _e[1];
    var _f = useState((isUser === null || isUser === void 0 ? void 0 : isUser.firstName) + " " + (isUser === null || isUser === void 0 ? void 0 : isUser.lastName)), isUserName = _f[0], setUserName = _f[1];
    var _g = useState(isUser === null || isUser === void 0 ? void 0 : isUser.email), isUserEmail = _g[0], setUserEmail = _g[1];
    var _h = useState(isUser === null || isUser === void 0 ? void 0 : isUser.address), isUserAddress = _h[0], setisUserAddress = _h[1];
    var handleCloseClick = function () {
        setLocationAmount(1);
        setLocationManagementShown(false);
    };
    useEffect(function () {
        setActiveBusinessLocations(areBusinessLocations.filter(function (_a) {
            var archived = _a.archived;
            return !archived;
        }).length);
    }, [areBusinessLocations]);
    useEffect(function () {
        setUserName((isUser === null || isUser === void 0 ? void 0 : isUser.firstName) + " " + (isUser === null || isUser === void 0 ? void 0 : isUser.lastName));
        setUserEmail(isUser === null || isUser === void 0 ? void 0 : isUser.email);
        setisUserAddress(isUser === null || isUser === void 0 ? void 0 : isUser.address);
    }, [isUser, locationManagementShown]);
    var buyLocations = function () {
        setLoading(true);
        //Add or remove a location
        if (locationAmount > 0) {
            var addLocationToSubscription = firebase
                .app()
                .functions("europe-west3")
                .httpsCallable("addLocationToSubscription");
            addLocationToSubscription({ amount: locationAmount })
                .then(function (result) {
                setLoading(false);
                setLocationManagementShown(false);
                toaster.success("Abonnement war erfolgreich!");
            })
                .catch(function (err) {
                setLoading(false);
                setLocationManagementShown(false);
                toaster.warning("Abonnement war nicht erfolgreich!");
            });
        }
        else {
            var removeLocationToSubscription = firebase
                .app()
                .functions("europe-west3")
                .httpsCallable("removeLocationToSubscription");
            removeLocationToSubscription({ amount: locationAmount })
                .then(function (result) {
                setLoading(false);
                setLocationManagementShown(false);
                toaster.success("Debbonnement war erfolgreich!");
            })
                .catch(function (err) {
                setLoading(false);
                setLocationManagementShown(false);
                toaster.warning("Debbonnement war nicht erfolgreich!");
            });
        }
    };
    var upgradePlan = function () {
        var changeSubscription = firebase
            .app()
            .functions("europe-west3")
            .httpsCallable("changeSubscription");
        changeSubscription({
            price_id: (isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.subscriptionPeriod) === "annual"
                ? "price_1MwotNGjTI7kgs1jIxrxnGpA"
                : "price_1KSoYHGjTI7kgs1j7PbNT2xi",
            price_label: "apomap-pro",
            price_type: (isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.subscriptionPeriod) === "annual" ? "annual" : "monthly",
        })
            .then(function (result) {
            toaster.success("apomap pro erfolgreich abonniert");
            buyLocations();
            setLoading(false);
        })
            .catch(function (err) {
            toaster.warning("apomap pro konnte nicht abonniert werden");
            setLoading(false);
        });
    };
    function setupSepaForCustomer(type) {
        return __awaiter(this, void 0, void 0, function () {
            var setupSepa;
            var _this = this;
            return __generator(this, function (_a) {
                setLoading(true);
                setupSepa = firebase
                    .app()
                    .functions("europe-west3")
                    .httpsCallable("setupSepa");
                setupSepa()
                    .then(function (resp) { return __awaiter(_this, void 0, void 0, function () {
                    var iban, result;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                iban = elements.getElement(IbanElement);
                                console.log(iban);
                                return [4 /*yield*/, stripe.confirmSepaDebitSetup(resp.data, {
                                        payment_method: {
                                            sepa_debit: iban,
                                            billing_details: {
                                                name: isUser.firstName + " " + isUser.lastName,
                                                email: firebase.auth().currentUser.email,
                                            },
                                        },
                                    })];
                            case 1:
                                result = _a.sent();
                                if (resp.error) {
                                    // Show error to your customer.
                                    console.log(result.error.message);
                                    setLoading(false);
                                }
                                else {
                                    if (result.error) {
                                        switch (result.error.code) {
                                            case "incomplete_iban":
                                                toaster.warning("Die angegebene IBAN ist nicht korrekt!");
                                                break;
                                            default:
                                                toaster.warning("Ein Fehler ist unterlaufen. Das Abonement konnte nicht abgeschlossen werden.");
                                        }
                                        setLoading(false);
                                        return [2 /*return*/];
                                    }
                                    setTimeout(function () {
                                        var setDefaultSepa = firebase
                                            .app()
                                            .functions("europe-west3")
                                            .httpsCallable("setDefaultSepa");
                                        setDefaultSepa({
                                            default_payment_method: result.setupIntent.payment_method,
                                        })
                                            .then(function (res) { return __awaiter(_this, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                console.log(res);
                                                if (type === "upgrade") {
                                                    toaster.success("Zahlungsmethode erfolgreich hinterlegt");
                                                    upgradePlan();
                                                }
                                                else {
                                                    toaster.success("Zahlungsmethode erfolgreich hinterlegt");
                                                    buyLocations();
                                                }
                                                return [2 /*return*/];
                                            });
                                        }); })
                                            .catch(function (res) {
                                            toaster.warning("Fehler bei der Hinterlegung der Zahlungsmethode");
                                            console.log(res.error);
                                            setLoading(false);
                                        });
                                    }, 1000);
                                }
                                return [2 /*return*/];
                        }
                    });
                }); })
                    .catch(function (err) {
                    toaster.notify(err);
                });
                return [2 /*return*/];
            });
        });
    }
    var checkPayment = function () {
        if (isPlanData.isPaymentSet) {
            buyLocations();
        }
        else {
            setupSepaForCustomer();
        }
    };
    var upgrade = function () {
        setLoading(true);
        if (isPlanData.isPaymentSet) {
            upgradePlan();
        }
        else {
            setupSepaForCustomer("upgrade");
        }
    };
    //Depriciated
    // const addLocation = () => {
    //   setLocationAmount(locationAmount + 1);
    // };
    // const subtractLocation = () => {
    //   if (isPlanData?.locationMaximum + locationAmount - 1 !== 0) {
    //     setLocationAmount(locationAmount - 1);
    //   }
    // };
    return (_jsx(ThemeProvider, __assign({ value: theme }, { children: _jsxs(Dialog, __assign({ isShown: locationManagementShown, cancelLabel: "Abbrechen", confirmLabel: "Hinzuf\u00FCgen", shouldCloseOnOverlayClick: false, shouldCloseOnEscapePress: false, hasHeader: false, hasFooter: false, contentContainerProps: {
                className: "dialog-content-container",
                padding: 0,
            }, containerProps: { className: "square-corners" }, width: "80vw" }, { children: [_jsx(BlueBar, {}), _jsx(CrossIcon, { onClick: handleCloseClick, style: {
                        position: "absolute",
                        right: 20,
                        top: 20,
                        cursor: "pointer",
                    } }), _jsx(Pane, __assign({ display: "flex" }, { children: _jsxs(Pane, __assign({ style: {
                            width: "100%",
                            padding: 20,
                            paddingBottom: 0,
                        } }, { children: [_jsx(Heading, __assign({ size: "large", marginBottom: 20 }, { children: "STANDORT ABONNEMENT" })), _jsxs(Pane, __assign({ style: {
                                    height: "65vh",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                } }, { children: [_jsxs(Pane, __assign({ style: {
                                            height: "100%",
                                            width: "50%",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-around",
                                            padding: 20,
                                        } }, { children: [_jsx(Heading, __assign({ color: "#2D3B51" }, { children: "Produktbeschreibung" })), (isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.subscriptionLabel) === "apomap-pro" ? (_jsx(Paragraph, __assign({ marginBottom: 5 }, { children: "Ein weiterer Standort erm\u00F6glicht es Dir, einen dezentralen Botendienst zu etablieren. Alle Auftr\u00E4ge flie\u00DFen in apomap und du kannst sogar mit einem Fahrer alle Auslieferungen durchf\u00FChren." }))) : (_jsxs(_Fragment, { children: [_jsx(Paragraph, __assign({ marginBottom: 5 }, { children: "Mit dem Enterprise-Plan von apomap, hast Du die M\u00F6glichkeit mehrere Standorte anzulegen und einen zentralen Botendienst zu etablieren." })), _jsxs(Pane, { children: [_jsx(Paragraph, __assign({ className: "headline" }, { children: (isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.subscriptionPeriod) === "annual"
                                                                    ? "Enterprise-Plan: 1609€/Jahr"
                                                                    : "Enterprise-Plan: 149€/mtl." })), _jsx(Paragraph, __assign({ className: "headline" }, { children: (isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.subscriptionPeriod) === "annual"
                                                                    ? "1x Standort: 588€/Jahr"
                                                                    : "1x Standort: 49€/mtl." }))] })] })), _jsx(Pane, { style: {
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                } }), ready && (_jsxs(Pane, __assign({ style: {
                                                    display: "flex",
                                                    flexDirection: "column",
                                                } }, { children: [_jsxs(Pane, __assign({ style: {
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            justifyContent: "space-between",
                                                        } }, { children: [_jsx(Paragraph, __assign({ color: "#2D3B51", style: { fontFamily: "bold" }, size: 300 }, { children: "Name:" })), isEditMode ? (_jsx(TextInput, { spellCheck: false, style: { textAlign: "right" }, value: isUserName, onChange: function (e) { return setUserName(e.target.value); } })) : (_jsx(Paragraph, __assign({ size: 300 }, { children: isUserName })))] })), _jsxs(Pane, __assign({ style: {
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            justifyContent: "space-between",
                                                        } }, { children: [_jsx(Paragraph, __assign({ color: "#2D3B51", style: { fontFamily: "bold" }, size: 300 }, { children: "E-Mail:" })), isEditMode ? (_jsx(TextInput, { spellCheck: false, style: { textAlign: "right" }, value: isUserEmail, onChange: function (e) { return setUserEmail(e.target.value); } })) : (_jsx(Paragraph, __assign({ size: 300 }, { children: isUserEmail })))] })), _jsxs(Pane, __assign({ style: {
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            justifyContent: "space-between",
                                                        } }, { children: [_jsx(Paragraph, __assign({ color: "#2D3B51", style: { fontFamily: "bold" }, size: 300 }, { children: "Rechnungsadresse:" })), isEditMode ? (_jsx(TextInput, { spellCheck: false, style: { textAlign: "right" }, value: isUserAddress, onChange: function (e) { return setisUserAddress(e.target.value); } })) : (_jsx(Paragraph, __assign({ size: 300 }, { children: isUserAddress })))] }))] }))), !(isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.isPaymentSet) && (_jsxs(_Fragment, { children: [_jsx(Heading, { children: "Rechnungskonto" }), _jsxs(Pane, __assign({ border: "1px #49beef solid", padding: 10, borderRadius: 10 }, { children: [_jsx(IbanElement, { options: {
                                                                    supportedCountries: ["SEPA"],
                                                                    placeholderCountry: "DE",
                                                                }, style: { width: "50%", height: 40 } }), _jsx("p", __assign({ style: { fontSize: "10px" } }, { children: "Durch Angabe Ihrer Zahlungsinformationen und der Best\u00E4tigung der vorliegenden Zahlung erm\u00E4chtigen Sie (A) apomap UG (haftungsbeschr\u00E4nkt) und Stripe, unseren Zahlungsdienstleister, bzw. PPRO, den lokalen Bankdienstleister von Stripe, Ihrem Kreditinstitut Anweisungen zur Belastung Ihres Kontos zu erteilen, und (B) Ihr Kreditinstitut, Ihr Konto gem\u00E4\u00DF diesen Anweisungen zu belasten. Im Rahmen Ihrer Rechte haben Sie, entsprechend den Vertragsbedingungen mit Ihrem Kreditinstitut, Anspruch auf eine R\u00FCckerstattung von Ihrem Kreditinstitut. Eine R\u00FCckerstattung muss innerhalb von 8 Wochen ab dem Tag, an dem Ihr Konto belastet wurde, geltend gemacht werden. Eine Erl\u00E4uterung Ihrer Rechte k\u00F6nnen Sie von Ihrem Kreditinstitut anfordern. Sie erkl\u00E4ren sich einverstanden, Benachrichtigungen \u00FCber k\u00FCnftige Belastungen bis sp\u00E4testens 2 Tage vor dem Buchungsdatum zu erhalten." }))] }))] })), (isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.subscriptionLabel) === "apomap-pro" ? (_jsx(Pane, __assign({ padding: 20 }, { children: _jsx(Button, __assign({ width: "100%", size: "large", appearance: "primary", intent: "success", onClick: checkPayment, isLoading: isLoading }, { children: (isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.subscriptionPeriod) === "annual"
                                                        ? "Jetzt einen Standort für 588€/Jahr buchen"
                                                        : "Jetzt einen Standort für 49€/mtl. buchen" })) }))) : (_jsx(Pane, __assign({ padding: 20 }, { children: _jsx(Button, __assign({ width: "100%", size: "large", appearance: "primary", intent: "success", onClick: upgrade, isLoading: isLoading }, { children: (isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.subscriptionPeriod) === "annual"
                                                        ? " Enterprise und einen Standort für 1900€/Jahr. buchen"
                                                        : " Enterprise und einen Standort für 198€/mtl. buchen" })) })))] })), _jsx(Pane, __assign({ style: {
                                            height: "100%",
                                            width: "50%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                        } }, { children: _jsx("img", { src: felix, style: { height: "60%", marginBottom: 25 }, alt: "location-icon" }) }))] }))] })) }))] })) })));
}
