var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { Pane, Paragraph, Heading, Button, toaster } from "evergreen-ui";
import { ThemeContext } from "evergreen-ui";
import LogoBig from "../components/LogoBig";
import theme from "../theme";
import ApoTextInput from "../components/ApoTextInput";
import { Link, useHistory } from "react-router-dom";
import validator from "email-validator";
import FooterBar from "../components/FooterBar";
import firebase from "../config";
import "firebase/auth";
import "firebase/firestore";
var ThemeProvider = ThemeContext.Provider;
export default function SignIn() {
    var _a = useState(), isEmail = _a[0], setEmail = _a[1];
    var _b = useState(), isPassword = _b[0], setPassword = _b[1];
    var _c = useState(true), available = _c[0], setAvailable = _c[1];
    var _d = useState(), isPasswordCheck = _d[0], setPasswordCheck = _d[1];
    var _e = useState(false), isLoading = _e[0], setLoading = _e[1];
    var history = useHistory();
    function signInToAccount() {
        setLoading(true);
        firebase
            .auth()
            .signInWithEmailAndPassword(isEmail, isPassword)
            .then(function () {
            setLoading(false);
            toaster.success("Willkommen zurück!");
        })
            .catch(function (err) {
            setLoading(false);
            switch (err.code) {
                case "auth/wrong-password":
                case "auth/user-not-found":
                    toaster.warning("Die E-Mail Adresse oder das Passwort sind inkorrekt!");
                    break;
                default:
                    break;
            }
        });
    }
    return (_jsxs(ThemeProvider, __assign({ value: theme }, { children: [_jsxs(Pane, __assign({ style: {
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                } }, { children: [_jsxs(Pane, __assign({ style: {
                            height: "100%",
                            width: "60%",
                            justifyContent: "center",
                            padding: 35,
                            display: "flex",
                            flexDirection: "column",
                            background: "linear-gradient #E4EBF5 0%, rgba(255,255,255,1) 100%)",
                        } }, { children: [_jsx(Pane, __assign({ style: { position: "absolute", top: 35, left: 35 } }, { children: _jsx(LogoBig, {}) })), _jsxs(Pane, __assign({ display: "flex", justifyContent: "center", flexDirection: "column", onKeyPress: function (e) { return e.key === "Enter" && signInToAccount(); }, marginLeft: 15 }, { children: [_jsx(Heading, __assign({ fontSize: "48px", color: "#2D3B51", size: "large" }, { children: "In deinen Account einloggen." })), _jsxs(Pane, __assign({ marginTop: 35, width: "50%" }, { children: [_jsx(ApoTextInput, { label: _jsx(Paragraph, __assign({ fontFamily: "bold" }, { children: "E-Mail Adresse*" })), type: "text", placeholder: "max.munstermann@apomap.de", spellCheck: false, value: isEmail, onChange: function (e) { return setEmail(e.target.value); } }), _jsx(ApoTextInput, { label: _jsx(Paragraph, __assign({ fontFamily: "bold" }, { children: "Passwort*" })), placeholder: "min. 6 Zeichen", hint: "min. 6 Zeichen", spellCheck: false, type: "password", value: isPassword, onChange: function (e) { return setPassword(e.target.value); } }), _jsx(Link, __assign({ to: "/forgotPassword", style: {
                                                    color: "#707070",
                                                    textDecoration: "none",
                                                    fontSize: 10,
                                                    marginTop: 8,
                                                } }, { children: "Passwort vergessen?" })), _jsx(Pane, __assign({ display: "flex", flexDirection: "column", alignItems: "flex-start" }, { children: _jsx(Button, __assign({ appearance: "primary", backgroundColor: "#2D3B51", marginTop: 26, size: "large", isLoading: isLoading, onClick: function () { return signInToAccount(); } }, { children: "Einloggen" })) }))] }))] }))] })), _jsx(Pane, __assign({ style: {
                            height: "100%",
                            width: "40%",
                            backgroundColor: "#49beef",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 35,
                        } }, { children: _jsxs(Pane, __assign({ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "50%" }, { children: [_jsx(Heading, __assign({ fontSize: "48px", size: "large", color: "white", textAlign: "center", fontWeight: 800 }, { children: "Du bist neu hier?" })), _jsx(Paragraph, __assign({ textAlign: "center", width: "50%", marginTop: 36, color: "white" }, { children: "Registriere dich jetzt und mache deinen Botendienst einfach." })), _jsx(Link, __assign({ style: { textDecoration: "none" }, to: "/signup" }, { children: _jsx(Button, __assign({ backgroundColor: "#2D3B51", marginTop: 36, size: "large", appearance: "primaryWhite" }, { children: "ZUR REGISTRIERUNG" })) }))] })) }))] })), _jsx(FooterBar, {})] })));
}
