var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, CalendarIcon, CameraIcon, Card, EditIcon, FilePicker, Heading, LinkIcon, Pane, Paragraph, PersonIcon, SavedIcon, Spinner, TextareaField, toaster, VideoIcon, } from "evergreen-ui";
import moment from "moment";
import React, { useState, useEffect } from "react";
import VideoRecorder from "react-video-recorder";
import { useHistory, useParams } from "react-router-dom";
import firebase from "../config";
import "firebase/auth";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import ReactPlayer from "react-player";
require("firebase/firestore");
require("firebase/storage");
//ref to database
var db = firebase.firestore();
//ref to storage (mediafiles)
var storage = firebase.storage();
//storageref
var storageRef = storage.ref();
//ffmpeg for clientside convertion (not used anymore)
//runs on server
var ffmpeg = createFFmpeg({ log: true });
export default function QuestionDetails(_a) {
    var question = _a.question;
    var history = useHistory();
    var _b = useState(false), wantFile = _b[0], setWantFile = _b[1];
    var _c = useState(false), wantRecord = _c[0], setWantRecord = _c[1];
    //if answer involves a note of business
    var _d = useState(false), wantNote = _d[0], setWantNote = _d[1];
    //if answer involves a link to customer
    var _e = useState(false), wantLink = _e[0], setWantLink = _e[1];
    //sets recorded video blob
    var _f = useState(undefined), isVideo = _f[0], setVideo = _f[1];
    //Sets uploading state
    var _g = useState(false), isUploading = _g[0], setUploading = _g[1];
    //Stores selected date for scheduled call
    var _h = useState({
        label: "Termin",
        value: undefined,
    }), selectedDate = _h[0], setSelectedDate = _h[1];
    //Hols note of business
    var _j = useState(""), isNote = _j[0], setNote = _j[1];
    //holds link of business
    var _k = useState(""), isLink = _k[0], setLink = _k[1];
    //uploads file to storage and refs the link to question
    function uploadFileToStorage() {
        return __awaiter(this, void 0, void 0, function () {
            //changes question status and more
            function changequestionStatus(id, url) {
                db.collection("app_questions")
                    .doc(id)
                    .update({
                    completed: false,
                    status: "done",
                    note: isNote,
                    link: isLink,
                    callDate: selectedDate.value ? selectedDate : "",
                    completedTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    url: "https://firebasestorage.googleapis.com/v0/b/apomapdreinull.appspot.com/o/converted-".concat(id, "?alt=media&token=23c334"),
                })
                    .then(function (doc) {
                    setUploading(false);
                    toaster.success("Beratung erfolgreich abgeschlossen");
                })
                    .catch(function (error) {
                    console.log(error);
                });
            }
            return __generator(this, function (_a) {
                setUploading(true);
                //if video blob is recorded
                if (isVideo) {
                    //save videofile to storage with same id as question
                    storageRef
                        .child("".concat(question.id))
                        .put(isVideo)
                        .then(function (snapshot) {
                        snapshot.ref
                            .getDownloadURL()
                            .then(function (url) {
                            //change questionstatus
                            changequestionStatus(question.id, url);
                        })
                            .catch(function (error) {
                            console.log(error);
                        });
                    })
                        .catch(function (error) {
                        setUploading(false);
                        toaster.warning("Video konnte nicht hochgeladen werden");
                    });
                }
                else {
                    setUploading(false);
                    toaster.warning("Bitte nehmen Sie zuerste ein Video auf");
                }
                return [2 /*return*/];
            });
        });
    }
    if (!question) {
        return (_jsx(Pane, __assign({ flex: "1", overflowY: "scroll", background: "tint1", padding: 16 }, { children: _jsx(Card, __assign({ backgroundColor: "white", elevation: 0, height: 240, display: "flex", alignItems: "center", justifyContent: "center" }, { children: _jsx(Spinner, {}) })) })));
    }
    if (question.completed) {
        return (_jsxs(Pane, __assign({ flex: "1", overflowY: "scroll", background: "tint1", padding: 16 }, { children: [_jsx(Card, __assign({ backgroundColor: "white", elevation: 0, flexDirection: "row", alignItems: "center", display: "flex" }, { children: _jsxs(Pane, __assign({ padding: 20, style: { flexDirection: "row" } }, { children: [_jsxs(Heading, __assign({ size: 200 }, { children: [moment(question === null || question === void 0 ? void 0 : question.timestamp.toDate()).format("DD.MM.YYYY"), " um", " ", moment(question === null || question === void 0 ? void 0 : question.timestamp.toDate()).format("HH:mm"), " Uhr"] })), _jsx(Paragraph, __assign({ size: 800 }, { children: question === null || question === void 0 ? void 0 : question.text }))] })) })), _jsxs(Card, __assign({ backgroundColor: "white", elevation: 0, flexDirection: "column", display: "flex", marginTop: 20, padding: 20 }, { children: [_jsx(Heading, { children: "Ihre Beratung" }), _jsx(ReactPlayer, { controls: true, width: 350, url: question.url })] }))] })));
    }
    return (_jsxs(Pane, __assign({ flex: "1", overflowY: "scroll", background: "tint1", padding: 16 }, { children: [_jsx(Card, __assign({ backgroundColor: "white", elevation: 0, flexDirection: "row", alignItems: "center", display: "flex" }, { children: _jsxs(Pane, __assign({ padding: 20, style: { flexDirection: "row" } }, { children: [_jsxs(Heading, __assign({ size: 200 }, { children: [moment(question === null || question === void 0 ? void 0 : question.timestamp.toDate()).format("DD.MM.YYYY"), " um", " ", moment(question === null || question === void 0 ? void 0 : question.timestamp.toDate()).format("HH:mm"), " Uhr"] })), _jsx(Paragraph, __assign({ size: 800 }, { children: question === null || question === void 0 ? void 0 : question.text }))] })) })), _jsx(Card, __assign({ backgroundColor: "white", elevation: 0, flexDirection: "row", alignItems: "center", display: "flex", marginTop: 20, padding: 20 }, { children: _jsxs(Pane, __assign({ style: {
                        display: "flex",
                        flexDirection: "column",
                        padding: 5,
                    } }, { children: [_jsx(Heading, __assign({ marginBottom: 20 }, { children: "Beratung durchf\u00FChren" })), _jsxs(Pane, __assign({ style: {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            } }, { children: [_jsx(Button, __assign({ iconBefore: EditIcon, onClick: function () { return setWantNote(!wantNote); }, marginRight: 16, appearance: "primary", intent: "none" }, { children: "Anmerkung" })), _jsx(Button, __assign({ iconBefore: LinkIcon, onClick: function () { return setWantLink(!wantLink); }, marginRight: 16, appearance: "primary", intent: "none" }, { children: "Link" })), _jsx(Button, __assign({ iconBefore: CameraIcon, onClick: function () { return setWantRecord(!wantRecord); }, marginRight: 16, appearance: "primary", intent: "none" }, { children: "Video aufnehmen" })), _jsx(Button, __assign({ iconBefore: SavedIcon, onClick: function () { return setWantFile(!wantFile); }, marginRight: 16, appearance: "primary", intent: "none" }, { children: "Datei hochladen" }))] })), wantNote && (_jsx(TextareaField, { marginTop: 10, label: "Ihre Anmerkung", placeholder: isNote, onChange: function (e) { return setNote(e.target.value); } })), wantLink && (_jsx(TextareaField, { marginTop: 10, label: "Ihr Link", placeholder: isNote, onChange: function (e) { return setNote(e.target.value); } })), wantRecord && (_jsxs(Pane, { children: [_jsx(Heading, __assign({ marginTop: 10 }, { children: "Video-Recoder" })), _jsx(VideoRecorder, { style: { marginTop: 10 }, onRecordingComplete: function (videoBlob) {
                                        setVideo(videoBlob);
                                    } })] })), wantFile && (_jsx(FilePicker, { multiple: true, marginTop: 10, width: 250, onChange: function (source) {
                                setVideo(source[0]);
                            }, placeholder: "Select the file here!" })), _jsx(Button, __assign({ onClick: function () { return uploadFileToStorage(); }, isLoading: isUploading, marginRight: 16, marginTop: 20, appearance: "primary", intent: "success" }, { children: "Jetzt hochladen" }))] })) }))] })));
}
