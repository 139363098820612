var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, Pane, CaretUpIcon, CaretDownIcon } from "evergreen-ui";
import React, { useCallback, useRef, useState } from "react";
import ApoTextInput from "./ApoTextInput";
import ApoFieldLabel from "./ApoFieldLabel";
var DurationField = function (_a) {
    var label = _a.label, _b = _a.value, value = _b === void 0 ? 0 : _b, onChange = _a.onChange, disabled = _a.disabled, step = _a.step, defaultValue = _a.defaultValue, suffix = _a.suffix;
    var STEP = step !== null && step !== void 0 ? step : 5;
    var _c = useState(false), isFocused = _c[0], setFocused = _c[1];
    var handleBlur = useCallback(function () {
        value = (value || 0) % STEP;
        onChange && onChange(value);
        setFocused(false);
    }, []);
    var inputRef = useRef(null);
    var handleUpClick = useCallback(function () {
        onChange && onChange((value || 0) + STEP);
    }, [onChange, value]);
    var handleDownClick = useCallback(function () {
        onChange && onChange(Math.max(0, (value || 0) - STEP));
    }, [onChange, value]);
    var handleChange = useCallback(function (e) {
        onChange && onChange(e.target.value);
    }, [onChange]);
    return (_jsxs(Pane, __assign({ style: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
        } }, { children: [_jsx(ApoFieldLabel, { value: label }), _jsxs(Pane, __assign({ display: "flex" }, { children: [_jsx(ApoTextInput, { disabled: disabled, inputRef: inputRef, value: (value || defaultValue || 0) + (isFocused ? "" : suffix !== null && suffix !== void 0 ? suffix : "min"), type: isFocused ? "number" : undefined, numberProps: { step: STEP }, onChange: handleChange, onFocus: function () { return setFocused(true); }, onBlur: handleBlur }), _jsx(IconButton, { type: "button", disabled: disabled, icon: CaretUpIcon, height: 45, onClick: handleUpClick }), _jsx(IconButton, { type: "button", disabled: disabled, icon: CaretDownIcon, height: 45, onClick: handleDownClick })] }))] })));
};
export default DurationField;
