var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
//The taskbar contains all tasks of a day and all tasks other the successfull
//The user can create/import tasks here
import { Alert, Button, Heading, Menu, Pane, Popover, Position, ThemeContext, toaster, TrashIcon, } from "evergreen-ui";
import "firebase/firestore";
import moment from "moment";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ChangeLayoutButton from "../components/ChangeLayoutButton";
import firebase from "../config";
import NewTaskDialog from "../container/NewTaskDialog";
import { LayoutContext } from "../contexts/LayoutContext";
import { LocationContext } from "../contexts/LocationContext";
import { TaskContext } from "../contexts/TaskContext";
import { getLabelFromStatus } from "../utility/getLabelFromStatus";
import { getLabelFromType } from "../utility/getLabelFromType";
import CSVImporter from "./CSVImporter";
import OptimizationModal from "./OptimizationModal";
//New theme
import AddTaskToTourDialog from "../components/AddTaskToTourDialog";
import ApoTextInput from "../components/ApoTextInput";
import BlueBar from "../components/BlueBar";
import { EditActionBar, EditButton } from "../components/Editmode";
import QuestionModal from "../components/QuestionModal";
import TaskDeleteDialog from "../components/TaskDeleteDialog";
import TaskDialog from "../components/TaskDialog";
import TaskHistory from "../components/TaskHistory";
import TasksTable from "../components/TasksTable";
import { QuestionContext } from "../contexts/QuestionContext";
import { TaskBarAlertContext } from "../contexts/TaskBarAlertContext";
import { TaskDialogsContext } from "../contexts/TaskDialogsContext";
import { TaskEditContext } from "../contexts/TaskEditContext";
import { UserContext } from "../contexts/UserContext";
import theme from "../theme";
import PayWall from "./PayWall";
var ThemeProvider = ThemeContext.Provider;
export default function TaskBar() {
    var _this = this;
    var _a;
    //context
    var tasks = useContext(TaskContext).tasks;
    var locations = useContext(LocationContext).locations;
    var areLocations = locations[0];
    var state = useContext(LayoutContext).state;
    var isEditMode = state[0], setEditMode = state[1];
    var modalState = useContext(QuestionContext).modalState;
    var isQuestionShown = modalState[0], setQuestionShown = modalState[1];
    var areTasks = tasks[0], setTasks = tasks[1];
    var _b = useState(false), isOptimisationModalShown = _b[0], setOptimisationModalShown = _b[1];
    var _c = useState(false), isPayWallShown = _c[0], setPayWallShown = _c[1];
    var _d = useState(0), openTaskCount = _d[0], setOpenTaskCount = _d[1];
    var _e = useState(0), unassignedTaskCount = _e[0], setUnassignedTaskCount = _e[1];
    var taskBarAlertContext = useContext(TaskBarAlertContext);
    var taskBarAlertBox = taskBarAlertContext[0], setTaskBarAlertBox = taskBarAlertContext[1];
    var dialogSettings = useContext(TaskDialogsContext).dialogSettings;
    var shownDialog = dialogSettings[0], setShownDialog = dialogSettings[1];
    var _f = useState(false), deleteMode = _f[0], setDeleteMode = _f[1];
    var user = useContext(UserContext).user;
    var isUser = user[0];
    var _g = useState([]), selectedTags = _g[0], setSelectedTags = _g[1];
    useEffect(function () {
        if (selectedTags === null && isUser.tags !== null) {
            setSelectedTags(isUser.tags);
        }
    }, [isUser, selectedTags]);
    var _h = useState(moment().format("YYYY-MM-DD")), selectedDate = _h[0], setSelectedDate = _h[1];
    //Holds the task only shown in the taskbar
    //with client side filter in function filterTasks()
    //HINT -> could be done serverside in future
    var _j = useState([]), shownTasks = _j[0], setShownTasks = _j[1];
    //Filtermechanism
    //TODO -> Needs to be done
    var _k = useState([
        "Status",
        undefined,
    ]), isActiveStatiFilter = _k[0], setActiveStatiFilter = _k[1];
    var _l = useState([
        "Standort",
        undefined,
    ]), isActiveLocationFilter = _l[0], setActiveLocationFilter = _l[1];
    var _m = useState([
        "Auftragsart",
        undefined,
    ]), isActiveTypeFilter = _m[0], setActiveTypeFilter = _m[1];
    //Everytime the server sends new data
    //the clientside filter needs to be run
    useEffect(function () {
        filterTasks();
    }, [selectedDate, areTasks]);
    //Clientside filter
    //only show tasks from today or
    //task with other status than successfull
    function filterTasks() {
        //When dateselector is on same date as today get data from listner
        //Else fetch future tasks of date
        // console.log("Selected Here:", selectedDate);
        var validDate = moment(selectedDate);
        if (validDate.isValid()) {
            if (moment(selectedDate, "YYYY-MM-DD").format("YY-MM-DD") !==
                moment().format("YY-MM-DD")) {
                if (moment(selectedDate, "YYYY-MM-DD").format("YY-MM-DD") >=
                    moment().format("YY-MM-DD")) {
                    firebase
                        .firestore()
                        .collection("tasks")
                        .where("task_owner", "==", firebase.auth().currentUser.uid)
                        .where("task_date", ">=", moment(selectedDate, "YYYY-MM-DD").startOf("day").toDate())
                        .where("task_date", "<=", moment(selectedDate, "YYYY-MM-DD").endOf("day").toDate())
                        .onSnapshot(function (snap) {
                        var tasksOnDate = [];
                        snap.forEach(function (doc) {
                            var data = doc.data();
                            tasksOnDate.push(data);
                        });
                        setShownTasks(tasksOnDate);
                    });
                }
                else {
                    toaster.notify("Alle Aufträge der vergangenen Tags sehen sie bereits!");
                }
            }
            else {
                setShownTasks(areTasks.filter(function (task) {
                    // Tag Filter
                    if (selectedTags.length === 0)
                        return true;
                    if (task.task_tags !== undefined &&
                        task.task_tags !== null &&
                        task.task_tags.length !== 0) {
                        for (var _i = 0, _a = task.task_tags; _i < _a.length; _i++) {
                            var tag = _a[_i];
                            if (selectedTags.includes(tag)) {
                                return true;
                            }
                        }
                        return false;
                    }
                    return false;
                }));
            }
        }
        else {
            console.log("Invalid Date:", selectedDate);
        }
    }
    // *####################
    function filterStati(task, stati) {
        return task.filter(function (task) { return task.task_status === stati; });
    }
    function filterLocation(task, location) {
        return task.filter(function (task) { return task.task_origin === location; });
    }
    function filterType(task, type) {
        return task.filter(function (task) { return task.task_type === type; });
    }
    function checkOtherFilters(tasks, type) {
        switch (type) {
            case "Status":
                if (isActiveLocationFilter[0] !== "Standort") {
                    tasks = filterLocation(tasks, isActiveLocationFilter[1]);
                }
                if (isActiveTypeFilter[0] !== "Auftragsart") {
                    tasks = filterType(tasks, isActiveTypeFilter[1]);
                }
                break;
            case "Standort":
                if (isActiveStatiFilter[0] !== "Status") {
                    tasks = filterStati(tasks, isActiveStatiFilter[1]);
                }
                if (isActiveTypeFilter[0] !== "Auftragsart") {
                    tasks = filterType(tasks, isActiveTypeFilter[1]);
                }
                break;
            case "Auftragsart":
                if (isActiveStatiFilter[0] !== "Status") {
                    tasks = filterStati(tasks, isActiveStatiFilter[1]);
                }
                if (isActiveLocationFilter[0] !== "Standort") {
                    tasks = filterLocation(tasks, isActiveLocationFilter[1]);
                }
                break;
        }
        return tasks;
    }
    //TODO -> Filterfunction
    var filterTasksByStati = useCallback(function (stati) {
        //Take all tasks there are at that current moment
        setShownTasks(function () {
            var newState = __spreadArray([], areTasks, true);
            var filteredTasks = newState;
            if (stati) {
                filteredTasks = filterStati(newState, stati);
                var label = getLabelFromStatus(stati);
                setActiveStatiFilter([label, stati]);
            }
            else {
                setActiveStatiFilter(["Status", undefined]);
            }
            filteredTasks = checkOtherFilters(filteredTasks, "Status");
            return filteredTasks;
        });
    }, [areTasks, checkOtherFilters, setShownTasks]);
    function filterTasksByLocation(location) {
        setShownTasks(function () {
            var newState = __spreadArray([], areTasks, true);
            var filteredTasks = newState;
            if (location) {
                filteredTasks = filterLocation(newState, location.id);
                setActiveLocationFilter([location.name, location.id]);
            }
            else {
                setActiveLocationFilter(["Standort", undefined]);
            }
            filteredTasks = checkOtherFilters(filteredTasks, "Standort");
            return filteredTasks;
        });
    }
    function filterTaskByType(type) {
        setShownTasks(function () {
            var newState = __spreadArray([], areTasks, true);
            var filteredTasks = newState;
            if (type) {
                filteredTasks = filterType(newState, type);
                setActiveTypeFilter([getLabelFromType(type), type]);
            }
            else {
                setActiveTypeFilter(["Auftragsart", undefined]);
            }
            filteredTasks = checkOtherFilters(filteredTasks, "Auftragsart");
            return filteredTasks;
        });
    }
    function resetAllFilters() {
        setActiveTypeFilter(["Auftragsart", undefined]);
        setActiveLocationFilter(["Standort", undefined]);
        setActiveStatiFilter(["Status", undefined]);
        filterTasks();
    }
    //TODO -> Filterfunction
    function LocationFilter() {
        return (_jsx(Popover, __assign({ position: Position.BOTTOM_LEFT, content: _jsx(Menu, { children: _jsxs(Menu.Group, { children: [_jsx(Menu.Item, __assign({ icon: TrashIcon, onClick: function () { return filterTasksByLocation(undefined); } }, { children: "Filter l\u00F6schen" })), areLocations.map(function (location) {
                            return (_jsx(Menu.Item, __assign({ onClick: function () { return filterTasksByLocation(location); } }, { children: location.name })));
                        })] }) }) }, { children: _jsx(Button, __assign({ marginRight: 16 }, { children: isActiveLocationFilter[0] })) })));
    }
    function StatusFilter() {
        return (_jsx(Popover, __assign({ position: Position.BOTTOM_LEFT, content: _jsx(Menu, { children: _jsxs(Menu.Group, { children: [_jsx(Menu.Item, __assign({ icon: TrashIcon, onClick: function () { return filterTasksByStati(undefined); } }, { children: "Filter l\u00F6schen" })), _jsx(Menu.Item, __assign({ onClick: function () { return filterTasksByStati("unassigned"); } }, { children: "Nicht zugeteilt" })), _jsx(Menu.Item, __assign({ onClick: function () { return filterTasksByStati("assigned"); } }, { children: "Zugeteilt" })), _jsx(Menu.Item, __assign({ onClick: function () { return filterTasksByStati("ontheway"); } }, { children: "Auf dem Weg" })), _jsx(Menu.Item, __assign({ onClick: function () { return filterTasksByStati("successfull"); } }, { children: "Zugestellt" })), _jsx(Menu.Item, __assign({ onClick: function () { return filterTasksByStati("declined"); } }, { children: "Nicht angetroffen" })), _jsx(Menu.Item, __assign({ onClick: function () { return filterTasksByStati("optimizing"); } }, { children: "In Optimierung" }))] }) }) }, { children: _jsx(Button, __assign({ marginRight: 16 }, { children: isActiveStatiFilter[0] })) })));
    }
    function TypeFilter() {
        return (_jsx(Popover, __assign({ position: Position.BOTTOM_LEFT, content: _jsx(Menu, { children: _jsxs(Menu.Group, { children: [_jsx(Menu.Item, __assign({ icon: TrashIcon, onClick: function () { return filterTaskByType(undefined); } }, { children: "Filter l\u00F6schen" })), _jsx(Menu.Item, __assign({ onClick: function () { return filterTaskByType("delivery"); } }, { children: "Lieferung" })), _jsx(Menu.Item, __assign({ onClick: function () { return filterTaskByType("pickup"); } }, { children: "Abholung" }))] }) }) }, { children: _jsx(Button, __assign({ marginRight: 16 }, { children: isActiveTypeFilter[0] })) })));
    }
    var handleDateChange = useCallback(function (e) { return setSelectedDate(new Date(e.target.value)); }, []);
    // Filtering search results by selected date
    var handleSearchResultsChange = useCallback(function (results) {
        setShownTasks(results);
    }, [selectedDate]);
    //Check open Tasks count
    useEffect(function () {
        var openTaskCount = areTasks.filter(function (_a) {
            var task_status = _a.task_status;
            return task_status === "unassigned" ||
                task_status === "assigned" ||
                task_status === "attached" ||
                task_status === "pickedup";
        }).length;
        var unassignedTaskCount = areTasks.filter(function (_a) {
            var task_status = _a.task_status;
            return task_status === "unassigned" ||
                task_status === "prepared" ||
                task_status === "optimizing";
        }).length;
        setOpenTaskCount(openTaskCount);
        setUnassignedTaskCount(unassignedTaskCount);
    }, [areTasks]);
    var _o = useContext(TaskEditContext), editTasks = _o.tasks, setEditTasks = _o.setTasks;
    var _p = useState(false), deleting = _p[0], setDeleting = _p[1];
    var deleteTasksRemote = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setDeleting(true);
                    return [4 /*yield*/, Promise.all(editTasks.map(function (id) {
                            return firebase.firestore().collection("tasks").doc(id).delete();
                        }))];
                case 1:
                    _a.sent();
                    setEditTasks([]);
                    setDeleteMode(false);
                    setDeleting(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var deleteAllTasks = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            firebase
                .firestore()
                .collection("tasks")
                .where("task_owner", "==", firebase.auth().currentUser.uid)
                .where("task_status", "==", "unassigned")
                .get()
                .then(function (querySnapshot) {
                var batch = firebase.firestore().batch();
                querySnapshot.forEach(function (doc) {
                    batch.delete(doc.ref);
                });
                // Commit the batch
                return batch.commit();
            });
            setEditMode(false);
            return [2 /*return*/];
        });
    }); };
    var tasksWithUnclearLocation = useMemo(function () { return shownTasks.filter(function (_a) {
        var unclear_location = _a.unclear_location;
        return unclear_location;
    }); }, [shownTasks]);
    // * Handles visibilty of Action Bar
    var _q = useState(false), showActionBar = _q[0], setShowActionBar = _q[1];
    // * Handles screen layout change
    var _r = useState(false), isExpandedView = _r[0], setIsExpandedView = _r[1];
    // * Handles Taskbar width
    var getTaskBarWidth = function () {
        var width;
        // width:  ? "70vw" : "30vw",
        if (isEditMode)
            width = "70vw";
        else if (isExpandedView)
            width = "180vw";
        else
            width = "30vw";
        return width;
    };
    return (_jsxs(_Fragment, { children: [_jsx(TaskDialog, { edit: shownDialog.type === "taskEditDialog" ? true : false, task: shownDialog.task, tasks: (_a = shownDialog === null || shownDialog === void 0 ? void 0 : shownDialog.tasks) !== null && _a !== void 0 ? _a : [], isShown: shownDialog.type === "taskEditDialog" ||
                    shownDialog.type === "taskCreateDialog", setShown: function () { return setShownDialog({ type: "", task: {} }); } }), _jsx(TaskDeleteDialog, { task: shownDialog.task, tasks: shownDialog.tasks, isShown: shownDialog.type === "taskDeleteDialog" ||
                    shownDialog.type === "removeTaskToTourDialog", setShown: function () { return setShownDialog({ type: "", task: {} }); }, isDeleteActiveAction: shownDialog.type === "taskDeleteDialog" ? true : false }), _jsx(AddTaskToTourDialog, { task: shownDialog.task, tasks: shownDialog.tasks, isShown: shownDialog.type === "addTaskToTourDialog", setShown: function () { return setShownDialog({ type: "", tasks: [] }); } }), _jsx(TaskHistory, { task: shownDialog.task, tasks: shownDialog.tasks, isShown: shownDialog.type === "taskHistory", setShown: function () { return setShownDialog({ type: "", task: {} }); } }), _jsx(OptimizationModal, { isShown: isOptimisationModalShown, setIsShown: setOptimisationModalShown, setPayWallShown: setPayWallShown, selectedDate: selectedDate }), _jsx(QuestionModal, { isShown: isQuestionShown, setIsShown: setQuestionShown }), _jsx(PayWall, { isShown: isPayWallShown, setShown: setPayWallShown }), _jsx(ThemeProvider, __assign({ value: theme }, { children: _jsxs(Pane, __assign({ style: {
                        width: getTaskBarWidth(),
                        minWidth: 440,
                        transition: "0.3s ease",
                        padding: 20,
                        paddingRight: 0,
                        overflow: "hidden",
                    } }, { children: [_jsxs(Pane, __assign({ style: {
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                            }, elevation: 2, border: "muted" }, { children: [_jsx(BlueBar, {}), _jsxs(Pane, __assign({ style: {
                                        width: "100%",
                                        flex: 1,
                                        minHeight: 100,
                                        display: "flex",
                                        flexDirection: "column",
                                    } }, { children: [_jsxs(Pane, __assign({ elevation: 1, style: {
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                padding: 14,
                                                justifyContent: "space-between",
                                                borderRadius: "1rem",
                                                backgroundColor: "#fff",
                                            } }, { children: [_jsxs(Pane, __assign({ style: {
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "flex-start",
                                                        justifyContent: "flex-start",
                                                        gap: 14,
                                                    } }, { children: [_jsx(Heading, __assign({ size: "large", className: "headline", style: { color: "#49beef" } }, { children: "AUFTR\u00C4GE" })), _jsx(ApoTextInput, { size: "medium", type: "date", marginBottom: 0, value: moment(selectedDate).format("YYYY-MM-DD"), onChange: handleDateChange })] })), _jsxs("div", __assign({ style: {
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "flex-start",
                                                        alignSelf: "flex-start",
                                                    } }, { children: [_jsx(NewTaskDialog, {}), _jsx(CSVImporter, {}), _jsx(EditButton, { isEditMode: isEditMode, setEditMode: setEditMode, setShowActionBar: setShowActionBar }), _jsx(ChangeLayoutButton, { isExpandedView: isExpandedView, setIsExpandedView: setIsExpandedView })] }))] })), taskBarAlertBox.intent &&
                                            tasksWithUnclearLocation.length !== 0 && (_jsx(Alert, __assign({ intent: taskBarAlertBox === null || taskBarAlertBox === void 0 ? void 0 : taskBarAlertBox.intent, title: taskBarAlertBox === null || taskBarAlertBox === void 0 ? void 0 : taskBarAlertBox.title, margin: 10 }, { children: taskBarAlertBox === null || taskBarAlertBox === void 0 ? void 0 : taskBarAlertBox.description }))), _jsx(TasksTable, { tasks: shownTasks, extended: isEditMode, editMode: isEditMode, isExpandedView: isExpandedView, setIsExpandedView: setIsExpandedView }), _jsx(Pane, __assign({ margin: 14, display: "flex" }, { children: deleteMode ? (_jsx(Button, __assign({}, (isEditMode
                                                ? { marginLeft: "auto", maxWidth: 240 }
                                                : {}), { width: "100%", size: "large", style: { backgroundColor: "red", color: "white" }, onClick: deleteTasksRemote, isLoading: deleting }, { children: "Ausgew\u00E4hlte Auftr\u00E4ge l\u00F6schen" }))) : (_jsx(Button, __assign({}, (isEditMode
                                                ? { marginLeft: "auto", maxWidth: 240 }
                                                : {}), { width: "100%", size: "large", appearance: "primary", 
                                                // disabled={unassignedTaskCount === 0}
                                                // intent={unassignedTaskCount !== 0 ? "success" : "none"}
                                                intent: "success", onClick: function () { return setOptimisationModalShown(true); } }, { children: "Tour planen" }))) }))] }))] })), _jsx(EditActionBar, { setEditMode: setEditMode, active: isEditMode, showActionBar: showActionBar, setShowActionBar: setShowActionBar, shownTasks: shownTasks })] })) }))] }));
}
