var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Combobox, Pane, Paragraph, TextInputField } from "evergreen-ui";
import React, { useImperativeHandle, useState } from "react";
export var AddressInputForm = React.forwardRef(function (_a, ref) {
    var _b, _c, _d, _e, _f, _g, _h;
    var initialData = _a.initialData, withoutName = _a.withoutName, title = _a.title;
    var _j = useState((_b = initialData === null || initialData === void 0 ? void 0 : initialData.firstName) !== null && _b !== void 0 ? _b : ""), firstName = _j[0], setFirstName = _j[1];
    var _k = useState((_c = initialData === null || initialData === void 0 ? void 0 : initialData.lastName) !== null && _c !== void 0 ? _c : ""), lastName = _k[0], setLastName = _k[1];
    var _l = useState((_d = initialData === null || initialData === void 0 ? void 0 : initialData.companyName) !== null && _d !== void 0 ? _d : ""), companyName = _l[0], setCompanyName = _l[1];
    var _m = useState((_e = initialData === null || initialData === void 0 ? void 0 : initialData.customerStreet) !== null && _e !== void 0 ? _e : ""), customerStreet = _m[0], setCustomerStreet = _m[1];
    var _o = useState((_f = initialData === null || initialData === void 0 ? void 0 : initialData.customerCountry) !== null && _f !== void 0 ? _f : ""), customerCountry = _o[0], setCustomerCountry = _o[1];
    var _p = useState((_g = initialData === null || initialData === void 0 ? void 0 : initialData.customerCity) !== null && _g !== void 0 ? _g : ""), customerCity = _p[0], setCustomerCity = _p[1];
    var _q = useState((_h = initialData === null || initialData === void 0 ? void 0 : initialData.customerZip) !== null && _h !== void 0 ? _h : ""), customerZip = _q[0], setCustomerZip = _q[1];
    useImperativeHandle(ref, function () { return ({
        getForm: function () {
            return __assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (firstName && { firstName: firstName })), (lastName && { lastName: lastName })), (customerCity && { customerCity: customerCity })), (customerCountry && { customerCountry: customerCountry })), (customerStreet && { customerStreet: customerStreet })), (companyName && { companyName: companyName })), (customerZip && { customerZip: customerZip }));
        },
    }); });
    return (_jsxs(Pane, { children: [title && (_jsx(Paragraph, __assign({ size: 500, fontWeight: "bold" }, { children: title }))), !withoutName && (_jsxs(_Fragment, { children: [_jsx(TextInputField, { marginTop: 10, label: _jsx(Paragraph, __assign({ fontWeight: "bold" }, { children: "Name*" })), spellCheck: false, value: firstName, onChange: function (e) { return setFirstName(e.target.value); }, marginBottom: 10 }), _jsx(TextInputField, { label: _jsx(Paragraph, __assign({ fontWeight: "bold" }, { children: "Vorname*" })), spellCheck: false, value: lastName, onChange: function (e) { return setLastName(e.target.value); }, marginBottom: 10 }), _jsx(TextInputField, { placeholder: "Name der Apotheke", label: _jsx(Paragraph, __assign({ fontWeight: "bold" }, { children: "Unternehmensname*" })), spellCheck: false, value: companyName, onChange: function (e) { return setCompanyName(e.target.value); }, marginBottom: 10 })] })), _jsx(TextInputField, { label: _jsx(Paragraph, __assign({ fontWeight: "bold" }, { children: "Adresse*" })), placeholder: "Stra\u00DFe und Hausnummer", spellCheck: false, value: customerStreet, onChange: function (e) { return setCustomerStreet(e.target.value); }, marginBottom: 10 }), _jsxs(Pane, __assign({ display: "flex", flexDirection: "column" }, { children: [_jsx(Paragraph, __assign({ marginBottom: 10, fontWeight: "bold" }, { children: "Land*" })), _jsx(Combobox, { marginBottom: 10, width: "100%", selectedItem: customerCountry, items: ["Deutschland", "Österreich", "Schweiz"], onChange: function (selected) {
                            switch (selected) {
                                case "Deutschland":
                                    setCustomerCountry("DE");
                                    break;
                                case "Österreich":
                                    setCustomerCountry("AT");
                                    break;
                                case "Schweiz":
                                    setCustomerCountry("CH");
                                    break;
                                default:
                                    break;
                            }
                        } })] })), _jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(TextInputField, { label: _jsx(Paragraph, __assign({ fontWeight: "bold" }, { children: "PLZ*" })), width: "20%", spellCheck: false, value: customerZip, onChange: function (e) { return setCustomerZip(e.target.value); } }), _jsx(TextInputField, { label: _jsx(Paragraph, __assign({ fontWeight: "bold" }, { children: "Ort*" })), width: "80%", marginLeft: 17, spellCheck: false, value: customerCity, onChange: function (e) { return setCustomerCity(e.target.value); } })] }))] }));
});
