var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Pane, Paragraph } from "evergreen-ui";
export function ContentLayout(_a) {
    var width = _a.width, children = _a.children;
    return (_jsx(Pane, __assign({ style: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            gap: "0.5rem",
            width: width ? width : "33.3%",
            flexWrap: "wrap",
        } }, { children: children })));
}
export function CardContent(_a) {
    var subject = _a.subject, value = _a.value, width = _a.width;
    return (_jsxs(Pane, __assign({ style: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: width ? width : "100%",
            gap: "1rem",
            flexWrap: "wrap",
        } }, { children: [_jsxs(Paragraph, __assign({ fontWeight: "bold" }, { children: [" ", subject, " "] })), _jsx(Paragraph, { children: value })] })));
}
