var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { MaximizeIcon, Tooltip } from "evergreen-ui";
export default function ChangeLayoutButton(_a) {
    var isExpandedView = _a.isExpandedView, setIsExpandedView = _a.setIsExpandedView;
    return (_jsx(Tooltip, __assign({ content: "Layout \u00E4ndern" }, { children: _jsx(MaximizeIcon, { onClick: function () { return setIsExpandedView(!isExpandedView); }, marginLeft: 24, size: 14, style: {
                cursor: "pointer",
            } }) })));
}
