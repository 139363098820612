import endpoint from "./endpoint";
export function sendNewCode(email, firstName) {
    return new Promise(function (res, rej) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({ email_address: email, firstName: firstName });
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        fetch("https://".concat(endpoint(), ".cloudfunctions.net/api/sendVerifyCode"), requestOptions)
            .then(function (response) { return response.json(); })
            .then(function (result) {
            res();
            return;
        })
            .catch(function (err) {
            console.log(err);
            rej(err);
        });
    });
}
