var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
//Chatbubble of BubblePeer
import React from "react";
import { Text } from "evergreen-ui";
export default function ChatBubblePeer(props) {
    return (_jsx("div", __assign({ style: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
        } }, { children: _jsx(Text, __assign({ style: {
                boxShadow: "0 0 1px rgba(0,0,0,0.3)",
                borderRadius: 2,
                paddingTop: 2,
                paddingBottom: 2,
                paddingLeft: 10,
                paddingRight: 10,
                maxWidth: "80%",
                marginBottom: 5,
                backgroundColor: "rgba(115, 93, 208, 0.2)",
            } }, { children: props.msg.deleted ? "Wurde gelöscht" : props.msg.msg })) }), props.msg.key));
}
