var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Pane, Paragraph, Spinner } from "evergreen-ui";
import React from "react";
export default function LoadingPanel() {
    return (_jsx(Pane, __assign({ style: {
            position: "absolute",
            zIndex: 10,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255,255,255,0.6)",
            justifyContent: "center",
            alignItems: "center",
        } }, { children: _jsx(Card, __assign({ elevation: 2, style: {
                position: "absolute",
                top: "50%",
                left: "50%",
                width: 500,
                height: 300,
                transform: "translate(-50%,-50%)",
                backgroundColor: "white",
            } }, { children: _jsxs(Pane, __assign({ style: {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                } }, { children: [_jsx(Spinner, { style: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-100%)",
                        }, size: "large", color: "#49beef" }), _jsx(Paragraph, __assign({ style: {
                            textAlign: "center",
                            fontFamily: "bold",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,30%)",
                        } }, { children: "Optimierung l\u00E4uft..." }))] })) })) })));
}
