var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState } from "react";
export var PaymentContext = createContext(null);
export default function PaymentContextProvider(_a) {
    var children = _a.children;
    //Subscription Dialogs
    var _b = useState(false), locationManagementShown = _b[0], setLocationManagementShown = _b[1];
    var _c = useState(false), quotaManagementShown = _c[0], setQuotaManagementShown = _c[1];
    var _d = useState(false), payMethodeManagementShown = _d[0], setPayMethodeManagementShown = _d[1];
    var _e = useState(0), payMethodeManagementVersion = _e[0], setPayMethodeManagementVersion = _e[1];
    var _f = useState("monthly"), selectedSubscriptionPlan = _f[0], setSelectedSubscriptionPlan = _f[1];
    //For Plan-Overview
    var _g = useState(false), planOverviewShown = _g[0], setPlanOverviewShown = _g[1];
    return (_jsx(PaymentContext.Provider, __assign({ value: {
            locationManagementState: [
                locationManagementShown,
                setLocationManagementShown,
            ],
            payMethodeManagementState: [
                payMethodeManagementShown,
                setPayMethodeManagementShown,
            ],
            payMethodeManagementLayout: [
                payMethodeManagementVersion,
                setPayMethodeManagementVersion,
            ],
            quotaManagementState: [quotaManagementShown, setQuotaManagementShown],
            planOverviewState: [planOverviewShown, setPlanOverviewShown],
            selectedSubscriptionState: [
                selectedSubscriptionPlan,
                setSelectedSubscriptionPlan,
            ],
        } }, { children: children })));
}
