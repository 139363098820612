export default function formatMoney(moneyRaw, unit) {
    var money;
    if (typeof moneyRaw === "number") {
        money = moneyRaw;
    }
    else {
        money = parseFloat(moneyRaw);
    }
    return money.toFixed(2).replace(".", ",") + unit;
}
