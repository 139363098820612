var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useState } from "react";
export function useQueryState(initialState) {
    var _a = useState(null), state = _a[0], _setState = _a[1];
    useEffect(function () {
        // On load, get the query to get the state
        var params = (new URL(document.location.toString())).searchParams;
        var capturedState = __assign({}, initialState);
        params.forEach(function (val, key) {
            try {
                capturedState[key] = JSON.parse(val);
            }
            catch (e) {
                console.log("Key", key, "did not have a valid value");
            }
        });
        // ...and store it in internally managed state for swift access
        _setState(capturedState);
        // Additionally, make sure state and url are consistent, in case initial state was used
        updateUrl(capturedState);
    }, []);
    var updateState = function (newData) {
        // Set internally managed state
        _setState(__assign(__assign({}, state), newData));
        // Set url to be consistent
        // @ts-ignore
        updateUrl(__assign(__assign({}, state), newData));
    };
    // Update the URL without refreshing to persist across reloads
    var updateUrl = function (query) {
        var newURL = new URL(window.location.href);
        newURL.search = '?' + Object.keys(query).filter(function (key) { return query[key] !== undefined; }).map(function (key) { return key + '=' + encodeURIComponent(JSON.stringify(query[key])); }).join('&');
        window.history.pushState({ path: newURL.href }, '', newURL.href);
    };
    // Expose the internal state and the managed update method.
    return [state, updateState];
}
