var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
//This is the main workingspace of the app where you can create tasks
//and tours and overview the process
import { Pane } from "evergreen-ui";
import "firebase/auth";
import "firebase/firestore";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import LoadingPanel from "../components/LoadingPanel";
import Map from "../components/Map";
import firebase from "../config";
import DriverNavBar from "../container/DriverNavBar";
import NavigatorBar from "../container/NavigatorBar";
import TaskBar from "../container/TaskBar";
import TourBar from "../container/TourBar";
import { LayoutContext } from "../contexts/LayoutContext";
import { LoadingPanelContext } from "../contexts/LoadingPanelContext";
import { TaskBarAlertContext } from "../contexts/TaskBarAlertContext";
import { TaskEditContext } from "../contexts/TaskEditContext";
import { UserContext } from "../contexts/UserContext";
// import { EditActionBar } from "../components/Editmode";
export default function Tourplanning() {
    var state = useContext(LayoutContext).state;
    var isEditMode = state[0], setEditMode = state[1];
    var user = useContext(UserContext).user;
    var isUser = user[0];
    var loadingPanelState = useContext(LoadingPanelContext).state;
    var isShown = loadingPanelState[0];
    var _a = useState([]), editTasks = _a[0], setEditTasks = _a[1];
    //Handles Alert-Box over Taskbar
    var _b = useState({
        intent: undefined,
        title: undefined,
        description: undefined,
    }), taskBarAlertBox = _b[0], setTaskBarAlertBox = _b[1];
    useEffect(function () {
        console.log("Change");
        console.log(editTasks);
    }, [editTasks]);
    useEffect(function () {
        if ((isUser === null || isUser === void 0 ? void 0 : isUser.ongoingOptimizationsSet) &&
            moment(isUser === null || isUser === void 0 ? void 0 : isUser.ongoingOptimizationsSet).diff(moment()) === 0) {
            firebase
                .firestore()
                .collection("customerPlans")
                .doc(firebase.auth().currentUser.uid)
                .update({
                ongoingOptimizations: 0,
                ongoingOptimizationsSet: firebase.firestore.FieldValue.delete(),
            });
        }
    }, [isUser]);
    return (_jsx(_Fragment, { children: _jsxs(TaskEditContext.Provider, __assign({ value: { tasks: editTasks, setTasks: setEditTasks } }, { children: [_jsxs(Helmet, { children: [_jsx("link", { rel: "icon", type: "image/png", href: "favicon.ico", sizes: "16x16" }), _jsx("title", { children: "apomap" }), _jsx("script", __assign({ type: "text/javascript", id: "hs-script-loader", async: true, defer: true, src: "https://client.crisp.chat/l.js" }, { children: (window.CRISP_WEBSITE_ID = "92f714e0-5914-465a-adb0-99df6fa015a3") }))] }), isShown && _jsx(LoadingPanel, {}), _jsx(NavigatorBar, {}), _jsx(TaskBarAlertContext.Provider, __assign({ value: [taskBarAlertBox, setTaskBarAlertBox] }, { children: _jsx(TaskBar, {}) })), _jsxs(Pane, __assign({ style: {
                        width: isEditMode ? "30vw" : "70vw",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        padding: 20,
                    } }, { children: [_jsxs(Pane, __assign({ style: {
                                height: "50%",
                                width: "100%",
                                borderRadius: 3,
                                overflow: "hidden",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            } }, { children: [_jsx(Map, {}), _jsx(DriverNavBar, {})] })), _jsx(TourBar, {})] }))] })) }));
}
