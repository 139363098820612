var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { toDDMMYYYY } from "../../formatDate";
export default function taskDeliveryHistogramPreProc(data) {
    if (data.taskDeliveryHistogram === null) {
        throw new Error("Analytics PreProc: Data to process is empty");
    }
    var hist = data.taskDeliveryHistogram;
    var locations = Array.from(new Set(hist.filter(function (he) { return he.location.length > 0; }).map(function (e) { return e.location; }).reduce(function (accu, curr) {
        return __spreadArray(__spreadArray([], accu, true), curr.map(function (c) { return c.name; }), true);
    }, [])));
    var bars = locations.map(function (lid) { return [lid]; });
    var points = hist.map(function (he) {
        var histElemData = {
            name: toDDMMYYYY(he.date * 1000),
        };
        for (var _i = 0, _a = he.location; _i < _a.length; _i++) {
            var loc = _a[_i];
            histElemData[loc.name] = loc.delivery + loc.pickup;
        }
        return histElemData;
    });
    var pieData = locations.map(function (loc) {
        return {
            name: loc,
            value: hist.reduce(function (accu, curr) {
                var targetLocationInd = curr.location.findIndex(function (l) { return l.name === loc; });
                if (targetLocationInd === -1) {
                    return accu;
                }
                return accu + curr.location[targetLocationInd].delivery + curr.location[targetLocationInd].pickup;
            }, 0)
        };
    });
    return {
        bar: {
            bars: bars,
            data: points
        },
        pie: {
            data: pieData
        }
    };
}
