var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
var COLORS = [
    '#FFB758',
    '#7B61FF',
    '#49BEEF',
    '#2EB67D',
    '#53D7CD',
    '#F12121'
];
var StatsBarChart = function (props) {
    var _a;
    return (_jsx(ResponsiveContainer, __assign({ width: "100%", height: "100%" }, { children: _jsxs(BarChart, __assign({ width: 1300, height: 450, data: props.data.bar.data, margin: {
                top: 20,
                right: 30,
                left: 20,
                bottom: 5
            } }, { children: [_jsx(CartesianGrid, { strokeDasharray: "2 2" }), _jsx(XAxis, { dataKey: "name" }), _jsx(YAxis, { unit: (typeof ((_a = props.data.meta) === null || _a === void 0 ? void 0 : _a.unit) === "string") ? props.data.meta.unit : "" }), _jsx(Tooltip, { formatter: function (l) { var _a; return l.toString() + ((typeof ((_a = props.data.meta) === null || _a === void 0 ? void 0 : _a.unit) === "string") ? props.data.meta.unit : ""); } }), _jsx(Legend, {}), props.data.bar.bars.map(function (bar, index) {
                    var _a;
                    return (_jsx(Bar, { dataKey: bar[0], stackId: "a", fill: (_a = bar[1]) !== null && _a !== void 0 ? _a : COLORS[index % COLORS.length] }, bar[0]));
                })] })) })));
};
export default StatsBarChart;
