var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { Pane, Popover, Menu, Heading, CrossIcon, Button, Dialog, TextInputField, Position, FilePicker, toaster, Paragraph, ThemeProvider, } from "evergreen-ui";
import firebase from "../config";
import { createId } from "../utility/utility";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import ApoLocationInput from "./ApoLocationInput";
import theme from "../theme";
import { ColorPickerButton } from "./Interface/Common/ColorPickerButton";
import { CustomAvatar } from "./Interface/Common/CustomAvatar";
var storageRef = firebase.storage().ref();
var apiToken = "pk.eyJ1IjoiYXBvbWFwc29mdHdhcmVkZXYiLCJhIjoiY2s4dzJlYnM4MDU5cjNlbnc5bjlhbTN1NSJ9.2MVbscPKHtJSJVcLmThq2Q";
var labelValuePair = {
    aposoft: "aposoft",
    adg_s3000: "ADG S3000",
    adg_a3000: "ADG A3000",
    adv_gavis: "ADV GAWIS ",
    awinta_infopharm: "Awinta Infopharm",
    awinta_prokas: "Awinta Prokas",
    awinta_jump: "Awinta Jump",
    awinta_one: "Awinta One",
    cida_nova_plus: "Cida Nova Plus",
    pharmatechnik: "Pharmatechnik IXOS",
    cgm_lauer: "CGM Lauer",
    deos_secret: "Deos Secret",
    others: "Andere",
};
export default function LocationDialog(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var _j = useState(props.location ? props.location.name : null), isLocationName = _j[0], setLocationName = _j[1];
    var _k = useState(((_a = props.location) === null || _a === void 0 ? void 0 : _a.idf) ? props.location.idf : null), isLocationIdf = _k[0], setLocationIdf = _k[1];
    var _l = useState(((_b = props.location) === null || _b === void 0 ? void 0 : _b.paypal) ? props.location.paypal : null), isLocationPaypal = _l[0], setLocationPaypal = _l[1];
    var _m = useState(((_c = props.location) === null || _c === void 0 ? void 0 : _c.image) ? props.location.image : null), isImageUrl = _m[0], setImageUrl = _m[1];
    var _o = useState(null), isImageFile = _o[0], setImageFile = _o[1];
    var _p = useState(((_d = props.location) === null || _d === void 0 ? void 0 : _d.type) ? props.location.type : null), isLocationType = _p[0], setLocationType = _p[1];
    var _q = useState((_f = (_e = props.location) === null || _e === void 0 ? void 0 : _e.color) !== null && _f !== void 0 ? _f : null), isLocationColor = _q[0], setIsLocationColor = _q[1];
    var _r = useState(((_g = props.location) === null || _g === void 0 ? void 0 : _g.system)
        ? {
            label: labelValuePair[props.location.system],
            value: props.location.system,
        }
        : null), isSystemName = _r[0], setSystemName = _r[1];
    //LocationPicker
    var _s = useState([]), areLocations = _s[0], setLocations = _s[1];
    var _t = useState(((_h = props.location) === null || _h === void 0 ? void 0 : _h.address)
        ? { address: props.location.address, location: props.location.coords }
        : null), isSelected = _t[0], setSelected = _t[1];
    var _u = useState([]), isSelection = _u[0], setSelection = _u[1];
    //Loading state
    var _v = useState(false), isLoading = _v[0], setLoading = _v[1];
    function searchLocations(query) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        // ['Apple', 'Apricot', 'Banana', 'Cherry', 'Cucumber'].map((label) => ({ label, value: label }))
        fetch("https://api.mapbox.com/geocoding/v5/mapbox.places/".concat(query, ".json?country=de&language=de&types=locality%2Cplace%2Caddress%2Cpostcode&access_token=").concat(apiToken), requestOptions)
            .then(function (response) { return response.json(); })
            .then(function (result) {
            var hints = [];
            result.features.map(function (loc) {
                var _a, _b, _c;
                var locality = (_b = (_a = loc.context.filter(function (_a) {
                    var id = _a.id;
                    return id.split(".")[0] === "locality";
                })[0]) === null || _a === void 0 ? void 0 : _a.text) !== null && _b !== void 0 ? _b : "";
                var obj = {
                    label: (_c = loc.place_name + ", " + locality) !== null && _c !== void 0 ? _c : "",
                    value: loc.center,
                };
                hints.push(obj);
            });
            setLocations(hints);
        })
            .catch(function (error) { return console.log("error", error); });
    }
    function addItemToSelection(item) {
        setSelected(item);
        setSelection(function (state) {
            var newState = __spreadArray([], state, true);
            newState.push(item);
            return newState;
        });
    }
    function uploadFileAndGetUrl(file, id) {
        return new Promise(function (resolve, reject) {
            storageRef
                .child("location_image_".concat(id))
                .put(file)
                .then(function (snapshot) {
                snapshot.ref.getDownloadURL().then(function (url) {
                    resolve(url);
                });
            })
                .catch(function (err) { return reject(err); });
        });
    }
    function addLocation() {
        return __awaiter(this, void 0, void 0, function () {
            var isImageUrlInCloud, id, locationObj;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!isSystemName) {
                            toaster.warning("Bitte geben Sie das an diesem Standort verwendete Kassensystem an!");
                            return [2 /*return*/];
                        }
                        if (!isLocationName) {
                            toaster.warning("Bitte geben Sie den Namen des Standortes an!");
                            return [2 /*return*/];
                        }
                        // if(!isLocationIdf){
                        //     toaster.warning("Bitte geben Sie die IDF des Standortes an!")
                        //     return;
                        // }
                        if (!isSelected) {
                            toaster.warning("Bitte geben Sie die Adresse des Standortes an!");
                            return [2 /*return*/];
                        }
                        if (!isLocationType) {
                            toaster.warning("Bitte geben Sie den Standort-Typ an!");
                            return [2 /*return*/];
                        }
                        isImageUrlInCloud = null;
                        id = createId();
                        if (!isImageUrl) return [3 /*break*/, 2];
                        return [4 /*yield*/, uploadFileAndGetUrl(isImageFile, id)];
                    case 1:
                        isImageUrlInCloud = _a.sent();
                        _a.label = 2;
                    case 2:
                        locationObj = {
                            owner: firebase.auth().currentUser.uid,
                            image: isImageUrlInCloud,
                            id: id,
                            system: isSystemName.value,
                            name: isLocationName,
                            idf: isLocationIdf,
                            address: isSelected.address,
                            coords: isSelected.location,
                            paypal: isLocationPaypal,
                            type: isLocationType,
                            archived: false,
                            color: isLocationColor,
                        };
                        return [4 /*yield*/, firebase.firestore().collection("locations").doc(id).set(locationObj)];
                    case 3:
                        _a.sent();
                        props.setShown(false);
                        return [2 /*return*/];
                }
            });
        });
    }
    function processImage(files) {
        if (files[0].type === "image/jpeg" || files[0].type === "image/png") {
            setImageFile(files[0]);
            setImageUrl(URL.createObjectURL(files[0]));
        }
        else {
            toaster.warning("Bitte laden Sie eine JPEG/PNG-Datei hoch!");
        }
    }
    function deleteLocation() {
        setLoading(true);
        firebase
            .firestore()
            .collection("tasks")
            .where("task_origin", "==", props.location.id)
            .get()
            .then(function (snap) {
            var tasksOfLocation = [];
            snap.forEach(function (doc) {
                var data = doc.data();
                tasksOfLocation.push(data);
            });
            if (tasksOfLocation.length > 0) {
                setLoading(false);
                toaster.notify("Mit diesem Standort sind Aufträge verbunden und kann daher nicht mehr gelöscht werden!");
                return;
            }
            else {
                firebase
                    .firestore()
                    .collection("locations")
                    .doc(props.location.id)
                    .delete();
                setLoading(false);
                props.setShown(false);
                toaster.success("Der Standort wurde erfolgreich gelöscht");
            }
        });
    }
    function saveChanges() {
        return __awaiter(this, void 0, void 0, function () {
            var isImageUrlInCloud;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setLoading(true);
                        isImageUrlInCloud = null;
                        if (!(isImageFile !== null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, uploadFileAndGetUrl(isImageFile, props.location.id)];
                    case 1:
                        isImageUrlInCloud = _a.sent();
                        _a.label = 2;
                    case 2:
                        firebase
                            .firestore()
                            .collection("locations")
                            .doc(props.location.id)
                            .update({
                            image: isImageUrlInCloud
                                ? isImageUrlInCloud
                                : props.location.image
                                    ? props.location.image
                                    : null,
                            system: isSystemName.value,
                            name: isLocationName,
                            idf: isLocationIdf,
                            address: isSelected.address,
                            coords: isSelected.location,
                            paypal: isLocationPaypal,
                            type: isLocationType,
                            color: isLocationColor,
                        })
                            .then(function () {
                            setLoading(false);
                            props.setShown(false);
                            toaster.notify("Änderungen erfolgreich gespeichert");
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    var _w = useState(false), kassensystemSelectorOpen = _w[0], setKSSOpen = _w[1];
    var _x = useState(false), locationTypeSelectorOpen = _x[0], setLTSOpen = _x[1];
    // * Event Handlers
    var handleSetKassenSystem = function (kassesystem) {
        setSystemName({
            label: "".concat(labelValuePair[kassesystem]),
            value: "".concat(kassesystem),
        });
        setKSSOpen(false);
    };
    return (_jsx(ThemeProvider, __assign({ value: theme }, { children: _jsx(Dialog, __assign({ isShown: props.isShown, hasHeader: false, hasFooter: false, shouldCloseOnOverlayClick: false, shouldCloseOnEscapePress: false, contentContainerProps: {
                padding: 20,
                borderRadius: 15,
                overflow: "hidden",
            } }, { children: _jsxs(Pane, __assign({ style: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "flex-start",
                    gap: "16px",
                    overflow: "hidden",
                } }, { children: [_jsxs(Pane, __assign({ style: {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            marginBottom: "32px",
                        } }, { children: [_jsx(Heading, __assign({ className: "headline", style: { color: "#2D3B51", fontSize: 24 } }, { children: props.location ? "Standort bearbeiten" : "Standort hinzufügen" })), _jsx(CrossIcon, { style: { cursor: "pointer" }, onClick: function () { return props.setShown(false); } })] })), _jsxs(Pane, __assign({ display: "flex", alignItems: "flex-end", style: { marginBottom: "12px" } }, { children: [_jsx(CustomAvatar, { name: isLocationName, src: isImageUrl, size: 80, color: isLocationColor }), _jsx(FilePicker, { marginLeft: 16, multiple: false, width: 250, onChange: function (files) { return processImage(files); } })] })), _jsxs(Pane, __assign({ style: {
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            width: "100%",
                            gap: "32px",
                        } }, { children: [_jsx(Pane, __assign({ width: "60%", display: "flex", flexDirection: "column" }, { children: _jsx(TextInputField, { description: "Standortname*", onChange: function (e) { return setLocationName(e.target.value); }, value: isLocationName, width: "100%", marginBottom: 10 }) })), _jsxs(Pane, __assign({ style: {
                                    width: "35%",
                                } }, { children: [_jsx(Paragraph, { children: "Standortart w\u00E4hlen*" }), _jsx(Popover, __assign({ isShown: locationTypeSelectorOpen, position: Position.BOTTOM_LEFT, content: _jsx(Menu, { children: _jsxs(Menu.Group, __assign({ title: "Standortarten" }, { children: [_jsx(Menu.Item, __assign({ onClick: function () {
                                                            setLocationType("main");
                                                            setLTSOpen(false);
                                                        } }, { children: "Hauptstandort" })), _jsx(Menu.Item, __assign({ onClick: function () {
                                                            setLocationType("branch");
                                                            setLTSOpen(false);
                                                        } }, { children: "Nebenstandort" }))] })) }) }, { children: _jsx("div", { style: { opacity: 0 } }) })), _jsx(Button
                                    // marginRight={16}
                                    // marginTop={5}
                                    , __assign({ 
                                        // marginRight={16}
                                        // marginTop={5}
                                        onClick: function () { return setLTSOpen(function (a) { return !a; }); } }, { children: isLocationType === "main"
                                            ? "Hauptstandort"
                                            : isLocationType === "branch"
                                                ? "Nebenstandort"
                                                : "Hier wählen" }))] }))] })), _jsxs(Pane, __assign({ style: {
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            width: "100%",
                            gap: "32px",
                        } }, { children: [_jsx(ApoLocationInput, { label: "Adresse", required: true, defaultValue: isSelected, onChange: function (e) {
                                    return setSelected({
                                        address: e.address,
                                        location: e.coords,
                                    });
                                }, style: { width: "65%" } }), _jsxs(Pane, __assign({ style: { width: "35%" } }, { children: [_jsx(Paragraph, { children: "Kassensystem*" }), _jsx(Popover, __assign({ isShown: kassensystemSelectorOpen, position: Position.BOTTOM_LEFT, content: _jsx(Menu, { children: _jsx(Menu.Group, __assign({ title: "Kassensystem" }, { children: Object.keys(labelValuePair).map(function (kassesystem) {
                                                    return (_jsx(Menu.Item, __assign({ onClick: function () { return handleSetKassenSystem(kassesystem); } }, { children: labelValuePair[kassesystem] })));
                                                }) })) }) }, { children: _jsx("div", { style: { opacity: 0 } }) })), _jsx(Button, __assign({ label: "hui", 
                                        // marginRight={16}
                                        // marginTop={5}
                                        onClick: function () {
                                            console.log("Click!");
                                            setKSSOpen(function (a) { return !a; });
                                        } }, { children: (isSystemName === null || isSystemName === void 0 ? void 0 : isSystemName.label)
                                            ? isSystemName.label
                                            : "Kassensystem wählen" }))] }))] })), _jsxs(Pane, __assign({ style: {
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            width: "100%",
                        } }, { children: [_jsxs(Pane, __assign({ style: {
                                    display: "flex",
                                    flexDirection: "column",
                                    // marginTop: "1rem",
                                } }, { children: [_jsx(Paragraph, __assign({ marginBottom: 5 }, { children: "Standortfarbe w\u00E4hlen" })), _jsx(ColorPickerButton, { color: isLocationColor, onClick: function (color) { return setIsLocationColor(color); } })] })), props.location ? (_jsx(Pane, { children: _jsx(Button, __assign({ isLoading: isLoading, intent: "success", marginLeft: 20, onClick: function () { return saveChanges(); } }, { children: "Speichern" })) })) : (_jsx(_Fragment, { children: _jsx(Button, __assign({ isLoading: isLoading, appearance: "primary", onClick: function () { return addLocation(); } }, { children: "Erstellen" })) }))] }))] })) })) })));
}
