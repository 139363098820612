var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useEffect, useState } from "react";
import firebase from "../config";
var database = firebase
    .app()
    .database("https://apomapdreinull-default-rtdb.europe-west1.firebasedatabase.app/");
export var NotificationContext = createContext(null);
export default function NotificationContextProvider(_a) {
    var children = _a.children;
    //Store for Notifications
    var _b = useState({}), notifications = _b[0], setNotifications = _b[1];
    useEffect(function () {
        database
            .ref("notifications_".concat(firebase.auth().currentUser.uid))
            .on("value", function (snapshot) {
            if (snapshot.val()) {
                // convert messages list from snapshot
                var notificationList_1 = {};
                snapshot.forEach(function (childSnapshot) {
                    notificationList_1[childSnapshot.key] = __assign(__assign({}, childSnapshot.val()), { uid: childSnapshot.key });
                });
                setNotifications(notificationList_1);
            }
        });
        database
            .ref("notifications_".concat(firebase.auth().currentUser.uid))
            .on("child_added", function (snapshot) {
            setNotifications(function (state) {
                var newState = __assign({}, state);
                newState[snapshot.key] = __assign(__assign({}, snapshot.val()), { uid: snapshot.key });
                return newState;
            });
        });
        database
            .ref("notifications_".concat(firebase.auth().currentUser.uid))
            .on("child_removed", function (snapshot) {
            setNotifications(function (state) {
                var newState = __assign({}, state);
                delete newState[snapshot.key];
                return newState;
            });
        });
    }, []);
    return (_jsx(NotificationContext.Provider, __assign({ value: { notifications: [notifications, setNotifications] } }, { children: children })));
}
