var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { toDDMMYYYY } from "../../formatDate";
import computeTimespanDuration from "../../computeTimespanDuration";
export function driverDutyHistogramDistPreProc(data) {
    if (data.driverDutyHistogram === null) {
        throw new Error("Analytics PreProc: Data to process is empty");
    }
    var hist = data.driverDutyHistogram;
    var drivers = Array.from(new Set(hist.map(function (h) { var _a; return (_a = h.driverId) !== null && _a !== void 0 ? _a : null; }).filter(function (e) { return e !== null; })));
    var driverInfo = hist.reduce(function (accu, curr) {
        var _a;
        var _b, _c, _d, _e;
        if (!curr.driverId)
            return accu;
        if (accu[curr.driverId] !== undefined)
            return accu;
        return __assign((_a = {}, _a[curr.driverId] = {
            name: (_b = curr.driverName) !== null && _b !== void 0 ? _b : "Name nicht verfügbar",
            color: (_c = curr.color) !== null && _c !== void 0 ? _c : "#45beef",
            kilometer: (_e = (_d = curr.individualKilometer) === null || _d === void 0 ? void 0 : _d.value) !== null && _e !== void 0 ? _e : 0,
        }, _a), accu);
    }, {});
    var bars = drivers.map(function (e) { return [
        driverInfo[e].name,
        driverInfo[e].color,
    ]; });
    var points = hist.reduce(function (accu, he) {
        var _a, _b;
        var newAccu = __spreadArray([], accu, true);
        var datapointIndex = newAccu.findIndex(function (a) { return a.name == toDDMMYYYY(he.date * 1000); });
        if (datapointIndex === -1) {
            datapointIndex =
                newAccu.push({
                    name: toDDMMYYYY(he.date * 1000),
                }) - 1;
        }
        var histElemData = newAccu[datapointIndex];
        if (he.driverId) {
            histElemData[driverInfo[he.driverId].name] = parseFloat((((_b = (_a = he.individualKilometer) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : 0) / 1000).toFixed(1));
        }
        return newAccu;
    }, []);
    var pieData = drivers.map(function (drv) {
        return {
            name: driverInfo[drv].name,
            value: parseFloat((hist.reduce(function (accu, curr) {
                if (curr.driverId === drv) {
                    return accu + curr.kilometerPlaned;
                }
                return accu;
            }, 0) / 1000).toFixed(1)),
            color: driverInfo[drv].color,
        };
    });
    return {
        bar: {
            bars: bars,
            data: points,
        },
        pie: {
            data: pieData,
        },
        meta: {
            unit: "km",
        },
    };
}
export function driverDutyHistogramTimePreProc(data) {
    if (data.driverDutyHistogram === null) {
        throw new Error("Analytics PreProc: Data to process is empty");
    }
    var hist = data.driverDutyHistogram;
    var drivers = Array.from(new Set(hist.map(function (h) { var _a; return (_a = h.driverId) !== null && _a !== void 0 ? _a : null; }).filter(function (e) { return e !== null; })));
    var driverInfo = hist.reduce(function (accu, curr) {
        var _a;
        var _b, _c, _d;
        if (!curr.driverId)
            return accu;
        if (accu[curr.driverId] !== undefined)
            return accu;
        return __assign((_a = {}, _a[curr.driverId] = {
            name: (_b = curr.driverName) !== null && _b !== void 0 ? _b : "Name nicht verfügbar",
            color: curr.color,
            kilometer: (_d = (_c = curr === null || curr === void 0 ? void 0 : curr.individualKilometer) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : 0,
        }, _a), accu);
    }, {});
    var bars = drivers.map(function (e) { return [
        driverInfo[e].name,
        driverInfo[e].color,
    ]; });
    var points = hist.reduce(function (accu, he) {
        var newAccu = __spreadArray([], accu, true);
        var datapointIndex = newAccu.findIndex(function (a) { return a.name == toDDMMYYYY(he.date * 1000); });
        if (datapointIndex === -1) {
            datapointIndex =
                newAccu.push({
                    name: toDDMMYYYY(he.date * 1000),
                }) - 1;
        }
        var histElemData = newAccu[datapointIndex];
        if (he.driverId && he.startTime && he.endTime) {
            histElemData[driverInfo[he.driverId].name] = computeTimespanDuration(he.startTime, he.endTime);
        }
        return newAccu;
    }, []);
    var pieData = drivers.map(function (drv) {
        return {
            name: driverInfo[drv].name,
            value: hist.reduce(function (accu, curr) {
                if (curr.driverId === drv && curr.startTime && curr.endTime) {
                    return accu + computeTimespanDuration(curr.startTime, curr.endTime);
                }
                return accu;
            }, 0),
            color: driverInfo[drv].color,
        };
    });
    return {
        bar: {
            bars: bars,
            data: points,
        },
        pie: {
            data: pieData,
        },
        meta: {
            unit: "min",
        },
    };
}
