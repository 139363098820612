import { toDDMMYYYY } from "../../formatDate";
export default function tourCreatedHistogramPreProc(data) {
    if (data.tourCreatedHistogram === null) {
        throw new Error("Analytics PreProc: Data to process is empty");
    }
    var hist = data.tourCreatedHistogram;
    var bars = [["Geplante Touren"], ["Geplante und durchgeführte Touren"]];
    var points = hist.map(function (he) {
        var completedTours = he.tourIds.reduce(function (accu, curr) {
            if (curr.count === curr.successfullTasks.value + curr.declinedTasks.value) {
                return accu + 1;
            }
            return accu;
        }, 0);
        var histElemData = {
            name: toDDMMYYYY(he.date * 1000),
            "Geplante Touren": he.count - completedTours,
            "Geplante und durchgeführte Touren": completedTours
        };
        return histElemData;
    });
    var pieData = [
        {
            name: "Geplante Touren",
            value: points.reduce(function (accu, curr) {
                var count = curr["Geplante Touren"];
                if (!count || typeof count !== "number") {
                    count = 0;
                }
                return accu + count;
            }, 0)
        },
        {
            name: "Geplante und durchgeführte Touren",
            value: points.reduce(function (accu, curr) {
                var count = curr["Geplante und durchgeführte Touren"];
                if (!count || typeof count !== "number") {
                    count = 0;
                }
                return accu + count;
            }, 0)
        }
    ];
    return {
        bar: {
            bars: bars,
            data: points
        },
        pie: {
            data: pieData
        }
    };
}
