var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Pane } from "evergreen-ui";
//@ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import { useCallback, useEffect } from "react";
import { useDocumentation } from "./utils";
export var CommandBar = function (_a) {
    var children = _a.children;
    var _b = useDocumentation(), commandBarOpen = _b.commandBarOpen, toggleCommandBar = _b.toggleCommandBar;
    var handleKeyPress = useCallback(function (event) {
        if (event.key === "Escape" && commandBarOpen)
            toggleCommandBar();
        if (event.metaKey === true || event.ctrlKey) {
            if (event.key === "k")
                toggleCommandBar();
        }
    }, [commandBarOpen, toggleCommandBar]);
    useEffect(function () {
        // attach the event listener
        document.addEventListener("keydown", handleKeyPress);
        // remove the event listener
        return function () {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress]);
    if (!commandBarOpen)
        return null;
    return (_jsxs(Pane, __assign({ style: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 99,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        } }, { children: [_jsx(motion.div, { initial: { opacity: 0 }, animate: {
                    opacity: commandBarOpen ? 0.2 : 0,
                }, transition: { duration: 0.2 }, style: {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "black",
                    zIndex: 99,
                    display: commandBarOpen ? "flex" : "none",
                } }), _jsx(motion.div, __assign({ initial: { opacity: 0, y: -100 }, animate: {
                    y: commandBarOpen ? -50 : -100,
                    opacity: commandBarOpen ? 1 : 0,
                }, transition: { duration: 0.2 }, style: {
                    position: "absolute",
                    zIndex: 100,
                } }, { children: children }))] })));
};
