var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Pane, Paragraph } from "evergreen-ui";
import { useMemo } from "react";
import { normaliseMoneyString } from "../utility/normaliseMoneyString";
import LocationLabel from "./LocationLabel";
import TourAccountingLayoutItem from "./TourAccountingLayoutItem";
export default function TourAccountingLayoutSection(_a) {
    // TODO: Unpaid-Once must be also shown here
    var id = _a.id, tasks = _a.tasks, tour = _a.tour, payedTaskIds = _a.payedTaskIds;
    var taskIds = useMemo(function () { return tasks.map(function (t) { return t.task_id; }); }, [tasks]);
    var relevantEntries = useMemo(function () { return tour.moneyStore.filter(function (e) { return taskIds.includes(e.id); }); }, [taskIds, tour.moneyStore]);
    var taskIdsNotPayed = useMemo(function () { return tour.successfullTasks.filter(function (id) { return !payedTaskIds.includes(id); }); }, [payedTaskIds, tour.successfullTasks]);
    var totalAmount = useMemo(function () { return relevantEntries.reduce(function (a, b) { return a + b.amount; }, 0); }, [relevantEntries]);
    var cardAmount = useMemo(function () {
        return relevantEntries
            .filter(function (e) { return tour.cardAmountArray.includes(e.id); })
            .reduce(function (a, b) { return a + b.amount; }, 0);
    }, [relevantEntries, tour]);
    var cashAmount = useMemo(function () {
        return relevantEntries
            .filter(function (e) { return tour.cashAmountArray.includes(e.id); })
            .reduce(function (a, b) { return a + b.amount; }, 0);
    }, [relevantEntries, tour]);
    return (_jsxs(_Fragment, { children: [_jsxs(Pane, __assign({ style: {
                    marginTop: 20,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    border: "1px solid black",
                } }, { children: [_jsx(LocationLabel, { id: id }), _jsxs(Paragraph, __assign({ style: { marginLeft: 12 } }, { children: ["Bar: ", normaliseMoneyString(cashAmount)] })), _jsxs(Paragraph, __assign({ style: { marginLeft: 12 } }, { children: ["Karte: ", normaliseMoneyString(cardAmount)] })), _jsxs(Paragraph, __assign({ style: { marginLeft: 12 } }, { children: ["Gesamt: ", normaliseMoneyString(totalAmount)] }))] })), _jsxs("table", __assign({ style: { backgroundColor: "white" }, pageBreakBefore: "always" }, { children: [_jsxs("tr", { children: [_jsx("th", { children: "Name" }), _jsx("th", { children: "Apotheke" }), _jsx("th", { children: "Status" }), _jsx("th", { children: "Hinweis (Bote)" }), _jsx("th", { children: "Zahlart" }), _jsx("th", { children: "Betrag" }), _jsx("th", { children: "Botendienstbelegnummer" })] }), tasks.map(function (task) { return (_jsx(TourAccountingLayoutItem, { isCash: tour.cashAmountArray.includes(task.task_id), task: task, tasks: tasks, taskIdsNotPayed: taskIdsNotPayed })); })] }))] }));
}
