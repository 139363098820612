var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Heading, Pane, Paragraph, SearchIcon, ThemeProvider, Tooltip, } from "evergreen-ui";
import moment from "moment";
import { useEffect, useState } from "react";
import { DocumentationBar, DocumentationTable, } from "../components/Documentation";
import DateRangePicker from "../components/Documentation/DateRangePicker";
import DriverSelect from "../components/Documentation/DriverSelect";
import { useDocumentation } from "../components/Documentation/utils";
import { TourReportButton } from "../components/TableSpacer";
import NavigatorBar from "../container/NavigatorBar";
import { shouldDisplayWithPlan } from "../hocs/withPaywall";
import { useQueryState } from "../hooks/useQueryState";
import theme from "../theme";
import ignoreTime from "../utility/ignoreTime";
import { usePlanInfo } from "../utility/use-plan-info";
import Loading from "./Loading";
var infoParagraph = {
    width: "80%",
    lineHeight: 1.25,
    marginTop: 6,
    marginBottom: 14,
};
var today = ignoreTime(new Date());
var oneWeekAgo = ignoreTime(moment().subtract(7, "days").toDate());
function Documentation() {
    var _a;
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useQueryState({
        tourId: undefined,
        driverId: undefined,
        start: undefined,
        end: undefined,
    }), queryData = _c[0], setQueryData = _c[1];
    var _d = useState([]), filterDrivers = _d[0], setFilterDrivers = _d[1];
    var _e = useDocumentation(), getDocumentation = _e.getDocumentation, toggleCommandBar = _e.toggleCommandBar;
    var currentPlan = usePlanInfo().currentPlan;
    useEffect(function () {
        if (queryData && filterDrivers) {
            setLoading(true);
            getDocumentation({
                startDate: (queryData === null || queryData === void 0 ? void 0 : queryData.start)
                    ? moment(queryData.start).format("YYYY-MM-DD")
                    : moment().add(-7, "days").format("YYYY-MM-DD"),
                endDate: (queryData === null || queryData === void 0 ? void 0 : queryData.end)
                    ? moment(queryData.end).add(1, "day").format("YYYY-MM-DD")
                    : moment().add(1, "day").format("YYYY-MM-DD"),
                driverIds: filterDrivers ? filterDrivers : [],
            }).finally(function () { return setLoading(false); });
        }
    }, [queryData, filterDrivers]);
    // Get driver on load
    useEffect(function () {
        if (filterDrivers.length === 0 && (queryData === null || queryData === void 0 ? void 0 : queryData.driverId)) {
            setFilterDrivers([queryData.driverId]);
        }
    }, []);
    if (!queryData) {
        return _jsx(Loading, {});
    }
    return (_jsx(ThemeProvider
    /* @ts-ignore */
    , __assign({ 
        /* @ts-ignore */
        value: theme }, { children: _jsxs(Pane, __assign({ style: {
                display: "flex",
                flexDirection: "row",
                overflow: "hidden",
            } }, { children: [_jsx(DocumentationBar, {}), _jsx(NavigatorBar, {}), _jsxs(Pane, __assign({ elevation: 2, style: {
                        padding: 25,
                        margin: 25,
                        width: "90vw",
                        overflow: "hidden",
                    } }, { children: [_jsxs("div", __assign({ style: { display: "flex", flexDirection: "row" } }, { children: [_jsxs("div", __assign({ style: { flex: 1 } }, { children: [_jsx(Heading, __assign({ size: 900, style: { marginBottom: 3 } }, { children: "Dokumentation" })), _jsx(Heading, __assign({ size: 700, style: { color: "#000000", marginBottom: 18 } }, { children: "Erhalte detaillierte Einblicke in deine ausgelieferten Touren" }))] })), _jsx("div", { children: queryData.start ? (_jsx(TourReportButton, { title: queryData.start === queryData.end
                                            ? "Tagesübersicht"
                                            : "Übersicht", startDate: moment(queryData.start).format("YY-MM-DD"), endDate: moment(queryData.end).format("YY-MM-DD"), disabled: false })) : (
                                    // @ts-ignore
                                    _jsx(Tooltip, __assign({ content: "W\u00E4hle zuerst einen Zeitraum aus" }, { children: _jsx(Pane, { children: _jsx(TourReportButton, { title: queryData.start === queryData.end
                                                    ? "Tagesübersicht"
                                                    : "Übersicht", startDate: moment(queryData.start).format("YY-MM-DD"), endDate: moment(queryData.end).format("YY-MM-DD"), disabled: true }) }) }))) })] })), ((_a = shouldDisplayWithPlan("apomap-plus", currentPlan)) !== null && _a !== void 0 ? _a : false) ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ style: { display: "flex", flexDirection: "row" } }, { children: [_jsxs("div", __assign({ style: { flex: 1 } }, { children: [_jsx(Heading, __assign({ size: 600, style: { color: "#474D66" } }, { children: "Fahrer*in" })), _jsx(Paragraph, __assign({ style: infoParagraph }, { children: "W\u00E4hle einen oder mehrere Fahrer*innen und lasse dir Ihre Touren anzeigen" }))] })), _jsxs("div", __assign({ style: { flex: 1 } }, { children: [_jsx(Heading, __assign({ size: 600, style: { color: "#474D66" } }, { children: "Datum & Zeitraum" })), _jsx(Paragraph, __assign({ style: infoParagraph }, { children: "W\u00E4hle einen Zeitraum, den du betrachten m\u00F6chtest" }))] })), _jsxs("div", __assign({ style: { flex: 1 } }, { children: [_jsx(Heading, __assign({ size: 600, style: { color: "#474D66" } }, { children: "Auftragssuche" })), _jsx(Paragraph, __assign({ style: infoParagraph }, { children: "Der schnellste Weg zu dem gesuchten Auftrag" }))] }))] })), _jsxs("div", __assign({ style: { display: "flex", flexDirection: "row" } }, { children: [_jsx("div", __assign({ style: { flex: 1 } }, { children: _jsx("div", __assign({ style: { width: "80%" } }, { children: _jsx(DriverSelect, { onChange: setFilterDrivers }) })) })), _jsx("div", __assign({ style: { flex: 1 } }, { children: _jsx("div", __assign({ style: { width: "80%" } }, { children: _jsx(DateRangePicker, { initialState: {
                                                        start: (queryData === null || queryData === void 0 ? void 0 : queryData.start)
                                                            ? new Date(queryData.start)
                                                            : oneWeekAgo,
                                                        end: (queryData === null || queryData === void 0 ? void 0 : queryData.end) ? new Date(queryData.end) : today,
                                                    }, onChange: function (startDate, endDate) {
                                                        var _a, _b;
                                                        setQueryData({
                                                            start: (_a = startDate === null || startDate === void 0 ? void 0 : startDate.toISOString()) !== null && _a !== void 0 ? _a : undefined,
                                                            end: (_b = endDate === null || endDate === void 0 ? void 0 : endDate.toISOString()) !== null && _b !== void 0 ? _b : undefined,
                                                        });
                                                    } }) })) })), _jsx("div", __assign({ style: { flex: 1 } }, { children: _jsx("div", __assign({ style: { width: "80%" } }, { children: _jsx("div", __assign({ style: {
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    } }, { children: _jsx(Button, __assign({ display: "flex", flex: 1, iconBefore: SearchIcon, onClick: function () { return toggleCommandBar(); } }, { children: "Suche \u00F6ffnen | \u2318K" })) })) })) }))] }))] })) : (_jsxs("div", __assign({ style: {
                                backgroundColor: "rgb(226,229,234)",
                                marginBottom: 10,
                                paddingLeft: 30,
                                paddingRight: 30,
                                paddingTop: 20,
                                paddingBottom: 20,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            } }, { children: [_jsxs("div", __assign({ style: { flex: 1 } }, { children: [_jsx(Heading, __assign({ style: { color: "#3f4356" } }, { children: "Filtere nach Fahrer*innen, Zeitr\u00E4umen, Namen und Adressen" })), _jsx(Paragraph, { children: "Mit apomap Plus oder Pro erh\u00E4ltst du noch genauere Einblicke in deinen Botendienst." })] })), _jsx(Button, __assign({ appearance: "primary", onClick: function () { return (window.location.href = "/upgrade"); } }, { children: "Jetzt upgraden" }))] }))), _jsx(DocumentationTable, { loading: loading })] }))] })) })));
}
export default Documentation;
