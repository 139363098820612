var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { AddIcon, Button, PlusIcon } from "evergreen-ui";
import DriverDialog from "./DriverDialog";
import { usePlanInfo } from "../utility/use-plan-info";
export default function AddDriverButton(_a) {
    var disabled = _a.disabled;
    var _b = useState(false), isShown = _b[0], setShown = _b[1];
    return (_jsxs(_Fragment, { children: [_jsx(DriverDialog, { isShown: isShown, setShown: setShown }), _jsx(Button, __assign({ backgroundColor: "#2F2E41", appearance: "primary", size: "large", disabled: disabled, onClick: function () { return setShown(true); }, marginY: 8, marginRight: 12, iconAfter: PlusIcon }, { children: "Hinzuf\u00FCgen" }))] }));
}
