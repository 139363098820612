var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
function searchMapboxLocation(query) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (res, rej) {
                    var requestOptions = {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        redirect: "follow",
                    };
                    var originalQuery = query;
                    if (!originalQuery) {
                        return;
                    }
                    //Convert . to spaces
                    query = query.replace(".", " ");
                    //Address to URL-Encode
                    query = encodeURIComponent(query);
                    fetch("https://api.mapbox.com/geocoding/v5/mapbox.places/".concat(query, ".json?country=de&language=de&types=locality%2Cplace%2Caddress%2Cpostcode&access_token=pk.eyJ1IjoiYXBvbWFwc29mdHdhcmVkZXYiLCJhIjoiY2s4dzJlYnM4MDU5cjNlbnc5bjlhbTN1NSJ9.2MVbscPKHtJSJVcLmThq2Q"), requestOptions)
                        .then(function (response) { return response.json(); })
                        .then(function (result) {
                        var hints = [];
                        result.features.map(function (loc) {
                            var locality = loc.context.filter(function (_a) {
                                var id = _a.id;
                                return id.split(".")[0] === "locality";
                            })[0];
                            var zip = loc.context.filter(function (_a) {
                                var id = _a.id;
                                return id.split(".")[0] === "postcode";
                            })[0];
                            var localityName = "";
                            if (locality) {
                                if (locality.hasOwnProperty("text")) {
                                    localityName = locality.text;
                                }
                            }
                            var obj = {
                                label: loc.place_name + ", " + localityName,
                                value: loc.center,
                                zip: zip ? zip : null,
                                street: loc.text,
                            };
                            hints.push(obj);
                        });
                        //Check if first address has anomaly to input query currently on ZIP/STEET
                        var returnObj = {
                            location: hints[0],
                            alternatives: hints,
                            originalAddressQuery: originalQuery,
                            unclear_location: false,
                            type: "mapbox",
                        };
                        var queryInParts = originalQuery.split(" ");
                        var zipOfQuery;
                        queryInParts.map(function (part) {
                            if (parseInt(part)) {
                                if (part.length > 4) {
                                    zipOfQuery = part;
                                }
                            }
                        });
                        var arr = [];
                        var intFound = false;
                        queryInParts.map(function (part) {
                            if (!parseInt(part)) {
                                if (!intFound) {
                                    arr.push(part);
                                }
                            }
                            else {
                                intFound = true;
                            }
                        });
                        var streetOfQuery = arr.join(" ");
                        //PLZ-Check
                        if (hints[0] && hints[0].hasOwnProperty("hint")) {
                            if (hints[0].zip.text !== zipOfQuery) {
                                returnObj.unclear_location = true;
                            }
                        }
                        var cleanStreet = streetOfQuery
                            .toUpperCase()
                            .replace("STRASSE", "")
                            .replace("STR", "")
                            .replace(".", "")
                            .replace("ß", "SS")
                            .split(" ")
                            .join("");
                        var cleanHint = hints[0].street
                            .toUpperCase()
                            .replace("STRASSE", "")
                            .replace("STR", "")
                            .replace(".", "")
                            .replace("ß", "SS")
                            .split(" ")
                            .join("")
                            .normalize();
                        if (cleanStreet !== cleanHint) {
                            returnObj.unclear_location = true;
                        }
                        res(returnObj);
                    })
                        .catch(function (error) { return rej(error); });
                })];
        });
    });
}
export function getCoordinates(query) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, searchMapboxLocation(query)];
        });
    });
}
export function searchGraphhopperLocation(query) {
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (res, rej) {
                    var requestOptions = {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        redirect: "follow",
                    };
                    var originalQuery = query;
                    //Convert . to spaces
                    query = query.replace(".", " ");
                    //Address to URL-Encode
                    query = encodeURIComponent(query);
                    fetch("https://graphhopper.com/api/1/geocode?q=".concat(query, "&locale=de&key=0566dad7-7329-4b76-ac19-7b4b60a398fb"), requestOptions)
                        .then(function (response) { return response.json(); })
                        .then(function (result) { return __awaiter(_this, void 0, void 0, function () {
                        var hints, returnObj, queryInParts, zipOfQuery, arr, intFound, streetOfQuery;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    hints = [];
                                    result.hits.map(function (loc) {
                                        var obj = {
                                            label: (loc.street ? loc.street : loc.name ? loc.name : "") +
                                                (loc.housenumber ? " " + loc.housenumber : "") +
                                                ", " +
                                                loc.postcode +
                                                " " +
                                                loc.city,
                                            value: [loc.point.lng, loc.point.lat],
                                            zip: loc.postcode,
                                            street: loc.street ? loc.street : loc.name ? loc.name : "",
                                        };
                                        hints.push(obj);
                                    });
                                    returnObj = {
                                        location: hints[0],
                                        alternatives: hints,
                                        originalAddressQuery: originalQuery,
                                        unclear_location: false,
                                        type: "graphhopper",
                                    };
                                    if (!!result.hits[0].housenumber) return [3 /*break*/, 2];
                                    console.log("🆘 NO HOUSENUMBER FOUND");
                                    return [4 /*yield*/, searchMapboxLocation(originalQuery)];
                                case 1:
                                    //No housenumber was found on graphhopper so fallback to mapbox
                                    returnObj = _a.sent();
                                    res(returnObj);
                                    return [2 /*return*/];
                                case 2:
                                    queryInParts = originalQuery.split(" ");
                                    queryInParts.map(function (part) {
                                        if (parseInt(part)) {
                                            if (part.length > 4) {
                                                zipOfQuery = part;
                                            }
                                        }
                                    });
                                    arr = [];
                                    intFound = false;
                                    queryInParts.map(function (part) {
                                        if (!parseInt(part)) {
                                            if (!intFound) {
                                                arr.push(part);
                                            }
                                        }
                                        else {
                                            intFound = true;
                                        }
                                    });
                                    streetOfQuery = arr.join(" ");
                                    if (hints[0].zip !== null) {
                                        if (hints[0].zip !== zipOfQuery) {
                                            console.log("Zip");
                                            returnObj.unclear_location = true;
                                        }
                                    }
                                    if (hints[0].street.toUpperCase() !== streetOfQuery.toUpperCase()) {
                                        returnObj.unclear_location = true;
                                    }
                                    res(returnObj);
                                    return [2 /*return*/];
                            }
                        });
                    }); })
                        .catch(function (error) { return rej(error); });
                })];
        });
    });
}
