import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useEffect } from "react";
import { toYYMMDD } from "../../utility/formatDate";
import Map from "../Map";
import { MapContext } from "../../contexts/MapContext";
import { useTours } from "../../hooks/useTours";
var MapFrame = function () {
    var map = useContext(MapContext).map;
    var tours = useTours();
    var mapRef = map[0];
    // ToDo: This is a temporary solution. Map needs refactoring to consume data properly
    function plotOnMap(color, opacity, tour) {
        try {
            var points = JSON.parse(tour.points);
            //find coords with the most elements
            var mostPoints_1 = { coordinates: [] };
            var allPoints_1 = { coordinates: [] };
            points.map(function (point) {
                if (point.coordinates.length > 2) {
                    allPoints_1.coordinates.push(point.coordinates);
                }
                else {
                    allPoints_1.coordinates.push([point.coordinates]);
                }
                if (point.coordinates.length > mostPoints_1.coordinates.length) {
                    mostPoints_1 = point;
                }
            });
            mapRef.current.addLayer({
                id: tour.id,
                type: "line",
                source: {
                    type: "geojson",
                    data: {
                        type: "Feature",
                        properties: {},
                        geometry: {
                            type: "LineString",
                            coordinates: allPoints_1.coordinates.flat(),
                        },
                    },
                },
                layout: {
                    "line-join": "round",
                    "line-cap": "round",
                },
                paint: {
                    "line-color": color !== null && color !== void 0 ? color : "#49beef",
                    "line-width": 5,
                    "line-opacity": opacity,
                },
            });
        }
        catch (error) {
            console.log("Tour konnte nicht auf der Karte angezeigt werden!");
        }
    }
    useEffect(function () {
        if (!Array.isArray(tours)) {
            return;
        }
        for (var _i = 0, tours_1 = tours; _i < tours_1.length; _i++) {
            var tour = tours_1[_i];
            if (tour.date !== toYYMMDD(Date.now())) {
                return;
            }
            // ToDo: Should we search for the color here, or just display one?
            plotOnMap("#49beef", 0.8, tour);
        }
    }, [tours, mapRef]);
    return _jsx(Map, {});
};
export default MapFrame;
