var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Heading, InfoSignIcon, Pane, Paragraph, ThemeProvider, Tooltip, } from "evergreen-ui";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import BlueBar from "../components/BlueBar";
import TourDialog from "../components/TourDialog";
import TourList from "../components/TourList";
import { PlanContext } from "../contexts/PlanContext";
import { useOngoingOptimizations } from "../hooks/useOngoingOptimizations.ts";
import theme from "../theme";
export default function TourBar() {
    var plan = useContext(PlanContext).plan;
    var isPlanData = plan[0];
    var _a = useState([]), ongoingOptimizations = _a[0], setOngoingOptimizations = _a[1];
    var _b = useState([]), ongoingOptimizationError = _b[0], setOngoingOptimizationError = _b[1];
    var _c = useState(false), tourDialogShown = _c[0], setTourDialogShown = _c[1];
    var optimizations = useOngoingOptimizations(true);
    var availableAmount = useMemo(function () {
        return (isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.availableUsage) !== 999999 ? isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.availableUsage : "∞";
    }, [isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.availableUsage]);
    useEffect(function () {
        var _a;
        setOngoingOptimizations((_a = isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.ongoingOptimizations) !== null && _a !== void 0 ? _a : 0);
        setOngoingOptimizationError(isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.ongoingOptimizationError);
    }, [isPlanData]);
    return (_jsx(ThemeProvider, __assign({ value: theme }, { children: _jsx("div", __assign({ style: { flex: 1, width: "100%", paddingTop: 20 } }, { children: _jsxs(Pane, __assign({ style: {
                    width: "100%",
                    height: "100%",
                    borderRadius: 3,
                    maxHeight: "50vh",
                }, elevation: 2 }, { children: [_jsx(BlueBar, {}), _jsx(Pane, __assign({ style: {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: 10,
                        } }, { children: _jsxs(Pane, __assign({ style: {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            } }, { children: [_jsx(Heading, __assign({ size: "large", className: "headline", style: { color: "#49beef" } }, { children: "TOUREN" })), _jsx(TourDialog, { isShown: tourDialogShown, setIsShown: setTourDialogShown }), _jsxs(Paragraph, __assign({ className: "bodytext", style: { marginLeft: 20 } }, { children: [isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.currentUsage, "/", availableAmount, " ", (isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.subscriptionPeriod) === "annual"
                                            ? "Aufträge dieses Jahr"
                                            : "Aufträge diesen Monat"] })), _jsx(Tooltip, __assign({ content: "Dein Kontingent an freien Auftr\u00E4gen setzt sich am ".concat(moment
                                        .unix(isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.currentPeriodEnds)
                                        .format("DD.MM.YYYY"), "  automatisch zur\u00FCck") }, { children: _jsx(InfoSignIcon, { marginLeft: 4, size: 10, color: "grey" }) }))] })) })), _jsx(TourList, { ongoingOptimizations: ongoingOptimizations, ongoingOptimizationError: ongoingOptimizationError, optimizations: optimizations, hover: true, filter: false })] })) })) })));
}
