var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Pane, Spinner } from 'evergreen-ui';
export default function Loading() {
    return (_jsx(Pane, __assign({ style: { width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroudn: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(73,190,239,0.2) 100%)" } }, { children: _jsx(Spinner, { color: "#49beef", size: "large" }) })));
}
