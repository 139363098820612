import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
// Maximum time to allow a scanner between character inputs before auto-submission
var MAX_IDLE_TIME = 200;
/**
 * This component gives a complete and self-managing interface for barcode scanners. Consumers don't need to worry about
 * partial inputs and can just get notified about the data that is sent.
 *
 * Props:
 *  - active If the input is active, it forces focus on itself. Unless the user explicitly wants to use a scanner,
 *    this prop should be disabled. True by default
 */
var BarcodeScannerInput = function (props) {
    // The content of the input element that the scanner writes into
    var _a = useState(""), content = _a[0], setContent = _a[1];
    var inputElem = useRef(null);
    // A timer that auto-submits when no changes are made but there is content in the input
    var autoSubmitTimer = useRef();
    // Stops the auto-submit timer
    var stopTimer = function () {
        if (autoSubmitTimer.current) {
            clearTimeout(autoSubmitTimer.current);
            autoSubmitTimer.current = undefined;
        }
    };
    var handleInput = function (input) {
        // Stop auto-submit timer
        stopTimer();
        // Start the auto-submit timer (again)
        autoSubmitTimer.current = setTimeout(function () { return submitCurrent(input); }, MAX_IDLE_TIME);
        // Reflect content in the input
        setContent(input);
    };
    var submitCurrent = function (data) {
        // NO-OP, if there is nothing to submit
        if (data === "") {
            return;
        }
        // Signal the submission to the parent
        props.onSubmission(data);
        // Clear the input for the next command
        setContent("");
        // And stop the timer, if it is still running
        stopTimer();
    };
    var handleKeydown = function (evt) {
        if (evt.key === "Enter") {
            submitCurrent(content);
        }
    };
    // Should the component mount unfocused, make sure to focus when active
    useEffect(function () {
        if (inputElem.current && props.active) {
            var regain_1 = setInterval(function () {
                if (inputElem.current) {
                    inputElem.current.focus();
                }
                if (!inputElem.current || inputElem.current === document.activeElement) {
                    clearInterval(regain_1);
                }
                console.log("Regaining");
            }, 100);
        }
    }, [props.active, inputElem.current]);
    return (_jsx("input", { ref: inputElem, type: "Text", value: content, onChange: function (evt) { return handleInput(evt.target.value); }, onKeyDown: handleKeydown, autoFocus: true, onBlur: function (evt) {
            var _a;
            if ((_a = props.active) !== null && _a !== void 0 ? _a : true) {
                var regain_2 = setInterval(function () {
                    evt.target.focus();
                    if (evt.target === document.activeElement) {
                        clearInterval(regain_2);
                    }
                    console.log("Regaining");
                }, 100);
            }
        }, style: { position: "absolute", top: 0, left: 0, width: 0, height: 0, opacity: 0 } }));
};
export default BarcodeScannerInput;
