var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import AddDriverButton from "../components/AddDriverButton";
import DriverBar from "../components/DriverBar";
import { Pane, Heading, Button, Paragraph, ChevronLeftIcon, ChevronRightIcon, } from "evergreen-ui";
import { DriverContext } from "../contexts/DriverContext";
import InputCard from "./InputCard";
import { usePlanInfo } from "../utility/use-plan-info";
import FeatureLabel from "./FeatureLabel";
import { Link } from "react-router-dom";
import Delivery from "../assets/images/delivery.svg";
import { PlanContext } from "../contexts/PlanContext";
export default function AddDriverCard(props) {
    var drivers = useContext(DriverContext).drivers;
    var areDrivers = drivers[0], setDrivers = drivers[1];
    var plan = useContext(PlanContext).plan;
    var isPlan = plan[0], setPlan = plan[1];
    return (_jsxs(Pane, __assign({ width: "100%", height: "100%", display: "flex", flexDirection: "row" }, { children: [_jsx(Link, __assign({ to: "/onboarding/3" }, { children: _jsxs(Pane, __assign({ position: "absolute", top: "35px", left: "35px", display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(ChevronLeftIcon, {}), _jsx(Paragraph, { children: "Zur\u00FCck" })] })) })), _jsx(Link, __assign({ to: "/onboarding/5" }, { children: _jsxs(Pane, __assign({ position: "absolute", top: "35px", right: "35px", display: "flex", flexDirection: "row", alignItems: "center" }, { children: [_jsx(Paragraph, { children: "Weiter" }), _jsx(ChevronRightIcon, {})] })) })), _jsxs(Pane, __assign({ width: "50%", padding: 50, display: "flex", flexDirection: "column" }, { children: [_jsxs(Pane, { children: [_jsx(Heading, __assign({ marginTop: 30, width: "100%", fontSize: "48px", color: "#2D3B51", size: "large", fontWeight: 800 }, { children: "Fahrer*in hinzuf\u00FCgen" })), _jsxs(Paragraph, __assign({ fontSize: "18px" }, { children: ["Du hast noch keinen Fahrer angelegt?", _jsx("br", {}), " Lege jetzt deinen ersten Fahrer an."] }))] }), _jsxs(Pane, __assign({ width: "100%", overflowY: "scroll" }, { children: [areDrivers.map(function (driver, index) {
                                return _jsx(DriverBar, { driver: driver }, index);
                            }), ((isPlan === null || isPlan === void 0 ? void 0 : isPlan.subscriptionLabel) === "test" && areDrivers.length > 3) ||
                                ((isPlan === null || isPlan === void 0 ? void 0 : isPlan.subscriptionLabel) === "apomap-plus" &&
                                    areDrivers.length > 8) ||
                                ((isPlan === null || isPlan === void 0 ? void 0 : isPlan.subscriptionLabel) === "apomap-lite" &&
                                    areDrivers.length > 3 && _jsx(FeatureLabel, {})), _jsx(Pane, __assign({ marginTop: 50 }, { children: _jsx(AddDriverButton, { disabled: ((isPlan === null || isPlan === void 0 ? void 0 : isPlan.subscriptionLabel) === "test" &&
                                        areDrivers.length > 3) ||
                                        ((isPlan === null || isPlan === void 0 ? void 0 : isPlan.subscriptionLabel) === "apomap-plus" &&
                                            areDrivers.length > 8) ||
                                        ((isPlan === null || isPlan === void 0 ? void 0 : isPlan.subscriptionLabel) === "apomap-lite" &&
                                            areDrivers.length > 3), setDrivers: setDrivers }) }))] })), _jsx(Pane, __assign({ position: "absolute", bottom: 60, left: "40%" }, { children: _jsx(Link, __assign({ style: { textDecoration: "none" }, to: "/onboarding/5" }, { children: _jsx(Button, __assign({ backgroundColor: "#2F2E41", appearance: "primary", size: "large" }, { children: "Weiter" })) })) }))] })), _jsxs(Pane, __assign({ width: "50%", padding: 50, display: "flex", flexDirection: "column", backgroundColor: "#49beef", textAlign: "center" }, { children: [_jsx(Heading, __assign({ marginTop: 30, fontSize: "48px", size: "large", color: "white", fontWeight: 800 }, { children: "Legen wir nun gemeinsam deine Fahrer*in an" })), _jsx(Paragraph, __assign({ fontFamily: "bold", width: "100%", marginTop: 10, color: "white" }, { children: "Lege jetzt deine Fahrer*innen an und plane deine optimalen Routen" })), _jsx("img", { src: Delivery, alt: "Person", style: { width: "100%", height: "50%", marginTop: 20 } })] }))] })));
}
