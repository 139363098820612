var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Badge, Card, Pane, Paragraph } from "evergreen-ui";
//@ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import { useHistory } from "react-router-dom";
import { getLabelFromStatus } from "../../utility/getLabelFromStatus";
export var DocumentationBarItem = function (_a) {
    var id = _a.id, tour = _a.tour, title = _a.title, subTitle = _a.subTitle, status = _a.status, address = _a.address, jobNo = _a.jobNo;
    var push = useHistory().push;
    return (_jsx(motion.div, __assign({ whileHover: {
            scale: 1.01,
            transition: { duration: 0.3 },
        }, initial: { opacity: 0 }, animate: {
            opacity: 1,
        }, transition: { duration: 0.2 } }, { children: _jsxs(Card, __assign({ onClick: function () { return push("/tour-details/" + tour); }, marginY: 8, cursor: "pointer", width: "100%", elevation: 2, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: 8 }, { children: [_jsxs(Pane, { children: [_jsxs(Pane, __assign({ display: "flex", flexDirection: "column" }, { children: [jobNo && (_jsxs(Paragraph, __assign({ marginBottom: 4, fontSize: 10 }, { children: ["Job-No: ", jobNo] }))), _jsx(Paragraph, __assign({ font: "menu" }, { children: title }))] })), subTitle && (_jsx(Paragraph, __assign({ color: "grey", fontSize: 10 }, { children: subTitle })))] }), _jsxs(Pane, __assign({ display: "flex", flexDirection: "column", justifyContent: "flex-end" }, { children: [status && (_jsx(Pane, __assign({ display: "flex", justifyContent: "flex-end" }, { children: _jsx(Badge, { children: getLabelFromStatus(status) }) }))), address && (_jsx(Paragraph, __assign({ color: "grey", fontSize: 10 }, { children: address })))] }))] })) })));
};
