var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Pane, Paragraph } from "evergreen-ui";
import { useHistory } from "react-router-dom";
export var AddonHintBox = function (_a) {
    var title = _a.title, subTitle = _a.subTitle;
    var push = useHistory().push;
    return (_jsxs(Pane, __assign({ maxWidth: 550, borderColor: "#49beef", borderWidth: 2, borderStyle: "solid", elevation: 1, margin: 8, padding: 8, borderRadius: 6 }, { children: [_jsx(Paragraph, __assign({ fontWeight: "bold" }, { children: title })), _jsx(Paragraph, __assign({ maxWidth: 320 }, { children: subTitle })), _jsx(Button, __assign({ onClick: function () { return push("/add-ons"); } }, { children: "Jetzt buchen" }))] })));
};
