var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useEffect, useMemo, useState } from "react";
import firebase from "../config";
export var LocationContext = createContext(null);
export default function LocationContextProvider(_a) {
    var children = _a.children;
    //Store for all locations of an organization
    var _b = useState([]), areLocations = _b[0], setLocations = _b[1];
    useEffect(function () {
        firebase
            .firestore()
            .collection("locations")
            .where("owner", "==", firebase.auth().currentUser.uid)
            .onSnapshot(function (snap) {
            setLocations(snap.docs.map(function (rawLocation) { return rawLocation.data(); }));
        });
    }, []);
    var availableLocations = useMemo(function () { return areLocations.filter(function (l) { return !l.archived; }); }, [areLocations]);
    return (_jsx(LocationContext.Provider, __assign({ value: { locations: [areLocations, setLocations], availableLocations: availableLocations } }, { children: children })));
}
