var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ChevronLeftIcon, Heading, Pane, Paragraph, } from "evergreen-ui";
import { useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import AddDriverButton from "../components/AddDriverButton";
import DriverBar from "../components/DriverBar";
import FeatureLabel from "../components/FeatureLabel";
import NavigatorBar from "../container/NavigatorBar";
import { DriverContext } from "../contexts/DriverContext";
import { usePlanInfo } from "../utility/use-plan-info";
export default function Employees() {
    var drivers = useContext(DriverContext).drivers;
    var areDrivers = drivers[0], setDrivers = drivers[1];
    var history = useHistory();
    var currentPlan = usePlanInfo().currentPlan;
    var unarchivedDrivers = useMemo(function () { return areDrivers.filter(function (driver) { return !driver.archived; }); }, [areDrivers]);
    return (_jsxs(Pane, __assign({ style: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            background: "linear-gradient(-3deg,#E4EBF5 0%, rgba(255,255,255,1) 100%)",
        } }, { children: [_jsx(NavigatorBar, {}), _jsxs(Pane, __assign({ style: {
                    padding: 25,
                    margin: 25,
                    width: "100%",
                    maxWidth: "950px",
                    overflowY: "auto",
                    overflowX: "hidden",
                } }, { children: [_jsx(Button, __assign({ onClick: function () { return history.push("/settings"); }, marginY: 8, marginRight: 12, iconBefore: ChevronLeftIcon }, { children: "Zur\u00FCck" })), _jsx(Heading, __assign({ size: 900 }, { children: "Fahrer*innen" })), _jsx(Paragraph, __assign({ size: 400 }, { children: "Hier k\u00F6nnen Sie ihre Fahrer*innen anlegen" })), _jsx("div", { style: {
                            width: "100%",
                            height: 2,
                            backgroundColor: "#EFEFEF",
                            borderRadius: 2,
                            marginTop: 10,
                            marginBottom: 10,
                        } }), (currentPlan === "test" && unarchivedDrivers.length < 8) ||
                        (currentPlan === "apomap-lite" && unarchivedDrivers.length < 3) ||
                        (currentPlan === "apomap-starter" && unarchivedDrivers.length < 3) ||
                        (currentPlan === "apomap-plus" && unarchivedDrivers.length < 8) ||
                        (currentPlan === "apomap-pro" && true) ? (_jsx(AddDriverButton, {})) : (_jsx(FeatureLabel, { type: "Pro-Feature", content: "Mit dem Plus-Plan k\u00F6nnen sie bis zu 8, mit dem Pro-Plan unbegrenzt Fahrer*innen anlegen." })), unarchivedDrivers.map(function (driver, index) {
                        return _jsx(DriverBar, { driver: driver }, driver.id);
                    })] }))] })));
}
