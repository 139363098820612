var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { IconButton, PlusIcon, Tooltip } from "evergreen-ui";
import TaskDialog from "../components/TaskDialog";
export default function NewTaskDialog(customerPlan) {
    var _a = useState(false), isShown = _a[0], setShown = _a[1];
    var _b = useState(0), formKey = _b[0], setFormKey = _b[1];
    function changeDialogKey() {
        setFormKey(formKey + 1);
    }
    return (_jsxs(_Fragment, { children: [_jsx(TaskDialog, { setFormKey: changeDialogKey, isShown: isShown, setShown: setShown, customerPlan: customerPlan }, formKey), _jsx(Tooltip, __assign({ content: "Auftrag hinzuf\u00FCgen" }, { children: _jsx(IconButton, { size: "large", intent: "success", appearance: "primary", icon: _jsx(PlusIcon, { size: 28 }), onClick: function () { return setShown(true); } }) }))] }));
}
