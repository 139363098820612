var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ArrowDownIcon, ArrowUpIcon, ChevronLeftIcon, ChevronRightIcon, IconButton, Pane, Paragraph, Spinner, Table, } from "evergreen-ui";
import { useMemo, useRef } from "react";
import { DocumentationTableItem } from "./Documentation-Table-Item";
import { useDocumentation } from "./utils";
export var DocumentationTable = function (_a) {
    var loading = _a.loading;
    var ref = useRef(null);
    var _b = useDocumentation(), documentationResults = _b.documentationResults, documentationOffset = _b.documentationOffset, setDocumentationOffset = _b.setDocumentationOffset, documentationSorting = _b.documentationSorting, setDocumentationSorting = _b.setDocumentationSorting;
    var offsetMode = useMemo(function () { return Math.floor(documentationOffset / 20); }, [documentationOffset]);
    return (_jsx(_Fragment, { children: _jsxs(Table, __assign({ marginTop: 16, marginBottom: 16, style: { height: 500, overflowY: "scroll" } }, { children: [_jsxs(Table.Head, { children: [_jsx(Table.TextHeaderCell, { children: "Datum" }), _jsx(Table.TextHeaderCell, { children: "Temperatur" }), _jsx(Table.TextHeaderCell, { children: "Fahrer" }), _jsx(Table.TextHeaderCell, { children: "Startzeit" }), _jsx(Table.TextHeaderCell, { children: "Endzeit" }), _jsx(Table.TextHeaderCell, { children: "Lieferquote" }), _jsx(Table.TextHeaderCell, {}), _jsx(Table.TextHeaderCell, { children: _jsxs(Pane, __assign({ style: {
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                } }, { children: [_jsx(IconButton, { appearance: documentationSorting === "desc" ? "default" : "minimal", onClick: function () { return setDocumentationSorting("desc"); }, icon: ArrowDownIcon }), _jsx(IconButton, { appearance: documentationSorting === "asc" ? "default" : "minimal", onClick: function () { return setDocumentationSorting("asc"); }, icon: ArrowUpIcon }), _jsx(Paragraph, __assign({ marginLeft: 8 }, { children: documentationSorting === "desc" ? "Absteigend" : "Aufsteigend" }))] })) })] }), _jsxs(Pane, __assign({ ref: ref }, { children: [documentationResults.length === 0 && (_jsx(_Fragment, { children: loading ? (_jsx(Spinner, {})) : (_jsx(Paragraph, { children: "Keine Touren gefunden" })) })), documentationResults.length === 0 && documentationOffset !== 0 && (_jsx(IconButton, { onClick: function () { return setDocumentationOffset(documentationOffset - 20); }, icon: ChevronLeftIcon })), documentationResults.map(function (tour) { return (_jsx(DocumentationTableItem, { tour: tour }, tour.id)); }), documentationResults.length === 20 && (_jsxs(Pane, __assign({ display: "flex", flexDirection: "row", alignItems: "center" }, { children: [offsetMode !== 0 && (_jsx(IconButton, { onClick: function () {
                                        setDocumentationOffset(documentationOffset - 20);
                                        //@ts-ignore
                                        if (ref.current)
                                            ref.current.scrollTop = 0;
                                    }, icon: ChevronLeftIcon })), _jsx(Paragraph, __assign({ marginX: 20 }, { children: offsetMode })), _jsx(IconButton, { onClick: function () {
                                        setDocumentationOffset(documentationOffset + 20);
                                        //@ts-ignore
                                        if (ref.current)
                                            ref.current.scrollTop = 0;
                                    }, icon: ChevronRightIcon })] })))] }))] })) }));
};
