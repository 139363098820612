var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect, } from "react-router-dom";
import SignUp from "../routes/SignUp";
import SignIn from "../routes/SignIn";
import Tracking from "../routes/Tracking";
import ForgotPassword from "../routes/ForgotPassword";
// import Onboarding from "../routes/Onboarding";
export default function OpenRoutes() {
    return (_jsxs(Switch, { children: [_jsx(Route, __assign({ path: "/signin" }, { children: _jsx(SignIn, {}) })), _jsx(Route, __assign({ path: "/signup" }, { children: _jsx(SignUp, {}) })), _jsx(Route, __assign({ path: "/forgotPassword" }, { children: _jsx(ForgotPassword, {}) })), _jsx(Route, __assign({ path: "/tracking/:id" }, { children: _jsx(Tracking, {}) })), _jsx(Route, __assign({ path: "*" }, { children: _jsx(Redirect, { to: "/signin" }) }))] }));
}
