var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useRef, useEffect, useState, useContext, useCallback, } from "react";
import { MapContext } from "../contexts/MapContext";
import { Spinner } from "evergreen-ui";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { LocationContext } from "../contexts/LocationContext";
import { centerMapToCoords } from "../utility/centerMapToCoords";
import addMarker from "../utility/addMarker";
import Pin from "./Pin";
import { TaskContext } from "../contexts/TaskContext";
mapboxgl.accessToken =
    "pk.eyJ1IjoiYXBvbWFwc29mdHdhcmVkZXYiLCJhIjoiY2s4dzJlYnM4MDU5cjNlbnc5bjlhbTN1NSJ9.2MVbscPKHtJSJVcLmThq2Q";
export default function Map(props) {
    //LocationContext
    var locations = useContext(LocationContext).locations;
    var areBusinessLocations = locations[0];
    var _a = useState(false), isCentered = _a[0], setCentered = _a[1];
    //Refs
    var mapContainer = useRef(null);
    var mapReference = useRef(null);
    //Map-Start Settings
    var _b = useState(8.52897), lng = _b[0], setLng = _b[1];
    var _c = useState(true), isLoading = _c[0], setLoading = _c[1];
    var _d = useState(51.201298), lat = _d[0], setLat = _d[1];
    var zoom = useState(13)[0];
    //context
    var _e = useContext(MapContext), map = _e.map, isMapLoaded = _e.isMapLoaded;
    var mapRef = map[0], setMapRef = map[1];
    var mapLoaded = isMapLoaded[0], setMapLoaded = isMapLoaded[1];
    //Tasks for zoomout
    var tasks = useContext(TaskContext).tasks;
    var areTasks = tasks[0], setTasks = tasks[1];
    useEffect(function () {
        if (mapReference.current)
            return; // initialize map only once
        mapReference.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/apomapsoftwaredev/cldod3xi7000i01l8slwz794q",
            center: [lng, lat],
            zoom: zoom,
        });
        setMapRef(mapReference);
        setLoading(false);
        setMapLoaded(true);
    }, [lat, lng, setMapLoaded, setMapRef, zoom]);
    useEffect(function () {
        try {
            var locCords = areBusinessLocations.map(function (location) {
                if (location.type === "main") {
                    return location;
                }
            })[0].coords;
            setLng(locCords[0]);
            setLat(locCords[1]);
        }
        catch (error) { }
        !isCentered && showMainLocation();
    }, [areBusinessLocations]);
    function showMainLocation() {
        areBusinessLocations.map(function (loc) {
            addMarker(mapReference, loc.coords, null, "location", loc.id, _jsx(Pin, { location: loc, activity: { type: "location" } }));
            if (loc.type === "main") {
                centerMapToCoords(mapReference, loc.coords);
                setCentered(true);
            }
        });
    }
    return (_jsx("div", __assign({ ref: mapContainer, style: { height: "100%", width: "100%" } }, { children: isLoading && _jsx(Spinner, {}) })));
}
