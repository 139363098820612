var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from "react";
import phil from "../assets/images/quota-phil.svg";
import "../theme/styles.css";
import { Dialog, CrossIcon, Heading, Pane, Paragraph, Button, toaster, Nudge, RadioGroup, } from "evergreen-ui";
import BlueBar from "../components/BlueBar";
import firebase from "../config";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import { useStripe, useElements, IbanElement } from "@stripe/react-stripe-js";
//New theme
import { ThemeContext } from "evergreen-ui";
import theme from "../theme";
import { PaymentContext } from "../contexts/PaymentContext";
import { UserContext } from "../contexts/UserContext";
import { PlanContext } from "../contexts/PlanContext";
import { useHistory } from "react-router-dom";
var ThemeProvider = ThemeContext.Provider;
export default function QuotaManagementDialog(props) {
    var stripe = useStripe();
    var elements = useElements();
    var quotaManagementState = useContext(PaymentContext).quotaManagementState;
    var quotaManagementShown = quotaManagementState[0], setQuotaManagementShown = quotaManagementState[1];
    var _a = useState(false), isLoading = _a[0], setLoading = _a[1];
    var plan = useContext(PlanContext).plan;
    var isPlanData = plan[0];
    var _b = useContext(UserContext), user = _b.user, ready = _b.ready;
    var isUser = user[0];
    var history = useHistory();
    var options = useState([
        {
            label: "Zahl was du verbrauchst: Du zahlst zukünfigt für jede optimierte Auslieferung 0,20€",
            value: "default",
        },
        {
            label: "Unseren apomap Plus oder apomap Pro Plan wählen. Dort ist das Kontingent an kostenlos optimierbaren Auslieferungen größer!",
            value: "free-to-drive",
        },
    ])[0];
    var _c = React.useState(), value = _c[0], setValue = _c[1];
    var handleCloseClick = function () {
        setQuotaManagementShown(false);
    };
    function upgradePlan() {
        return __awaiter(this, void 0, void 0, function () {
            var enableUnlimitedDeliveries;
            var _this = this;
            return __generator(this, function (_a) {
                setLoading(true);
                enableUnlimitedDeliveries = firebase
                    .app()
                    .functions("europe-west3")
                    .httpsCallable("enableUnlimitedDeliveries");
                enableUnlimitedDeliveries()
                    .then(function (resp) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        setLoading(false);
                        toaster.success("Sie haben nun kein Auslieferungslimit mehr!");
                        window.localStorage.setItem("apomap_feeOptIn", "accepted");
                        setQuotaManagementShown(false);
                        return [2 /*return*/];
                    });
                }); })
                    .catch(function (err) {
                    toaster.warning("Ein Fehler ist unterlaufen");
                    setLoading(false);
                    setQuotaManagementShown(false);
                });
                return [2 /*return*/];
            });
        });
    }
    function setupSepaForCustomer() {
        return __awaiter(this, void 0, void 0, function () {
            var setupSepa;
            var _this = this;
            return __generator(this, function (_a) {
                setLoading(true);
                setupSepa = firebase
                    .app()
                    .functions("europe-west3")
                    .httpsCallable("setupSepa");
                setupSepa()
                    .then(function (resp) { return __awaiter(_this, void 0, void 0, function () {
                    var iban, result;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                iban = elements.getElement(IbanElement);
                                return [4 /*yield*/, stripe.confirmSepaDebitSetup(resp.data, {
                                        payment_method: {
                                            sepa_debit: iban,
                                            billing_details: {
                                                name: isUser.firstName + " " + isUser.lastName,
                                                email: firebase.auth().currentUser.email,
                                            },
                                        },
                                    })];
                            case 1:
                                result = _a.sent();
                                if (resp.error) {
                                    // Show error to your customer.
                                    console.log(result.error.message);
                                    setLoading(false);
                                }
                                else {
                                    if (result.error) {
                                        switch (result.error.code) {
                                            case "incomplete_iban":
                                                toaster.warning("Die angegebene IBAN ist nicht korrekt!");
                                                break;
                                            default:
                                                toaster.warning("Ein Fehler ist unterlaufen. Das Abonement konnte nicht abgeschlossen werden.");
                                        }
                                        setLoading(false);
                                        return [2 /*return*/];
                                    }
                                    setTimeout(function () {
                                        var setDefaultSepa = firebase
                                            .app()
                                            .functions("europe-west3")
                                            .httpsCallable("setDefaultSepa");
                                        setDefaultSepa({
                                            default_payment_method: result.setupIntent.payment_method,
                                        })
                                            .then(function (res) { return __awaiter(_this, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                toaster.success("Zahlungsmethode erfolgreich hinterlegt");
                                                upgradePlan();
                                                return [2 /*return*/];
                                            });
                                        }); })
                                            .catch(function (res) {
                                            console.log(res.error);
                                            toaster.warning("Zahlungsmethode konnte nicht hinterlegt werden");
                                            setLoading(false);
                                        });
                                    }, 1000);
                                }
                                return [2 /*return*/];
                        }
                    });
                }); })
                    .catch(function (err) {
                    toaster.notify("Bitte geben Sie eine IBAN an!");
                    setLoading(false);
                });
                return [2 /*return*/];
            });
        });
    }
    var checkPayment = function () {
        setupSepaForCustomer();
    };
    return (_jsx(ThemeProvider, __assign({ value: theme }, { children: _jsxs(Dialog, __assign({ isShown: quotaManagementShown, cancelLabel: "Abbrechen", confirmLabel: "Hinzuf\u00FCgen", shouldCloseOnOverlayClick: false, shouldCloseOnEscapePress: false, hasHeader: false, hasFooter: false, contentContainerProps: {
                className: "dialog-content-container",
                padding: 0,
            }, containerProps: { className: "square-corners" }, width: "80vw" }, { children: [_jsx(BlueBar, {}), _jsx(CrossIcon, { onClick: handleCloseClick, style: { position: "absolute", right: 20, top: 20 } }), _jsx(Pane, __assign({ display: "flex" }, { children: _jsxs(Pane, __assign({ style: {
                            width: "100%",
                            padding: 20,
                            paddingBottom: 0,
                        } }, { children: [_jsx(Heading, __assign({ size: "large", marginBottom: 20 }, { children: "KOSTENLOSES LIEFERUNGSLIMIT ERREICHT" })), _jsxs(Pane, __assign({ style: {
                                    height: "65vh",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                } }, { children: [_jsxs(Pane, __assign({ style: {
                                            height: "100%",
                                            width: "50%",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-around",
                                            padding: 20,
                                        } }, { children: [(isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.subscriptionLabel) !== "apomap-lite" ? (_jsx(_Fragment, { children: _jsxs(Pane, { children: [_jsx(Heading, __assign({ marginBottom: 15, color: "#2D3B51" }, { children: "Beschreibung" })), _jsxs(Paragraph, __assign({ marginBottom: 5 }, { children: ["Wow! Du und dein Team seit ja der Wahnsinn! Ihr habt nun die ", isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.availableUsage, " monatlich inkludierten Lieferung von ", isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.subscriptionLabel, " erreicht. F\u00FCr jeden weiteren optimierten Auftrag wirst du ab jetzt 0,20\u20AC zahlen. Beim Start deines n\u00E4chsten Abrechnungszyklus startest du mit 0 Auftr\u00E4gen und hast deine ", isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.availableUsage, " monatlichen Auftr\u00E4ge wieder inkludiert!"] }))] }) })) : (_jsxs(_Fragment, { children: [_jsxs(Pane, { children: [_jsx(Heading, __assign({ marginBottom: 15, color: "#2D3B51" }, { children: "Beschreibung" })), _jsx(Paragraph, __assign({ marginBottom: 5 }, { children: "Wunderbar! Du und dein Team haben wirklich ganze Arbeit geleistet. Deinen kostenloses Kontingent an Lieferungen ist aufgebraucht! Du kannst nun zwischen zwei Optionen w\u00E4hlen." }))] }), _jsxs(Pane, { children: [_jsx(Heading, __assign({ marginBottom: 25, color: "#2D3B51" }, { children: "Optionen" })), _jsx(RadioGroup, { value: value, options: options, onChange: function (event) { return setValue(event.target.value); } })] })] })), !(isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.isPaymentSet) && (_jsxs(Pane, { children: [_jsx(Heading, __assign({ marginBottom: 15 }, { children: "Rechnungskonto" })), _jsx(IbanElement, { options: {
                                                            supportedCountries: ["SEPA"],
                                                            placeholderCountry: "DE",
                                                        }, style: { width: "50%", height: 40 } })] })), (isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.subscriptionLabel) === "apomap-lite" ? (_jsx(Pane, __assign({ padding: 20 }, { children: value === "free-to-drive" ? (_jsx(Button, __assign({ width: "100%", size: "large", appearance: "primary", intent: "success", onClick: function () { return history.push("/upgrade"); }, isLoading: isLoading }, { children: "Zur \u00DCbersicht" }))) : (_jsx(Button, __assign({ width: "100%", size: "large", appearance: "primary", intent: "success", onClick: function () {
                                                        return (isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.isPaymentSet)
                                                            ? upgradePlan()
                                                            : checkPayment();
                                                    }, isLoading: isLoading }, { children: (isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.isPaymentSet)
                                                        ? "Weiter für 0,20€ pro optimiertem Auftrag"
                                                        : "Zahlungsmethode hinterlegen" }))) }))) : (_jsx(Pane, __assign({ padding: 20 }, { children: _jsx(Button, __assign({ width: "100%", size: "large", appearance: "primary", intent: "success", onClick: upgradePlan, isLoading: isLoading }, { children: "Habe ich verstanden!" })) })))] })), _jsx(Pane, __assign({ style: {
                                            height: "100%",
                                            width: "50%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                        } }, { children: _jsx("img", { src: phil, style: { height: "60%", marginBottom: 25 }, alt: "location-icon" }) }))] }))] })) }))] })) })));
}
