import { useContext, useEffect, useState } from "react";
import { DriverContext } from "../../../contexts/DriverContext";
var useDrivers = function () {
    //@ts-ignore
    var drivers = useContext(DriverContext).drivers;
    var areDrivers = drivers[0];
    var _a = useState({}), driversMap = _a[0], setDriversMap = _a[1];
    useEffect(function () {
        if (drivers)
            setDriversMap(Object.fromEntries(areDrivers.map(function (driver) { return [driver.id, driver]; })));
    }, [areDrivers, drivers]);
    return { driversMap: driversMap };
};
export default useDrivers;
