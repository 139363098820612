import firebase from "firebase/app";
import "firebase/analytics";
// Production
var productionConfig = {
    apiKey: "AIzaSyCCmGRb1hCvo9ZmKtq_t0JS97warDJjoPA",
    authDomain: "apomapdreinull.firebaseapp.com",
    projectId: "apomapdreinull",
    storageBucket: "apomapdreinull.appspot.com",
    messagingSenderId: "943099191401",
    appId: "1:943099191401:web:b2f81e5e55a971c781c2f8",
    measurementId: "G-X4PD40W5XX",
};
// Staging
var stagingConfig = {
    apiKey: "AIzaSyAiktSnMIspwNJXa6YcJc8gbxIvPfs24g8",
    authDomain: "apomap-staging.firebaseapp.com",
    projectId: "apomap-staging",
    storageBucket: "apomap-staging.appspot.com",
    messagingSenderId: "271095381026",
    appId: "1:271095381026:web:cd98ce896cd6808144de7b",
    measurementId: "G-KPG6PSGB15",
};
firebase.initializeApp(process.env.REACT_APP_ENV === "production" ? productionConfig : stagingConfig);
export default firebase;
