var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useContext, useState } from "react";
import { Pane, Table } from "evergreen-ui";
import moment from "moment";
import { convertClockToSeconds } from "../utility/utility";
import firebase from "../config";
import "firebase/firestore";
import "firebase/auth";
import { TaskContext } from "../contexts/TaskContext";
import { toDDMMYYYY } from "../utility/formatDate";
import TourProgressCell from "./TourProgressCell";
export default function TourCard(props) {
    var _a, _b;
    var tasks = useContext(TaskContext).tasks;
    var areTasks = tasks[0], setTasks = tasks[1];
    var _c = useState([]), areTasksInTour = _c[0], setTasksInTour = _c[1];
    var _d = useState(), isDriverData = _d[0], setDriverData = _d[1];
    var _e = useState([]), areDoneTasksInTour = _e[0], setDoneTasksInTour = _e[1];
    var _f = useState(false), isHover = _f[0], setHover = _f[1];
    useEffect(function () {
        getDriverInfo();
        geatherTasks();
    }, []);
    function geatherTasks() {
        var taskIdsInTour = [];
        props.tour.activities.map(function (task) {
            if (task.type === "deliverShipment") {
                taskIdsInTour.push(task.id);
            }
        });
        var tourTasks = [];
        areTasks.map(function (task, i) {
            if (taskIdsInTour.includes(task.task_id)) {
                tourTasks.push(task);
            }
        });
        var sortedTour = tourTasks.sort(function (a, b) {
            return convertClockToSeconds(a.task_planed_delivery) >
                convertClockToSeconds(b.task_planed_delivery)
                ? 1
                : -1;
        });
        var doneTasksInTour = sortedTour.filter(function (task) { return task.task_status === "successfull"; });
        setDoneTasksInTour(doneTasksInTour);
        setTasksInTour(sortedTour);
    }
    function getDriverInfo() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                firebase
                    .firestore()
                    .collection("drivers")
                    .doc(props.tour.driver)
                    .get()
                    .then(function (doc) {
                    var data = doc.data();
                    setDriverData(data);
                });
                return [2 /*return*/];
            });
        });
    }
    return (_jsx(Pane, __assign({ style: {
            backgroundColor: props.isSelectedTourId === props.tour.id ? isDriverData === null || isDriverData === void 0 ? void 0 : isDriverData.color : "",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
        }, onClick: function () { return props.setId(props.tour.id); } }, { children: _jsxs(Table.Row, __assign({ backgroundColor: "rgba(0,0,0,0)", style: { width: "100%" } }, { children: [_jsx(Table.TextCell, { children: props.tour.id.split("-")[0] }), _jsx(Table.TextCell, { children: ((_a = props.tour) === null || _a === void 0 ? void 0 : _a.name)
                        ? (_b = props.tour) === null || _b === void 0 ? void 0 : _b.name
                        : (isDriverData === null || isDriverData === void 0 ? void 0 : isDriverData.firstName) + " " + (isDriverData === null || isDriverData === void 0 ? void 0 : isDriverData.lastName) }), _jsx(Table.TextCell, { children: toDDMMYYYY("20" + props.tour.date) }), _jsx(Table.TextCell, { children: props.tour.startTime }), _jsxs(Table.TextCell, { children: [areDoneTasksInTour.length, "/", areTasksInTour.length, _jsx("div", { children: "Tour Select" })] })] })) })));
}
