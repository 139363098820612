import { createId } from "./utility";
import generateApiKey from "generate-api-key";
import "firebase/firestore";
//COPY ON BACKEND API!!!
export function createNewTask(isCustomerName, isCustomerMail, isCustomerPhone, isDeliveryType, startDate, earliest, latest, selectedPrio, isTime, isTaskNote, isSelectedLabel, isSelectedCoords, isOpenAmount, responsibleBusiness, uid, isTaskSource, jobNo, alternativeAdresses, originalAddressQuery, unclear_location, taskTags) {
    var _a, _b, _c;
    var id = createId();
    var taskObj = {
        customer_name: (_a = isCustomerName === null || isCustomerName === void 0 ? void 0 : isCustomerName.trim()) !== null && _a !== void 0 ? _a : "",
        customer_mail: (_b = isCustomerMail === null || isCustomerMail === void 0 ? void 0 : isCustomerMail.trim()) !== null && _b !== void 0 ? _b : "",
        customer_phone: (_c = isCustomerPhone === null || isCustomerPhone === void 0 ? void 0 : isCustomerPhone.trim()) !== null && _c !== void 0 ? _c : "",
        task_owner: uid,
        task_type: isDeliveryType,
        task_date: startDate,
        task_earliest: earliest,
        task_latest: latest,
        task_priority: selectedPrio > 10 ? null : selectedPrio,
        task_process_time: isTime,
        task_created: new Date(),
        task_id: id,
        task_note: isTaskNote !== "" ? isTaskNote : null,
        task_status: "unassigned",
        task_address: isSelectedLabel,
        task_alternative_addresses: alternativeAdresses,
        task_original_address_query: originalAddressQuery,
        unclear_location: unclear_location,
        task_coords: isSelectedCoords,
        task_open_amount: isOpenAmount
            ? parseFloat(isOpenAmount.replace(",", "."))
            : "0",
        task_origin: responsibleBusiness === null || responsibleBusiness === void 0 ? void 0 : responsibleBusiness.id,
        task_origin_coords: responsibleBusiness === null || responsibleBusiness === void 0 ? void 0 : responsibleBusiness.coords,
        task_to_optimization: false,
        task_source: isTaskSource ? isTaskSource : "Unbekannt",
        task_history: [
            {
                type: "unassigned",
                msg: "Auftrag wurde erstellt",
                timestamp: new Date(),
                id: id,
            },
        ],
        task_job_no: jobNo !== null && jobNo !== void 0 ? jobNo : generateApiKey({
            method: "string",
            length: 8,
            pool: "0123456789",
        }),
        task_tags: taskTags !== null && taskTags !== void 0 ? taskTags : [],
    };
    return taskObj;
}
