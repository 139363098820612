// This is used to infer capabilities from lower-tier plans. Larger index corresponds with superior plan
export var PlanRank = [
    "apomap-starter",
    "apomap-lite",
    "apomap-plus",
    "apomap-pro",
];
export var PlanNames = {
    "apomap-starter": "apomap Starter",
    "apomap-lite": "apomap Lite",
    "apomap-plus": "apomap Plus",
    "apomap-pro": "apomap Enterprise",
};
