var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Pane, Paragraph, Heading, EditIcon, IconButton, Badge, Button, Popover, Menu, Position, Tooltip, } from "evergreen-ui";
import LocationDialog from "./LocationDialog";
import firebase from "../config";
import "firebase/firestore";
import { CustomAvatar } from "./Interface/Common/CustomAvatar";
export default function LocationBar(props) {
    var _this = this;
    var _a = useState(false), isShown = _a[0], setShown = _a[1];
    var archiveLocation = function (id) { return __awaiter(_this, void 0, void 0, function () {
        var tokenJob;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tokenJob = firebase
                        .firestore()
                        .collection("tokens")
                        .where("location", "==", id);
                    return [4 /*yield*/, tokenJob.get().then(function (querySnapshot) {
                            if (!querySnapshot.empty) {
                                querySnapshot.forEach(function (doc) {
                                    doc.ref.delete();
                                });
                            }
                        })];
                case 1:
                    _a.sent();
                    firebase
                        .firestore()
                        .collection("locations")
                        .doc(id)
                        .update({ archived: true });
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Pane, __assign({ style: {
            opacity: props.location.archived ? 0.2 : 1,
        }, pointerEvents: props.location.archived && "none" }, { children: [_jsx(LocationDialog, { isShown: isShown, setShown: setShown, location: props.location }), _jsxs(Pane, __assign({ elevation: 1, style: {
                    width: "100%",
                    padding: 20,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderColor: "#49beef",
                    borderWidth: props.location.type === "main" ? 2 : 0,
                    margin: 10,
                    backgroundColor: "white",
                } }, { children: [_jsxs("div", __assign({ style: { display: "flex", flexDirection: "row" } }, { children: [_jsx("div", __assign({ style: { marginRight: "30px" } }, { children: _jsx(CustomAvatar, { name: props.location.name, src: props.location.image, size: 80, color: props.location.color }) })), _jsxs("div", { children: [_jsx(Heading, { children: props.location.name }), _jsx(Paragraph, { children: props.location.address }), _jsx(Paragraph, { children: props.location.idf }), _jsx(Paragraph, { children: props.location.paypal }), _jsx(Badge, { children: props.location.type === "main"
                                            ? "Hauptstandort"
                                            : "Nebenstandort" }), props.location.mtPlaceId && _jsx(Badge, __assign({ marginLeft: 6 }, { children: "Cure" }))] })] })), _jsxs(Pane, { children: [_jsx(Popover, __assign({ position: Position.BOTTOM_LEFT, content: _jsx(Menu, { children: _jsx(Menu.Group, { children: _jsx(Tooltip, __assign({ content: "Dieser Standort kann dann nicht mehr in der Routenoptimierung angew\u00E4hlt werden." }, { children: _jsx(Menu.Item, __assign({ onSelect: function () { return archiveLocation(props.location.id); }, intent: "danger" }, { children: "Ja, Standort archivieren!" })) })) }) }) }, { children: _jsx(Button, __assign({ marginRight: 16 }, { children: " Archivieren " })) })), _jsx(IconButton, { onClick: function () { return setShown(true); }, icon: EditIcon })] })] }))] })));
}
