var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, CrossIcon, Dialog, Heading, Pane, Paragraph, ThemeProvider, } from "evergreen-ui";
import { useContext, useEffect, useState } from "react";
import { PrinterSelectionContext, PrinterSelectionMode, } from "../contexts/PrinterSelectionContext";
import theme from "../theme";
import BlueBar from "./BlueBar";
var PrinterSelectionModal = function () {
    var data = useContext(PrinterSelectionContext);
    var _a = data.mode, mode = _a[0], setMode = _a[1];
    var _b = data.shown, shown = _b[0], setShown = _b[1];
    var _c = data.candidates, candidates = _c[0], setCandidates = _c[1];
    var _d = data.selectionCallback, selCallback = _d[0], setSelCallback = _d[1];
    var _e = useState(null), selected = _e[0], setSelected = _e[1];
    // Reset selection if the item disappeared
    useEffect(function () {
        if (selected !== null) {
            if (!candidates.map(function (c) { return c.pid; }).includes(selected.pid)) {
                setSelected(null);
            }
        }
    }, [shown]);
    if (!shown) {
        return null;
    }
    var cancel = function () {
        setShown(false);
        selCallback(null);
    };
    var print = function () {
        setShown(false);
        selCallback(selected);
    };
    return (
    // @ts-ignore
    _jsx(ThemeProvider, __assign({ value: theme }, { children: _jsxs(Dialog, __assign({ isShown: true, cancelLabel: "Abbrechen", confirmLabel: "Drucken", shouldCloseOnOverlayClick: false, shouldCloseOnEscapePress: false, hasHeader: false, hasFooter: false, contentContainerProps: {
                className: "dialog-content-container",
                padding: 0,
            }, containerProps: { className: "square-corners" }, width: "600px" }, { children: [_jsx(BlueBar, {}), _jsx(CrossIcon, { onClick: cancel, style: {
                        position: "absolute",
                        right: 20,
                        top: 20,
                        cursor: "pointer",
                    } }), _jsx(Pane, __assign({ display: "flex" }, { children: _jsxs(Pane, __assign({ style: {
                            width: "100%",
                            padding: 20,
                            paddingBottom: 0,
                        } }, { children: [_jsx(Heading, __assign({ fontSize: "large", marginBottom: 5 }, { children: "Drucker ausw\u00E4hlen" })), _jsxs(Paragraph, __assign({ marginBottom: 20, style: { lineHeight: 1.4, maxWidth: 450 } }, { children: [mode === PrinterSelectionMode.DEFAULT &&
                                        "Bitte wähle aus, welchen Drucker wir verwenden sollen.", mode === PrinterSelectionMode.FALLBACK &&
                                        "Für die relevante Apotheke liegt kein mit apomap verknüpfter Drucker vor. " +
                                            "Wenn du möchtest kannst du alternativ einen Drucker in einem anderen Standort auswählen."] }))] })) })), _jsx(Pane, __assign({ display: "flex", overflow: "scroll", flex: 1, padding: 20 }, { children: _jsx("div", __assign({ style: {
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "6px",
                        } }, { children: candidates.map(function (p) { return (_jsx("button", __assign({ style: {
                                backgroundColor: (selected === null || selected === void 0 ? void 0 : selected.pid) === p.pid ? "#49beef" : "#efefef",
                                color: (selected === null || selected === void 0 ? void 0 : selected.pid) === p.pid ? "#ffffff" : "#2c2c2c",
                                cursor: "pointer",
                                margin: "0",
                                padding: "2px 14px",
                                border: "none",
                                fontSize: "14px",
                                textAlign: "left",
                            }, onClick: function () {
                                if ((selected === null || selected === void 0 ? void 0 : selected.pid) === p.pid) {
                                    setSelected(null);
                                }
                                else {
                                    setSelected(p);
                                }
                            } }, { children: _jsx("p", { children: p.name }) }), p.pid)); }) })) })), _jsxs(Pane, __assign({ style: { display: "flex", padding: 20, flex: 1 }, className: "spaced-h-30" }, { children: [_jsx(Button, __assign({ size: "large", style: { marginRight: "auto" }, appearance: "primary", onClick: cancel }, { children: "Abbrechen" })), _jsx(Button, __assign({ size: "large", intent: "success", appearance: selected === null ? undefined : "primary", disabled: selected === null, onClick: print }, { children: "Drucken" }))] }))] })) })));
};
export default PrinterSelectionModal;
