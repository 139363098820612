var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, Pane, Paragraph } from "evergreen-ui";
import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo.png";
import { useParams } from "react-router-dom";
import firebase from "../config";
import "firebase/firestore";
import { getLabelFromStatus } from "../utility/getLabelFromStatus";
import moment from "moment";
export default function Tracking() {
    var id = useParams().id;
    var _a = useState(), data = _a[0], setData = _a[1];
    var getTaskInfo = function () {
        firebase
            .firestore()
            .collection("tasks")
            .doc(id)
            .onSnapshot(function (doc) {
            var data = doc.data();
            console.log(data);
            setData(data);
        });
    };
    useEffect(function () {
        getTaskInfo(id);
    }, [id]);
    return (_jsxs("div", __assign({ style: {
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
        } }, { children: [_jsx("img", { src: logo, style: { height: 30, marginBottom: 20 }, alt: "apomap-logo" }), _jsxs(Pane, __assign({ elevation: 2, style: { padding: 20 } }, { children: [_jsxs(Paragraph, { children: ["Kundenname: ", data === null || data === void 0 ? void 0 : data.customer_name] }), _jsxs(Paragraph, { children: ["Eingeganen:", " ", moment.unix(data === null || data === void 0 ? void 0 : data.task_created.seconds).format("DD.MM.YYYY - HH:mm")] }), _jsx(Badge, { children: getLabelFromStatus(data === null || data === void 0 ? void 0 : data.task_status) })] }))] })));
}
