var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import BarcodeScannerIcon from './../../assets/icons/barcodescanner.svg';
var BarcodeHint = function (props) { return (_jsxs("div", __assign({ style: __assign({ display: "flex", flexDirection: "row", maxWidth: 400, gap: 15, alignItems: "center", justifyContent: "center" }, props.style) }, { children: [_jsx("img", { src: BarcodeScannerIcon, alt: "Barcode Scanner Hint", style: { width: 32, height: 32, opacity: .75 } }), _jsx("p", __assign({ style: {
                margin: 0,
                fontSize: 14,
                color: "#52525C"
            } }, { children: props.children }))] }))); };
export default BarcodeHint;
