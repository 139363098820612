var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Pane, StatusIndicator, Tooltip } from "evergreen-ui";
import { useMemo } from "react";
export var CornerLabel = function (_a) {
    var _b;
    var locationId = _a.locationId, locations = _a.locations, plan = _a.plan;
    var targetLocation = useMemo(function () {
        return locations === null || locations === void 0 ? void 0 : locations.find(function (location) {
            return location.id === locationId;
        });
    }, [locationId, locations]);
    var stringToColour = function (str) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var colour = "#";
        for (var i = 0; i < 3; i++) {
            var value = (hash >> (i * 8)) & 0xff;
            colour += ("00" + value.toString(16)).substr(-2);
        }
        return colour;
    };
    var idColor = useMemo(function () { var _a; return stringToColour((_a = targetLocation === null || targetLocation === void 0 ? void 0 : targetLocation.id) !== null && _a !== void 0 ? _a : ""); }, [targetLocation === null || targetLocation === void 0 ? void 0 : targetLocation.id]);
    return (plan === null || plan === void 0 ? void 0 : plan.subscriptionLabel) === "apomap-pro" ? (_jsx(Pane, __assign({ display: "flex", flexDirection: "column" }, { children: _jsx(Tooltip, __assign({ content: targetLocation === null || targetLocation === void 0 ? void 0 : targetLocation.name }, { children: _jsx(StatusIndicator, { color: (_b = targetLocation === null || targetLocation === void 0 ? void 0 : targetLocation.color) !== null && _b !== void 0 ? _b : idColor, dotSize: 10 }) })) }))) : null;
};
