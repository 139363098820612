var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Avatar } from "evergreen-ui";
export var CustomAvatar = function (_a) {
    var name = _a.name, src = _a.src, size = _a.size, color = _a.color;
    return (_jsx("div", __assign({ style: {
            backgroundColor: color,
            borderRadius: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        } }, { children: _jsx(Avatar, { name: name, src: src, size: size, color: "transparent" }) })));
};
