var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, InfoSignIcon, Menu, Pane, Popover, Position, PrintIcon, toaster, Tooltip, TrashIcon, } from "evergreen-ui";
import "firebase/auth";
import "firebase/firestore";
import { useCallback, useContext, useEffect, useState } from "react";
import firebase from "../config";
import { LayoutContext } from "../contexts/LayoutContext";
import PrintingService from "../services/PrintingService";
import deleteTour from "../utility/deleteTour";
import { usePlanInfo } from "../utility/use-plan-info";
import TourProgressBar from "./TourProgressBar";
export default function TourProgressCell(props) {
    var _this = this;
    var state = useContext(LayoutContext).state;
    var isEditMode = state[0], setEditMode = state[1];
    var _a = useState(false), isLoading = _a[0], setLoading = _a[1];
    var _b = useState(""), driverName = _b[0], setDriverName = _b[1];
    var currentPlan = usePlanInfo().currentPlan;
    // Handler for deleting a tour
    var handleDeleteTour = function () { return __awaiter(_this, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, deleteTour(props.tour, setLoading, toaster)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    console.error("Error deleting tour:", err_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getDriverName = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var doc, driverData, name, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, firebase
                            .firestore()
                            .collection("drivers")
                            .doc(props.tour.driver)
                            .get()];
                case 1:
                    doc = _a.sent();
                    if (doc.exists) {
                        driverData = doc.data();
                        name = "".concat(driverData.firstName, " ").concat(driverData.lastName);
                        setDriverName(name);
                    }
                    else {
                        setDriverName("");
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_2 = _a.sent();
                    console.log(err_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [props.tour]);
    useEffect(function () {
        getDriverName();
    }, [getDriverName]);
    return (_jsxs(Pane, __assign({ style: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
        } }, { children: [_jsx(TourProgressBar, { status: props.tour.status, involvedTasks: props.involvedTasks }), _jsxs(Pane, __assign({ style: { display: "flex", flexDirection: "row", alignItems: "center" } }, { children: [_jsx(Tooltip, __assign({ content: "Detailansicht" }, { children: _jsx(IconButton, { style: { backgroundColor: "rgba(0,0,0,0)", borderWidth: 0 }, iconBefore: InfoSignIcon, marginLeft: 5, onClick: function () { return props.setModalShown(true); } }) })), currentPlan !== "apomap-lite" && (_jsx(Tooltip, __assign({ content: "Tour-Label drucken" }, { children: _jsx(PrintIcon, { onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a, _b;
                                return __generator(this, function (_c) {
                                    switch (_c.label) {
                                        case 0:
                                            _b = (_a = console).log;
                                            return [4 /*yield*/, PrintingService.printTourLabel(props.tour, driverName)];
                                        case 1:
                                            _b.apply(_a, [_c.sent()]);
                                            toaster.notify("Tour-Label wird gedruckt");
                                            return [2 /*return*/];
                                    }
                                });
                            }); }, style: { cursor: "pointer" } }) }))), props.tour.status === "prepared" || props.tour.status === "empty" ? (_jsx(Popover, __assign({ position: Position.BOTTOM_LEFT, content: _jsx(Menu, { children: _jsx(Menu.Group, __assign({ title: "Wirklich l\u00F6schen?" }, { children: _jsx(Menu.Item, __assign({ onClick: handleDeleteTour, icon: TrashIcon, intent: "danger" }, { children: "Ja, l\u00F6schen!" })) })) }) }, { children: _jsx(IconButton, { isLoading: isLoading, marginLeft: 5, style: { backgroundColor: "rgba(0,0,0,0)", borderWidth: 0 }, icon: _jsx(TrashIcon, {}) }) }))) : (_jsx(Pane, { style: { width: 35, height: 35 } }))] }))] })));
}
