var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Pane, Select } from "evergreen-ui";
import ApoFieldLabel from "./ApoFieldLabel";
var ApoSelect = function (_a) {
    var _b = _a.options, options = _b === void 0 ? [] : _b, value = _a.value, label = _a.label, required = _a.required, onChange = _a.onChange, disabled = _a.disabled;
    return (_jsxs(Pane, __assign({ marginBottom: 10 }, { children: [label && _jsx(ApoFieldLabel, { required: required, value: label }), _jsx(Select, __assign({ disabled: disabled, value: value, size: "large", height: 45, width: "100%", onChange: onChange }, { children: options.map(function (o) { return (_jsx("option", __assign({ value: o.value, selected: true }, { children: o.label }))); }) }))] })));
};
export default ApoSelect;
