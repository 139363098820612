var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from "react";
import felix from "../assets/images/payment-felix.svg";
import moritz from "../assets/images/payment-moritz.svg";
import "../theme/styles.css";
import { Button, CrossIcon, Dialog, Heading, Pane, Paragraph, TextInput, toaster, } from "evergreen-ui";
import BlueBar from "../components/BlueBar";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import firebase from "../config";
import { IbanElement, useElements, useStripe } from "@stripe/react-stripe-js";
//New theme
import { ThemeContext } from "evergreen-ui";
import { useHistory } from "react-router-dom";
import { PaymentContext } from "../contexts/PaymentContext";
import { PlanContext } from "../contexts/PlanContext";
import { UserContext } from "../contexts/UserContext";
import theme from "../theme";
var ThemeProvider = ThemeContext.Provider;
var DialogTitle = function (_a) {
    var version = _a.version;
    var el;
    switch (version) {
        case 0:
            el = (_jsx(Paragraph, { children: "Damit du diese Abonnement durchf\u00FChren kannst, musst du eine Bank-Verbindung hinterlegen. Du bekommst monatlich eine Rechnung von uns und kannst die Zahlungs- und Abonementeinstellungen jederzeit \u00E4ndern!" }));
            break;
        default:
            el = (_jsx(Paragraph, { children: "Damit du diese Abonnement durchf\u00FChren kannst, musst du eine Bank-Verbindung hinterlegen. Du bekommst monatlich eine Rechnung von uns und kannst die Zahlungs- und Abonnementeinstellungen jederzeit \u00E4ndern!" }));
    }
    return el;
};
export var DialogImage = function (_a) {
    var version = _a.version;
    var el;
    switch (version) {
        case 0:
            el = (_jsx("img", { src: moritz, style: { height: "60%", marginBottom: 25 }, alt: "location-icon" }));
            break;
        case 1:
            el = (_jsx("img", { src: felix, style: { height: "60%", marginBottom: 25 }, alt: "location-icon" }));
            break;
        default:
            el = (_jsx("img", { src: moritz, style: { height: "60%", marginBottom: 25 }, alt: "location-icon" }));
    }
    return el;
};
export default function PayMethodeManagementDialog(props) {
    var stripe = useStripe();
    var elements = useElements();
    var _a = useContext(PaymentContext), payMethodeManagementState = _a.payMethodeManagementState, payMethodeManagementLayout = _a.payMethodeManagementLayout, selectedSubscriptionState = _a.selectedSubscriptionState;
    var payMethodeManagementShown = payMethodeManagementState[0], setPayMethodeManagementShown = payMethodeManagementState[1];
    var payMethodeManagementVersion = payMethodeManagementLayout[0], setPayMethodeManagementVersion = payMethodeManagementLayout[1];
    var selectedSubscriptionPlan = selectedSubscriptionState[0], setSelectedSubscriptionPlan = selectedSubscriptionState[1];
    var _b = useState(false), isLoading = _b[0], setLoading = _b[1];
    var plan = useContext(PlanContext).plan;
    var isPlanData = plan[0];
    var _c = useContext(UserContext), user = _c.user, ready = _c.ready;
    var isUser = user[0];
    var history = useHistory();
    var _d = useState(null), promoCode = _d[0], setPromoCode = _d[1];
    var handleCloseClick = function () {
        setPayMethodeManagementShown(false);
    };
    function makeSubscription(configuration) {
        return __awaiter(this, void 0, void 0, function () {
            var id, label, type, startSubscription;
            return __generator(this, function (_a) {
                setLoading(true);
                id = configuration.id, label = configuration.label, type = configuration.type;
                startSubscription = firebase
                    .app()
                    .functions("europe-west3")
                    .httpsCallable("startSubscription");
                startSubscription(__assign({ price_id: id, price_label: label, price_type: type }, (promoCode && { promoCode: promoCode })))
                    .then(function (result) {
                    toaster.success("Plan wurde erfolgreich gebucht");
                    setPayMethodeManagementShown(false);
                    setLoading(false);
                    history.push("/onboarding/3");
                })
                    .catch(function (err) {
                    console.log(err);
                });
                return [2 /*return*/];
            });
        });
    }
    function setupSepaForCustomer() {
        return __awaiter(this, void 0, void 0, function () {
            var setupSepa;
            var _this = this;
            return __generator(this, function (_a) {
                setLoading(true);
                setupSepa = firebase
                    .app()
                    .functions("europe-west3")
                    .httpsCallable("setupSepa");
                setupSepa()
                    .then(function (resp) { return __awaiter(_this, void 0, void 0, function () {
                    var iban, result;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                iban = elements.getElement(IbanElement);
                                return [4 /*yield*/, stripe.confirmSepaDebitSetup(resp.data, {
                                        payment_method: {
                                            sepa_debit: iban,
                                            billing_details: {
                                                name: isUser.firstName + " " + isUser.lastName,
                                                email: firebase.auth().currentUser.email,
                                            },
                                        },
                                    })];
                            case 1:
                                result = _a.sent();
                                if (resp.error) {
                                    // Show error to your customer.
                                    console.log(result.error.message);
                                    setLoading(false);
                                }
                                else {
                                    if (result.error) {
                                        switch (result.error.code) {
                                            case "incomplete_iban":
                                                toaster.warning("Die angegebene IBAN ist nicht korrekt!");
                                                break;
                                            default:
                                                toaster.warning("Ein Fehler ist unterlaufen. Das Abonement konnte nicht abgeschlossen werden.");
                                        }
                                        setLoading(false);
                                        return [2 /*return*/];
                                    }
                                    setTimeout(function () {
                                        var configuration = getConfiguration();
                                        var setDefaultSepa = firebase
                                            .app()
                                            .functions("europe-west3")
                                            .httpsCallable("setDefaultSepa");
                                        setDefaultSepa({
                                            default_payment_method: result.setupIntent.payment_method,
                                        })
                                            .then(function (res) { return __awaiter(_this, void 0, void 0, function () {
                                            var id, label;
                                            return __generator(this, function (_a) {
                                                toaster.success("Zahlungsmethode erfolgreich hinterlegt");
                                                setPayMethodeManagementShown(false);
                                                setLoading(false);
                                                if (isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.subscriptionId) {
                                                    id = void 0;
                                                    label = void 0;
                                                    if (payMethodeManagementVersion === 0) {
                                                        label = "apomap-starter";
                                                        selectedSubscriptionPlan === "monthly"
                                                            ? (id = "price_1NsQZVGjTI7kgs1jFsYF7ifo")
                                                            : (id = "price_1NsQZVGjTI7kgs1jEB72Ik45");
                                                    }
                                                    if (payMethodeManagementVersion === 1) {
                                                        label = "apomap-plus";
                                                        selectedSubscriptionPlan === "monthly"
                                                            ? (id = "price_1KSoUCGjTI7kgs1jUxTUi3fz")
                                                            : (id = "price_1NflDGGjTI7kgs1jPlFHdjV5");
                                                    }
                                                    if (payMethodeManagementVersion === 2) {
                                                        label = "apomap-pro";
                                                        selectedSubscriptionPlan === "monthly"
                                                            ? (id = "price_1KSoYHGjTI7kgs1j7PbNT2xi")
                                                            : (id = "price_1MwotNGjTI7kgs1jIxrxnGpA");
                                                    }
                                                    upgradePlan({ id: id, label: label });
                                                }
                                                else {
                                                    makeSubscription(configuration);
                                                }
                                                return [2 /*return*/];
                                            });
                                        }); })
                                            .catch(function (res) {
                                            console.log(res.error);
                                            toaster.warning("Zahlungsmethode konnte nicht hinterlegt werden");
                                            setPayMethodeManagementShown(false);
                                            setLoading(false);
                                        });
                                    }, 1000);
                                }
                                return [2 /*return*/];
                        }
                    });
                }); })
                    .catch(function (err) {
                    toaster.notify(err);
                });
                return [2 /*return*/];
            });
        });
    }
    var upgradePlan = function (plan) {
        setLoading(true);
        var changeSubscription = firebase
            .app()
            .functions("europe-west3")
            .httpsCallable("changeSubscription");
        changeSubscription(__assign({ price_id: plan.id, price_label: plan.label, price_type: plan.type }, (promoCode && { promoCode: promoCode })))
            .then(function (result) {
            toaster.success("Dein Plan wurde erfolgreich geändert");
            setPayMethodeManagementShown(false);
            setLoading(false);
        })
            .catch(function (err) {
            toaster.warning("Dein Plan konnte nicht geändert werden!");
            setPayMethodeManagementShown(false);
            setLoading(false);
        });
    };
    var getConfiguration = function () {
        //apomap-starter
        if (payMethodeManagementVersion === 0) {
            if (selectedSubscriptionPlan === "monthly") {
                //monthly
                return {
                    label: "apomap-starter",
                    id: "price_1NsQZVGjTI7kgs1jFsYF7ifo",
                    type: "monthly",
                };
            }
            else {
                //yearly
                return {
                    label: "apomap-starter",
                    id: "price_1NsQZVGjTI7kgs1jEB72Ik45",
                    type: "annual",
                };
            }
        }
        //apomap-plus
        if (payMethodeManagementVersion === 1) {
            if (selectedSubscriptionPlan === "monthly") {
                //monthly
                return {
                    label: "apomap-plus",
                    id: "price_1KSoUCGjTI7kgs1jUxTUi3fz",
                    type: "monthly",
                };
            }
            else {
                //yearly
                return {
                    label: "apomap-plus",
                    id: "price_1NflDGGjTI7kgs1jPlFHdjV5",
                    type: "annual",
                };
            }
        }
        //apomap-pro
        if (payMethodeManagementVersion === 2) {
            if (selectedSubscriptionPlan === "monthly") {
                //monthly
                return {
                    label: "apomap-pro",
                    id: "price_1KSoYHGjTI7kgs1j7PbNT2xi",
                    type: "monthly",
                };
            }
            else {
                //yearly
                return {
                    label: "apomap-pro",
                    id: "price_1MwotNGjTI7kgs1jIxrxnGpA",
                    type: "annual",
                };
            }
        }
    };
    var checkAction = function () {
        var configuration = getConfiguration();
        if (isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.isPaymentSet) {
            if (isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.subscriptionId) {
                upgradePlan(configuration);
            }
            else {
                makeSubscription(configuration);
            }
        }
        else {
            setupSepaForCustomer();
        }
    };
    var actionButtonLabel = useMemo(function () {
        return payMethodeManagementVersion === 0
            ? selectedSubscriptionPlan === "monthly"
                ? "Jetzt für 30 Tage testen, dann 39,-€ mtl."
                : "Jetzt für 468,-€ pro Jahr buchen"
            : payMethodeManagementVersion === 1
                ? selectedSubscriptionPlan === "monthly"
                    ? "Jetzt für 30 Tage testen, dann 99,-€ mtl."
                    : "Jetzt für 1.069,-€ pro Jahr buchen"
                : payMethodeManagementVersion === 2
                    ? selectedSubscriptionPlan === "monthly"
                        ? "Jetzt für 30 Tage testen, dann 149,-€ mtl."
                        : "Jetzt für 1.609,-€ pro Jahr buchen"
                    : null;
    }, [payMethodeManagementVersion, selectedSubscriptionPlan]);
    return (_jsx(ThemeProvider, __assign({ value: theme }, { children: _jsxs(Dialog, __assign({ isShown: payMethodeManagementShown, cancelLabel: "Abbrechen", confirmLabel: "Hinzuf\u00FCgen", shouldCloseOnOverlayClick: false, shouldCloseOnEscapePress: false, hasHeader: false, hasFooter: false, contentContainerProps: {
                className: "dialog-content-container",
                padding: 0,
            }, containerProps: { className: "square-corners" }, width: "80vw" }, { children: [_jsx(BlueBar, {}), _jsx(CrossIcon, { onClick: handleCloseClick, style: {
                        position: "absolute",
                        right: 20,
                        top: 20,
                        cursor: "pointer",
                    } }), _jsx(Pane, __assign({ display: "flex" }, { children: _jsxs(Pane, __assign({ style: {
                            width: "100%",
                            padding: 20,
                            paddingBottom: 0,
                        } }, { children: [_jsx(Heading, __assign({ size: "large", marginBottom: 20 }, { children: "ZAHLUNGSMETHODE HINTERLEGEN" })), _jsxs(Pane, __assign({ style: {
                                    height: "65vh",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                } }, { children: [_jsxs(Pane, __assign({ style: {
                                            height: "100%",
                                            width: "50%",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-around",
                                            padding: 20,
                                        } }, { children: [_jsxs(Pane, { children: [_jsx(Heading, __assign({ marginBottom: 15, color: "#2D3B51" }, { children: "Beschreibung" })), _jsx(Paragraph, __assign({ marginBottom: 5 }, { children: _jsx(DialogTitle, { version: payMethodeManagementVersion }) }))] }), _jsxs(Pane, { children: [_jsx(Heading, __assign({ marginBottom: 15, color: "#2D3B51" }, { children: "Rechnungsadresse" })), _jsxs(Paragraph, __assign({ marginBottom: 5 }, { children: [_jsx(Paragraph, { children: isUser === null || isUser === void 0 ? void 0 : isUser.businessName }), _jsxs(Paragraph, { children: [isUser === null || isUser === void 0 ? void 0 : isUser.firstName, " ", isUser === null || isUser === void 0 ? void 0 : isUser.lastName] }), _jsx(Paragraph, { children: isUser === null || isUser === void 0 ? void 0 : isUser.address })] }))] }), _jsxs(Pane, { children: [_jsx(Heading, __assign({ marginBottom: 15, color: "#2D3B51" }, { children: "Gutscheincode" })), _jsx(TextInput, { value: promoCode, onChange: function (e) { return setPromoCode(e.target.value); } })] }), !(isPlanData === null || isPlanData === void 0 ? void 0 : isPlanData.isPaymentSet) && (_jsx(Pane, __assign({ border: "1px #49beef solid", padding: 10, borderRadius: 10 }, { children: _jsx(IbanElement, { options: {
                                                        supportedCountries: ["SEPA"],
                                                        placeholderCountry: "DE",
                                                    }, style: { width: "50%", height: 100 } }) }))), _jsx("p", __assign({ style: { fontSize: "10px" } }, { children: "Durch Angabe Ihrer Zahlungsinformationen und der Best\u00E4tigung der vorliegenden Zahlung erm\u00E4chtigen Sie (A) apomap GmbH und Stripe, unseren Zahlungsdienstleister, bzw. PPRO, den lokalen Bankdienstleister von Stripe, Ihrem Kreditinstitut Anweisungen zur Belastung Ihres Kontos zu erteilen, und (B) Ihr Kreditinstitut, Ihr Konto gem\u00E4\u00DF diesen Anweisungen zu belasten. Im Rahmen Ihrer Rechte haben Sie, entsprechend den Vertragsbedingungen mit Ihrem Kreditinstitut, Anspruch auf eine R\u00FCckerstattung von Ihrem Kreditinstitut. Eine R\u00FCckerstattung muss innerhalb von 8 Wochen ab dem Tag, an dem Ihr Konto belastet wurde, geltend gemacht werden. Eine Erl\u00E4uterung Ihrer Rechte k\u00F6nnen Sie von Ihrem Kreditinstitut anfordern. Sie erkl\u00E4ren sich einverstanden, Benachrichtigungen \u00FCber k\u00FCnftige Belastungen bis sp\u00E4testens 2 Tage vor dem Buchungsdatum zu erhalten." })), _jsx(Pane, __assign({ padding: 20 }, { children: _jsx(Button, __assign({ width: "100%", size: "large", appearance: "primary", intent: "success", onClick: checkAction, isLoading: isLoading }, { children: actionButtonLabel })) }))] })), _jsx(Pane, __assign({ style: {
                                            height: "100%",
                                            width: "50%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                        } }, { children: _jsx(DialogImage, { version: payMethodeManagementVersion }) }))] }))] })) }))] })) })));
}
