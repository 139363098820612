//Creates komplex preview for dashboard of the tour out of activities from mapbox
//Example-Output in utility/examples/converttoQuciklist.json
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import combineActions from "./combineActions";
import { addSecondsToStartTime, convertSecondsToClock } from "./utility";
import firebase from "../config";
import "firebase/firestore";
import "firebase/auth";
function injectActionTime(startTimeOfTour, action) {
    var planedActionTime = addSecondsToStartTime(action);
    action.action_planed_time = planedActionTime;
    return action;
}
export default function createTaskActionList(tour, areTasks) {
    return __awaiter(this, void 0, void 0, function () {
        var taskIdsInTour, idsInAreTasks, allTasksAlreadyThere, tourTasks, startTimeOfTour, stopsOfTour, doneTasksInTour, taskId, cleanedUpTour;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    taskIdsInTour = [];
                    tour.activities.map(function (task) {
                        if (task.type === "deliverShipment") {
                            taskIdsInTour.push(task.id);
                        }
                    });
                    idsInAreTasks = areTasks.map(function (_a) {
                        var task_id = _a.task_id;
                        return task_id;
                    });
                    allTasksAlreadyThere = true;
                    taskIdsInTour.map(function (id) {
                        if (!idsInAreTasks.includes(id)) {
                            allTasksAlreadyThere = false;
                        }
                    });
                    if (!!allTasksAlreadyThere) return [3 /*break*/, 2];
                    return [4 /*yield*/, Promise.all(taskIdsInTour.map(function (id) {
                            return new Promise(function (res, rej) {
                                return firebase
                                    .firestore()
                                    .collection("tasks")
                                    .where("task_id", "==", id)
                                    .get()
                                    .then(function (snap) {
                                    var tasksWithId = [];
                                    snap.forEach(function (doc) {
                                        var data = doc.data();
                                        tasksWithId.push(data);
                                    });
                                    if (tasksWithId.length !== 0) {
                                        console.log(tasksWithId[0]);
                                        res(tasksWithId[0]);
                                    }
                                    else {
                                        var deletedObj = {
                                            task_status: "deleted",
                                            customer_name: "Auftrag gelöscht",
                                            task_tour: null,
                                            task_id: id,
                                            task_type: "delivery",
                                        };
                                        res(deletedObj);
                                    }
                                })
                                    .catch(function (error) {
                                    console.log(error);
                                    rej("ERR");
                                });
                            });
                        }))];
                case 1:
                    areTasks = _a.sent();
                    _a.label = 2;
                case 2:
                    tourTasks = {};
                    areTasks.map(function (task, i) {
                        if (taskIdsInTour.includes(task.task_id)) {
                            tourTasks[task.task_id] = task;
                        }
                    });
                    startTimeOfTour = tour.startTime;
                    stopsOfTour = [];
                    tour.activities.map(function (action) {
                        switch (action.type) {
                            //start and end are always driver action no tasks
                            case "start":
                                //Planed starttime at start is starttime
                                action.action_planed_time = startTimeOfTour;
                                stopsOfTour.push({ type: "action", data: action });
                                break;
                            case "end":
                                // action = injectActionTime(startTimeOfTour, action);
                                stopsOfTour.push({ type: "action", data: action });
                                break;
                            //Difference between action or task
                            //deliverindividual -> always a action other is task
                            case "deliverShipment":
                                if (action.location_id.split("_")[0] === "deliverindividual") {
                                    stopsOfTour.push({ type: "task", data: tourTasks[action.id] });
                                }
                                else {
                                    // action = injectActionTime(startTimeOfTour, action);
                                    stopsOfTour.push({ type: "action", data: action });
                                }
                                break;
                            case "pickupShipment":
                                if (action.location_id.split("_")[0] === "pickupindividual") {
                                    stopsOfTour.push({ type: "task", data: tourTasks[action.id] });
                                }
                                else {
                                    // action = injectActionTime(startTimeOfTour, action);
                                    stopsOfTour.push({ type: "action", data: action });
                                }
                                break;
                            default:
                                break;
                        }
                    });
                    doneTasksInTour = [];
                    //Count the successfull in tour
                    for (taskId in tourTasks) {
                        if (tourTasks[taskId].task_status === "successfull") {
                            doneTasksInTour.push(tourTasks[taskId]);
                        }
                    }
                    cleanedUpTour = combineActions(stopsOfTour);
                    return [2 /*return*/, { doneTasksInTour: doneTasksInTour, cleanedUpTour: cleanedUpTour }];
            }
        });
    });
}
