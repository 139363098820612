var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, Heading, Paragraph } from "evergreen-ui";
import { toDDMMYYYY } from "../../utility/formatDate";
import { usePlanInfo } from "../../utility/use-plan-info";
import DashboardCard from "./DashboardCard";
var PlanOverviewCard = function () {
    var isPlan = usePlanInfo().isPlan;
    return (_jsx(DashboardCard, __assign({ size: 1, style: {
            height: "30%",
        }, contentStyle: {
            height: "100%",
        } }, { children: _jsxs("div", __assign({ style: {
                width: "100%",
                height: "100%",
            } }, { children: [_jsx(Badge, __assign({ isInteractive: true, onClick: function () { return (window.location.href = "/upgrade"); }, color: "#49beef" }, { children: _jsx("span", __assign({ color: "#FFF" }, { children: "Aktives Abonnement" })) })), _jsxs(Heading, __assign({ size: 600, style: { marginTop: 8 } }, { children: [(isPlan === null || isPlan === void 0 ? void 0 : isPlan.subscriptionLabel) === "apomap-lite" && "apomap Lite", (isPlan === null || isPlan === void 0 ? void 0 : isPlan.subscriptionLabel) === "apomap-starter" && "apomap Starter", (isPlan === null || isPlan === void 0 ? void 0 : isPlan.subscriptionLabel) === "apomap-plus" && "apomap Plus", (isPlan === null || isPlan === void 0 ? void 0 : isPlan.subscriptionLabel) === "apomap-pro" && "apomap Enterprise"] })), _jsx(Paragraph, { children: (isPlan === null || isPlan === void 0 ? void 0 : isPlan.subscriptionPeriod) === "annual" ? "Jährlich" : "Monatlich" }), _jsx("hr", { style: {
                        border: "2px solid #ECF0F9",
                        width: "40%",
                        marginRight: "60%",
                    } }), _jsxs(Paragraph, { children: ["bis zum ", toDDMMYYYY((isPlan === null || isPlan === void 0 ? void 0 : isPlan.currentPeriodEnds) * 1000)] }), _jsxs(Paragraph, { children: [isPlan === null || isPlan === void 0 ? void 0 : isPlan.currentUsage, "/", (isPlan === null || isPlan === void 0 ? void 0 : isPlan.availableUsage) === 999999
                            ? (isPlan === null || isPlan === void 0 ? void 0 : isPlan.subscriptionLabel) === "apomap-lite"
                                ? "200"
                                : (isPlan === null || isPlan === void 0 ? void 0 : isPlan.subscriptionLabel) === "apomap-plus"
                                    ? "1000"
                                    : (isPlan === null || isPlan === void 0 ? void 0 : isPlan.subscriptionLabel) === "apomap-pro"
                                        ? "10000"
                                        : "∞"
                            : isPlan === null || isPlan === void 0 ? void 0 : isPlan.availableUsage, " ", "Auftr\u00E4ge"] })] })) })));
};
export default PlanOverviewCard;
