var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { Heading } from "evergreen-ui";
import moment from "moment";
var DateTimeIndicator = function () {
    var _a = useState(null), refreshInterval = _a[0], setRefreshInterval = _a[1];
    var _b = useState(""), timeString = _b[0], setTimeString = _b[1];
    var _c = useState(""), dateString = _c[0], setDateString = _c[1];
    useEffect(function () {
        var refreshTime = function () {
            setTimeString(moment().format("HH:mm"));
            setDateString(moment().format("DD.MM.YY"));
        };
        refreshTime();
        setRefreshInterval(setInterval(refreshTime, 20 * 1000));
        return function () {
            clearInterval(refreshInterval);
        };
    }, []);
    return (_jsxs(Heading, __assign({ size: 700, style: { fontFamily: "bold", color: "#2D3B51" } }, { children: [timeString, " | ", dateString] })));
};
export default DateTimeIndicator;
