var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts';
var COLORS = [
    '#FFB758',
    '#7B61FF',
    '#49BEEF',
    '#2EB67D',
    '#53D7CD',
    '#F12121'
];
var StatsPieChart = function (props) {
    var _a;
    return (_jsxs(_Fragment, { children: [_jsxs(PieChart, __assign({ width: 800, height: 450 }, { children: [_jsx(Pie, __assign({ dataKey: "value", isAnimationActive: false, data: props.data.pie.data, cx: 300, cy: 225, outerRadius: 175, innerRadius: 135, paddingAngle: 2, fill: "#8884D8", label: true }, { children: props.data.pie.data.map(function (entry, index) {
                            var _a;
                            return (_jsx(Cell, { fill: (_a = entry.color) !== null && _a !== void 0 ? _a : COLORS[index % COLORS.length] }, "cell-".concat(index)));
                        }) })), _jsx(Tooltip, { formatter: function (l) { var _a; return l.toString() + ((typeof ((_a = props.data.meta) === null || _a === void 0 ? void 0 : _a.unit) === "string") ? props.data.meta.unit : ""); } }), _jsx(Legend, { layout: "vertical", verticalAlign: "middle", align: "left" })] })), _jsx("p", __assign({ style: { color: "#646464", marginTop: -40, marginLeft: 8 } }, { children: ((typeof ((_a = props.data.meta) === null || _a === void 0 ? void 0 : _a.unit) === "string") ? "in " + props.data.meta.unit : "") }))] }));
};
export default StatsPieChart;
