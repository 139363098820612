var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, InfoSignIcon, Table, Tooltip } from "evergreen-ui";
import moment from "moment-timezone";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import useDrivers from "./utils/use-drivers";
export var DocumentationTableItem = function (_a) {
    var _b, _c;
    var tour = _a.tour;
    var history = useHistory();
    var driversMap = useDrivers().driversMap;
    var driverName = useMemo(function () {
        var _a, _b;
        return ((_a = driversMap[tour.driver]) === null || _a === void 0 ? void 0 : _a.firstName) +
            " " +
            ((_b = driversMap[tour.driver]) === null || _b === void 0 ? void 0 : _b.lastName);
    }, [driversMap, tour.driver]);
    var tourDate = useMemo(function () { return moment(tour.date, "YY-MM-DD").format("DD.MM.YYYY"); }, [tour.date]);
    var startTime = useMemo(function () {
        return tour.actualStartTime
            ? moment(tour.actualStartTime, "HH:mm")
                .add(moment(tour.actualStartTime, "HH:mm").isDST() ? 2 : 1, "hours")
                .format("HH:mm") + " Uhr"
            : "__:__ Uhr";
    }, [tour.actualStartTime]);
    var endTime = useMemo(function () {
        return tour.actualEndTime
            ? moment(tour.actualEndTime, "HH:mm")
                .add(moment(tour.actualEndTime, "HH:mm").isDST() ? 2 : 1, "hours")
                .format("HH:mm") + " Uhr"
            : "__:__ Uhr";
    }, [tour.actualEndTime]);
    return (_jsxs(Table.Row, __assign({ onClick: function () { return history.push("/tour-details/" + tour.id); }, isSelectable: true }, { children: [_jsx(Table.TextCell, { children: tourDate }), _jsx(Table.TextCell, { children: (tour === null || tour === void 0 ? void 0 : tour.withTracker) ? (
                // @ts-ignore
                _jsx(Tooltip, __assign({ content: "Temperatur Tracking" }, { children: _jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", className: "icon icon-tabler icon-tabler-temperature", width: "24", height: "24", viewBox: "0 0 24 24", "stroke-width": "2", stroke: "currentColor", fill: "none", strokeLinecap: "round", strokeLinejoin: "round" }, { children: [_jsx("path", { stroke: "none", d: "M0 0h24v24H0z", fill: "none" }), _jsx("path", { d: "M10 13.5a4 4 0 1 0 4 0v-8.5a2 2 0 0 0 -4 0v8.5" }), _jsx("path", { d: "M10 9l4 0" })] })) }))) : null }), _jsx(Table.TextCell, { children: driverName }), _jsx(Table.TextCell, { children: startTime }), _jsx(Table.TextCell, { children: endTime }), _jsx(Table.TextCell, { children: ((_b = tour.successfullTasks) === null || _b === void 0 ? void 0 : _b.length) + "/" + ((_c = tour.declinedTasks) === null || _c === void 0 ? void 0 : _c.length) }), _jsx(Table.TextCell, { children: _jsx(IconButton, { icon: InfoSignIcon }) }), _jsx(Table.TextCell, {})] }), tour.id));
};
