var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export default function combineActions(stopsOfTour) {
    var merge = false;
    var containerIndex = 0;
    var combinedStops = [];
    stopsOfTour.forEach(function (entry, index) {
        var _a, _b, _c, _d;
        var nextEntry = stopsOfTour[index + 1];
        var prevEntry = stopsOfTour[index - 1];
        if (entry.type === "action") {
            if (entry.data.type !== "start" &&
                entry.data.type !== "end" &&
                entry.data.address.lat === ((_b = (_a = nextEntry === null || nextEntry === void 0 ? void 0 : nextEntry.data) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.lat)) {
                if (!merge) {
                    entry.data.ids = [entry.data.id];
                    containerIndex = combinedStops.length;
                    combinedStops.push(entry);
                }
                else {
                    if (entry.data.type === combinedStops[containerIndex].data.type) {
                        combinedStops[containerIndex].data.ids.push(entry.data.id);
                    }
                    else {
                        entry = __assign(__assign({}, entry), { data: __assign(__assign({}, entry.data), { ids: [entry.data.id] }) });
                        combinedStops.push(entry);
                        merge = false;
                        return;
                    }
                }
                merge = true;
            }
            else {
                if (merge) {
                    combinedStops[containerIndex].data.ids.push(entry.data.id);
                    merge = false;
                }
                else {
                    if (entry.data.type === "pickupShipment" ||
                        entry.data.type === "deliverShipment") {
                        if (entry.data.address.lat === ((_d = (_c = prevEntry === null || prevEntry === void 0 ? void 0 : prevEntry.data) === null || _c === void 0 ? void 0 : _c.address) === null || _d === void 0 ? void 0 : _d.lat)) {
                            combinedStops[combinedStops.length - 1].data.ids.push(entry.data.id);
                        }
                        else {
                            entry = __assign(__assign({}, entry), { data: __assign(__assign({}, entry.data), { ids: [entry.data.id] }) });
                            combinedStops.push(entry);
                        }
                    }
                    else {
                        entry = __assign(__assign({}, entry), { data: __assign(__assign({}, entry.data), { ids: [entry.data.id] }) });
                        combinedStops.push(entry);
                    }
                }
            }
        }
        else {
            combinedStops.push(entry);
        }
    });
    return combinedStops;
}
