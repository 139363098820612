var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
//Determens weather the Layout of the app is in Editmode or not
//Editmode -> Bigger Left colum with more details -> Smaller Right colum
// @ts-nocheck
import { createContext, useState } from "react";
export var LayoutContext = createContext({ state: [false, function () { }] });
export default function LayoutContextProvider(props) {
    //Stores the Layout-Type
    var _a = useState(false), isEditMode = _a[0], setEditMode = _a[1];
    return (_jsx(LayoutContext.Provider, __assign({ value: { state: [isEditMode, setEditMode] } }, { children: props.children })));
}
